import './index.styl'
import { ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'
import capitalize from 'lodash/capitalize'

type Props = {
    as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span' | 'header'
    variant?: 'withDash'
    variantAlign?: 'center' | 'left'
    dashColor?: 'blue' | 'green'
    children: ReactNode
    className?: Argument
}

export const Title = ({
    as = 'span',
    className: _className,
    children,
    variant,
    variantAlign = 'center',
    dashColor = 'blue',
}: Props): ReactElement => {
    const TagName = as
    const className = classNames(
        'Title',
        {
            [`Title--${variant}`]: variant,
            [`Title--${variantAlign}`]: variantAlign,
            [`Title--dashColor${capitalize(dashColor)}`]: dashColor,
        },
        _className,
    )
    return <TagName className={className}>{children}</TagName>
}
