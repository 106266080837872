import './index.styl'
import { ReactElement } from 'react'
import { Image, ImageProps } from '../Image'
import { VisualIcon, VisualIconProps } from '../VisualIcon'
import { ToggleOpenerGeneral, ToggleOpenerGeneralProps } from '../ToggleOpenerGeneral'
import { CountryCode } from '../AtrMileageTable/countryCodes'
import { FlagImage } from '../Flag'
import { Info } from '../Info'

export type ToggleOpenerGeneralSimpleProps = Omit<ToggleOpenerGeneralProps, 'children'> & {
    image?: ImageProps
    visualIcon?: VisualIconProps
    titleLeft?: string
    titleRight?: string
    countryCode?: CountryCode
    showRightChecked?: boolean
    showAlertTitleRight?: boolean
}

export const ToggleOpenerGeneralSimple = ({
    image,
    visualIcon,
    titleLeft,
    titleRight,
    countryCode,
    showRightChecked,
    showAlertTitleRight,
    ...props
}: ToggleOpenerGeneralSimpleProps): ReactElement => {
    const titleRightText = () => {
        if (showAlertTitleRight) {
            return (
                <Info variant="alert" withIcon={true}>
                    {titleRight}
                </Info>
            )
        }

        return <>{titleRight} </>
    }

    return (
        <ToggleOpenerGeneral
            className="ToggleOpenerGeneralSimple"
            {...props}
            showArrowDown={!(showRightChecked ?? false)}
        >
            {(countryCode?.length ?? 0) > 0 && (
                <div className="ToggleOpenerGeneralSimple-visual">
                    <div className="Image">
                        <FlagImage
                            className="TogglerFlag"
                            countryCode={countryCode!}
                            alt={countryCode!.toString()}
                        />
                    </div>
                </div>
            )}
            {(image || visualIcon) && (
                <div className="ToggleOpenerGeneralSimple-visual">
                    {image && <Image {...image} />}
                    {visualIcon && (
                        <VisualIcon className="ToggleOpenerGeneralSimple-image" {...visualIcon} />
                    )}
                </div>
            )}
            {titleLeft && <span className="ToggleOpenerGeneralSimple-titleLeft">{titleLeft}</span>}
            {titleRight &&
                (!(showRightChecked ?? false) ? (
                    <span className="ToggleOpenerGeneralSimple-titleRight">{titleRightText()}</span>
                ) : (
                    <>
                        <span className="ToggleOpenerGeneralSimple-titleRight d-flex">
                            {titleRightText()}
                        </span>
                        <span className="Check Check--standalone ml-64"></span>
                    </>
                ))}
        </ToggleOpenerGeneral>
    )
}
