import React from 'react'
import { SignalRContext } from './SignalRContext'
import SignalRSetting, { SignalRReturns, SignalRSettings } from './SignalRSetting'

interface props {
    children: React.ReactNode | React.ReactNode[]
    urls: string[]
}

//urls se udava v endpoint|actionMethoda...napr:hubs/dashboard|ClientReceivedDashboardMessage
export const SignalRProvider = ({ children, urls }: props) => {
    const currentSett = {
        settings: new Map<string, SignalRReturns>(),
    } as SignalRSettings

    for (let i = 0; i < urls.length; i++)
        currentSett.settings?.set(urls[i], SignalRSetting({ url: urls[i] }))

    return <SignalRContext.Provider value={currentSett}>{children}</SignalRContext.Provider>
}
