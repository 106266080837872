import './index.styl'
import { ReactElement } from 'react'
import classNames, { Argument } from 'classnames'

export type LogoProps = {
    variant?: 'small' | 'medium'
    src: string
    alt?: string
    className?: Argument
    href?: string
}

export const Logo = ({
    src,
    alt,
    variant,
    className: _className,
    href,
}: LogoProps): ReactElement => {
    const className = classNames(
        'Logo',
        {
            [`Logo--${variant}`]: variant,
        },
        _className,
    )
    return (
        <div className={className}>
            {href && (
                <a href={href} target="_blank" rel="noreferrer">
                    <img src={src} alt={alt} />
                </a>
            )}
            {!href && <img src={src} alt={alt} />}
        </div>
    )
}
