import { useQuery } from 'react-query'
import { ReferenceType } from '../enums/ReferenceType'
import { HistoryItem } from '../models/history'
import { PayedStateModel } from '../models/payedResponseModel'
import http from './http-common'

export const isPayed = (
    id: string,
    connectionId?: string,
    referenceType?: ReferenceType,
    utm_content?: string,
    utm_campaign?: string,
) => {
    let retVal: PayedStateModel | undefined = undefined

    const { data, error, isError, refetch, isFetched } = useQuery(
        'isPayed',
        () =>
            http.get<PayedStateModel>(
                `/${
                    referenceType == ReferenceType.SmartCode ? 'SmartCode' : 'Payed'
                }/IsPayed?requestId=${id}&clientConnectionId=${connectionId}
                ${
                    referenceType == ReferenceType.SmartCode
                        ? '&utm_content=' + utm_content + '&utm_campaign=' + utm_campaign
                        : ''
                }`,
            ),
        {
            enabled: (id?.length ?? 0) > 0 && (connectionId?.length ?? 0) > 0,
        },
    )

    if (!isError) {
        //zaplaceno
        retVal = data?.data
    }

    return { retVal: retVal, refetch: refetch, isError: isError, isFetched: isFetched }
}

export const getHistory = (vin: string, viewId: string) => {
    let retVal: HistoryItem[] | undefined
    const { data, error, isError, refetch, isLoading } = useQuery(
        'getHistory',
        () => http.get<HistoryItem[]>('/Payed/GetHistory?vin=' + vin + '&requestId=' + viewId),
        {
            enabled: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            cacheTime: 86400000, //den
        },
    )

    if (!isError) {
        retVal = data?.data
    }

    return {
        history: retVal,
        historyRefetch: refetch,
        historyIsLoading: isLoading,
        historyError: isError,
    }
}
