import http from './http-common'
import { useQuery } from 'react-query'
import { PayedStateModel } from '../models/payedResponseModel'
import { CebiaReportEsa } from '../models/payedRegResponseModel'
import { RequestOptionsModel } from '../models/requestOptionsModel'

export const getInfo = (id: string, connectionId: string) => {
    let retVal: PayedStateModel | undefined = undefined

    const { data, error, isError, refetch, isLoading } = useQuery(
        'getInfo',
        () =>
            http.get<PayedStateModel>(
                `/PayedReg/GetInfo?requestId=${id}&clientConnectionId=${connectionId}`,
            ),
        {
            enabled: (id?.length ?? 0) > 0 && (connectionId.length ?? 0) > 0,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    )

    if (!isError) {
        retVal = data?.data
    }

    return { retVal: retVal, refetch: refetch, isLoading: isLoading, data: data?.data }
}

export const postCebiaReportEsa = async (model: CebiaReportEsa) => {
    let retVal: boolean | null = null
    if (model) {
        const tmpResponse = await http.post('/PayedReg/PostCebiaReportEsa', model)

        if (tmpResponse.status === 200) {
            retVal = true
        } else {
            retVal = false
        }
    }
    return retVal
}

export const getActivateCoupon = async (requestId: string) => {
    if (requestId) {
        const tmpResponse = await http.get('/PayedReg/ActivateCoupon?requestId=' + requestId)

        if (tmpResponse.status === 200) {
            return true
        }
    }
    return false
}

export const getMDCRProtocol = (protocolNumber: string) => {
    return useQuery(
        ['getMDCRProtocol', protocolNumber],
        () => {
            return http
                .get(`/payed/GetMDCRProtocol?protocolNumber=${protocolNumber}`)
                .catch(error => {})
        },
        {
            enabled: false,
            onError: error => {},
        },
    )
}

export const getDotazOptions = (requestId: string) => {
    return useQuery(
        ['getDotazOptions', requestId],
        () => {
            return http
                .get<RequestOptionsModel>(`/payed/GetDotazOptions?requestId=${requestId}`)
                .catch(error => {})
        },
        {
            enabled: false,
            onError: error => {},
        },
    )
}
