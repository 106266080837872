import numbro from 'numbro'
import cultureCZ from 'numbro/languages/cs-CZ'

numbro.registerLanguage(cultureCZ)
numbro.setLanguage(document.documentElement.getAttribute('lang') === 'cs' ? 'cs-CZ' : 'en-US')

export function limit(value: number, min: number, max: number): number {
    return Math.min(max, Math.max(min, value))
}

export function format(value: unknown): string {
    return numbro(value).format({
        thousandSeparated: true,
        spaceSeparated: true,
    })
}

export function toStringWithTextUnit(value: number, unit = 'auto'): string {
    if (value > 1000000000 || unit === 'mld') {
        value /= 1000000000
        return `${format(+value.toFixed(1))} mld.`
    }

    if (value > 1000000 || unit === 'mil') {
        value /= 1000000
        return `${format(+value.toFixed(1))} mil.`
    }

    return format(value)
}

export function parse(value: string): number {
    return parseFloat(value.replace(/\s/gi, '').replace(',', '.'))
}

export function parseInteger(value: string): number {
    return parseInt(value.replace(/\s/gi, ''))
}

export function formatCurrency(value: unknown): string {
    return numbro(value).formatCurrency({
        mantissa: 0,
        thousandSeparated: true,
        spaceSeparated: true,
    })
}

export function formatPercentage(value: unknown): string {
    return numbro(value).format({
        output: 'percent',
        mantissa: 2,
        spaceSeparated: true,
    })
}
