import Highcharts from 'highcharts'

export const createGradient = (color: string): Highcharts.GradientColorObject => {
    return {
        linearGradient: {
            x1: 0,
            x2: 0.5,
            y1: 0,
            y2: 1,
        },
        stops: [
            [0, Highcharts.color(color).setOpacity(0.8).get('rgba').toString()],
            [1, Highcharts.color(color).setOpacity(0).get('rgba').toString()],
        ],
    }
}
