import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionVehicleColor } from '../../../../detailPaid/atr-items/SectionVehicleInfo/Section/vehicleColor'
import './body.styl'

let PrintAllowIncrement = true

export const CarColor = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    if (!sourceData?.vehicleInfoControlResult) return <></>

    const carColorData = new PayedJsonResult()
    carColorData.vehicleInfoControlResult = sourceData.vehicleInfoControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola barvy vozidla')}
            </h2>

            {(sourceData?.vehicleInfoControlResult?.vehicleColor?.length ?? 0) > 0 && (
                <Section className="CarColorSection">
                    <SectionVehicleColor
                        sourceData={carColorData.vehicleInfoControlResult}
                        tran={tran}
                    />
                </Section>
            )}
            {(sourceData?.vehicleInfoControlResult?.vehicleColor?.length ?? 0) <= 0 && (
                <p>
                    {tran.GetText(
                        'Nebyly nalezeny záznamy o barvě vozidla evidované výrobcem vozidla či při první registraci vozidla v ČR.',
                    )}
                </p>
            )}
        </div>
    )
}
