import './index.styl'
import { ReactElement } from 'react'
import { Table } from '../../../../components/Table'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate, _ } from '../../../../translations/CebiaTranslator'
import { CountryCode } from '../../../../components/AtrMileageTable/countryCodes'
import { TextUtils } from '../../../../utils/textUtils'
import { ToggleOpenerGeneralSimple } from '../../../../components/ToggleOpenerGeneralSimple'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import Moment from 'react-moment'
import moment from 'moment'

export const SectionLienRights = ({ sourceData, isPrint }: DetailPayedProps): ReactElement => {
    if (!sourceData?.lienRightsControlResult) return <></>
    const tran = new Translate()
    if (sourceData?.lienRightsControlResult.unavailable)
        return (
            <div className="sectionLienRights">
                <div className="text-center pb-25">
                    <p className="pt-15 smallInfoText">
                        {tran.GetText(
                            'Kontrola zástavních práv není momentálně k dispozici. Zkuste znovu načíst stránku po několika minutách.',
                        )}
                    </p>
                </div>
            </div>
        )

    const utils = new TextUtils()
    const hasRights = sourceData?.lienRightsControlResult.lienRightsItems?.length ?? 0 > 0
    if (isPrint)
        return (
            <div className="pbAllways">
                <div className="text-center pb-25">
                    {!isPrint && <span>{tran.GetText('Výsledek kontroly')}</span>}
                    {hasRights ? (
                        <>
                            <h2 className="warning font-bold">
                                {tran.GetText('Nalezeno zástavní právo')}
                            </h2>
                            <p className="pt-15 smallInfoText text-justify">
                                {tran.GetText(
                                    'Vozidlo je předmětem jednoho nebo více zástavních práv v uvedených státech a existuje možnost jeho zabavení v případném exekučním řízení osoby uvedené v zástavním rejstříku. Nákup vozidla se zástavním právem může být velmi rizikové. Data získáváme z oficiálních registrů zástav dané země.',
                                )}
                            </p>
                        </>
                    ) : (
                        <>
                            <h2 className="noLineRightsText font-bold color-green">
                                {tran.GetText('Zástavní právo nebylo nalezené')}
                            </h2>
                            <p className="pt-15 smallInfoText text-justify">
                                {tran.GetText(
                                    'Výsledkem dotazu je zjištění, zda vozidlo není předmětem zástavního práva v uvedených státech a nehrozí tedy jeho zabavení v případném exekučním řízení současného majitele. Data získáváme z oficiálních registrů zástav dané země.',
                                )}
                            </p>
                        </>
                    )}
                </div>
                <div className="Table text-noWrap mb-20 md:mb-50 half">
                    <table className="Table-table">
                        <thead>
                            <tr>
                                <th className="text-left">{tran.GetText('Země')}</th>
                                <th className="text-left">{`${tran.GetText('Ke dni:')} ${moment(
                                    sourceData?.lienRightsControlResult.datum,
                                ).format('DD. MM. YYYY')}`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={hasRights ? 'warning' : ''}>
                                    {tran.GetText('Slovensko')}
                                </td>
                                <td className={hasRights ? 'warning' : ''}>
                                    {hasRights
                                        ? tran.GetText('ANO')
                                        : tran.GetText('NE - není evidováno')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {hasRights ? (
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-left">{tran.GetText('Spisová značka')}</th>
                                <th className="text-left">
                                    <Moment format="DD.MM.YYYY">
                                        {sourceData?.lienRightsControlResult.datum}
                                    </Moment>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sourceData?.lienRightsControlResult.lienRightsItems?.map(
                                (lienRightItem, lienRightItemIndex) => (
                                    <tr key={lienRightItemIndex}>
                                        <td>{lienRightItem.number}</td>
                                        <td>
                                            <a href={lienRightItem.url} target="blank">
                                                {tran.GetText('Více informací')}
                                            </a>
                                        </td>
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </Table>
                ) : (
                    <></>
                )}
            </div>
        )
    return (
        <div className="sectionLienRights">
            <div className="text-center pb-25">
                {!isPrint && <span>{tran.GetText('Výsledek kontroly')}</span>}
                {hasRights ? (
                    <>
                        <h2 className="warning font-bold">
                            {tran.GetText('Nalezeno zástavní právo')}
                        </h2>
                        <p className="pt-15 smallInfoText text-justify">
                            {tran.GetText(
                                'Vozidlo je předmětem jednoho nebo více zástavních práv v uvedených státech a existuje možnost jeho zabavení v případném exekučním řízení osoby uvedené v zástavním rejstříku. Nákup vozidla se zástavním právem může být velmi rizikové. Data získáváme z oficiálních registrů zástav dané země.',
                            )}
                        </p>
                    </>
                ) : (
                    <>
                        <h2 className="noLineRightsText font-bold color-green">
                            {tran.GetText('Zástavní právo nebylo nalezené')}
                        </h2>
                        <p className="pt-15 smallInfoText text-justify">
                            {tran.GetText(
                                'Výsledkem dotazu je zjištění, zda vozidlo není předmětem zástavního práva v uvedených státech a nehrozí tedy jeho zabavení v případném exekučním řízení současného majitele. Data získáváme z oficiálních registrů zástav dané země.',
                            )}
                        </p>
                    </>
                )}
            </div>
            <ToggleOpenerGeneralSimple
                href={`#toglelienRights`}
                countryCode={'sk' as CountryCode}
                titleLeft={tran.GetText('Slovensko')}
                titleRight={
                    hasRights
                        ? utils.ZaznamyText(
                              sourceData?.lienRightsControlResult.lienRightsItems?.length ?? 0,
                          )
                        : tran.GetText('Bez záznamů ')
                }
                isStatic={hasRights ? false : true}
                isOpened={false}
            />
            {hasRights ? (
                <ToggleItemGeneral id={`toglelienRights`}>
                    <div className="mb-30">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-left">{tran.GetText('Spisová značka')}</th>
                                    <th className="text-left">
                                        <Moment format="DD.MM.YYYY">
                                            {sourceData?.lienRightsControlResult.datum}
                                        </Moment>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sourceData?.lienRightsControlResult.lienRightsItems?.map(
                                    (lienRightItem, lienRightItemIndex) => (
                                        <tr key={lienRightItemIndex}>
                                            <td>{lienRightItem.number}</td>
                                            <td>
                                                <a href={lienRightItem.url} target="blank">
                                                    {tran.GetText('Více informací')}
                                                </a>
                                            </td>
                                        </tr>
                                    ),
                                )}
                            </tbody>
                        </Table>
                    </div>
                </ToggleItemGeneral>
            ) : (
                <></>
            )}
        </div>
    )
}
