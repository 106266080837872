import classNames from 'classnames'
import { ReactElement } from 'react'
import Moment from 'react-moment'
import {
    TimeLineItem,
    TimeLineRegCzSk,
} from '../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { Translate } from '../../translations/CebiaTranslator'
import { navigateToFirstElement } from '../AtrTimeLineTable'
import { CountryCode } from '../AtrMileageTable/countryCodes'
import { getCountries } from '../../busLog/enums_gen'

type Props = {
    row: TimeLineItem
    isPrint: boolean
}
export const TimeLineRegCzSkTable = ({ row, isPrint }: Props): ReactElement => {
    const item = row.timeLineObject as TimeLineRegCzSk
    const tran = new Translate()
    const countries = getCountries(tran)
    const tableClasses = classNames('Table', { ['noBackground line2rem']: isPrint })
    return (
        <>
            <div className={tableClasses}>
                <table>
                    <tbody>
                        {(item.firstRegCz || item.firstRegSk) && (
                            <tr>
                                <td>{tran.GetText('Datum přihlášení vozidla')}:</td>
                                <td>
                                    <Moment format="DD. MM. YYYY">
                                        {item.firstRegCz ?? item.firstRegSk}
                                    </Moment>
                                </td>
                            </tr>
                        )}
                        {item.firstReg && (
                            <tr>
                                <td>
                                    {tran.GetText('Datum 1. registrace uvedené při přihlášení')}:
                                </td>
                                <td>
                                    <Moment format="DD. MM. YYYY">{item.firstReg}</Moment>
                                </td>
                            </tr>
                        )}
                        {item.fromManifacture?.length > 0 && item.wasNew && (
                            <tr>
                                <td className={item.showError ? 'warning normal' : ''}>
                                    {tran.GetText('Doba od výroby po 1. přihlášení')}:
                                </td>
                                <td className={item.showError ? 'warning normal' : ''}>
                                    {item.fromManifacture}
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td>{tran.GetText('Přihlášeno jako')}:</td>
                            <td className="firstCapitalize">
                                <span className="firstCapitalize">
                                    {item.wasNew && tran.GetText('nové')}
                                    {!item.wasNew && tran.GetText('ojeté')}
                                </span>
                            </td>
                        </tr>
                        {item.color && (
                            <tr>
                                <td>{tran.GetText('Barva vozu při přihlášení')}:</td>
                                <td>{item.color}</td>
                            </tr>
                        )}

                        {item.showTechDecriptionLink && !isPrint && (
                            <tr>
                                <td>{tran.GetText('Technický popis vozidla')}:</td>
                                <td>
                                    <a
                                        className="pointer"
                                        //href={`#${row.linkValue}`}
                                        onClick={() => {
                                            navigateToFirstElement(row.linkValue, 'a')
                                            setTimeout(() => {
                                                const countryId =
                                                    countries.find(x => x.code2 == row.flagIconName)
                                                        ?.id ?? ''
                                                navigateToFirstElement(
                                                    'vehicleTechDescToggle' + countryId,
                                                    'a',
                                                )
                                            }, 100)
                                        }}
                                    >
                                        {tran.GetText('Zobrazit')}
                                    </a>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}
