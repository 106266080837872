import './index.styl'
import classNames from 'classnames'
import { CSSProperties, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { queries } from '../../utils/queries'

export type NavLayerName = 'about' | 'langswitch' | 'history' | 'service' | 'aboutSk'

export type NavLayerProps = {
    name: NavLayerName
    active: boolean
    opener: HTMLElement | null | undefined
    children: ReactNode
    onEnter: (event: null, navLayer: NavLayerName) => void
    onLeave: () => void
}

export const NavLayer = ({
    name,
    active,
    opener,
    children,
    onEnter,
    onLeave,
}: NavLayerProps): ReactElement => {
    const isMediumUp = useMediaQuery(queries.mediumUp)
    const [style, setStyle] = useState<CSSProperties>()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!(ref && ref.current && opener)) {
            return
        }

        const openerRect = opener.getBoundingClientRect()
        const navLayerRect = ref.current.getBoundingClientRect()

        let left =
            openerRect.left + openerRect.width / 2 - navLayerRect.left - navLayerRect.width / 2

        const offset = 20
        const overflowDiff =
            navLayerRect.left + left + navLayerRect.width - window.innerWidth + offset
        if (overflowDiff > 0) {
            left -= overflowDiff
        }

        const currentLeft = style?.left ? parseInt(style.left.toString()) : 0
        setStyle(() => ({
            left: left + currentLeft,
        }))
    }, [opener])

    const className = classNames(`NavLayer`, {
        [`NavLayer--${name}`]: name,
        [`is-active`]: active,
    })

    const handleMouseEnter = () => {
        onEnter(null, name)
    }
    const handleMouseLeave = () => {
        onLeave()
    }
    const handleHeaderClick = () => {
        onLeave()
    }

    return (
        <div
            className={className}
            ref={ref}
            style={style}
            onMouseEnter={isMediumUp ? handleMouseEnter : undefined}
            onMouseLeave={isMediumUp ? handleMouseLeave : undefined}
        >
            <div className="NavLayer-arrow"></div>
            <div className="NavLayer-header" onClick={handleHeaderClick}>
                <span className="NavLayer-title">O nás</span>
            </div>
            <div className="NavLayer-content">{children}</div>
        </div>
    )
}
