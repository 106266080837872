import './index.styl'
import { ReactElement } from 'react'
import { Image, ImageProps } from '../Image'
import classNames from 'classnames'

export type VisualProps = {
    variant?: '620x510' | '620x540' | '390x520' | '595x615'
    clip?: 'bottom' | 'top' | 'none'
    image: ImageProps
}

export const Visual = ({ variant, image, clip = 'bottom' }: VisualProps): ReactElement => {
    const className = classNames('Visual', {
        [`Visual--${variant}`]: variant,
        [`Visual--${clip}`]: clip,
    })
    return (
        <div className={className}>
            <div className="Visual-content">
                <Image {...image} />
                {/* <img
                        src="assets/frontend/img/pages/contact/office.jpg"
                        srcset="assets/frontend/img/pages/contact/office@2x.jpg 2x"
                        alt="Kancelář"> */}
            </div>
        </div>
    )
}
