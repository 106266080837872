import { ReactElement, useEffect, useState } from 'react'
import { Container } from '../../components/Container'
import { FadedBackground } from '../../components/FadedBackground'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { PageHeader } from '../../components/PageHeader'
import { PageShape } from '../../components/PageShape'
import { Section } from '../../components/Section'
import { Shapes } from '../../components/Shapes'
import { ReferenceType } from '../../enums/ReferenceType'
import { Translate } from '../../translations/CebiaTranslator'
import { importScript } from './importScript'

export const PageCookies = (): ReactElement => {
    const tran = new Translate()

    importScript('https://consent.cookiebot.com/72d7730f-bd08-4baa-a8b2-650b340fcb8c/cd.js')

    return (
        <div className="PageCookies">
            <PageShape sectionShape={{ variant: 'radialLeftTop' }} />

            <Header showLanguage={false} referenceType={ReferenceType.Default} />

            <PageHeader>
                <h1 className="Beta color-darkBlue">{tran.GetText('Zásady používání cookies')}</h1>
            </PageHeader>

            <Section variantPend="appended">
                <Container>
                    <Shapes variant="2" />

                    <FadedBackground variant="small" onlyDesktop rounded>
                        <Section>
                            <Container variant="slim">
                                <div id="pageCookiesText"></div>
                            </Container>
                        </Section>
                    </FadedBackground>
                </Container>
            </Section>

            <Footer />
        </div>
    )
}
