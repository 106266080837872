/* eslint-disable */

export function normalize(progValues) {
    const first = progValues[0]
    const last = progValues[progValues.length - 1]

    if (first[0] !== 0) {
        progValues.unshift([0 - (1 - last[0]), last[1]])
    }

    if (last[0] !== 1) {
        progValues.push([1 + first[0], first[1]])
    }

    return progValues
}

export function getValueOfProgress(progress, progValues) {
    const interval = getInterval(progress, progValues)
    return interval.current[1]
}

function getInterval(progress, progValues) {
    let startIndex = 0
    for (let i = 0; i < progValues.length; i++) {
        if (progress < progValues[i][0]) {
            break
        }
        startIndex = i
    }
    const endIndex = progValues.length - 1 === startIndex ? startIndex : startIndex + 1
    const diffProgress =
        progValues[endIndex][0] - progValues[startIndex][0] === 0
            ? 0
            : (progress - progValues[startIndex][0]) /
              (progValues[endIndex][0] - progValues[startIndex][0])

    const diffValue = (progValues[endIndex][1] - progValues[startIndex][1]) * diffProgress
    return {
        start: progValues[startIndex],
        end: progValues[endIndex],
        diff: [diffProgress, diffValue],
        current: [
            progValues[startIndex][0] + diffProgress,
            round(progValues[startIndex][1] + diffValue),
        ],
    }
}

function round(number) {
    return Math.round((number + Number.EPSILON) * 10000) / 10000
}
