import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionDamage } from '../../../../detailPaid/atr-items/SectionDamages'
import './body.styl'

let PrintAllowIncrement = true

export const Damages = ({
    sourceData,
    tran,
    captionNumberRef,
    countryId,
}: PrintDataProps): ReactElement => {
    // if (!sourceData?.damageControlResult) return <></>
    const damagesData = new PayedJsonResult()
    damagesData.damageControlResult = sourceData!.damageControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola poškození')}
            </h2>

            <SectionDamage sourceData={damagesData} isPrint={true} countryId={countryId} />
        </div>
    )
}
