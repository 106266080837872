import './index.styl'
import moment from 'moment'
import { ReactElement } from 'react'
import { Table } from '../../../../components/Table'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate, _ } from '../../../../translations/CebiaTranslator'
import { Flag } from '../../../../components/Flag'
import { CountryCode } from '../../../../components/AtrMileageTable/countryCodes'

export const SectionTheft = ({ sourceData, isPrint }: DetailPayedProps): ReactElement => {
    if (!sourceData?.theftControlResult) return <></>

    const tran = new Translate()
    const privateItem = sourceData.theftControlResult.theftItems?.filter(x => x.isPrivateSource)

    return (
        <div className="sectionTheft">
            <div className="pb-25 text-center">
                {!isPrint && <span>{tran.GetText('Výsledek kontroly')}</span>}
                {sourceData?.theftControlResult?.isTheft ?? false ? (
                    <h2 className="warning font-bold">
                        {tran.GetText('Vozidlo je evidováno jako odcizené')}
                    </h2>
                ) : (
                    <>
                        <h2 className="noTheftText font-bold">
                            {tran.GetText('Není evidováno jako odcizené')}
                        </h2>
                        <h2>{tran.GetText('v aktuálně dostupných databázích')}</h2>
                    </>
                )}
                <p className="pt-15 smallInfoText text-justify">
                    {tran.GetText(
                        'Sdělené údaje mají pouze informativní charakter. Cebia neodpovídá za škody vzniklé v souvislosti s využitím sdělených dat.',
                    )}
                </p>
            </div>

            <div className="mb-30">
                <Table>
                    <thead>
                        <tr>
                            <th className="text-left">{tran.GetText('Zdroj')}</th>
                            <th className="text-left">
                                {sourceData.theftControlResult?.couponDateActivation
                                    ? `${tran.GetText('Ke dni')} ${moment(
                                          sourceData.theftControlResult?.couponDateActivation,
                                      ).format('DD. MM. YYYY')}`
                                    : ''}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(sourceData.theftControlResult.theftItems?.length ?? 0) > 0 &&
                            sourceData.theftControlResult.theftItems
                                ?.filter(x => !x.isPrivateSource)
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td
                                            className={
                                                item.isTheft
                                                    ? 'warning d-flex align-center text-noWrap'
                                                    : 'd-flex align-center text-noWrap'
                                            }
                                        >
                                            <Flag
                                                countryCode={item.countryISO as CountryCode}
                                                className="mr-10"
                                            />
                                            <span>{tran.GetText(item.source)}</span>
                                        </td>
                                        <td
                                            className={
                                                item.isTheft ? 'warning text-noWrap' : 'text-noWrap'
                                            }
                                        >{`${item.result} ${
                                            item.isTheft && item.created
                                                ? moment(item.created).format('DD. MM. YYYY')
                                                : ''
                                        }`}</td>
                                    </tr>
                                ))}
                        {privateItem && (
                            <tr>
                                <td
                                    className={
                                        privateItem[0].isTheft
                                            ? 'warning d-flex align-center text-noWrap'
                                            : 'd-flex align-center text-noWrap'
                                    }
                                >
                                    <Flag
                                        countryCode={privateItem[0].countryISO as CountryCode}
                                        className="mr-10"
                                    />
                                    <span>{tran.GetText(privateItem[0].source)}</span>
                                </td>
                                <td
                                    className={
                                        privateItem[0].isTheft
                                            ? 'warning text-noWrap'
                                            : 'text-noWrap'
                                    }
                                >
                                    {`${tran.GetText(privateItem[0].result)} ${
                                        privateItem[0].isTheft
                                            ? moment(privateItem[0].created).format('DD. MM. YYYY')
                                            : ''
                                    }`}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <div className="pt-15 text-justify">
                <p>
                    {tran.GetHtml(
                        '* Soukromé databáze umožňují <strong>zpravidla</strong> komukoliv vložit informace o odcizení.',
                    )}
                </p>
            </div>
        </div>
    )
}
