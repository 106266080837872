import { PhotosDescriptionItem } from '../../../../../models/DetailPayedResults/Photos/photosControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'
import { SectionAdvertToggle } from './advertToggleSection'

export type Props = {
    sourceData?: PhotosDescriptionItem[]
    tran: Translate
}

export const SectionAdvertsOther = ({ sourceData, tran }: Props) => {
    if ((sourceData?.length ?? 0) <= 0) return <></>

    return (
        <>
            <div className="sectionAdvertsOther">
                <p>
                    {tran.GetText(
                        'Zobrazíme vám inzeráty obdobných vozidel, která jsou aktuálně nabízena na trhu, a v některých případech také ceny skutečně prodaných obdobných vozidel (pomocí VIN porovnáváme přesnou specifikaci vozidel). Pomocí inzerce podobných vozidel si můžete porovnat, zda cena ověřovaného vozidla odpovídá podobným vozidlům na trhu. Ušetříme vám spoustu času stráveného vyhledáváním a porovnáváním podobných inzerátů na webu.',
                    )}
                </p>
                <div className="Grid gmb-5">
                    <SectionAdvertToggle
                        advertTypeName="iva"
                        sourceData={sourceData?.filter(x => x.advertMatchType == 3)}
                        tran={tran}
                    />
                </div>
                <h2 className="pd40Title">{tran.GetText('Inzerce vozidel s menší shodou')}</h2>
                <div className="Grid gmb-5">
                    <SectionAdvertToggle
                        advertTypeName="model"
                        sourceData={sourceData?.filter(x => x.advertMatchType == 4)}
                        tran={tran}
                    />
                </div>
            </div>
        </>
    )
}
