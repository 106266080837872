type Viewport = 'small' | 'smallWide' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'
type Viewports = Record<Viewport, [number, number?]>
type Queries = Record<string, string>

const viewports: Viewports = {
    small: [320, 539],
    smallWide: [540, 767],
    medium: [768, 1023],
    large: [1024, 1199],
    xlarge: [1200, 1399],
    xxlarge: [1400, 1919],
    xxxlarge: [1920],
}

export const queries = Object.keys(viewports).reduce<Queries>((queries, key) => {
    const viewport = viewports[key as Viewport].map(value => (value ? value / 16 : 0))

    queries[`${key}Up`] = `screen and (min-width: ${viewport[0]}em)`

    if (viewport.length === 2) {
        queries[
            `${key}Only`
        ] = `screen and (min-width: ${viewport[0]}em) and (max-width: ${viewport[1]}em)`
        queries[`${key}Max`] = `screen and (max-width: ${viewport[1]}em)`
    }

    return queries
}, {})
