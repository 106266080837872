import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Visual } from '../../../../components/Visual'
import AssetsImgCebiaCallOperator from './img/cebia-calloperator.jpg'
import AssetsImgCebiaCallOperator2x from './img/cebia-calloperator@2x.jpg'
import { Title } from '../../../../components/Title'
import { SectionShape } from '../../../../components/SectionShape'
import { Button } from '../../../../components/Button'
import { Translate } from '../../../../translations/CebiaTranslator'
import { useModal } from '../../../../components/Modal'
import { ContactForm } from '../../../faq/ContactForm'

export const SectionAboutComplaints = (): ReactElement => {
    const tran = new Translate()
    const { open } = useModal('contactFormModal')
    return (
        <Section className="SectionAboutComplaints">
            <Container variant="wide" className="smwMax:hidden">
                <SectionShape variant="rightMiddle" />
            </Container>
            <Container>
                <div className="SectionAboutComplaints-body">
                    <div className="SectionAboutComplaints-visual">
                        <Visual
                            clip="top"
                            variant="390x520"
                            image={{
                                src: AssetsImgCebiaCallOperator,
                                srcSet: `${AssetsImgCebiaCallOperator2x} 2x`,
                                alt: tran.GetText('Recepce Cebia'),
                            }}
                        />
                    </div>
                    <div className="SectionAboutComplaints-content">
                        <Title
                            className="Beta color-darkBlue mb-20"
                            as="h2"
                            variantAlign="left"
                            variant="withDash"
                            dashColor="green"
                        >
                            {tran.GetText('Reklamace a stížnosti')}
                        </Title>
                        <div className="Text">
                            <p>
                                {tran.GetHtml(
                                    'Reklamace a stížnosti jsou pro nás cenným zdrojem podnětů pro zlepšení našich služeb. Kontaktujte nás prosím pomocí kontaktního formuláře nebo na: <a href="mailto:autotracer@cebia.com">autotracer@cebia.com</a>',
                                )}
                            </p>
                            <p>
                                {tran.GetText(
                                    'Reklamaci vyřídíme nejpozději do 14 dní od jejího obdržení a o jejím výsledku vás budeme informovat.',
                                )}
                            </p>
                            <div className="text-center md:text-left">
                                <Button as="button" variantColor="lightBlue" onClick={() => open()}>
                                    {tran.GetText('Kontaktní formulář')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    )
}
