import './index.styl'
import { ReactElement } from 'react'
import { SectionShape, SectionShapeProps } from '../SectionShape'
import { Container } from '../Container'

type Props = {
    sectionShape?: SectionShapeProps
}

export const PageShape = ({ sectionShape }: Props): ReactElement => {
    return <Container>{sectionShape && <SectionShape {...sectionShape} />}</Container>
}
