import { ReactElement } from 'react'
import { Translate, _, __ } from '../../translations/CebiaTranslator'
import useScript from '../../hooks/useScript'
import { LanguageDefinition, LanguageEnum, i18n } from '../../translations/i18n'
import { Container } from '../Container'
import { Title } from '../Title'

type GoogleWidgetDashboardProps = {
    showTitle: boolean
}
export const GoogleWidgetDashboard = ({ showTitle }: GoogleWidgetDashboardProps): ReactElement => {
    const tran = new Translate()
    useScript('https://static.elfsight.com/platform/platform.js', [
        'data-use-service-core',
        'defer',
    ])
    const classContainer = showTitle ? 'pt-80 pb-50' : 'pb-50'

    return (
        <Container className={classContainer}>
            <>
                {showTitle && (
                    <Title className="mb-50" as="header" variant="withDash">
                        <h2 className="Beta color-darkBlue">
                            {tran.GetText('Zkušenosti zákazníků')}
                        </h2>
                        <p>{tran.GetText('Zjistěte, co o našem prověření říkají lidé')}</p>
                    </Title>
                )}
                <div
                    className={
                        LanguageDefinition[LanguageEnum[i18n.language as keyof typeof LanguageEnum]]
                            ?.googleWidgetDashboard ?? ''
                    }
                    data-elfsight-app-lazy
                ></div>
            </>
        </Container>
    )
}
