import './index.styl'
import { ReactElement } from 'react'
import { ToggleOpener, ToggleOpenerProps } from '../ToggleOpener'
import classNames from 'classnames'

export type ToggleOpenerGeneralProps = ToggleOpenerProps & {
    button?: string
    showArrowDown?: boolean
    showArrowRight?: boolean
}

export const ToggleOpenerGeneral = ({
    className: _className,
    button,
    children,
    showArrowDown,
    showArrowRight,
    ...props
}: ToggleOpenerGeneralProps): ReactElement => {
    const className = classNames('ToggleOpenerGeneral', _className)

    return (
        <ToggleOpener
            className={`${className} ${showArrowRight ? 'arrowRight' : ''} ${
                showArrowDown ? 'arrowDown' : ''
            }`}
            {...props}
        >
            {children}
            {button && <span className="ToggleOpenerGeneral-button">{button}</span>}
        </ToggleOpener>
    )
}
