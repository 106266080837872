import './index.styl'
import { ReactElement } from 'react'

export type RatingProps = {
    text?: string
    stars?: number
}

export const Rating = ({ text, stars = 5 }: RatingProps): ReactElement => {
    return (
        <div className={`Rating Rating--${stars}`}>
            <span>{text}</span>
        </div>
    )
}
