import React, { ReactElement } from 'react'
import { Title } from '../Title'
import { Translate } from '../../translations/CebiaTranslator'

export const VinModalBlackList = (): ReactElement => {
    const tran = new Translate()
    return (
        <div className="Grid">
            <div className="Grid-cell md:w-12/12">
                <Title as="header">
                    <h2 className="color-darkBlue">
                        {' '}
                        {tran.GetText('Litujeme, vozidlo bohužel nelze prověřit.')}
                    </h2>
                    <p>
                        {tran.GetText(
                            'Stávající majitel vozidla si nepřeje zobrazení údajů o vozidle.',
                        )}
                    </p>
                </Title>
            </div>
        </div>
    )
}
