import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { FormVin } from '../../../../components/FormVin'
import { Poster } from '../../../../components/Poster'
import { StickySwapper } from '../../../../components/StickySwapper'
import { Translate, _, __ } from '../../../../translations/CebiaTranslator'

export const SectionFormVin = (): ReactElement => {
    const tran = new Translate()
    return (
        <Section variant="medium">
            <Container>
                <StickySwapper stickyRange="fromTopToElement" offset={1}>
                    {({ intersectionRef, staticVisible, stickyActive }) => [
                        <Poster
                            key="primary"
                            variant="formVin"
                            variantDisplay="static"
                            customRef={intersectionRef}
                            active={staticVisible}
                        >
                            <div className="Grid justify-center align-center">
                                <div className="Grid-cell text-center Gama mb-30">
                                    {tran.GetText(
                                        'Zjistěte jaké informace jsou dostupné k vašemu vozu:',
                                    )}
                                </div>
                                <div className="Grid-cell md:w-9/12">
                                    <FormVin variant="static" id="" />
                                </div>
                            </div>
                        </Poster>,

                        <Poster
                            key="secondary"
                            variant="formVin"
                            variantDisplay="fixed"
                            active={!staticVisible && stickyActive}
                        >
                            <div className="Grid justify-between align-center gmb-10">
                                <div className="Grid-cell text-center w-full smw:w-4/12 smw:text-left">
                                    <span className="font-xl">
                                        {tran.GetText('Prověřit historii vozidla')}
                                    </span>
                                </div>
                                <div className="Grid-cell w-full smw:w-8/12">
                                    <FormVin variant="fixed" id="" />
                                </div>
                            </div>
                        </Poster>,
                    ]}
                </StickySwapper>
            </Container>
        </Section>
    )
}
