import { ReactElement } from 'react'
import { CouponSalesProps } from '..'
import { czCurrency } from '../../../../..'
import { Table } from '../../../../../components/Table'
import { ToggleItemGeneral } from '../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../../components/ToggleOpenerGeneralVoucher'

export const SectionVinTest = ({ sourceData, tran }: CouponSalesProps): ReactElement => {
    return (
        <>
            <ToggleOpenerGeneralVoucher
                href="#vinTestToggle"
                titleLeft={tran.GetText('Sleva na fyzickou prověrku')}
                titleRight={`${tran.GetText('- 925')} ${tran.GetText(czCurrency)}`}
                button={tran.GetText('Detail')}
                isOpened={false}
            />
            <ToggleItemGeneral id="vinTestToggle">
                <div className="toggleContent">
                    <p className="text-justify">
                        {tran.GetText(
                            'Chcete mít jistotu, že Vámi vybrané vozidlo není nelegálně předělané?  Doporučujeme Vám využít službu komplexního prověření původnosti identifikátorů vozidla VINTEST.',
                        )}
                    </p>
                    <Table className="text-left mb-30">
                        <tbody>
                            <tr>
                                <th className="font-normal">{tran.GetText('Základní cena')}</th>
                                <td className="pl-10 md:pl-80 text-right text-noWrap">
                                    3 950 {tran.GetText(czCurrency)}
                                </td>
                                <td></td>
                            </tr>
                            <tr className="color-green">
                                <th className="">{tran.GetText('SLEVA')}</th>
                                <td className="text-right text-noWrap">
                                    - 925 {tran.GetText(czCurrency)}
                                </td>
                                <td></td>
                            </tr>
                            <tr className="color-darkBlue">
                                <th className="">
                                    <strong>{tran.GetText('Cena po slevě')}</strong>
                                </th>
                                <td className="text-right font-bold text-noWrap">
                                    3 025 {tran.GetText(czCurrency)}
                                </td>
                                <td className="font-bold text-noWrap">{tran.GetText('s DPH')}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className="text-justify">
                        {tran.GetText(
                            'Služba VINTEST poskytuje klientům 100% garanci výsledku fyzického prověření vozidla. Na vozidle provedeme detailní fyzickou expertízu s využitím nejmodernější digitální diagnostiky. Cílem této služby je ověřit, jestli vozidlo má všechny identifikátory (VIN, štítky, svary karoserie) originální a není podezření, že vozidlo, nebo část vozidla pochází z trestné činnosti.',
                        )}
                    </p>
                    <div className="ButtonList mb-10">
                        <a
                            href="https://www.cebia.cz/nase-sluzby/pred-nakupem-vozidla/vintest/popis-sluzeb.html"
                            target="_blank"
                            className="Button Button--blue Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">{tran.GetText('Více info')}</span>
                        </a>
                        <a
                            href="https://www.cebia.cz/common/2014/video-banners/?utm_source=1videobanner2014&utm_medium=1videobanner2014&utm_content=1videobanner2014&utm_campaign=1videobanner2014#treti-dil"
                            target="_blank"
                            className="Button Button--blue Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">{tran.GetText('Video')}</span>
                        </a>
                        <a
                            href="https://www.cebia.cz/nase-sluzby/pred-nakupem-vozidla/vintest/objednavka/objednavka-on-line.html"
                            target="_blank"
                            className="Button Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">{tran.GetText('Objednat')}</span>
                        </a>
                    </div>
                    <h3>{tran.GetText('Postup pro objednání:')}</h3>
                    <p className="text-justify">
                        {tran.GetHtml(
                            'Přes tlačítko Objednat se dostanete na on-line objednávku služby VINTEST. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
                        )}
                    </p>
                </div>
            </ToggleItemGeneral>
        </>
    )
}
