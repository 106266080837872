import './index.styl'
import { ReactElement } from 'react'
import { ToggleOpenerGeneral } from '../ToggleOpenerGeneral'
import { ToggleOpenerGeneralSimpleProps } from '../ToggleOpenerGeneralSimple'
import { Translate } from '../../translations/CebiaTranslator'

type Props = Omit<ToggleOpenerGeneralSimpleProps, 'children'> & {
    preTitleRightCaption?: string
    preTitleRightCaptionType?: 'standard' | 'alert'
    preTitleRightValue?: string
}

export const ToggleOpenerGeneralVoucher = ({
    showRightChecked = false,
    titleLeft = '',
    titleRight = '',
    preTitleRightCaption = '',
    preTitleRightValue = '',
    preTitleRightCaptionType = 'standard',
    ...props
}: Props): ReactElement => {
    const tran = new Translate()
    props = {
        button: tran.GetText('Detail'),
        ...props,
    }

    return (
        <ToggleOpenerGeneral
            className="ToggleOpenerGeneralVoucher"
            {...props}
            showArrowDown={!(showRightChecked ?? false)}
        >
            <span className="ToggleOpenerGeneralVoucher-check">
                <span className="Check Check--standalone"></span>
            </span>
            {titleLeft && <span className="ToggleOpenerGeneralSimple-titleLeft">{titleLeft}</span>}
            {preTitleRightValue && (
                <span className="ToggleOpenerGeneralVoucher-info">
                    {preTitleRightCaption}{' '}
                    {preTitleRightCaptionType === 'alert' ? (
                        <>
                            <span className="color-red font-bold text-noWrap">
                                {preTitleRightValue}
                            </span>
                        </>
                    ) : (
                        <>
                            <span className="color-blue text-noWrap">{preTitleRightValue}</span>
                        </>
                    )}
                </span>
            )}
            {titleRight && (
                <span className="ToggleOpenerGeneralSimple-titleRight text-noWrap">
                    {titleRight}
                </span>
            )}
        </ToggleOpenerGeneral>
    )
}
