import { ReactElement, useEffect, useRef, useState } from 'react'
import { Title } from '../Title'
import { Translate } from '../../translations/CebiaTranslator'
import { Button } from '../Button'
import { ButtonList } from '../ButtonList'
import { link } from 'fs'
import { Input } from '../Input'
import { FormItem } from '../FormItem'
import { Formik } from 'formik'
import { ToggleOpenerGeneralSimple } from '../ToggleOpenerGeneralSimple'
import { ToggleItemGeneral } from '../ToggleItemGeneral'
import i18next from 'i18next'

type Props = {
    couponCode: string
    smartCode: string
    closeModal: () => void
    isActive: boolean
}
export const CouponModalShare = ({
    couponCode,
    smartCode,
    closeModal,
    isActive,
}: Props): ReactElement => {
    const shareLink = `${window.location.origin}/?s_coupon=${couponCode}`
    const smartCodeLength = smartCode.length
    const shareSmartCodeLink = `${window.location.origin}/SmartCode/${smartCode}`
    const handleFocus = (event: any) => event.target.select()
    const [step, setStep] = useState(1)
    const tran = new Translate()
    const [isCopied, setIsCopied] = useState<boolean>()
    const [isCouponCopied, setIsCouponCopied] = useState<boolean>()
    useEffect(() => {
        if (!isActive) {
            setIsCopied(false)
            if (i18next.language == 'cz') {
                setStep(1)
            } else {
                setStep(2)
            }
        }
    }, [isActive])

    useEffect(() => {
        if (smartCodeLength === 0) {
            if (i18next.language == 'cz') {
                setStep(2)
            }
        }
    }, [smartCodeLength])
    return (
        <div className="Grid">
            <div className="Grid-cell md:w-12/12">
                <Title as="header" variantAlign="left">
                    {step === 1 && (
                        <>
                            <h3 className="pb-20 blue">{tran.GetText('Sdílet prověření')}</h3>
                            <p>
                                {tran.GetText(
                                    'Vyberte prosím, kterou verzi prověření chcete sdílet.',
                                )}
                            </p>
                            <a
                                onClick={() => setStep(2)}
                                className="ToggleOpenerGeneral ToggleOpenerGeneralSimple arrowRight arrowDown mb-5"
                            >
                                <span className="ToggleOpenerGeneralSimple-titleLeft">
                                    {tran.GetText('Kompletní prověření')}
                                </span>
                                <span className="ToggleOpenerGeneral-button">
                                    {tran.GetText('Zobrazit')}
                                </span>
                            </a>
                            {smartCodeLength > 0 && (
                                <a
                                    onClick={() => setStep(3)}
                                    className="ToggleOpenerGeneral ToggleOpenerGeneralSimple arrowRight arrowDown"
                                >
                                    <span className="ToggleOpenerGeneralSimple-titleLeft">
                                        {tran.GetText('Základní prověření')}
                                    </span>
                                    <span className="ToggleOpenerGeneralSimple-titleRight">
                                        {tran.GetText('Pro inzerci vozidla při prodeji')}
                                    </span>
                                    <span className="ToggleOpenerGeneral-button">
                                        {tran.GetText('Zobrazit')}
                                    </span>
                                </a>
                            )}
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <h3 className="pb-20 blue">
                                {tran.GetText('Sdílet kompletní prověření')}
                            </h3>
                            <p>
                                {tran.GetText(
                                    'Pro sdílení zkopírujte odkaz níže a zašlete jej komukoliv, s kým chcete prověření sdílet.',
                                )}
                            </p>
                            <Formik initialValues={{ link: shareLink }} onSubmit={values => {}}>
                                <FormItem className="smw:w-full md:w-full lg:w-full FormItem--separator">
                                    <Input
                                        id="link"
                                        name="link"
                                        type="text"
                                        placeholder={tran.GetText('Odkaz')}
                                        value={shareLink}
                                        onFocus={handleFocus}
                                    />
                                </FormItem>
                            </Formik>
                            <p>
                                {isCopied && (
                                    <span className="Check">
                                        {tran.GetText('Odkaz zkopírován')}
                                    </span>
                                )}
                            </p>
                            <div className="lg:w-auto">
                                <ButtonList visible={true}>
                                    <Button
                                        as="button"
                                        variantColor="blue"
                                        variantSize="small"
                                        onClick={() => {
                                            if (i18next.language == 'cz') {
                                                setIsCopied(false)
                                                setStep(1)
                                            } else {
                                                closeModal()
                                            }
                                        }}
                                    >
                                        {tran.GetText('Zavřít')}
                                    </Button>
                                    <Button
                                        as="button"
                                        variantColor="green"
                                        variantSize="small"
                                        onClick={() => {
                                            navigator.clipboard.writeText(shareLink)
                                            const input = document.getElementById('link')
                                            input?.focus()
                                            setIsCopied(true)
                                        }}
                                    >
                                        {tran.GetText('Kopírovat odkaz')}
                                    </Button>
                                </ButtonList>
                            </div>
                        </>
                    )}

                    {step === 3 && smartCodeLength > 0 && (
                        <>
                            <h3 className="pb-20 blue">
                                {tran.GetText('Sdílet základní prověření')}
                            </h3>
                            <p>
                                {tran.GetHtml(
                                    'Základní prověření obsahuje základní kontroly a informace o historii vozidla. Je určené především při jeho prodeji.',
                                )}{' '}
                                <a
                                    href={shareSmartCodeLink}
                                    className="Link-blue"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {tran.GetText('Zobrazit základní prověření')}
                                </a>
                            </p>
                            <p>
                                {tran.GetHtml(
                                    '<b>Sauto.cz:</b><br> Zkopírujte níže uvedený kód kuponu a zadejte jej do určeného pole při vytváření inzerátu.',
                                )}
                                <br></br>
                                {tran.GetHtml('<b>Kód kuponu:</b>')} {couponCode}
                                <br></br>
                                <Button
                                    as="button"
                                    className="mt-10"
                                    variantColor="green"
                                    variantSize="small"
                                    onClick={() => {
                                        navigator.clipboard.writeText(couponCode)
                                        setIsCouponCopied(true)
                                    }}
                                >
                                    {tran.GetText('Kopírovat kód')}
                                </Button>
                            </p>
                            <p>
                                {isCouponCopied && (
                                    <span className="Check">
                                        {tran.GetText('Kód kuponu zkopírován')}
                                    </span>
                                )}
                            </p>
                            <p>
                                {tran.GetHtml(
                                    '<b>Ostatní portály:</b><br> Zkopírujte níže uvedený odkaz a vložte jej přímo do textu inzerátu.',
                                )}
                                <Formik
                                    initialValues={{ link: shareSmartCodeLink }}
                                    onSubmit={values => {}}
                                >
                                    <FormItem className="smw:w-full md:w-full lg:w-full FormItem--separator">
                                        <Input
                                            id="linkSmart"
                                            name="linkSmart"
                                            type="text"
                                            placeholder={tran.GetText('Odkaz')}
                                            value={shareSmartCodeLink}
                                            onFocus={handleFocus}
                                        />
                                    </FormItem>
                                </Formik>
                            </p>
                            <p>
                                {isCopied && (
                                    <span className="Check">
                                        {tran.GetText('Odkaz zkopírován')}
                                    </span>
                                )}
                            </p>
                            <div className="lg:w-auto">
                                <div className="d-flex justify-between">
                                    <Button
                                        as="button"
                                        variantColor="green"
                                        variantSize="small"
                                        onClick={() => {
                                            navigator.clipboard.writeText(shareSmartCodeLink)
                                            const input = document.getElementById('linkSmart')
                                            input?.focus()
                                            setIsCopied(true)
                                        }}
                                    >
                                        {tran.GetText('Kopírovat odkaz')}
                                    </Button>
                                    <Button
                                        as="button"
                                        variantColor="blue"
                                        variantSize="small"
                                        onClick={() => {
                                            if (i18next.language == 'cz') {
                                                setIsCopied(false)
                                                setStep(1)
                                            } else {
                                                closeModal()
                                            }
                                        }}
                                    >
                                        {tran.GetText('Zavřít')}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </Title>
            </div>
        </div>
    )
}
