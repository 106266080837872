import './index.styl'
import { ReactElement, ReactNode } from 'react'

type Props = {
    children: ReactNode
}

export const SectionControls = ({ children }: Props): ReactElement => {
    return <div className="SectionControls">{children}</div>
}
