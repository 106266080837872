import './index.styl'
import { ReactElement, ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
    variant?: 'info' | 'error'
    children?: ReactNode
}

export const StripeAlert = ({ variant = 'info', children }: Props): ReactElement => {
    const className = classNames('StripeAlert', {
        [`StripeAlert--${variant}`]: variant,
    })
    return <div className={className}>{children}</div>
}
