import { Formik } from 'formik'
import parse from 'html-react-parser'
import { Fragment, ReactElement, useState } from 'react'
import * as Yup from 'yup'
import { updateVehicleIdentification } from '../../../../../../busLog/vehicleIndetificationBL'
import { AtrGallery } from '../../../../../../components/AtrGallery'
import { Button } from '../../../../../../components/Button'
import { Table } from '../../../../../../components/Table'
import { ToggleItemGeneral } from '../../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../../../components/ToggleOpenerGeneralVoucher'
import { DropDownItem } from '../../../../../../models/DetailPayedResults/payedJsonResult'
import { getPhotosUrlArray } from '../../../../../../models/DetailPayedResults/Photos/photosControlModel'
import {
    VehicleIdentificationPhotoItem,
    VehicleInfoControlData,
} from '../../../../../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { Translate } from '../../../../../../translations/CebiaTranslator'
import { SectionVehicleIdentificationForm } from './vehicleIdentificationForm'
import { SectionVehicleIdentificationPhotos } from './vehicleIdentificationPhotos'

export type VehicleIdentProps = {
    sourceData: VehicleInfoControlData
    tran: Translate
}

function generateImageDesc(index: number, items?: VehicleIdentificationPhotoItem[]): ReactElement {
    if (index >= 0 && (items?.length ?? 0) > index) {
        const descs = items![index].description.split('|')
        const texts = items![index].caption.split('|')

        if ((texts?.length ?? 0) > 0) {
            return (
                <>
                    {texts.map(
                        (item, idx) =>
                            (item?.length ?? 0) > 0 && (
                                <p className="mb-10 imageDesc" key={`phtIcarDesc_${index}_${idx}`}>
                                    <strong>{item}:</strong> {descs[idx]}
                                </p>
                            ),
                    )}
                </>
            )
        }
    }

    return <></>
}

function ShowIcarisPhotoDescItem(
    index: number,
    items: VehicleIdentificationPhotoItem[],
    isPrint?: boolean,
) {
    if (index >= items.length) return <></>

    return (
        <div className={isPrint ? '' : 'Grid-cell w-1/2'} key={`phtIcarGroup_${index}`}>
            <AtrGallery
                mainImage={undefined}
                images={getPhotosUrlArray([items[index].url], !isPrint)}
                id="phtIcarisDesc"
                isPrint={isPrint}
                isAnonymized={false}
                generateImageDesc={index => generateImageDesc(index, items)}
            />
        </div>
    )
}

export function ShowIcarisPhotoDescription(
    items?: VehicleIdentificationPhotoItem[],
    isPrint?: boolean,
) {
    if (isPrint === true) {
        const photos = []
        for (let i = 0; i < (items?.length ?? 0); i++)
            photos.push(
                <div className="fl" key={`divIndexIcaris_${i}`}>
                    {ShowIcarisPhotoDescItem(i, items!, isPrint)}
                </div>,
            )

        return photos
    }

    return (
        <>
            <AtrGallery
                mainImage={undefined}
                images={getPhotosUrlArray(
                    items?.map(x => x.url),
                    true,
                )}
                id="phtIcarisDesc"
                isPrint={isPrint}
                isAnonymized={false}
                generateImageDesc={index => generateImageDesc(index, items)}
            />
        </>
    )
}

export const SectionVehicleIdentification = ({ sourceData, tran }: VehicleIdentProps) => {
    if (!sourceData?.identification) return <></>

    const [vehicleInfo, setVehicleInfo] = useState<VehicleInfoControlData>(sourceData!)
    const [errorMessage, setErrorMessage] = useState('')
    const validationSchema = () => {
        return Yup.object({
            isGdpr: Yup.boolean().oneOf([true], tran.GetText('Musíte odsouhlasit zadané údaje.')),
            druhId: Yup.number()
                .min(1, tran.GetText('Nesprávna hodnota Typ vozidla.'))
                .max(6, tran.GetText('Nesprávna hodnota Typ vozidla.'))
                .required(tran.GetText('Prosím zadejte Typ vozidla.')),
            make: Yup.string()
                .min(1, tran.GetText('Minimalni pocet znaků musi byt 1.'))
                .max(30, tran.GetText('Maximalni počet znaků musi byt 30.'))
                .required(tran.GetText('Prosím zadejte položku Značka.')),
            model: Yup.string()
                .min(1, tran.GetText('Minimalni pocet znaků musi byt 1.'))
                .max(30, tran.GetText('Maximalni počet znaků musi byt 30.'))
                .required(tran.GetText('Prosím zadejte položku Model.')),
            typ: Yup.string().when('carTypes', {
                is: (carTypes: any) => (carTypes?.length ?? 0) > 0,
                then: Yup.string()
                    .nullable()
                    .required(tran.GetText('Prosím zadejte položku Detail určení.')),
                otherwise: Yup.string().nullable(),
            }),
        })
    }

    return (
        <div className="sectionVehicleIdentification">
            {vehicleInfo.isIdentified && (
                <>
                    <div className="mb-30">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-left" colSpan={2}>
                                        {tran.GetText('Kontrola identifikátorů')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{tran.GetText('Značka')}</td>
                                    <td>{vehicleInfo.identification!.make}</td>
                                </tr>
                                {(vehicleInfo.identification!.model?.length ?? 0) > 0 && (
                                    <tr>
                                        <td>{tran.GetText('Model')}</td>
                                        <td>{vehicleInfo.identification!.model}</td>
                                    </tr>
                                )}
                                {(vehicleInfo.identification!.typ?.length ?? 0) > 0 && (
                                    <tr>
                                        <td>{tran.GetText('Detailní určení')}</td>
                                        <td>{vehicleInfo.identification!.typ}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <div className="mb-10">
                        <div className="Grid gap-30 gmb-20 ">
                            <div className="smw:w-6/12 md:w-full lg:w-6/12"></div>
                            <div className="FormItem FormItem--submit smw:w-6/12 md:w-full lg:w-6/12">
                                <Button
                                    as="a"
                                    variantColor="blue"
                                    variantSize="small"
                                    href={`/print/GetIdentificationDocument?requestId=${vehicleInfo.identification?.requestId}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    {tran.GetText('PDF')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    {(vehicleInfo.identification?.icarisIndetification?.length ?? 0) > 0 &&
                        vehicleInfo.identification?.icarisIndetification?.map((item, index) => (
                            <Fragment key={`phtIcar_${index}`}>
                                <ToggleOpenerGeneralVoucher
                                    href={`#icarisPhotosGroup_${index}`}
                                    titleLeft={item.caption}
                                    isOpened={false}
                                    className="mb-10"
                                />
                                <ToggleItemGeneral id={`icarisPhotosGroup_${index}`}>
                                    <p className="text-justify">{parse(item.description)}</p>
                                    <p>{item.car}</p>
                                    {item.photoGroups?.map((phtItem, index) => (
                                        <div
                                            className="Grid gap-30 gmb-20"
                                            key={`currentPhtIcar_${index}`}
                                        >
                                            <p>{phtItem.groupName}</p>
                                            {ShowIcarisPhotoDescription(phtItem?.photos, false)}
                                        </div>
                                    ))}
                                </ToggleItemGeneral>
                            </Fragment>
                        ))}
                    {(vehicleInfo.identification?.carPhotos?.length ?? 0) > 0 && (
                        <SectionVehicleIdentificationPhotos
                            sourceData={vehicleInfo.identification?.carPhotos}
                            tran={tran}
                            isLoading={false}
                        />
                    )}
                </>
            )}
            {!vehicleInfo.isIdentified && (
                <>
                    <h2>{tran.GetText('Vyberte vozidlo')}</h2>
                    <p className="text-justify">
                        {tran.GetText(
                            'Po zadání výběru vozidla Vám systém AUTOTRACER poskytne Návod na základní kontrolu identifikátorů. Návod platí obecně na zadanou značku a modelu vozidla. Pro ukázku bylo vybráno náhodně zvolené vozidlo.',
                        )}
                    </p>
                    <p className="text-justify">
                        {tran.GetText(
                            'Výběr vozidla může být již částečně předvyplněn, na základě zadaného VIN. Pokud předvyplněný výběr vozidla neodpovídá vozidlu, které prověřujete, upravte výběr ručně.',
                        )}
                    </p>

                    {errorMessage?.length > 0 && (
                        <p className="InputError">
                            <span className=" Icon Icon--alert AlertIcon"></span> {errorMessage}
                        </p>
                    )}

                    <div className="mb-20">
                        <Formik
                            initialValues={vehicleInfo.identification!}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                updateVehicleIdentification(values).then(x => {
                                    if (x)
                                        setVehicleInfo(p => {
                                            return { ...p, ...x }
                                        })
                                    else
                                        setErrorMessage(
                                            tran.GetText('Chyba uložení. Opakujte akci.'),
                                        )
                                })
                            }}
                        >
                            <SectionVehicleIdentificationForm
                                sourceData={vehicleInfo}
                                tran={tran}
                            />
                        </Formik>
                    </div>
                </>
            )}
        </div>
    )
}
