import './index.styl'
import { ReactElement } from 'react'
import { Image } from '../Image'
import { i18n } from '../../translations/i18n'
import AssetsImgRibbonCZ from './img/ribbon-overeno_CZ.svg'
import AssetsImgRibbonDE from './img/ribbon-overeno_DE.svg'
import AssetsImgRibbonEN from './img/ribbon-overeno_EN.svg'
import AssetsImgRibbonRO from './img/ribbon-overeno_RO.svg'
import AssetsImgRibbonAL from './img/ribbon-overeno_AL.svg'
import AssetsImgRibbonSK from './img/ribbon-overeno_SK.svg'
import AssetsImgRibbonUA from './img/ribbon-overeno_UA.svg'
import AssetsImgRibbonIT from './img/ribbon-overeno_IT.svg'
import AssetsImgRibbonFR from './img/ribbon-overeno_FR.svg'
import AssetsImgRibbonNL from './img/ribbon-overeno_NL.svg'

import { Translate, _, __ } from '../../translations/CebiaTranslator'

export const TrustedService = (): ReactElement => {
    const tran = new Translate()

    const overenoDefinition: Record<string, string> = {
        cz: AssetsImgRibbonCZ,
        de: AssetsImgRibbonDE,
        en: AssetsImgRibbonEN,
        ro: AssetsImgRibbonRO,
        sk: AssetsImgRibbonSK,
        uk: AssetsImgRibbonUA,
        al: AssetsImgRibbonAL,
        it: AssetsImgRibbonIT,
        fr: AssetsImgRibbonFR,
        nl: AssetsImgRibbonNL,
    }
    return (
        <div className="TrustedService">
            <Image
                className="DefaultCursor"
                src={overenoDefinition[i18n.language.toLowerCase()]}
                alt={tran.GetText('Ověřeno zakazniky')}
            />
        </div>
    )
}
