import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../Container'
import classNames from 'classnames'
import { LogoCebia } from '../LogoCebia'

type Props = {
    variant?: 'light'
}

export const PayedRegHeader = ({ variant }: Props): ReactElement => {
    const className = classNames('Header', {
        [`Header--${variant}`]: variant,
    })
    const url =
        process.env.NODE_ENV !== 'production'
            ? 'https://app.cebia.com/autotracer_test/'
            : 'https://app.cebia.com/atrpro/'
    return (
        <header className={className}>
            <Container variant="full">
                <div className="Header-content">
                    <div className="Header-logo">
                        <a href={url}>
                            <LogoCebia variant={variant} />
                        </a>
                    </div>
                </div>
            </Container>
        </header>
    )
}
