import './index.styl'
import { ReactElement } from 'react'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Section } from '../../../../components/Section'

export const SectionCarInfoZV = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="LockedSection">
            <div className="text-center pb-25">
                <h2 className="orange font-bold pt-10">
                    {tran.GetText('Kategorie je dostupná pouze v kompletním prověření')}
                </h2>
            </div>
            <p className="text-justify pb-25">
                {tran.GetHtml(
                    'Kompletní prověření může obsahovat detailní informace o vozidle, které mohou zahrnovat jeho technické parametry, seznam výbavy podle výrobce, návod na identifikaci vozu, údaje z technického průkazu, záznamy o prohlídkách STK (včetně protokolů), záznamy o měření emisí (včetně protokolů), záznamy o evidenčních kontrolách (včetně protokolů) a mnoho dalších užitečných informací.',
                )}
            </p>
        </Section>
    )
}
