import './index.styl'
import { ReactElement } from 'react'
import { ToggleItem, ToggleItemProps } from '../ToggleItem'

type Props = ToggleItemProps

export const ToggleItemAtr = ({ children, ...props }: Props): ReactElement => {
    return (
        <ToggleItem className="ToggleItemAtr" {...props}>
            <div className="ToggleItemAtr-inner">{children}</div>
        </ToggleItem>
    )
}
