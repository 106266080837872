import './index.styl'
import { ReactElement } from 'react'

export const Hamburger = (): ReactElement => {
    return (
        <span className="Hamburger">
            <span className="Hamburger-content">
                <span className="Hamburger-line"></span>
                <span className="Hamburger-line"></span>
                <span className="Hamburger-line"></span>
            </span>
        </span>
    )
}
