import './index.styl'
import { ReactElement } from 'react'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Section } from '../../../../components/Section'

export const SectionSafetyZV = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="LockedSection">
            <div className="text-center pb-25">
                <h2 className="orange font-bold pt-10">
                    {tran.GetText('Kategorie je dostupná pouze v kompletním prověření')}
                </h2>
            </div>
            <p className="text-justify pb-25">
                {tran.GetHtml(
                    'Lze prověřit, zda je vozidlo zabezpečeno značením skel OCIS. Kompletní prověření může obsahovat datum instalace zabezpečení a unikátní kód vyznačený na sklech. Riziko odcizení je u takto zabezpečeného vozidla 30krát nižší.',
                )}
            </p>
        </Section>
    )
}
