import './index.styl'
import { ReactElement } from 'react'
import { Image, ImageProps } from '../Image'
import { ToggleOpenerGeneral, ToggleOpenerGeneralProps } from '../ToggleOpenerGeneral'

type Props = Omit<ToggleOpenerGeneralProps, 'children'> & {
    image: ImageProps
    mileage?: string
    date?: string
    title: string
    id?: string
}

export const ToggleOpenerGeneralAdvert = ({
    image,
    mileage,
    date,
    title,
    id,
    ...props
}: Props): ReactElement => {
    props = {
        button: 'Detail',
        ...props,
    }

    return (
        <ToggleOpenerGeneral className="ToggleOpenerGeneralVoucher" {...props} showArrowDown={true}>
            {image && (
                <div className="ToggleOpenerGeneralAdvert-visual" id={id}>
                    {image && <Image {...image} />}
                </div>
            )}

            <span className="ToggleOpenerGeneralAdvert-mileage">{mileage}</span>
            <span className="ToggleOpenerGeneralAdvert-date">{date}</span>
            <span className="ToggleOpenerGeneralAdvert-title">{title}</span>
        </ToggleOpenerGeneral>
    )
}
