import './index.styl'
import { MouseEventHandler, ReactElement, ReactNode, useEffect } from 'react'
import classNames, { Argument } from 'classnames'
import { useToggleGroup } from '../../hooks/useToggleGroup'

export type ToggleOpenerProps = {
    href: string
    children?: ReactNode
    className?: Argument
    cluster?: string
    noFlash?: boolean
    clickAction?: 'open' | 'close' | 'toggle'
    isStatic?: boolean
    isOpened: boolean
    hrefBlank?: boolean
    blurInActive?: boolean
}

export const ToggleOpener = ({
    href,
    className: _className,
    cluster,
    clickAction = 'toggle',
    noFlash = false,
    isStatic = false,
    children,
    isOpened,
    hrefBlank,
    blurInActive,
}: ToggleOpenerProps): ReactElement => {
    const id = href.split('#')[1]

    const { toggleGroup, toggleGroupActions } = useToggleGroup(id, cluster, isOpened)
    const handleClick: MouseEventHandler = event => {
        event.preventDefault()
        const active = clickAction === 'toggle' ? !toggleGroup.active : clickAction === 'open'
        const shouldFlash = clickAction === 'open' && !noFlash ? true : false
        let shouldScroll = false
        if (clickAction === 'open') {
            shouldScroll = true
        }
        if (!active && blurInActive) {
            const target = event.target as HTMLElement
            if (target) target.blur()
        }
        toggleGroupActions.setActive(active, shouldFlash, shouldScroll)
    }

    useEffect(() => {
        clickAction = isOpened ? 'toggle' : 'open'
        const shouldFlash = clickAction === 'open' && !noFlash ? true : false
        const shouldScroll = !isOpened
        if (isOpened) {
            toggleGroup.active = true
        }
        toggleGroupActions.setActive(isOpened, shouldFlash, shouldScroll)
    }, [isOpened])

    const className = classNames(
        {
            'is-active': toggleGroup.active,
            'is-static': isStatic,
        },
        _className,
    )
    if (hrefBlank) {
        return (
            <a href={href} target="blank" id={href} className={className}>
                {children}
            </a>
        )
    }

    if (isStatic) {
        return <span className={className}>{children}</span>
    } else {
        return (
            <a href={href} id={href} className={className} onClick={handleClick}>
                {children}
            </a>
        )
    }
}
