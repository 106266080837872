import { Dispatch, EffectCallback, SetStateAction } from 'react'

type Listener<T> = Dispatch<SetStateAction<T>>

export type Observable<T> = {
    get: () => T
    set: (arg: T) => void
    subscribe: (arg: Listener<T>) => void
    unsubscribe: (arg: Listener<T>) => void
}

export function makeObservable<T>(target: T): Observable<T> {
    let listeners: Listener<T>[] = []
    let value = target

    function get() {
        return value
    }

    function set(newValue: T) {
        if (value === newValue) return
        value = newValue
        listeners.forEach(l => l(value))
    }

    function subscribe(listenerFunc: Listener<T>): EffectCallback {
        listeners.push(listenerFunc)
        return () => unsubscribe(listenerFunc)
    }

    function unsubscribe(listenerFunc: Listener<T>): void {
        listeners = listeners.filter(l => l !== listenerFunc)
    }

    return {
        get,
        set,
        subscribe,
        unsubscribe,
    }
}
