import { useEffect, useMemo, useState } from 'react'
import { makeObservable } from '../utils/makeObservable'

type State = {
    active: boolean
}

type Actions = {
    setActive: (active: boolean) => void
}

type Store = {
    atrNavState: State
    atrNavActions: Actions
}

const store = makeObservable<State>({
    active: false,
})

export const useAtrNav = (): Store => {
    const [state, setState] = useState(store.get())

    useEffect(() => {
        return store.subscribe(setState)
    }, [])

    const actions = useMemo((): Actions => {
        return {
            setActive: active => {
                store.set({ ...state, active })
            },
        }
    }, [state])

    return {
        atrNavState: state,
        atrNavActions: actions,
    }
}
