import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { BannerCarolinaLarge } from '../../../../components/BannerCarolinaLarge'

export const SectionBanner = (): ReactElement => {
    return (
        <Section variant="medium">
            <Container>
                <BannerCarolinaLarge />
            </Container>
        </Section>
    )
}
