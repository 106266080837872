import React, { ReactElement, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { usePayedDetailPrintData } from '../../../busLog/printBL'
import { LoadArea } from '../../../components/LoadArea'
import { PrintDataModel } from '../../../models/Prints/printDataModel'
import { Translate } from '../../../translations/CebiaTranslator'
import { BaseCarInfo } from '././sections/body/baseCarInfo'
import { CallingAction } from '././sections/body/callingAction'
import { CarColor } from '././sections/body/carColor'
import { CarPhoto } from '././sections/body/carPhoto'
import { CheckLease } from '././sections/body/checkLease'
import { CouponInfo } from '././sections/body/couponInfo'
import { Damages } from '././sections/body/damages'
import { Equipment } from '././sections/body/equipment'
import { Rokvy } from '././sections/body/rokvy'
import { Security } from '././sections/body/security'
import { ServiceRecs } from '././sections/body/serviceRec'
import { Tacho } from '././sections/body/tacho'
import { Taxi } from '././sections/body/taxi'
import { TechDescription } from '././sections/body/techDescription'
import { Theft } from '././sections/body/theft'
import { Valuation } from '././sections/body/valuation'
import './index.styl'
import { TimeLine } from './sections/body/timeline'
import { PrintHeader } from './sections/header'
import { Exekuce } from './sections/body/exekuce'
import { LienRights } from './sections/body/lienRights'

export type PrintDataProps = {
    sourceData?: PrintDataModel | undefined
    captionNumberRef?: React.MutableRefObject<number>
    tran: Translate
    countryId?: number
}

export const PrintSectionInc = 1

export const PrintDetailPaid = (): ReactElement => {
    const { id } = useParams()
    const { isError, isLoading, data, isSuccess } = usePayedDetailPrintData(id!)
    const captionNumberRef = useRef(0)
    const tran = new Translate()

    useEffect(() => {
        if (isSuccess) {
            setInterval(() => {
                window.status = '4b2ad8f0165f9152b2f5dca535b88663b91b095a1ba5a2e335e55a7ae81ac86c'
            }, 5000)
        }
    }, [isSuccess])

    return (
        <>
            {isLoading && (
                <LoadArea
                    isLoading={isLoading}
                    hideBackGround={true}
                    variant="fullscreen"
                    loader={{ variant: 'bars', size: 'big' }}
                    text={tran.GetText('Načítáme data...')}
                ></LoadArea>
            )}

            {!isLoading && isSuccess && (
                <div className="PrintDetailPaid">
                    {/* <PrintHeader isCarDetect={data!.data.isCarDetect} vin={data!.data.vin} /> */}
                    <h2 className="bolder">{tran.GetText('Výpis z historie vozidla')}</h2>
                    <CouponInfo
                        sourceData={data!.data}
                        tran={tran}
                        captionNumberRef={captionNumberRef}
                    />
                    <BaseCarInfo
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    {/* <Rokvy
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    /> */}
                    <Tacho
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <TimeLine
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <Damages
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                        countryId={data!.data.countryId}
                    />
                    <ServiceRecs
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <Theft
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <LienRights
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <Taxi sourceData={data!.data} captionNumberRef={captionNumberRef} tran={tran} />
                    <CheckLease
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <Exekuce
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <Valuation
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <Equipment
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <CallingAction
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <CarColor
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <Security
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <TechDescription
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    <CarPhoto
                        sourceData={data!.data}
                        captionNumberRef={captionNumberRef}
                        tran={tran}
                    />
                    {data?.data && (
                        <div className="mt-100">
                            <hr />

                            <p>
                                {tran.GetText(
                                    'Provozovatel systému AUTOTRACER - Cebia, spol. s r.o., upozorňuje uživatele systému, že uváděné informace byly poskytnuty do systému partnery provozovatele bez možnosti ověřit jejich správnost. Veškeré informace mají výhradně informativní charakter.',
                                )}
                            </p>
                            <p>
                                {tran.GetText(
                                    'Na výsledek prověření vozidla v systému AUTOTRACER není poskytována záruka.',
                                )}
                            </p>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
