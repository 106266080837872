import './index.styl'
import { ReactElement } from 'react'
import { ToggleOpener, ToggleOpenerProps } from '../ToggleOpener'
import classNames from 'classnames'
import { VisualIcon, VisualIconProps } from '../VisualIcon'
import { AtrInfoStatus, AtrInfoStatusProps } from '../AtrInfoStatus'
import { BubbleOpener } from '../BubbleOpener'
import { Loader } from '../Loader'

type Props = Omit<ToggleOpenerProps, 'children'> & {
    visualIcon?: VisualIconProps
    atrInfoStatus?: AtrInfoStatusProps
    title: string
    status: string
    statusIcon?: VisualIconProps
    questionClass?: string
    labelQuestionHandler?: () => void
    isOpened: boolean
    isLoading?: boolean
    children?: ReactElement
}

export const ToggleOpenerAtr = ({
    className: _className,
    title,
    atrInfoStatus,
    status,
    statusIcon,
    visualIcon,
    questionClass,
    labelQuestionHandler,
    isOpened,
    children,
    ...props
}: Props): ReactElement => {
    const className = classNames('ToggleOpenerAtr', _className)
    return (
        <>
            <ToggleOpener className={className} {...props} isOpened={isOpened}>
                {visualIcon && <VisualIcon {...visualIcon} />}
                <span className="ToggleOpenerAtr-title">
                    {title}
                    {questionClass && (
                        <BubbleOpener
                            className={`ml-5 modal-${questionClass}`}
                            onClickHandler={labelQuestionHandler}
                        />
                    )}
                </span>
                {props.isLoading ? (
                    <Loader variant="bars" />
                ) : (
                    atrInfoStatus && (
                        <>
                            <AtrInfoStatus
                                className="ToggleOpenerAtr-infoStatus"
                                {...atrInfoStatus}
                            />

                            <span className="ToggleOpenerAtr-status">
                                {status} {statusIcon && <VisualIcon {...statusIcon} />}
                                {children}
                            </span>
                        </>
                    )
                )}
            </ToggleOpener>
        </>
    )
}
