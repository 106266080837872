import moment from 'moment'
import { Table } from '../../../../../components/Table'
import { VehicleInfoControlData } from '../../../../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'
import { i18n } from '../../../../../translations/i18n'

export type Props = {
    sourceData: VehicleInfoControlData
    tran: Translate
}

export const SectionVehicleSecurity = ({ sourceData, tran }: Props) => {
    return (
        <div className="sectionVehicleSecurity">
            <p className="text-justify">
                {tran.GetText(
                    'Výsledkem kontroly je informace, zda Vámi prověřované vozidlo je zabezpečeno formou značení skel a registrováno v Systému OCIS. Pokud ano, sdělíme Vám vyznačený kód a datum registrace.',
                )}
            </p>

            {(sourceData.vehicleSecurity?.length ?? 0) > 0 && (
                <>
                    <p>
                        {tran.GetText(
                            'Na vozidle bylo v minulosti provedeno značení skel a je registrováno v mezinárodní databázi systému OCIS.',
                        )}
                    </p>
                    <p className="redWarning">{tran.GetText('ZKONTROLUJTE:')}</p>
                    <p>
                        {tran.GetText(
                            'Pokud na některých či na všech sklech vozidla unikátní kód chybí, může se jednat o vozidlo havarované, předělané či odcizené.',
                        )}
                    </p>
                    <div className="mb-20">
                        {sourceData.vehicleSecurity!.map((item, index) => (
                            <Table key={`tblOcis_${index}`}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>
                                            {`${tran.GetText(
                                                'Informace o provedeném značení skel:',
                                            )} ${index + 1}/${sourceData.vehicleSecurity?.length}`}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{tran.GetText('Datum registrace:')}</td>
                                        <td>{moment(item.regDate).format('MM/YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td>{tran.GetText('Typ zabezpečení vozidla:')}</td>
                                        <td>{item.securityDescription}</td>
                                    </tr>
                                    <tr>
                                        <td>{tran.GetText('Kód značení:')}</td>
                                        <td>{item.securityCode}</td>
                                    </tr>
                                    <tr>
                                        <td>{tran.GetText('Barva vozidla:')}</td>
                                        <td>{item.vehicleColor}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        ))}
                    </div>
                </>
            )}

            {(sourceData.vehicleSecurity?.length ?? 0) <= 0 && (
                <>
                    <div className="mb-20">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-left">
                                        {tran.GetText('Informace o provedeném značení skel')}
                                    </th>
                                    <th className="text-left">
                                        {moment(sourceData.couponActivation).format('DD. MM. YYYY')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{tran.GetText('Značení skel')}</td>
                                    <td>{tran.GetText('NE')}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    {sourceData.showVehicleSecurityOffer && i18n.language === 'cz' && (
                        <>
                            <h4>{tran.GetText('MÁTE ZÁJEM O ZABEZPEČENÍ VOZIDLA?')}</h4>
                            <p>
                                {tran.GetHtml(
                                    "<strong>Systém OCIS –</strong> značení skel vozidla. Jednoduché, efektivní a cenově výhodné zabezpečení vozidla. <a style='color: #013475; font-weight: bold;' href='https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/znaceni-oken/popis-sluzeb.html' target='_blank'>Více zde.</a>",
                                )}
                            </p>
                            <p>
                                {tran.GetHtml(
                                    "<strong>CEBIA SAT –</strong> moderní zabezpečení vozidla. Chrání auto před krádeží i posádku při havárii. Okamžitá reakce a nepřetržitá kontrola ve vašem telefonu. <a style='color: #013475; font-weight: bold;' href='https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/popis-sluzby.html' target='_blank'>Více zde.</a>",
                                )}
                            </p>
                        </>
                    )}
                </>
            )}
        </div>
    )
}
