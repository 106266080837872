export enum TimeLineObjectType {
    Rokvy,
    FirstReg,
    CzSkReg,
    WorldReg,
    DeRegAbroad,
    DeRegPermanently,
    STK,
    Emise,
    Damages,
    ServiceRecords,
    Adverts,
    Taxis,
    Ocis,
    Calling,
    Thefts,
    TechLicence,
}
