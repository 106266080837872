import { DropDownItem } from '../payedJsonResult'

export class CebiaReportControlData {
    vin = ''
    cebiaReportNumber = ''
    firstRegistrationYear?: number
    km?: number
    isTheft = false
    isGdpr = false
    isTest = false
    reportModel = ''
    reportModels?: DropDownItem[]
    requestId?: string
    apcRefNumber?: string
}
