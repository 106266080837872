import './index.styl'
import { ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'

type Props = {
    variant?: 'half'
    className?: Argument
    children?: ReactNode
}

export const Table = ({ className: _className, variant, children }: Props): ReactElement => {
    const className = classNames(
        'Table',
        {
            [`Table--${variant}`]: variant,
        },
        _className,
    )

    return (
        <div className={className}>
            <table>{children}</table>
        </div>
    )
}
