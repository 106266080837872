import React, { ReactElement } from 'react'
import { AtrNav } from '../../../../components/AtrNav'
import { Container } from '../../../../components/Container'
import { LoadArea } from '../../../../components/LoadArea'
import { Section } from '../../../../components/Section'
import { Sticky } from '../../../../components/Sticky'
import { ReferenceType } from '../../../../enums/ReferenceType'
import {
    DetailPayedSectionDefinitionModel,
    getGlobalSectionDefinition,
} from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { PayedJsonResult } from '../../../../models/DetailPayedResults/payedJsonResult'
import { Translate } from '../../../../translations/CebiaTranslator'
import { SectionContent } from './Content/sectionContent'
import { SetcionHeaderContent } from './Content/sectionHeaderContent'
import './index.styl'
import { PayedStateModel } from '../../../../models/payedResponseModel'

type PayedDatas = {
    sourceData?: PayedJsonResult[]
    showLoader: boolean
    model?: PayedStateModel
    referenceType?: ReferenceType
}

export const SectionMain = ({
    sourceData,
    showLoader,
    referenceType,
    model,
}: PayedDatas): ReactElement => {
    const tran = new Translate()

    function getData(item: DetailPayedSectionDefinitionModel) {
        return item.sectionItems.filter(
            x =>
                x.sectionId ==
                sourceData?.find(y => y.showSection && y.sectionId == x.sectionId)?.sectionId,
        )
    }

    return (
        <Section className="SectionMain" variantPend="appended">
            <Container variant="medium">
                <div className="SectionMain-layout">
                    {referenceType !== ReferenceType.SmartCode && (
                        <div className="SectionMain-nav">
                            <Sticky offsetTop={40} offsetBottom={150}>
                                <AtrNav>
                                    {getGlobalSectionDefinition(tran).map((item, index) => {
                                        const tmpItem = getData(item)
                                        return (
                                            <SetcionHeaderContent
                                                key={`navBlock_${index}`}
                                                sectionItems={tmpItem}
                                                sourceData={sourceData}
                                                tran={tran}
                                            />
                                        )
                                    })}
                                </AtrNav>
                            </Sticky>
                        </div>
                    )}

                    <div className="SectionMain-main">
                        {getGlobalSectionDefinition(tran).map((item, index) => {
                            const tmpItems = getData(item)
                            if (tmpItems.length !== 0) {
                                return (
                                    <SectionContent
                                        key={`section_${index}`}
                                        model={model}
                                        mainCaption={item.mainSectionCaption}
                                        smartCodeCaption={item.smartCodeSectionCaption}
                                        sectionItems={tmpItems}
                                        sourceData={sourceData}
                                        tran={tran}
                                        referenceType={referenceType}
                                    />
                                )
                            }
                        })}
                    </div>
                </div>
                <LoadArea
                    hideBackGround={true}
                    loader={{ variant: 'bars', size: 'big' }}
                    align="center"
                    isLoading={showLoader}
                />
            </Container>
        </Section>
    )
}
