import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Title } from '../../../../components/Title'
import { Logo } from '../../../../components/Logo'
import AssetsImgCT from './img/ceska_televize.svg'
import AssetsImgNova from './img/tv_nova.svg'
import AssetsImgPrima from './img/tv_prima.svg'
import AssetsImgForbes from './img/forbes.svg'
import AssetsImgTiriac from './img/tiriac.svg'
import AssetsImgEuropa2 from './img/evropa_2.svg'
import AssetsImgAutoCz from './img/autocz.svg'
import AssetsImgDenik from './img/denik.svg'
import AssetsImgBlesk from './img/blesk.svg'
// import AssetsImgCarvago from './img/carvago.svg'
import AssetsImgAutoVitRO from './img/autovit.ro.svg'
// import AssetsImgAutoexpertRO from './img/autoexpert.ro.svg'
import AssetsImgOLXRO from './img/olx.ro.svg'
// import AssetsImgProfitRO from './img/profit.ro.svg'
// import AssetsImgCarzz from './img/carzz-logo-red.svg'
// import AssetsImgLajumate from './img/lajumate-logo-orizontal-green.svg'
import AssetsImgAutoClab from './img/autoclab.svg'
import AssetsImgBrokerAuto from './img/brokerauto.svg'
// import AssetsImgVerbita from './img/verbita.svg'
// import AssetsImgHitocars from './img/hitocars.svg'
import AssetsImgBcchauto from './img/bcchauto.ro.svg'
import AssetsImgAutoRulateLeasing from './img/AutoRulateLeasing.ro.svg'
import AssetsImgBavariaUsed from './img/BavariaUsed.ro.svg'

import { Translate, _, __ } from '../../../../translations/CebiaTranslator'
import { i18n } from '../../../../translations/i18n'

export const SectionReferers = (): ReactElement => {
    const tran = new Translate()
    interface dataLogo {
        id: number
        image: string
        alt: string
    }

    const logoDefinition: Record<string, dataLogo[]> = {
        cz: [
            { id: 1, image: AssetsImgCT, alt: 'Logo Česká televize' },
            { id: 2, image: AssetsImgNova, alt: 'Logo TV NOVA' },
            { id: 3, image: AssetsImgPrima, alt: 'Logo TV Prima' },
            { id: 4, image: AssetsImgForbes, alt: 'Logo Forbes' },
            { id: 5, image: AssetsImgEuropa2, alt: 'Logo Rádio Evropa 2' },
            { id: 6, image: AssetsImgAutoCz, alt: 'Logo AutoCz' },
            { id: 7, image: AssetsImgDenik, alt: 'Logo Deník' },
            { id: 8, image: AssetsImgBlesk, alt: 'Logo Blesk' },
        ],
        sk: [
            { id: 1, image: AssetsImgCT, alt: 'Logo Česká televize' },
            { id: 2, image: AssetsImgNova, alt: 'Logo TV NOVA' },
            { id: 3, image: AssetsImgPrima, alt: 'Logo TV Prima' },
            { id: 4, image: AssetsImgForbes, alt: 'Logo Forbes' },
            { id: 5, image: AssetsImgEuropa2, alt: 'Logo Rádio Evropa 2' },
            { id: 6, image: AssetsImgAutoCz, alt: 'Logo AutoCz' },
            { id: 7, image: AssetsImgDenik, alt: 'Logo Deník' },
            { id: 8, image: AssetsImgBlesk, alt: 'Logo Blesk' },
        ],
        en: [
            { id: 1, image: AssetsImgCT, alt: 'Logo Česká televize' },
            { id: 2, image: AssetsImgNova, alt: 'Logo TV NOVA' },
            { id: 3, image: AssetsImgPrima, alt: 'Logo TV Prima' },
            { id: 4, image: AssetsImgForbes, alt: 'Logo Forbes' },
            { id: 5, image: AssetsImgEuropa2, alt: 'Logo Rádio Evropa 2' },
            { id: 6, image: AssetsImgAutoCz, alt: 'Logo AutoCz' },
            { id: 7, image: AssetsImgDenik, alt: 'Logo Deník' },
            { id: 8, image: AssetsImgBlesk, alt: 'Logo Blesk' },
        ],
        ro: [
            { id: 1, image: AssetsImgTiriac, alt: 'Logo Tiriac Auto' },
            { id: 2, image: AssetsImgAutoClab, alt: 'Logo AutoClab' },
            { id: 3, image: AssetsImgBrokerAuto, alt: 'Logo BrokerAuto' },
            { id: 4, image: AssetsImgAutoVitRO, alt: 'Logo AutoVit RO' },
            { id: 5, image: AssetsImgOLXRO, alt: 'Logo OLX RO' },
            { id: 6, image: AssetsImgBcchauto, alt: 'Logo Bcchauto' },
            { id: 7, image: AssetsImgAutoRulateLeasing, alt: 'Logo AutoRulateLeasing' },
            { id: 8, image: AssetsImgBavariaUsed, alt: 'Logo BavariaUsed' },
        ],
        al: [{ id: 1, image: AssetsImgForbes, alt: 'Logo Forbes' }],
    }
    return !logoDefinition[i18n.language.toLowerCase()]?.length ||
        logoDefinition[i18n.language.toLowerCase()]?.length == 0 ? (
        <></>
    ) : (
        <Section className="SectionReferers" variant="medium">
            <Container>
                <Title className="Beta color-darkBlue mb-30" as="h2" variant="withDash">
                    <strong>{tran.GetText('Znáte z...')}</strong>
                </Title>
                <div className="Grid gap-10 md:gap-30 gmb-10 md:gmb-30 justify-center">
                    {(i18n?.language?.length ?? 0) > 0 &&
                        logoDefinition[i18n.language.toLowerCase()] &&
                        logoDefinition[i18n.language.toLowerCase()].map(listValue => {
                            return (
                                <div
                                    className="Grid-cell w-6/12 md:w-4/12 xl:w-3/12"
                                    key={listValue.id.toString()}
                                >
                                    <div className="SectionReferers-logoItem">
                                        <Logo src={listValue.image} alt={listValue.alt} />
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </Container>
        </Section>
    )
}
