import http, { createHttpClient } from './http-common'
import { VinModel } from '../models/vinModel'
import { CouponModel } from '../models/couponModel'
import { ValidationResponseModel } from '../models/validationResponseModel'
import { UtmCampaignModel } from '../models/UtmCampaignModel'

const validate = async (data: VinModel) => {
    let retVal = new ValidationResponseModel(null)
    retVal.isValid = 0

    const response = await http.post<ValidationResponseModel>('/Validation/ZadaniVinValidate', data)
    if (response.status === 200 && response.data) retVal = response.data

    return retVal
}

const validateVinFromUrl = async (vin: string) => {
    return await http.post('/Validation/VinRequestUrlValidate', null, {
        params: {
            vin,
        },
    })
}

const validateCoupon = async (data: CouponModel) => {
    let retVal = new ValidationResponseModel(null)
    retVal.isValid = 0

    const response = await http.post('/Validation/CisloKuponuValidate', data)
    if (response.status === 200 && response.data) retVal = response.data

    return retVal
}

const validateUtmCampaign = async (data: UtmCampaignModel) => {
    try {
        const httpCsrf = await createHttpClient()
        const response = await httpCsrf.post('/Validation/UtmCampaignValidate', data)
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

const validateCrCode = (crId: string, vinHash: string) => {
    return http.post('/Validation/Cr', null, {
        params: {
            crId,
            vinHash,
        },
    })
}

// eslint-disable-next-line import/no-default-export
export default {
    validateVin: validate,
    validateCoupon: validateCoupon,
    validateVinFromUrl: validateVinFromUrl,
    validateCrCode: validateCrCode,
    validateUtmCampaign: validateUtmCampaign,
}
