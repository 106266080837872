import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { FadedBackground } from '../../../../components/FadedBackground'
import { Section } from '../../../../components/Section'
import { Title } from '../../../../components/Title'
import { VisualIcon } from '../../../../components/VisualIcon'
import { Translate, _, __ } from '../../../../translations/CebiaTranslator'
import { TrustedService } from '../../../../components/TrustedService'
import { GoogleWidgetHP } from '../../../../components/GoogleWidgetHP'
import i18n from 'i18next'

export const SectionHpHighlights = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="SectionHpHighlights" variant="bare">
            <Container variant="medium">
                <FadedBackground variant="small" mobileBox onlyDesktop rounded>
                    <div className="SectionHpHighlights-inner">
                        <div className="SectionHpHighlights-ribbon">
                            {i18n.language !== 'al' ? (
                                <GoogleWidgetHP
                                    key={`${i18n.language}_googleWidgetHPHighlights`}
                                    hiddenUntilLarge={true}
                                />
                            ) : (
                                <TrustedService />
                            )}
                        </div>
                        <div className="SectionHpHighlights-title">
                            <Title
                                className="Beta color-darkBlue mb-20 lg:mb-50"
                                as="h2"
                                variant="withDash"
                            >
                                {tran.GetText('U každého vozu prověřujeme')}
                            </Title>
                        </div>
                        <div className="SectionHpHighlights-gridWrap mb-10">
                            <div className="Grid md:gap-40 smw:gmb-15 color-darkBlue justify-center">
                                <div className="Grid-cell smw:w-6/12">
                                    <div className="SectionHpHighlights-item">
                                        <VisualIcon
                                            size="desktopLarge"
                                            className="SectionHpHighlights-visualIcon"
                                            name="speedometerCheck"
                                        />
                                        <strong>{tran.GetText('Najeté kilometry')}</strong>
                                    </div>
                                </div>
                                <div className="Grid-cell smw:w-6/12">
                                    <div className="SectionHpHighlights-item">
                                        <VisualIcon
                                            size="desktopLarge"
                                            className="SectionHpHighlights-visualIcon"
                                            name="carCrash"
                                        />
                                        <strong>{tran.GetText('Historie poškození')}</strong>
                                    </div>
                                </div>
                                <div className="Grid-cell smw:w-6/12">
                                    <div className="SectionHpHighlights-item">
                                        <VisualIcon
                                            size="desktopLarge"
                                            className="SectionHpHighlights-visualIcon"
                                            name="thiefCar"
                                        />
                                        <strong>{tran.GetText('Odcizení vozidla')}</strong>
                                    </div>
                                </div>
                                <div className="Grid-cell smw:w-6/12">
                                    <div className="SectionHpHighlights-item">
                                        <VisualIcon
                                            size="desktopLarge"
                                            className="SectionHpHighlights-visualIcon"
                                            name="tabletCar"
                                        />
                                        <strong>{tran.GetText('Servisní historie')}</strong>
                                    </div>
                                </div>
                                <div className="Grid-cell smw:w-6/12">
                                    <div className="SectionHpHighlights-item">
                                        <VisualIcon
                                            size="desktopLarge"
                                            className="SectionHpHighlights-visualIcon"
                                            name="photoCamera"
                                        />
                                        <strong>{tran.GetText('Záznamy inzerce')}</strong>
                                    </div>
                                </div>
                                <div className="Grid-cell smw:w-6/12">
                                    <div className="SectionHpHighlights-item">
                                        <VisualIcon
                                            size="desktopLarge"
                                            className="SectionHpHighlights-visualIcon"
                                            name="glassCar"
                                        />
                                        <strong>{tran.GetText('Využítí jako taxi')}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FadedBackground>
            </Container>
        </Section>
    )
}
