import { ReactElement, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PagePayedReg } from '../../pages/payedReg'

export const PagePayedRegRouting = (): ReactElement => {
    const [searchParams] = useSearchParams()
    const dotaz_id = searchParams.get('dotaz_id')
    const navigate = useNavigate()
    const urltype = searchParams.get('type') ?? '0'

    useEffect(() => {
        if (urltype && urltype === '2') {
            window.location.assign('/payed/detail/' + dotaz_id + '?s_page=Identifikace')
        }
        if (urltype && urltype === '1') {
            window.location.assign('/payed/detail/' + dotaz_id)
        }
    }, [urltype])

    if (urltype && urltype !== '1' && urltype !== '2') {
        return <PagePayedReg></PagePayedReg>
    } else {
        return <></>
    }
}
