import classNames from 'classnames'
import { ReactElement } from 'react'
import Moment from 'react-moment'
import {
    TimeLineDeRegAbroad,
    TimeLineItem,
} from '../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { Translate } from '../../translations/CebiaTranslator'

type Props = {
    row: TimeLineItem
    isPrint: boolean
}
export const TimeLineDegRegAbroadTable = ({ row, isPrint }: Props): ReactElement => {
    const item = row.timeLineObject as TimeLineDeRegAbroad
    const tran = new Translate()
    const tableClasses = classNames('Table', { ['noBackground line2rem']: isPrint })
    return (
        <>
            <div className={tableClasses}>
                <table>
                    <tbody>
                        <tr>
                            <td>{tran.GetText('Datum odhlášení')}:</td>
                            <td>
                                <Moment format="DD. MM. YYYY">{row.currentDate}</Moment>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
