import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import { Translate } from '../../translations/CebiaTranslator'
import { PayedJsonResult } from '../../models/DetailPayedResults/payedJsonResult'
import { Form, Formik } from 'formik'
import { LoadArea } from '../LoadArea'
import { Button } from '../Button'
import ReactSwitch from 'react-switch'
import { CebiaReportEsa } from '../../models/payedRegResponseModel'
import { postCebiaReportEsa } from '../../busLog/payedRegBL'
import { useNavigate } from 'react-router'
import { Container } from '../Container'
import { StripeAlert } from '../StripeAlert'
import { useSearchParams } from 'react-router-dom'
import { TextUtils } from '../../utils/textUtils'

export type PayedRegEsa = {
    tachosResult: PayedJsonResult | undefined
    cebiaReportEsa: CebiaReportEsa
    changeIsEsaReport?: React.Dispatch<React.SetStateAction<boolean>>
    changeIsEsaReportCreating?: React.Dispatch<React.SetStateAction<boolean>>
}

export const PayedRegEsa = ({
    tachosResult,
    cebiaReportEsa,
    changeIsEsaReport,
    changeIsEsaReportCreating,
}: PayedRegEsa): ReactElement => {
    const className = 'PayedRegRowEsa Grid'
    const [searchParams] = useSearchParams()
    cebiaReportEsa.requestId = searchParams.get('dotaz_id') ?? ''
    const tran = new Translate()
    const [model, setModel] = useState(cebiaReportEsa)
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const navigate = useNavigate()
    const udajText = new TextUtils()
    const existingDetail = cebiaReportEsa.certEsaId > 0
    useEffect(() => {
        if (tachosResult?.recordsCount && cebiaReportEsa.certEsaId === -1) {
            setModel(value => {
                return {
                    ...value,
                    checkTachoState:
                        tachosResult?.recordsCount === 0 ? 'NE' : 'ANO - na základě údajů',
                }
            })
        }
    }, [])
    return (
        <>
            <Formik
                initialValues={model}
                enableReinitialize={true}
                onSubmit={async values => {
                    setIsLoading(true)
                    const result = await postCebiaReportEsa(model)
                    if (result) {
                        navigate(0)
                    } else {
                        setErrorMessage(tran.GetText('Chyba načtení kuponu.'))
                    }
                    setIsLoading(false)
                }}
            >
                <Form>
                    {errorMessage?.length > 0 && (
                        <Container variant="medium">
                            <StripeAlert variant="error">
                                <span className=" Icon Icon--alert AlertIcon"></span> {errorMessage}
                            </StripeAlert>
                        </Container>
                    )}
                    <h2 className="mb-10 mt-50">
                        {tran.GetText('CERTIFIKÁT KONTROLY VOZU - přehled dostupných informací')}
                    </h2>
                    <div className={'PayedRegRowEsaHead Grid'}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kategorie')}
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Možnost kontroly')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola VIN')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {cebiaReportEsa.checkVin && true
                                ? tran.GetText('ANO')
                                : tran.GetText('NE')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola odcizení')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {cebiaReportEsa.checkStealing
                                ? tran.GetText('ANO')
                                : tran.GetText('NE')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola financování')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {cebiaReportEsa.checkLeasing && true
                                ? tran.GetText('ANO')
                                : tran.GetText('NE')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola data registrace')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {cebiaReportEsa.checkRegistrationDate
                                ? existingDetail || isEdit
                                    ? tran.GetText('ANO')
                                    : tran.GetText('Fyzická prověrka')
                                : tran.GetText('NE')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola najetých kilometrů')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {isEdit && (
                                <ReactSwitch
                                    onChange={(checked, event, id) => {
                                        setModel(value => {
                                            return { ...value, checkKmAge: checked }
                                        })
                                    }}
                                    checked={model.checkKmAge}
                                    className="react-switch"
                                />
                            )}
                            {!isEdit &&
                                (cebiaReportEsa.checkKmAge
                                    ? existingDetail
                                        ? tran.GetText('ANO')
                                        : tran.GetText('Fyzická prověrka')
                                    : tran.GetText('NE'))}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola stavu tachometru')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {(tachosResult?.recordsCount ?? 0) > 0 && (
                                <>
                                    {tran.GetText('ANO - na základě')}{' '}
                                    {udajText.UdajeText(tachosResult?.recordsCount)}
                                </>
                            )}
                            {(tachosResult?.recordsCount ?? 0) <= 0 && tran.GetText('Ne')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola původu vozu')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {isEdit && (
                                <ReactSwitch
                                    onChange={(checked, event, id) => {
                                        setModel(value => {
                                            return { ...value, checkOrigin: checked }
                                        })
                                    }}
                                    checked={model.checkOrigin}
                                    className="react-switch"
                                />
                            )}
                            {!isEdit &&
                                (model.checkOrigin
                                    ? tran.GetText('Fyzická prověrka')
                                    : tran.GetText('Ne'))}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola platby EKO daně')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {cebiaReportEsa.checkEcoTax
                                ? existingDetail || isEdit
                                    ? tran.GetText('ANO')
                                    : tran.GetText('Fyzická prověrka')
                                : tran.GetText('NE')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola DPH')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {cebiaReportEsa.checkVat
                                ? existingDetail || isEdit
                                    ? tran.GetText('ANO')
                                    : tran.GetText('Fyzická prověrka')
                                : tran.GetText('NE')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola vážné havárie')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {isEdit && (
                                <ReactSwitch
                                    onChange={(checked, event, id) => {
                                        setModel(value => {
                                            return { ...value, checkMajorAccident: checked }
                                        })
                                    }}
                                    checked={model.checkMajorAccident}
                                    className="react-switch"
                                />
                            )}
                            {!isEdit &&
                                (cebiaReportEsa.checkMajorAccident
                                    ? existingDetail || isEdit
                                        ? tran.GetText('ANO')
                                        : tran.GetText('Fyzická prověrka')
                                    : tran.GetText('NE'))}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola registrace')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {cebiaReportEsa.checkRegistration
                                ? existingDetail || isEdit
                                    ? tran.GetText('ANO')
                                    : tran.GetText('Fyzická prověrka')
                                : tran.GetText('NE')}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola poškození povodní')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {isEdit && (
                                <ReactSwitch
                                    onChange={(checked, event, id) => {
                                        setModel(value => {
                                            return { ...value, checkFloodAccident: checked }
                                        })
                                    }}
                                    checked={model.checkFloodAccident}
                                    className="react-switch"
                                />
                            )}
                            {!isEdit &&
                                (cebiaReportEsa.checkFloodAccident
                                    ? existingDetail
                                        ? tran.GetText('ANO')
                                        : tran.GetText('Fyzická prověrka')
                                    : tran.GetText('NE'))}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola typu motoru')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {isEdit && (
                                <ReactSwitch
                                    onChange={(checked, event, id) => {
                                        setModel(value => {
                                            return { ...value, checkTypeOfEngine: checked }
                                        })
                                    }}
                                    checked={model.checkTypeOfEngine}
                                    className="react-switch"
                                />
                            )}
                            {!isEdit &&
                                (cebiaReportEsa.checkTypeOfEngine
                                    ? existingDetail
                                        ? tran.GetText('ANO')
                                        : tran.GetText('Fyzická prověrka')
                                    : tran.GetText('NE'))}
                        </div>
                    </div>
                    <div className={className}>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {tran.GetText('Kontrola identifikátorů vozu')}:
                        </div>
                        <div className="Grid-cell smw:w-full md:w-full lg:w-6/12">
                            {isEdit && (
                                <ReactSwitch
                                    onChange={(checked, event, id) => {
                                        setModel(value => {
                                            return { ...value, checkIdentificators: checked }
                                        })
                                    }}
                                    checked={model.checkIdentificators}
                                    className="react-switch"
                                />
                            )}
                            {!isEdit &&
                                (cebiaReportEsa.checkIdentificators
                                    ? existingDetail
                                        ? tran.GetText('ANO')
                                        : tran.GetText('Fyzická prověrka')
                                    : tran.GetText('NE'))}
                        </div>
                    </div>
                    <div className="Grid-cell smw:w-full md:w-full lg:w-full text-right mt-20">
                        {cebiaReportEsa.certEsaId === -1 && (
                            <Button
                                as="button"
                                type="button"
                                variantColor="green"
                                onClick={() => {
                                    if (isEdit) {
                                        setIsEdit(false)
                                    } else {
                                        if (changeIsEsaReportCreating)
                                            changeIsEsaReportCreating(false)
                                    }
                                }}
                            >
                                {tran.GetText('ZPĚT')}
                            </Button>
                        )}

                        {!isEdit && cebiaReportEsa.certEsaId === -1 && (
                            <Button
                                as="button"
                                type="button"
                                className="ml-10"
                                variantColor="green"
                                onClick={() => {
                                    setIsEdit(true)
                                }}
                            >
                                {tran.GetText('POKRAČOVAT')}
                            </Button>
                        )}
                        {isEdit && (
                            <Button as="button" className="ml-10" variantColor="green">
                                {tran.GetText('VYGENEROVAT')}
                            </Button>
                        )}
                        {cebiaReportEsa.certEsaId > 0 && (
                            <>
                                <Button
                                    as="a"
                                    type="button"
                                    className="ml-10"
                                    variantColor="green"
                                    href={`../Print/CebiaReportEsa?requestId=${cebiaReportEsa.requestId}`}
                                >
                                    {tran.GetText('ZOBRAZIT CERTIFIKÁT KONTROLY VOZU')}
                                </Button>
                                <Button
                                    as="a"
                                    type="button"
                                    className="ml-10"
                                    variantColor="green"
                                    href={`../Print/CebiaReport?requestId=${cebiaReportEsa.requestId}`}
                                >
                                    {tran.GetText('ZOBRAZIT CEBIA REPORT')}
                                </Button>
                            </>
                        )}
                    </div>
                    <LoadArea
                        hideBackGround={true}
                        loader={{ variant: 'bars', size: 'big' }}
                        align="center"
                        isLoading={isLoading}
                    />
                </Form>
            </Formik>
        </>
    )
}
