/* eslint-disable */

import ScrollService from './NativeScroll'
// import {queries} from '../../core/config'

ScrollService.getScrollOffset = () => {
    // if (window.matchMedia(queries.smallWideMax).matches) {
    //     const fixedHeader = document.querySelector('.FixedHeader')
    //     if (!fixedHeader) {
    //         return 100
    //     }
    //     return fixedHeader.offsetHeight + fixedHeader.offsetTop + 10
    // } else {
    return 0
    // }
}

export const disableScroll = () => ScrollService.disableScroll()
export const enableScroll = () => ScrollService.enableScroll()
export const getScroll = () => ScrollService.getScroll()
export const getScrollTop = () => ScrollService.getScrollTop()
export const scrollToElement = (element, options) => ScrollService.scrollToElement(element, options)

export default ScrollService
export const Scroll = ScrollService
