import { useEffect } from 'react'

export const importScript = (resourceUrl: string) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = resourceUrl
        script.async = true
        const pageCookiesText = document.getElementById('pageCookiesText')
        if (pageCookiesText !== undefined && pageCookiesText !== null) {
            pageCookiesText.innerHTML = ''
            pageCookiesText.appendChild(script)
        }
    }, [resourceUrl])
}
