import { LoadArea } from '../../../../../components/LoadArea'
import { Table } from '../../../../../components/Table'
import { VehicleInfoControlData } from '../../../../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'

export type Props = {
    sourceData: VehicleInfoControlData
    tran: Translate
}

export const SectionVehicleColor = ({ sourceData, tran }: Props) => {
    if ((sourceData?.vehicleColor?.length ?? 0) <= 0)
        return (
            <p>
                {tran.GetText(
                    'Nebyly nalezeny záznamy o barvě vozidla evidované výrobcem vozidla či při první registraci vozidla v ČR.',
                )}
            </p>
        )

    return (
        <>
            <div className="sectionVehicleColor">
                <div className="pb-25">
                    <p className="pt-15 smallInfoText text-justify">
                        {tran.GetText(
                            'Zkontrolujte, zda se aktuální barva na vozidle shoduje s barvou deklarovanou výrobcem nebo s barvou evidovanou po první registraci v ČR (případně na Slovensku). V případě, že je barva rozdílná, může se jednat o vozidlo přebarvené, ale i havarované či odcizené.',
                        )}
                    </p>
                </div>

                <div className="mb-30">
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-left" colSpan={2}>
                                    {tran.GetText('Barva vozidla')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sourceData.vehicleColor!.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.description}</td>
                                    <td>{item.colorName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <LoadArea
                hideBackGround={true}
                position="inherit"
                loader={{ variant: 'bars', size: 'big' }}
                align="center"
                isLoading={sourceData?.vehicleColor === undefined}
            />
        </>
    )
}
