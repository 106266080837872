import './index.styl'
import * as Yup from 'yup'
import { ReactElement, useEffect, useState } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Translate } from '../../../../translations/CebiaTranslator'
import { useModal, Modal } from '../../../../components/Modal'
import { Form, Formik } from 'formik'
import { Button } from '../../../../components/Button'
import { ButtonList } from '../../../../components/ButtonList'
import { FormItem } from '../../../../components/FormItem'
import { Input } from '../../../../components/Input'
import { Title } from '../../../../components/Title'
import { getRating, sendRatingMessage } from '../../../../busLog/contactFormBL'
import { PayedStateModel } from '../../../../models/payedResponseModel'
import { RatingMessageModel } from '../../../../models/RatingMessageModel'
import StarRatings from '../../../../components/StarRating'

type Props = {
    model: PayedStateModel | undefined
}

export const SectionRating = ({ model }: Props): ReactElement => {
    const [ratingVal, setRatingVal] = useState(new RatingMessageModel(model?.couponCode ?? ''))
    const [errorMessage, setErrorMessage] = useState('')
    const [sendedMessage, setSendedMessage] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [rating, setRating] = useState(0)
    const { open: openModal, close: closeModal } = useModal('ratingModal')
    const tran = new Translate()
    const changeRating = (newRating: number) => {
        setRating(newRating)
        const newVal = new RatingMessageModel(model?.couponCode ?? '')
        newVal.starsCount = newRating
        if (newRating < 4) openModal()
        if (newRating > 3) {
            newVal.message = 'Hodnocení více než 3 hvězdičky'
            sendRatingMessage(newVal).then(x => {
                if (x) {
                    setSendedMessage(true)
                    window.open('https://g.page/r/CbbS6-gUfXZpEBM/review')
                } else setErrorMessage(tran.GetText('Chyba odeslání. Opakujte akci.'))
            })
        }
        setRatingVal(prevstate => ({ ...prevstate, ...newVal }))
    }
    const validationSchema = () => {
        return Yup.object({
            message: Yup.string()
                .max(2000, tran.GetText('Maximální počet znaků je 2000.'))
                .required(tran.GetText('Prosím zadejte položku Zpráva')),
        })
    }

    useEffect(() => {
        if (ratingVal.couponNumber.length > 0) {
            getRating(ratingVal.couponNumber).then(x => {
                if (x) {
                    const model = x as RatingMessageModel
                    if (model && model.starsCount > 0) {
                        setSendedMessage(true)
                    }
                }
            })
        }
    }, [ratingVal.couponNumber])
    return (
        <Section variantPend="appended" className="SectionRating">
            <Container>
                {!sendedMessage && (
                    <>
                        <p className="text-center RatingText">
                            {tran.GetText(
                                'Jste spokojeni s výsledkem prověření historie vozidla? Budeme rádi za vaše hodnocení.',
                            )}
                        </p>
                        <StarRatings
                            rating={rating}
                            starRatedColor="#ffd125"
                            starHoverColor="#ffd125"
                            starEmptyColor="#b2b7c4"
                            changeRating={changeRating}
                            starDimension="85"
                            starSpacing="5"
                            numberOfStars={5}
                            svgIconPath={
                                'M 15.654195,6.8280089 A 0.259,0.259 0 0 0 15.447195,6.6490089 L 10.805195,5.9580089 L 8.7331946,1.6470089 A 0.253,0.253 0 0 0 8.2731946,1.6470089 L 6.1941946,5.9580089 L 1.5531946,6.6490089 A 0.259,0.259 0 0 0 1.3461946,6.8280089 A 0.267,0.267 0 0 0 1.4101946,7.1000089 L 4.7691946,10.454009 L 3.9761946,15.193009 A 0.267,0.267 0 0 0 4.0761946,15.450009 A 0.249,0.249 0 0 0 4.3461946,15.470009 L 8.4981946,13.233009 L 12.649195,15.470009 A 0.252,0.252 0 0 0 12.919195,15.450009 A 0.266,0.266 0 0 0 13.019195,15.193009 L 12.233195,10.454009 L 15.589195,7.1000089 A 0.268,0.268 0 0 0 15.654195,6.8280089 Z'
                            }
                            name="rating"
                        />
                    </>
                )}
                {sendedMessage && (
                    <p className="text-center RatingTextSuccess">
                        {tran.GetHtml(
                            '<strong className="color-green">Děkujeme!</strong> Vaše hodnocení bylo odesláno a pomůže nám dále zlepšovat naše služby.',
                        )}
                    </p>
                )}
            </Container>
            <Modal id="ratingModal">
                <Container>
                    <div className="Grid">
                        <div className="Grid-cell md:w-12/12">
                            <Title as="header" variantAlign="left">
                                <h2 className="pb-20 blue">{tran.GetText('Hodnocení')}</h2>
                                <p>
                                    {tran.GetText(
                                        'Mrzí nás, že prověření historie vozidla nesplnilo vaše očekávání. Napište nám prosím, co bychom měli do příště zlepšit.',
                                    )}
                                </p>

                                <Formik
                                    initialValues={ratingVal}
                                    enableReinitialize={true}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    validationSchema={validationSchema}
                                    onSubmit={values => {
                                        setDisabled(true)
                                        sendRatingMessage(values).then(x => {
                                            if (x) {
                                                setDisabled(false)
                                                setSendedMessage(true)
                                                closeModal()
                                            } else {
                                                setDisabled(false)

                                                setErrorMessage(
                                                    tran.GetText('Chyba odeslání. Opakujte akci.'),
                                                )
                                            }
                                        })
                                    }}
                                >
                                    <Form>
                                        <FormItem className="smw:w-full md:w-full lg:w-full FormItem--separator">
                                            <Input
                                                id="message"
                                                name="message"
                                                type="text"
                                                component="textarea"
                                                placeholder={tran.GetText('Zpráva')}
                                                className="Input--textarea"
                                            />
                                        </FormItem>

                                        <div className="lg:w-auto mt-30">
                                            <ButtonList visible={true}>
                                                <Button
                                                    as="button"
                                                    variantColor="blue"
                                                    variantSize="small"
                                                    type="button"
                                                    onClick={() => {
                                                        closeModal()
                                                    }}
                                                >
                                                    {tran.GetText('Zavřít')}
                                                </Button>
                                                <Button
                                                    as="button"
                                                    variantColor="green"
                                                    disabled={disabled}
                                                    variantSize="small"
                                                    type="submit"
                                                >
                                                    {tran.GetText('Odeslat')}
                                                </Button>
                                            </ButtonList>
                                        </div>
                                        {errorMessage?.length > 0 && (
                                            <p className="InputError">
                                                <span className=" Icon Icon--alert AlertIcon"></span>{' '}
                                                {errorMessage}
                                            </p>
                                        )}
                                    </Form>
                                </Formik>
                            </Title>
                        </div>
                    </div>
                </Container>
            </Modal>
        </Section>
    )
}
