import { Translate } from '../../translations/CebiaTranslator'
import './index.styl'
import { ReactElement } from 'react'

export const OrderStep = (): ReactElement => {
    const tran = new Translate()
    return (
        <>
            <div className="orderStep">
                <div className="orderStep-item">
                    <span className="orderStep-number">01</span>
                    <span className="orderStep-text">{tran.GetText('Objednávka')}</span>
                </div>
                <div className="orderStep-item is-active">
                    <span className="orderStep-number">02</span>
                    <span className="orderStep-text">{tran.GetText('Způsob platby')}</span>
                </div>
                <div className="orderStep-item">
                    <span className="orderStep-number">03</span>
                    <span className="orderStep-text">{tran.GetText('Zobrazení informací')}</span>
                </div>
            </div>
        </>
    )
}
