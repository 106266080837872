export enum LanguageType {
    CZ = 43,
    SK = 198,
    US = 202,
    DE = 157,
    RO = 184,
    AL = 3,
    UK = 237,
    IT = 90,
    FR = 60,
    NL = 163,
}
