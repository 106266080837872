import './index.styl'
import { ReactElement } from 'react'
import { VisualIcon, VisualIconProps } from '../VisualIcon'
import { Translate, _, __ } from '../../translations/CebiaTranslator'
import { Modal, useModal } from '../Modal'
import { Container } from '../Container'
import { Button } from '../Button'
import { Title } from '../Title'
import classNames from 'classnames'
import { LoadArea } from '../LoadArea'

export type DashboardDescriptionItemNew = {
    Id: number
    Description?: any
}

type Props = {
    title: string
    visualIcon: VisualIconProps
    isVisible: boolean
    description: any
    isDisabled: boolean
    isLoading: boolean
    descriptionId?: number
}

export const AtrCategoryCardNew = ({
    title,
    visualIcon,
    isVisible = true,
    description,
    isDisabled = false,
    isLoading = false,
    descriptionId,
}: Props): ReactElement => {
    const {
        open: openModal,
        close: closeModal,
        active: isActive,
    } = useModal(`AtrCategoryCardInfoModal_${descriptionId}`)

    const className = classNames('AtrCategoryCardNew AtrCategoryCardNew--main', {
        [`is-disabled`]: isDisabled,
    })
    const tran = new Translate()
    if (!isVisible) return <></>

    return (
        <article
            className={className}
            onClick={() => {
                if (!isActive) openModal()
            }}
        >
            <div className="AtrCategoryCardNew-info">
                <VisualIcon {...visualIcon} />
                <LoadArea
                    isLoading={isLoading}
                    text={tran.GetText('Načítám data')}
                    position="initial"
                    align="centerRight"
                ></LoadArea>
                <Modal
                    className="AtrCategoryCardInfoModal"
                    id={`AtrCategoryCardInfoModal_${descriptionId}`}
                >
                    <Container>
                        <div className="Grid">
                            <div className="Grid-cell md:w-12/12">
                                <Title as="header" variantAlign="left">
                                    <VisualIcon
                                        className="AtrCategoryCard-visualIcon fl"
                                        {...visualIcon}
                                    />
                                    <h2 className="title fl mt-20 ml-30">
                                        {descriptionId == 10
                                            ? 'Verificarea istoricului RAR'
                                            : tran.GetText('Dostupné po zaplacení')}
                                    </h2>
                                </Title>
                            </div>
                            <div className="Grid-cell md:w-12/12 mt-20">
                                <div className="description">
                                    <div className="mt-20">
                                        {descriptionId == 10
                                            ? 'În cazul în care vehiculul este înmatriculat în România, raportul poate conține înregistrări ale tuturor inspecțiilor tehnice periodice (ITP). Puteți verifica data inspecției, kilometrajul și alte informații importante. Datele sunt furnizate de Registrul Auto Român (RAR).'
                                            : tran.GetText(
                                                  'Detailní informace a data o prověřovaném vozidlu budou dostupná po zaplacení služby.',
                                              )}
                                    </div>
                                </div>
                            </div>
                            <div className="Grid-cell md:w-12/12 mt-20 button">
                                <Button
                                    as="button"
                                    variantColor="blue"
                                    variantSize="small"
                                    onClick={() => {
                                        closeModal()
                                    }}
                                >
                                    {tran.GetText('Zavřít')}
                                </Button>
                            </div>
                        </div>
                    </Container>
                </Modal>
                <span className="AtrCategoryCardNew-title left font-lg">{title}</span>
                <span className="AtrCategoryCardNew-title font-smaller">{description}</span>
                {!isLoading && (
                    <>
                        <div className="AtrCategoryCardNew-divIcon">
                            <span className="AtrCategoryCardNew-icon Icon Icon--check"></span>
                        </div>
                    </>
                )}
            </div>
        </article>
    )
}
