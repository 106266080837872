import './index.styl'
import { useFormikContext } from 'formik'
import { Translate } from '../../../../translations/CebiaTranslator'
import { ReactElement, useEffect } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { VisualIcon } from '../../../../components/VisualIcon'
import GoPayImgLogo from '../../img/goPay.svg'
import LiqPayImgLogo from '../../img/liqPay.svg'
import IPayImgLogo from '../../img/IPay.svg'
import { useModal } from '../../../../components/Modal/hooks'
import { Modal } from '../../../../components/Modal'
import { RadioButton } from '../../../../components/RadioButton'
import { PaymentReqModel } from '../../../../models/paymentReqModel'
import { ToggleOpenerAtr } from '../../../../components/ToggleOpenerAtr'
import { ToggleItemAtr } from '../../../../components/ToggleItemAtr'
import { LanguageType } from '../../../../enums/LanguageType'
import { LanguageEnum } from '../../../../translations/i18n'
import { RadioButtonPayment } from '../../../../components/RadioButtonPayment'
import { BubbleOpener } from '../../../../components/BubbleOpener'

type Props = {
    isVisible: boolean
}

export const SectionPayMethod = ({ isVisible = true }: Props): ReactElement => {
    const { open: openK } = useModal('paymentMethodCard')
    const { open: openO } = useModal('paymentMethodOnline')
    const { open: openG } = useModal('paymentMethodG')
    const { open: openA } = useModal('paymentMethodA')
    const { open: openB } = useModal('paymentMethodBank')
    const { open: openC } = useModal('paymentMethodCash')
    const formikContext = useFormikContext()
    const paymentModel = formikContext.values as PaymentReqModel
    const defaultPayment = paymentModel.countryId === LanguageType.UK ? 'LIQPAY' : 'PAYMENT_CARD'
    const tran = new Translate()
    const allowedForLanguage = [LanguageType.CZ, LanguageType.SK]
    /*Na PC a apple desktopu je mozne pozuit google.A proto se tohle zjistuje pro GooglePay.  */
    function isApple() {
        if (window.navigator.userAgent.toLowerCase().indexOf('os x') > 0) {
            return true
        }

        return false
    }

    /*Na iphonu je mozne pouzit jenom applePay.Proto se applePay zobrazi jenom na iphonech a googlePAy se na iphonech nezobrazi. */
    function isIphone() {
        if (window.navigator.userAgent.toLowerCase().indexOf('iphone') > 0) {
            return false
        }

        return true
    }

    function onClick(e: React.ChangeEvent<HTMLInputElement>) {
        const values = formikContext.values as PaymentReqModel
        values.paymentMethod = e.target.value
        if (e.target.value) formikContext.setValues(values, false)
    }

    useEffect(() => {
        const values = formikContext.values as PaymentReqModel
        values.paymentMethod = defaultPayment
        formikContext.setValues(values, false)
    }, [defaultPayment])

    // const rbBuble = () => {
    //     return <BubbleOpener className="questionPayMethod" onClickHandler={openG} />
    // }

    if (!isVisible) return <></>
    return (
        <Section variant="bare" className="SectionPaymentMethods">
            <Container>
                <ToggleOpenerAtr
                    className="TogglePadding SectionPaymentMethods"
                    title={tran.GetText('Platební metoda')}
                    href="#sectionMethod"
                    atrInfoStatus={{}}
                    status=""
                    isOpened={true}
                    isStatic={true}
                >
                    <div></div>
                </ToggleOpenerAtr>
                <ToggleItemAtr active={true} id="sectionMethod" className="sectionInvoiceItem">
                    <div className="payMethodItem payMethodOnline Grid gmb-20 lg:gmb-10 md:w-12/12">
                        <RadioButtonPayment
                            className="payMethod"
                            id={'paymentMethod_6'}
                            name={'paymentMethod'}
                            value="PAYMENT_CARD"
                            iconVariant="visaNew"
                        >
                            <div className="baseItems">
                                <div className="w-11/12 smw:w-11/12 md:w-4/12 flex">
                                    <div className="text-inline">
                                        <span>{tran.GetText('On-line platební kartou')}</span>
                                        <BubbleOpener
                                            className="questionPayMethod"
                                            onClickHandler={openK}
                                        />
                                    </div>
                                </div>
                                <div className="w-11/12 smw:w-11/12 md:w-6/12 description">
                                    <span className="description">
                                        {tran.GetText(
                                            'Výsledky jsou k dispozici hned po zaplacení',
                                        )}
                                    </span>
                                </div>
                            </div>
                            <Modal id="paymentMethodCard">
                                <Container>
                                    {tran.GetHtml(
                                        'Při objednávce budete přesměrováni na platební bránu GoPay, kde provedete bezpečnou platbu platební kartou. V případě, že Vaše platba proběhne úspěšně, budete automaticky přesměrováni zpět do systému AUTOTRACER k zobrazení detailních informací k vozidlu. Zároveň systém AUTOTRACER odešle automatickou informaci na Vámi uvedenou e-mailovou adresu, která obsahuje číslo kuponu AUTOTRACER a účetní doklad.',
                                    )}
                                </Container>
                            </Modal>
                        </RadioButtonPayment>
                        {allowedForLanguage.indexOf(paymentModel.countryId) !== -1 && (
                            <>
                                <RadioButtonPayment
                                    className="payMethod"
                                    id={'paymentMethod_8'}
                                    name={'paymentMethod'}
                                    value="BANK_ACCOUNT"
                                    onClick={onClick}
                                    iconVariant="onlineTransfer"
                                >
                                    <div className="baseItems">
                                        <div className="w-11/12 smw:w-11/12 md:w-4/12 flex">
                                            <div className="text-inline">
                                                <span>{tran.GetText('On-line převod')}</span>
                                                <BubbleOpener
                                                    className="questionPayMethod"
                                                    onClickHandler={openO}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-11/12 smw:w-11/12 md:w-6/12 description">
                                            <span className="description">
                                                {tran.GetText(
                                                    'Výsledky jsou k dispozici obvykle do 60 minut',
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <Modal id="paymentMethodOnline">
                                        <Container>
                                            {tran.GetHtml(
                                                'Pokud zvolíte tento způsob platby, budete přesměrováni na platební bránu GoPay, kde si vyberete Váš bankovní ústav, ze kterého budete chtít platbu provést.',
                                            )}
                                            <br />
                                            {tran.GetHtml(
                                                'V případě, že Vaše banka nabízí tzv. on-line převod, tak Vás přesměrujeme do Vašeho internetového bankovnictví, kde budete mít předvyplněný platební příkaz, provedete úhradu služby.',
                                            )}
                                            <br />
                                            {tran.GetHtml(
                                                'Pokud obdržíme platbu od banky ihned, tak Vás automaticky přesměrujeme do placené části systému AUTOTRACER. V opačném případě prosím vyčkejte na obdržení emailu.',
                                            )}
                                            <br />
                                            {tran.GetHtml(
                                                'Po připsání platby na náš účet systém AUTOTRACER odešle automatickou informaci na Vámi uvedenou e-mailovou adresu, která obsahuje číslo kuponu AUTOTRACER (= vstup na placené informace) a účetní doklad.',
                                            )}
                                        </Container>
                                    </Modal>
                                </RadioButtonPayment>
                            </>
                        )}

                        {isIphone() &&
                            paymentModel.countryId !== LanguageType.UK &&
                            paymentModel.countryId !== LanguageType.AL && (
                                <>
                                    <RadioButtonPayment
                                        className="payMethod"
                                        id={'paymentMethod_49'}
                                        name={'paymentMethod'}
                                        value="GPAY"
                                        onClick={onClick}
                                        iconVariant="googlePay"
                                    >
                                        <div className="baseItems">
                                            <div className="w-11/12 smw:w-11/12 md:w-4/12 flex">
                                                <div className="text-inline">
                                                    <span>{tran.GetText('Google Pay')}</span>
                                                    <BubbleOpener
                                                        className="questionPayMethod"
                                                        onClickHandler={openG}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-11/12 smw:w-11/12 md:w-6/12 description">
                                                <span className="description">
                                                    {tran.GetText(
                                                        'Výsledky jsou k dispozici hned po zaplacení',
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <Modal id="paymentMethodG">
                                            <Container>
                                                {tran.GetHtml(
                                                    'Google Pay je rychlý a snadný způsob placení kdekoliv na Googlu, bez zadávání platebních údajů. Můžete rychle zaplatit pomocí karty uložené ve službě Google Pay. Abyste tuto službu placení  mohli využívat, musí ji také podporovat Vaše banka.<p></p>V případě, že Vaše platba proběhne úspěšně, budete automaticky přesměrováni zpět do systému AUTOTRACER k zobrazení detailních informací o vozidle. Zároveň systém AUTOTRACER odešle automatickou informaci na Vámi uvedenou e-mailovou adresu, která obsahuje číslo kuponu AUTOTRACER pro následné zobrazení informací o vozidle a daňový doklad.',
                                                )}
                                            </Container>
                                        </Modal>
                                    </RadioButtonPayment>
                                </>
                            )}
                        {isApple() && paymentModel.countryId !== LanguageType.UK && (
                            <>
                                <RadioButtonPayment
                                    className="payMethod"
                                    id={'paymentMethod_50'}
                                    name={'paymentMethod'}
                                    value="APPLE_PAY"
                                    onClick={onClick}
                                    iconVariant="applePay"
                                >
                                    <div className="baseItems">
                                        <div className="w-11/12 smw:w-11/12 md:w-4/12 flex">
                                            <div className="text-inline">
                                                <span>{tran.GetText('Apple Pay')}</span>
                                                <BubbleOpener
                                                    className="questionPayMethod"
                                                    onClickHandler={openA}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-11/12 smw:w-11/12 md:w-6/12 description">
                                            <span className="description">
                                                {tran.GetText(
                                                    'Výsledky jsou k dispozici hned po zaplacení',
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <Modal id="paymentMethodA">
                                        <Container>
                                            {tran.GetHtml(
                                                'Apple Pay je platební metoda, která umožňuje snadné platby pomocí karty uložené v Apple Wallet. Abyste tuto službu placení  mohli využívat, musí ji také podporovat Vaše banka.</p><p>V případě, že Vaše platba proběhne úspěšně, budete automaticky přesměrováni zpět do systému AUTOTRACER k zobrazení detailních informací o vozidle. Zároveň systém AUTOTRACER odešle automatickou informaci na Vámi uvedenou e-mailovou adresu, která obsahuje číslo kuponu AUTOTRACER pro následné zobrazení informací o vozidle a daňový doklad.',
                                            )}
                                        </Container>
                                    </Modal>
                                </RadioButtonPayment>
                            </>
                        )}
                        {allowedForLanguage.indexOf(paymentModel.countryId) !== -1 && (
                            <>
                                <RadioButtonPayment
                                    className="payMethod"
                                    id={'paymentMethod_81'}
                                    name={'paymentMethod'}
                                    value="BANK_ACCOUNT_NO_ONLINE"
                                    onClick={onClick}
                                    iconVariant="savingBankCash"
                                >
                                    <div className="baseItems">
                                        <div className="w-11/12 smw:w-11/12 md:w-4/12 flex">
                                            <div className="text-inline">
                                                <span>{tran.GetText('Bankovním převodem')}</span>
                                                <BubbleOpener
                                                    className="questionPayMethod"
                                                    onClickHandler={openB}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-11/12 smw:w-11/12 md:w-6/12 description">
                                            <span className="description">
                                                {tran.GetText(
                                                    'Výsledky budou k dispozici do 48 hodin',
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <Modal id="paymentMethodBank">
                                        <Container>
                                            {tran.GetHtml(
                                                'Pokud zvolíte tento způsob platby, budete přesměrováni na platební bránu GoPay, kde si vyberete Váš bankovní ústav, ze kterého budete chtít platbu provést.',
                                            )}
                                            <br />
                                            {tran.GetHtml(
                                                'V případě, že Vaše banka nabízí pouze tzv. off-line převod, vygenerujeme Vám pokyny k bankovnímu převodu, Vy následně u Vaší banky provedete převod peněžních prostředků a jakmile bude částka připsána na náš bankovní účet, zašleme Vám email s přístupem do placené části systému AUTOTRACER a doklad o provedené platbě.',
                                            )}
                                            <br />
                                            {tran.GetHtml(
                                                'Tato operace může trvat až 2 pracovní dny (obvykle však 24 hodin).',
                                            )}
                                        </Container>
                                    </Modal>
                                </RadioButtonPayment>
                                {/* {paymentModel.countryId === LanguageType.CZ && (
                                    <RadioButtonPayment
                                        className="payMethod"
                                        id={'paymentMethod_43'}
                                        name={'paymentMethod'}
                                        value="CEBIACASH"
                                        onClick={onClick}
                                        iconVariant="cash"
                                    >
                                        <div className="w-5/12 smw:w-5/12 md:w-5/12 flex">
                                            <div className="text-inline">
                                                <span>
                                                    {tran.GetText('V hotovosti v sídle Cebia')}
                                                </span>
                                                <BubbleOpener
                                                    className="questionPayMethod"
                                                    onClickHandler={openC}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-5/12 smw:w-5/12 md:w-5/12 text-right">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: tran.GetText(
                                                        'Výsledky budou k dispozici v <a href="https://www.cebia.cz/kontakt1.html/" target="_blank" class="Link Link--lightBlue">sídle Cebia</a>',
                                                    ),
                                                }}
                                            ></span>
                                        </div>
                                        <Modal id="paymentMethodCash">
                                            <Container>
                                                {tran.GetHtml(
                                                    'Kupon AUTOTRACER uhradíte osobně v hotovosti v sídle Cebia, spol. s r.o., BB Centrum - budova Alpha, Vyskočilova 1461/2a, 140 00 Praha 4 v pracovní době pondělí až pátek od 8.00 do 16.30 hod. S sebou je nutné mít VIN prověřovaného vozidla.',
                                                )}
                                            </Container>
                                        </Modal>
                                    </RadioButtonPayment>
                                )} */}
                            </>
                        )}
                    </div>
                </ToggleItemAtr>
            </Container>
        </Section>
    )
}
