import { DashBoardCarInfoJsonResult } from './dashBoardJsonResult'

class ValidationResponseModel {
    isValid = 0
    imageData?: []
    message = ''
    carMake = ''
    carModel = ''
    carVin = ''
    viewId = ''
    countryId = 0
    isOnBlackList = 0

    constructor(item: DashBoardCarInfoJsonResult | null) {
        this.carMake = item?.carMake ?? ''
        this.carModel = item?.carModel ?? ''
        this.imageData = item?.imageData
        this.carVin = item?.vin ?? ''
    }
}
export { ValidationResponseModel }
