import { Translate, _, __ } from '../../translations/CebiaTranslator'

interface reviewDefinitionType {
    id: number
    stars: number
    text: string
    author: string
}

export class reviewDefinitions {
    GetData(): Array<reviewDefinitionType> {
        const tran = new Translate()
        const data: Array<reviewDefinitionType> = [
            {
                id: 1,
                stars: 5,
                text: tran.GetText(
                    'Super služba, vyplatí se ji pořídit. Zjistila jsem manipulaci s tachometrem i u předváděcího vozidla.',
                ),
                author: 'Renault Clio 2021',
            },
            {
                id: 2,
                stars: 5,
                text: tran.GetText(
                    'Chtěla jsem si koupit auto, o kterém mi tvrdili, že není havarované. Bylo havarované několikrát.',
                ),

                author: 'Hyundai i40 2017',
            },
            {
                id: 3,
                stars: 5,
                text: tran.GetText(
                    'Naprosto perfektní věc, nekoupili jsme za mnoho peněz bourané auto, díky!',
                ),
                author: 'BMW 320d 2015',
            },
            {
                id: 4,
                stars: 4,
                text: tran.GetText(
                    'Vybral jsem si vůz, kde nebylo uvedeno, že je po dvou nehodách s vysokou škodou a zde sem to objevil ještě před koupí. Děkuji.',
                ),
                author: 'Ford Focus 2009',
            },
            {
                id: 5,
                stars: 5,
                text: tran.GetText(
                    'Děkuji za tento program. Málem jsem přišla o 90 tisíc za auto. Auto prý jen lehce ťuknuté ze předu,podle majitele. Podle programu 2x bourané s velkou škodou.',
                ),
                author: 'Škoda Fabia 2012',
            },
            {
                id: 6,
                stars: 4,
                text: tran.GetText('Ověření mě uklidnilo a pomohlo mi se rozhodnout ke koupi.'),
                author: 'Škoda Octavia 2012',
            },
            {
                id: 7,
                stars: 5,
                text: tran.GetText('Zjištěno bourané auto a přetočený tachometr. Děkuji.'),
                author: 'VW Passat 2012',
            },
            {
                id: 8,
                stars: 4,
                text: tran.GetText(
                    'Objevilo se rozsáhle poškození o kterém se mi prodejce nezmínil.',
                ),
                author: 'Ford EcoSport 2018',
            },
            {
                id: 9,
                stars: 5,
                text: tran.GetText('Splnilo očekávání, našlo poškození vozidla. Děkuji.'),
                author: 'Škoda Kodiaq 2017',
            },
            {
                id: 10,
                stars: 4,
                text: tran.GetText(
                    'Zjištěná menší kolize + kontrola tachometru od data registrace.',
                ),
                author: 'BMW 330i 2007',
            },
            {
                id: 11,
                stars: 5,
                text: tran.GetText(
                    'Díky aplikaci jsem se dozvěděla, že s autem cosi bylo. Prodejce mi o tom samozřejmě nic neřekl a prodávající asi také mlčel. Děkuji.',
                ),
                author: 'Škoda Rapid 2016',
            },
            {
                id: 12,
                stars: 5,
                text: tran.GetText(
                    'Při kontrole jsem zjistil závažné nedostatky, které mi prodávající zatajil, např. stav tachometru, bourané vozidlo v minulosti.',
                ),
                author: 'Fiat Ducato 2018',
            },
            {
                id: 13,
                stars: 5,
                text: tran.GetText(
                    'Zistil som že vozidlo ma stočené kilometre. Predajca uvadza 155.000 km a reálně má tak 250.000 km...',
                ),
                author: 'Volkswagen Multivan 2003',
            },
        ]
        return data
    }
}
