import { ReactElement } from 'react'
import { Translate, _, __ } from '../../translations/CebiaTranslator'
import useScript from '../../hooks/useScript'
import { LanguageDefinition, LanguageEnum, i18n } from '../../translations/i18n'
import classNames from 'classnames'
import './index.styl'

type GoogleWidgetHPProps = {
    hiddenUntilLarge: boolean
}

export const GoogleWidgetHP = ({ hiddenUntilLarge }: GoogleWidgetHPProps): ReactElement => {
    const tran = new Translate()
    useScript('https://static.elfsight.com/platform/platform.js', [
        'data-use-service-core',
        'defer',
    ])

    const className = classNames(
        `${hiddenUntilLarge ? 'mdMax:hidden' : 'lg:hidden xl:hidden googleWidget pt-30'}`,
    )

    return (
        <div className={className}>
            <>
                <div
                    className={
                        LanguageDefinition[LanguageEnum[i18n.language as keyof typeof LanguageEnum]]
                            ?.googleWidgetHP ?? ''
                    }
                    data-elfsight-app-lazy
                ></div>
            </>
        </div>
    )
}
