import './index.styl'
import { ReactElement, ReactNode } from 'react'
import { Visual, VisualProps } from '../Visual'
import classNames from 'classnames'
import { Shape, ShapeProps } from '../Shape'

type Props = {
    variant?: 'elevated'
    variantSize?: 'half'
    variantColor?: 'dark'
    mobileSnap?: boolean
    withShapes?: boolean
    visual: VisualProps
    visualCaption?: string
    visualShape?: ShapeProps
    children: ReactNode
}

export const VisualBlock = ({
    variant,
    variantSize,
    variantColor,
    withShapes = false,
    mobileSnap = false,
    visual,
    visualCaption = '',
    visualShape,
    children,
}: Props): ReactElement => {
    const className = classNames('VisualBlock', {
        [`VisualBlock--${variant}`]: variant,
        [`VisualBlock--${variantSize}`]: variantSize,
        [`VisualBlock--${variantColor}`]: variantColor,
        [`VisualBlock--mobileSnap`]: mobileSnap,
    })
    return (
        <div className={className}>
            {withShapes && (
                <>
                    <Shape className="VisualBlock-shapeGreen" variant="green" />
                    <Shape
                        className="VisualBlock-shapeLightBlueGradient"
                        variant="lightBlueGradient"
                    />
                    <Shape className="VisualBlock-shapeGreenLine" variant="greenLine" />
                    <Shape className="VisualBlock-shapeBlueLine" variant="blueLine" />
                </>
            )}
            <div className="VisualBlock-text">{children}</div>
            <div className="VisualBlock-visual">
                <figure className="VisualBlock-figure">
                    <Visual {...visual} />
                    <figcaption
                        className="VisualBlock-figcaption"
                        dangerouslySetInnerHTML={{ __html: visualCaption }}
                    ></figcaption>
                    {visualShape && <Shape className="VisualBlock-visualShape" {...visualShape} />}
                </figure>
            </div>
        </div>
    )
}
