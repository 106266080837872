import { useQuery } from 'react-query'
import { Version } from '../models/version'
import http from './http-common'
export const getVersion = () => {
    let retVal: Version | undefined
    const { data, error, isError, refetch, isLoading } = useQuery(
        'getVersion',
        () => http.get<Version>('/Support/getversion'),
        {
            enabled: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            cacheTime: 86400000, //den
        },
    )

    if (!isError) {
        retVal = data?.data
    }

    return {
        version: retVal,
        versionRefetch: refetch,
        versionIsLoading: isLoading,
        versionError: isError,
    }
}
