import { DetectorOptions } from 'i18next-browser-languagedetector'
import { LanguageEnum } from './i18n'

export default {
    name: 'customDetector',

    lookup(options: DetectorOptions) {
        if (
            window.location.hostname === 'zkontrolujsi.cz' ||
            window.location.hostname === 'auto-tracer.com'
        ) {
            return 'al'
        }
        const found = 'en'
        if (typeof window !== 'undefined') {
            const language = window.location.hostname.split('.')
            if (language[0] === 'ua') return 'uk'
            if (language instanceof Array && language.length) {
                if (Object.keys(LanguageEnum).find(x => x.toString() === language[0])) {
                    return language[0]
                }
            }
        }
        const name = 'i18next'
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
        if (match) {
            if (Object.keys(LanguageEnum).find(x => x === match[2])) {
                return match[2]
            }
        }
        if (navigator && navigator.language) {
            if (Object.keys(LanguageEnum).find(x => x === navigator.language)) {
                return navigator.language
            }
            if (navigator.language === 'cs') return 'cz'
        }
        return found
    },
}
