import './index.styl'
import { ReactElement } from 'react'
import classNames, { Argument } from 'classnames'

type Props = {
    className?: Argument
}

export const PulseDot = ({ className: _className }: Props): ReactElement => {
    const className = classNames('PulseDot', _className)
    return <span className={className} aria-hidden="true"></span>
}
