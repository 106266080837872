import './index.styl'
import { ReactElement } from 'react'
import { EuropeMap } from '../../../../components/EuropeMap'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Translate } from '../../../../translations/CebiaTranslator'

export const SectionMap = (): ReactElement => {
    const tran = new Translate()
    return (
        <Section className="SectionMap">
            <Container>
                <div className="Grid md:gap-50 gap-80 gmb-30">
                    <div className="Grid-cell md:w-4/12">
                        <h2 className="Gama color-darkBlue mb-10">
                            <strong>{tran.GetText('Záznamy pochází z 32 zemí')}</strong>
                        </h2>
                        <p>
                            {tran.GetHtml(
                                'Cebia má více než <strong>2 000 partnerů</strong> z celé Evropy, kteří poskytují záznamy o vozidlech do systému AUTOTRACER.',
                            )}
                        </p>
                        <div className="SectionMap-quote color-darkBlue">
                            <strong className="block Gama mb-10">
                                {tran.GetText('Víte, že...')}
                            </strong>
                            <p>
                                {tran.GetText(
                                    'Systém AUTOTRACER prověřuje každý vůz v databázi poškozených vozidel s více než 200 miliony záznamů z 32 zemí? Nejvíce záznamů (62 %) pochází z Francie a Německa.',
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="Grid-cell md:w-8/12">
                        <EuropeMap></EuropeMap>
                    </div>
                </div>
            </Container>
        </Section>
    )
}
