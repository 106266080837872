import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import './body.styl'
import { SectionLienRights } from '../../../../detailPaid/atr-items/SectionLienRights'

let PrintAllowIncrement = true

export const LienRights = ({
    sourceData,
    tran,
    captionNumberRef,
}: PrintDataProps): ReactElement => {
    if (!sourceData?.lienRightsControlResult) return <></>

    const lienRightsData = new PayedJsonResult()
    lienRightsData.lienRightsControlResult = sourceData.lienRightsControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola zástavních práv')}
            </h2>

            <Section className="LienRightsSection">
                <SectionLienRights sourceData={lienRightsData} isPrint={true} />
            </Section>
        </div>
    )
}
