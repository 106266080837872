import './index.styl'
import { ReactElement, ReactNode, RefObject } from 'react'
import { Shape } from '../Shape'
import classNames from 'classnames'

export type PosterProps = {
    variantDisplay: 'static' | 'fixed'
    variant?: 'formVin' | 'basicList'
    withShapes?: boolean
    children: ReactNode
    customRef?: RefObject<HTMLDivElement>
    active?: boolean
}

export const Poster = ({
    variant,
    variantDisplay,
    withShapes = false,
    children,
    active = false,
    customRef,
}: PosterProps): ReactElement => {
    const className = classNames('Poster', {
        [`Poster--${variant}`]: variant,
        [`is-${variantDisplay}`]: variantDisplay,
        'is-active': active,
    })

    return (
        <div className={className} ref={customRef}>
            {withShapes && (
                <>
                    <div className="Poster-shapes Poster-shapes--inside">
                        <Shape className="Poster-shapeBlueGradient" variant="darkBlueGradient" />
                        <Shape
                            className="Poster-shapeDarkBlueGradient"
                            variant="darkBlueGradient"
                        />
                    </div>
                    <div className="Poster-shapes">
                        <Shape className="Poster-shapeGreenLine" variant="greenLine" />
                        <Shape className="Poster-shapeBlueLine" variant="blueLine" />
                    </div>
                </>
            )}

            <div className="Poster-inner">{children}</div>
        </div>
    )
}
