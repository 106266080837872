import axios, { AxiosInstance } from 'axios'
import { i18n } from '../translations/i18n'

export default axios.create({
    baseURL: `${process.env.PUBLIC_URL}/`,
    headers: {
        'Content-type': 'application/json',
        appLang: getCoutryToLanguageCode(i18n.language),
    },
})

export function getCoutryToLanguageCode(value: string): string {
    switch (value) {
        case 'en':
            return 'us'
        case 'uk':
            return 'ua'
        default:
            return value
    }
}

export function getLanguageCode(value: string): string {
    switch (value) {
        case 'cz':
            return 'cs'
        case 'al':
            return 'sq'
        default:
            return value
    }
}

export async function createHttpClient(): Promise<AxiosInstance> {
    const csrfToken = await getCsrfToken()

    const instance = axios.create({
        baseURL: `${process.env.PUBLIC_URL}/`,
        headers: {
            'Content-type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
            appLang: getCoutryToLanguageCode(i18n.language),
        },
    })

    instance.interceptors.request.use(async config => {
        config.headers['X-XSRF-TOKEN'] = await getCsrfToken()
        return config
    })

    return instance
}

export async function getCsrfToken(): Promise<string> {
    try {
        const response = await axios.get('/antiforgery/token')
        return response.data.token
    } catch (error) {
        console.error('Error fetching CSRF token:', error)
        throw error
    }
}
