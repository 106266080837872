import './index.styl'
import { ReactElement } from 'react'
import { Table } from '../../../../components/Table'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import moment from 'moment'

export const SectionServiceRec = ({ sourceData }: DetailPayedProps): ReactElement => {
    const tran = new Translate()
    return (
        <>
            <p className="text-justify">
                {(sourceData?.serviceControlResult?.serviceRecords?.length ?? 0) > 0
                    ? tran.GetText(
                          'Byly nalezeny záznamy o servisních úkonech, které byly v minulosti na vozidle provedeny v rámci jeho oprav či běžného servisu.',
                      )
                    : tran.GetText(
                          'V databázích servisních úkonů nebyly nalezeny žádné záznamy. Vyžádejte si k vozidlu výpis ze servisní knihy (nejlépe z elektronické servisní knihy). Pravost servisních úkonu lze zkontrolovat přímo v autoservisu, kde byly dle servisní knihy provedeny.',
                      )}
            </p>

            {(sourceData?.serviceControlResult?.serviceRecords?.length ?? 0) > 0 &&
                sourceData?.serviceControlResult?.serviceRecords?.map((item, index) => (
                    <div className="mb-30 pb-none" id={item.clientId} key={`tableDamage_${index}`}>
                        <Table variant="half" className="mb-20 md:mb-50">
                            <thead>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        {tran.GetText('Číslo záznamu') +
                                            ' ' +
                                            `${index + 1} / ${
                                                sourceData.serviceControlResult!.serviceRecords!
                                                    .length
                                            }`}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{tran.GetText('Datum')}</td>
                                    <td>{`${moment(item.serviceDate).format('MM/YYYY')}`}</td>
                                </tr>
                                {item.tachoKm > 0 && (
                                    <tr>
                                        <td>{tran.GetText('Stav tachometru:')}</td>
                                        <td>{`${tran.GetNumber(item.tachoKm)} ${tran.GetText(
                                            'km',
                                        )}`}</td>
                                    </tr>
                                )}

                                {(item.materials?.length ?? 0) > 0 && (
                                    <>
                                        {item?.materials?.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    {index === 0 && (
                                                        <td
                                                            rowSpan={item.materials?.length}
                                                            style={{
                                                                verticalAlign:
                                                                    row.length > 1 ? 'top' : '',
                                                                paddingTop: '10px',
                                                            }}
                                                        >
                                                            {tran.GetText('Materiál (výčet)')}
                                                        </td>
                                                    )}
                                                    <td>{row}</td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}

                                {(item.works?.length ?? 0) > 0 && (
                                    <>
                                        {item?.works?.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    {index === 0 && (
                                                        <td
                                                            rowSpan={item.works?.length}
                                                            style={{
                                                                verticalAlign:
                                                                    row.length > 1 ? 'top' : '',
                                                                paddingTop: '10px',
                                                            }}
                                                        >
                                                            {tran.GetText('Práce (výčet)')}
                                                        </td>
                                                    )}
                                                    <td>{row}</td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </div>
                ))}
            {(sourceData?.serviceControlResult?.serviceRecords?.length ?? 0) > 0 && (
                <p className="text-justify">
                    {tran.GetHtml(
                        "<span class='warning'>Poznámka:</span> V systému nemusí být k dispozici všechny servisní záznamy vozidla. Doporučujeme porovnat záznamy se servisní knihou nebo se obrátit na příslušný autorizovaný servis značky se žádostí o výpis z digitální servisní knihy vozidla.",
                    )}
                </p>
            )}
        </>
    )
}
