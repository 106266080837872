import { ReactElement } from 'react'
import './index.styl'
import { Translate } from '../../translations/CebiaTranslator'
import { BubbleOpener } from '../BubbleOpener'
import { Modal, useModal } from '../Modal'
import { Container } from '../Container'
import { Button } from '../Button'
import { SaleSVGShape } from './SaleSVGShape'
import { DashBoardJsonResult } from '../../models/dashBoardJsonResult'
import { SaleBackgroundSVG } from './SaleBackground'

type Props = {
    priceInfo: DashBoardJsonResult
    nextSale: number | undefined
}

export const AtrOverviewSale = ({ priceInfo, nextSale }: Props): ReactElement => {
    const tran = new Translate()
    const { open: openModal, close: closeModal } = useModal('saleModal')
    const saleNumber =
        priceInfo?.sale?.nl_sleva_proc ?? 0 > 0 ? priceInfo.sale.nl_sleva_proc : nextSale

    let saleDescription = ''
    if (saleNumber) {
        switch (Math.floor(nextSale ?? saleNumber)) {
            case 10:
                saleDescription = tran.GetText('Sleva 10 % na další prověření')
                break
            case 20:
                saleDescription = tran.GetText('Sleva 20 % na další prověření')
                break
            case 30:
                saleDescription = tran.GetText('Sleva 30 % na další prověření')
                break
            case 35:
                saleDescription = tran.GetText('Sleva 35 % na další prověření')
                break
            case 40:
                saleDescription = tran.GetText('Sleva 40 % na další prověření')
                break
            case 50:
                saleDescription = tran.GetText('Sleva 50 % na další prověření')
                break
        }
    } else if (priceInfo.sale.nd_sleva_pevna > 0) {
        switch (Math.round(priceInfo.sale.nd_sleva_pevna)) {
            case 100:
                saleDescription = tran.GetText('Sleva 100 Kč na další prověření')
                break
        }
    }

    let salePrice = 0.0
    if (nextSale) {
        try {
            salePrice = parseFloat(
                (
                    (priceInfo.standardPrice ?? 0) -
                    parseFloat(
                        (((priceInfo.standardPrice ?? 0) / 100) * nextSale).toFixed(
                            priceInfo?.decimalPlaces,
                        ),
                    )
                ).toFixed(priceInfo.decimalPlaces),
            )
        } catch {
            salePrice = 0.0
        }
    } else if (priceInfo.sale.nd_sleva_pevna > 0) {
        salePrice = (priceInfo.standardPrice ?? 599.0) - Math.round(priceInfo.sale.nd_sleva_pevna)
    }

    return (
        <div className="AtrOverviewSale Sale">
            <SaleBackgroundSVG priceInfo={priceInfo} nextSale={nextSale}></SaleBackgroundSVG>
            <div className="Sale-description">
                <span className="Sale-description-label">
                    {Math.floor(salePrice ?? 0) > 0 ? (
                        <>
                            {tran.GetText('Další proveření')} <br />
                            {tran.GetText('za')}{' '}
                            <b>
                                {salePrice} {priceInfo.currency}
                            </b>
                        </>
                    ) : (
                        tran.GetHtml('Další proveření <br/> <b>se slevou!</b>')
                    )}
                </span>
                <div className="Sale-Shape">
                    <SaleSVGShape priceInfo={priceInfo} nextSale={nextSale}></SaleSVGShape>
                </div>
            </div>
            <BubbleOpener
                onClickHandler={openModal}
                className="Gray AtrOverviewSale-bubble"
            ></BubbleOpener>

            <Modal id="saleModal">
                <Container>
                    <h2>{saleDescription}</h2>
                    <p className="mt-20">
                        {tran.GetText(
                            'Nákupem prověření získáváte automaticky slevu na další prověření jakéhokoliv dalšího vozidla. Platnost slevy je 30 dní od nákupu.',
                        )}
                    </p>
                    <div className="Grid-cell md:w-12/12 mt-20 button">
                        <Button
                            as="button"
                            variantColor="blue"
                            variantSize="small"
                            onClick={() => {
                                closeModal()
                            }}
                        >
                            {tran.GetText('Zavřít')}
                        </Button>
                    </div>
                </Container>
            </Modal>
        </div>
    )
}
