import './index.styl'
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import classNames, { Argument } from 'classnames'
import { ToggleCluster, useToggleGroup } from '../../hooks/useToggleGroup'
import { scrollToElement } from '../../services/Scroll'

export type ToggleItemProps = {
    children?: ReactNode
    id: string
    active?: boolean
    cluster?: ToggleCluster
    className?: Argument
}

export const ToggleItem = ({
    id,
    children,
    cluster,
    active = false,
    className: _className,
}: ToggleItemProps): ReactElement => {
    const [isFlashing, setIsFlashing] = useState<boolean>(false)
    const rootRef = useRef<HTMLDivElement | null>(null)
    const { toggleGroup, toggleGroupActions } = useToggleGroup(id, cluster, active)

    useEffect(() => {
        toggleGroupActions.setActive(toggleGroup.active)
    }, [])

    useEffect(() => {
        if (toggleGroup.active && toggleGroup.shouldScroll && rootRef && rootRef.current) {
            setTimeout(() => {
                if (toggleGroup.active && toggleGroup.shouldScroll && rootRef && rootRef.current) {
                    scrollToElement(rootRef.current.parentNode, {
                        mode: 'auto',
                    })
                }
            }, 0)
        }

        if (toggleGroup.shouldFlash) {
            setIsFlashing(true)
        }

        if (toggleGroup.active) {
            rootRef?.current?.parentElement?.dispatchEvent(
                new CustomEvent('toggleitem-childopen', {
                    bubbles: true,
                    detail: {
                        id: id,
                    },
                }),
            )
        }
    }, [toggleGroup])

    useEffect(() => {
        const handleChildOpen = () => {
            const active = true
            const shouldFlash = false
            const shouldScroll = false

            toggleGroupActions.setActive(active, shouldFlash, shouldScroll)
        }

        rootRef.current?.addEventListener('toggleitem-childopen', handleChildOpen as EventListener) //kdyz bude chut, tak override lib.dom.d.ts a zrusit assertion. Neslo mi to...

        return () => {
            rootRef.current?.removeEventListener(
                'toggleitem-childopen',
                handleChildOpen as EventListener,
            )
        }
    }, [rootRef])

    const className = classNames(
        'ToggleItem',
        {
            'is-active': toggleGroup.active,
            'is-flashing': isFlashing,
        },
        _className,
    )

    return (
        <div
            id={id}
            className={className}
            ref={rootRef}
            onAnimationEnd={() => setIsFlashing(false)}
        >
            {children}
        </div>
    )
}
