import { useQuery } from 'react-query'
import { AppModel } from '../models/appModel'
import { ReloadDataResponseModel } from '../models/reloadDataModel'
import http from './http-common'

export const getData = (requestId: string, connectionId?: string) => {
    let retVal: ReloadDataResponseModel | undefined = undefined
    const { data, error, isError, isSuccess, refetch } = useQuery(
        'reloadData',
        () =>
            http.get<ReloadDataResponseModel>(
                `/DashBoard/RealoadData?requestId=${requestId}&clientConnectionId=${connectionId}`,
            ),
        {
            enabled: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    )
    if (!isError) {
        //zaplaceno
        retVal = data?.data
    }
    return { retVal: retVal, refetch: refetch, isSuccess: isSuccess }
}
