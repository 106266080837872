import { useQuery } from 'react-query'
import { PrintDataModel } from '../models/Prints/printDataModel'
import http from './http-common'

export const usePayedDetailPrintData = (requestId: string) => {
    return useQuery(
        ['printPayedData', requestId],
        () => {
            return http.post(`/payed/getPrintData?requestId=${requestId}`, null)
        },
        {
            enabled: (requestId?.length ?? 0) > 0,
        },
    )
}

export const usePayedIdentificationPrintData = (requestId: string) => {
    return useQuery(
        ['printIdentificationData', requestId],
        () => {
            return http.post(`/payed/getIdentificationPrintData?requestId=${requestId}`, null)
        },
        {
            enabled: (requestId?.length ?? 0) > 0,
        },
    )
}

export const useTachoStatesPrintData = (requestId: string) => {
    return useQuery(
        ['printTachoStatesData', requestId],
        () => {
            return http
                .get(`/payed/getTachoStatesPrintData?requestId=${requestId}`)
                .catch(error => {})
        },
        {
            enabled: (requestId?.length ?? 0) > 0,
            onError: error => {},
        },
    )
}
