import { Environment, LanguageEnum } from '../translations/i18n'

export function redirectDotazLang(
    countryId: number,
    pathName?: string,
    definitelyChange?: boolean,
) {
    const hostnameSplit = window.location.hostname.split('.')
    let language = ''
    let newDomain =
        process.env.NODE_ENV !== Environment.devel
            ? countryId === 3
                ? `${process.env.REACT_APP_AL_DOMAIN}`
                : `${process.env.REACT_APP_DOMAIN}`
            : window.location.host
    let change = definitelyChange ?? false
    if (hostnameSplit instanceof Array && hostnameSplit.length) {
        if (Object.keys(LanguageEnum).find(x => x.toString() === hostnameSplit[0])) {
            language = hostnameSplit[0]
        }
        if (hostnameSplit[0] == 'ua') language = hostnameSplit[0]
    }
    if (language) {
        switch (countryId) {
            case 202:
                if (language !== LanguageEnum.en || change) {
                    newDomain = LanguageEnum.en + '.' + newDomain
                    change = true
                }
                break
            case 157:
                if (language !== LanguageEnum.de || change) {
                    newDomain = LanguageEnum.de + '.' + newDomain
                    change = true
                }
                break
            case 184:
                if (language !== LanguageEnum.ro || change) {
                    newDomain = LanguageEnum.ro + '.' + newDomain
                    change = true
                }
                break
            case 43:
                if (language !== LanguageEnum.cz || change) {
                    newDomain = LanguageEnum.cz + '.' + newDomain
                    change = true
                }
                break
            case 198:
                if (language !== LanguageEnum.sk || change) {
                    newDomain = LanguageEnum.sk + '.' + newDomain
                    change = true
                }
                break
            case 3:
                if (language !== LanguageEnum.al || change) {
                    change = true
                }
                break
            case 60:
                if (language !== LanguageEnum.fr || change) {
                    newDomain = LanguageEnum.fr + '.' + newDomain
                    change = true
                }
                break
            case 90:
                if (language !== LanguageEnum.it || change) {
                    newDomain = LanguageEnum.it + '.' + newDomain
                    change = true
                }
                break
            case 237:
                if (language !== 'ua' || change) {
                    newDomain = 'ua' + '.' + newDomain
                    change = true
                }
                break
            case 163:
                if (language !== LanguageEnum.nl || change) {
                    newDomain = LanguageEnum.nl + '.' + newDomain
                    change = true
                }
                break
        }
        if (change)
            window.location.assign(
                'https://' +
                    newDomain +
                    ((pathName?.length ?? 0) > 0 ? pathName : window.location.pathname),
            )
    } else {
        if (change)
            window.location.assign(
                'https://' +
                    newDomain +
                    ((pathName?.length ?? 0) > 0 ? pathName : window.location.pathname),
            )
    }
}

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}
