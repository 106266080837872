import './index.styl'
import { ReactElement, ReactNode } from 'react'
import { Shape } from '../Shape'
import classNames from 'classnames'

type Props = {
    variant?: '1' | '2'
    children?: ReactNode
}

export const Shapes = ({ variant = '1', children }: Props): ReactElement => {
    const className = classNames('Shapes', {
        [`Shapes--${variant}`]: variant,
    })

    return (
        <div className={className}>
            {variant === '1' && (
                <>
                    <Shape className="Shapes-shapeLightBlue" variant="lightBlue" />
                    <Shape className="Shapes-shapeBlue" variant="blue" />
                    <Shape className="Shapes-shapeGreen" variant="green" />
                    <Shape className="Shapes-shapeGreenLine" variant="greenLine" />
                    <Shape className="Shapes-shapeBlueLine" variant="blueLine" />
                </>
            )}
            {variant === '2' && (
                <>
                    <Shape className="Shapes-shapeBlue" variant="blue" />
                    <Shape className="Shapes-shapeGreen" variant="green" />
                    <Shape className="Shapes-shapeGreenLine" variant="greenLine" />
                    <Shape className="Shapes-shapeBlueLine" variant="blueLine" />
                </>
            )}

            {children && <div className="Shapes-content">{children}</div>}
        </div>
    )
}
