import { Translate, _, __ } from '../../translations/CebiaTranslator'
interface linksTextType {
    url: string
    title: string
    modalContact?: boolean
    type?: string
}
export class LinksTexts {
    GetMainLinks(): Record<string, linksTextType[]> {
        const tran = new Translate()

        const data: Record<string, linksTextType[]> = {
            cz: [
                {
                    url: 'https://www.cebia.cz/autorizovana-pracoviste',
                    title: 'Autorizovaná pracoviště',
                },
                {
                    url: 'https://www.cebia.cz/kontakty',
                    title: 'Kontakty',
                },
                {
                    url: 'https://www.cebia.cz/o-nas#media',
                    title: 'Pro média',
                },
                {
                    url: 'https://www.cebia.cz/o-nas#volne-pozice',
                    title: 'Kariéra',
                },
                {
                    url: 'https://www.cebia.cz/overeni-osvedceni',
                    title: 'Ověření osvědčení',
                },
                {
                    url: 'https://www.cebia.cz/helpdesk-ocis',
                    title: 'HelpDesk OCIS',
                },
                {
                    url: 'https://www.cebia.cz/pruvodce/partner-eu',
                    title: 'Partner EU',
                },
                {
                    url: 'https://www.cebia.cz/pravni-informace',
                    title: 'Právní informace',
                },
                {
                    url: 'https://www.cebia.cz/kontakty#reklamace',
                    title: 'Reklamace a stížnosti',
                },
                {
                    url: 'https://cebia.com/checklease/frmHledej.aspx',
                    title: 'CheckLease',
                },
                {
                    url: 'https://www.cebianet.cz',
                    title: 'CebiaNET',
                },
                {
                    url: 'https://www.cebia.cz/mapa-stranek',
                    title: 'Mapa stránek',
                },
                {
                    url: 'https://www.cebia.cz/pravni-informace/cookies ',
                    title: 'Nastavení cookies',
                },
                {
                    url: 'https://www.cebia.cz/sluzby',
                    title: 'Služby',
                },
            ],
            sk: [
                {
                    url: `#`,
                    title: tran.GetText('Pro podnikatele'),
                    modalContact: true,
                },
                {
                    url: `https://sk.${process.env.REACT_APP_DOMAIN}/payed/detail/exampleSK`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/blog', title: tran.GetText('Blog') },
                { url: '/about', title: tran.GetText('O nás') },
            ],
            en: [
                {
                    url: `#`,
                    title: tran.GetText('Pro podnikatele'),
                    modalContact: true,
                },
                {
                    url: `https://en.${process.env.REACT_APP_DOMAIN}/payed/detail/exampleUS`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/blog', title: tran.GetText('Blog') },
                { url: '/about', title: tran.GetText('O nás') },
            ],
            de: [
                {
                    url: `#`,
                    title: tran.GetText('Pro podnikatele'),
                    modalContact: true,
                },
                {
                    url: `https://de.${process.env.REACT_APP_DOMAIN}/payed/detail/exampleDE`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/blog', title: tran.GetText('Blog') },
                { url: '/about', title: tran.GetText('O nás') },
            ],
            ro: [
                {
                    url: `#`,
                    title: tran.GetText('Pro podnikatele'),
                    modalContact: true,
                },
                {
                    url: `https://ro.${process.env.REACT_APP_DOMAIN}/payed/detail/exampleRO`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/blog', title: tran.GetText('Blog') },
                { url: '/about', title: tran.GetText('O nás') },
            ],
            al: [
                {
                    url: `https://${process.env.REACT_APP_AL_DOMAIN}/payed/detail/exampleAL`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/blog', title: tran.GetText('Blog') },
                { url: '/about', title: tran.GetText('O nás') },
            ],
            uk: [
                {
                    url: `#`,
                    title: tran.GetText('Pro podnikatele'),
                    modalContact: true,
                },
                {
                    url: `https://ua.${process.env.REACT_APP_DOMAIN}/payed/detail/exampleUA`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/about', title: tran.GetText('O nás') },
                { url: '/blog ', title: tran.GetText('Blog') },
            ],
            fr: [
                {
                    url: `#`,
                    title: tran.GetText('Pro podnikatele'),
                    modalContact: true,
                },
                {
                    url: `https://fr.${process.env.REACT_APP_DOMAIN}/payed/detail/exampleFR`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/blog', title: tran.GetText('Blog') },
                { url: '/about', title: tran.GetText('O nás') },
            ],
            it: [
                {
                    url: `#`,
                    title: tran.GetText('Pro podnikatele'),
                    modalContact: true,
                },
                {
                    url: `https://it.${process.env.REACT_APP_DOMAIN}/payed/detail/exampleIT`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/blog', title: tran.GetText('Blog') },
                { url: '/about', title: tran.GetText('O nás') },
            ],
            nl: [
                {
                    url: `#`,
                    title: tran.GetText('Pro podnikatele'),
                    modalContact: true,
                },
                {
                    url: `https://nl.${process.env.REACT_APP_DOMAIN}/payed/detail/exampleNL`,
                    title: tran.GetText('Ukázka prověření'),
                },
                { url: '/faqs', title: tran.GetText('Časté dotazy') },
                { url: '/blog', title: tran.GetText('Blog') },
                { url: '/about', title: tran.GetText('O nás') },
            ],
        }
        return data
    }

    GetServiceLinks(): Record<string, linksTextType[]> {
        const tran = new Translate()

        const data: Record<string, linksTextType[]> = {
            cz: [
                {
                    url: 'https://cz.cebia.com',
                    title: 'Online prověření historie AUTOTRACER',
                },
                {
                    url: 'https://www.cebia.cz/cebia-smart-kod',
                    title: 'Cebia Smart kód',
                },
                {
                    url: 'https://www.cebia.cz/sluzby/provin',
                    title: 'Prověření v zemi původu PROVIN',
                },
                {
                    url: 'https://www.cebia.cz/sluzby/vintest',
                    title: 'Fyzická kontrola VIN a identifikátorů VINTEST',
                },
                {
                    url: 'https://www.cebia.cz/sluzby/piskovani-skel',
                    title: 'Pískování skel OCIS',
                },
                {
                    url: 'https://www.cebia.cz/sluzby/leptani-skel',
                    title: 'Leptání skel OCIS',
                },
                {
                    url: 'https://www.cebia.cz/sluzby/cebia-sat',
                    title: 'Satelitní zabezpečení',
                },
                {
                    url: 'https://www.cebia.cz/uniqa-safeline',
                    title: 'UNIQA SafeLine',
                },
                {
                    url: 'https://www.cebia.cz/allianz-bonusdrive',
                    title: 'Allianz BonusDrive',
                },
                {
                    url: 'https://www.cebia.cz/sluzby/vinfoto',
                    title: 'Prohlídka k pojištění vozidla VINFOTO',
                },
                {
                    url: 'https://exekuce.cebia.cz/pub',
                    title: 'Kontrola exekucí',
                },
                {
                    url: 'https://www.carolina.cz',
                    title: 'Mobilní aplikace Carolina',
                },
                {
                    url: 'https://www.cebianet.cz',
                    title: 'Pro partnery',
                },
            ],
            en: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/files/general_terms_and_conditions_car_history.pdf',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://en.${process.env.REACT_APP_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
            ],
            sk: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/pruvodce?id=123&do=downloadFile',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://sk.${process.env.REACT_APP_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
            ],
            de: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/pruvodce?id=107&do=downloadFile',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://de.${process.env.REACT_APP_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
                {
                    url: `/impressum`,
                    title: 'Impressum',
                },
            ],
            ro: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/pruvodce?id=110&do=downloadFile',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://ro.${process.env.REACT_APP_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
            ],
            al: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/pruvodce?id=203&do=downloadFile',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://${process.env.REACT_APP_AL_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
            ],
            uk: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/pruvodce?id=157&do=downloadFile',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://ua.${process.env.REACT_APP_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
                {
                    url: `https://ua.${process.env.REACT_APP_DOMAIN}/about/payment`,
                    title: 'Публічний договір (оферта)',
                },
            ],
            fr: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/pruvodce?id=162&do=downloadFile',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://fr.${process.env.REACT_APP_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
            ],
            it: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/pruvodce?id=160&do=downloadFile',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://it.${process.env.REACT_APP_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
            ],
            nl: [
                { url: '/about#contacts', title: tran.GetText('Kontakt') },
                { url: '/about#complaints', title: tran.GetText('Reklamace a stížnosti') },
                {
                    url: 'https://www.cebia.cz/pruvodce?id=197&do=downloadFile',
                    title: tran.GetText('Všeobecné podmínky služby'),
                },
                {
                    url: `https://nl.${process.env.REACT_APP_DOMAIN}/about/cookies`,
                    title: tran.GetText('Zásady zpracování Cookies'),
                },
            ],
        }
        return data
    }

    GetMinorLinks(): Record<string, linksTextType[]> {
        const tran = new Translate()

        const data: Record<string, linksTextType[]> = {
            sk: [
                { url: '', title: 'Služby' },
                { url: 'https://sk.cebia.com/', title: 'Car History Report' },
                {
                    url: 'https://www.cebia.cz/sluzby/vinfoto',
                    title: 'Prehliadka na poistenie vozidla VINFOTO',
                },
                {
                    url: 'https://www.cebia.cz/sluzby/homologace-vozidel',
                    title: 'Homologácie vozidiel',
                },
                {
                    url: `https://www.cebia.cz/sluzby/cebia-foto`,
                    title: 'Mobilní aplikácia Cebia Foto',
                },
            ],
        }

        return data
    }
}
