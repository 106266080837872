import './index.styl'
import { ChangeEvent, ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'
import { Field, useField } from 'formik'
import { VisualIcon } from '../VisualIcon'
import { VisualIconVariant } from '../VisualIcon/VisualIconVariant'

type Props = {
    variant?: 'dark' | 'green'
    iconVariant: VisualIconVariant
    id: string
    name: string
    required?: boolean
    className?: Argument
    children?: ReactNode
    value?: string | number
    checked?: boolean
    onClick?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const RadioButtonPayment = ({
    variant,
    iconVariant,
    className: _className,
    required = false,
    children,
    onClick,
    ...props
}: Props): ReactElement => {
    const [, meta] = useField(props.name)
    const className = classNames(
        'Radio',
        {
            [`RadioButton--${variant}`]: variant,
        },
        _className,
    )

    function handleClick(e: React.ChangeEvent<HTMLInputElement>) {
        if (onClick) {
            onClick(e)
        }
    }

    return (
        <label className={`${className} ${meta.error ? 'is-invalid' : ''}`}>
            <Field
                onClick={handleClick}
                type="radio"
                required={required}
                {...props}
                className="Radio-input"
            />
            <div className="Radio-trigger Grid gap-10 gmb-5 Grid--alignMiddle baseLine">
                <div className="Radio-title w-1/12 sm:w-1/12 smw:w-1/12 md:w-2/12">
                    <div className="w-3/12 smw:w-3/12 md:w-3/12">
                        <div className="Radio-placeholder"></div>
                    </div>
                    <div className="w-9/12 smw:w-9/12 md:w-9/12 smwMax:hidden methodItem">
                        <VisualIcon name={iconVariant} variant="inline" />
                    </div>
                </div>
                {children}
            </div>
        </label>
    )
}
