type Formats = 'd / m / Y' | 'Y-m-d'

export function formatDate(date: Date, format: Formats): string {
    if (format === 'd / m / Y')
        return `
        ${('0' + date.getDate()).slice(-2)}
        /
        ${('0' + (date.getMonth() + 1)).slice(-2)}
        /
        ${date.getFullYear()}`
    else if (format === 'Y-m-d') {
        return `
        ${date.getFullYear()}
        -
        ${('0' + (date.getMonth() + 1)).slice(-2)}
        -
        ${('0' + date.getDate()).slice(-2)}
        `
    } else {
        return ''
    }
}
