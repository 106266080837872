import './index.styl'
import { ReactElement, useState } from 'react'
import * as Yup from 'yup'
import { FormItem } from '../FormItem'
import { ErrorMessage, Form, Formik } from 'formik'
import { SubscriberValues } from '../../models/subscriberModel'
import { Checkbox } from '../Checkbox'
import SendData from '../../busLog/subscriberBL'
import { Input } from '../Input'
import { Translate } from '../../translations/CebiaTranslator'

export const NewsletterForm = (): ReactElement => {
    const tran = new Translate()
    const validSchema = createValidationSchema()
    const [submitted, setSubmitted] = useState(false)
    const [isError, setError] = useState(false)
    const [subscriberVal] = useState(new SubscriberValues())

    function createValidationSchema() {
        return Yup.object({
            emailNews: Yup.string()
                .email(tran.GetText('Email má nesprávný formát!'))
                .required(tran.GetText('Prosím zadejte položku Email')),
            isGdprNews: Yup.boolean().oneOf(
                [true],
                tran.GetText('Prosím odsouhlaste zadané údaje!'),
            ),
        })
    }

    const handleSubmit = (data: SubscriberValues) => {
        SendData.create(data)
            .then(response => {
                if (response.status == 200 && response.data != null && response.data)
                    setSubmitted(true)
                else setError(true)
            })
            .catch(() => {
                setError(true)
            })

        setSubmitted(true)
    }

    return (
        <>
            {submitted ? (
                !isError ? (
                    <p className="newsletter-Form-confirmation">Odesláno, děkujeme.</p>
                ) : (
                    <p className="newsletter-Form-confirmation InputError">
                        Bohužel nastala chyba komunikace. Prosím opakujte požadavek později.
                    </p>
                )
            ) : (
                <Formik
                    initialValues={subscriberVal}
                    validationSchema={validSchema}
                    onSubmit={values => handleSubmit(values)}
                >
                    <Form className="NewsletterForm ajax">
                        <FormItem className="NewsletterForm-formItem NewsletterForm-formItem--input">
                            <Input
                                className="NewsletterForm-input"
                                type="email"
                                placeholder={tran.GetText('Vaše e-mailová adresa')}
                                name="emailNews"
                                id="emailNews"
                                maxLength={50}
                                variant="dark"
                            />
                        </FormItem>
                        <FormItem className="NewsletterForm-formItem NewsletterForm-formItem--checkbox">
                            <Checkbox
                                className=""
                                variant="dark"
                                aria-label={tran.GetText('Souhlas se zpracováním osobních údajů')}
                                name="isGdprNews"
                                id="isGdprNews"
                            >
                                <label className="color-white" htmlFor="isGdprNews">
                                    Souhlasím se zpracováním
                                </label>{' '}
                                {tran.GetHtml(
                                    '<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/pravni-informace/osobni-udaje">osobních údajů</a>',
                                )}
                            </Checkbox>
                            <ErrorMessage
                                name="isGdprNews"
                                component="div"
                                className="InputError"
                            />
                        </FormItem>

                        <FormItem className="NewsletterForm-formItem NewsletterForm-formItem--button">
                            <button
                                type="submit"
                                className="Button NewsletterForm-button"
                                aria-label={tran.GetText('Odebírat')}
                                value={tran.GetText('Odebírat')}
                            >
                                <span className="Button-content">{tran.GetText('Odebírat')}</span>
                            </button>
                        </FormItem>
                    </Form>
                </Formik>
            )}
        </>
    )
}
