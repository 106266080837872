import './index.styl'
import { ReactElement, UIEventHandler, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official'
import classNames from 'classnames'
import { useChartOverflow } from '../Chart/useChartOverflow'
import Highcharts from 'highcharts'
import {
    ChartOptions,
    Coords,
    TimestampRange,
    getHightchartsOptions,
} from './getHightchartsOptions'

type Options = {
    pixelsPerPoint?: number
    pixelsPerPointMobile?: number
}

type Props = {
    variant?: 'short'
    data: Coords[]
    unverifiedRanges: TimestampRange[]
    options?: Options
    chartOptions?: ChartOptions
    maxValue: number
}

export const ChartSimple = ({
    variant,
    data,
    unverifiedRanges,
    options = {},
    chartOptions,
    maxValue,
}: Props): ReactElement => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<HighchartsReact.RefObject | null>(null)

    if (chartOptions?.isPrint === true) {
        chartRef.current?.chart.setSize(905, null, false)
    }

    //CUSTOM OVERFLOW
    const { pixelsPerPoint = 15, pixelsPerPointMobile = 40 } = options
    const {
        isOverflow,
        overflowDirection,
        checkOverflowDirection,
        isOverflowReady,
        overflowChildStyle,
    } = useChartOverflow({
        wrapperRef: scrollRef,
        pointCount: data.length,
        pixelsPerPoint,
        pixelsPerPointMobile,
    })

    const handleElementScroll: UIEventHandler<HTMLDivElement> = () => {
        checkOverflowDirection()
    }

    const hightchartOptions: Highcharts.Options = {
        plotOptions: {
            series: {
                animation: chartOptions?.animations ?? true,
            },
        },
    }
    const highchartsOptions = {
        ...getHightchartsOptions(data, unverifiedRanges, chartOptions, maxValue),
        ...hightchartOptions,
    }

    const className = classNames('Chart', {
        [`Chart--${variant}`]: variant,
        [`is-overflow`]: isOverflow,
        [`is-overflow-left`]: overflowDirection?.left,
        [`is-overflow-right`]: overflowDirection?.right,
    })

    return (
        <div className={className}>
            <div
                className="Chart-scroll"
                ref={scrollRef}
                onScroll={isOverflow ? handleElementScroll : undefined}
            >
                <div className="Chart-chart" style={overflowChildStyle}>
                    {isOverflowReady && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={highchartsOptions}
                            ref={chartRef}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
