import './index.styl'
import { ReactElement } from 'react'
import { Rating, RatingProps } from '../Rating'

type Props = {
    title?: string
    rating: RatingProps
    text: string
    author: string
}

export const Review = ({ title = '', text, rating, author }: Props): ReactElement => {
    return (
        <article className="Review">
            {title && (
                <header>
                    <span className="Review-title">{title}</span>
                </header>
            )}
            <div className="rating-flex">
                <Rating {...rating} />
                <p className="Review-text">{text}</p>
            </div>
            <footer className="Review-author">{author}</footer>
        </article>
    )
}
