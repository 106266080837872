import { ReactElement } from 'react'
import { ToggleOpenerGeneralVoucher } from '../../../../components/ToggleOpenerGeneralVoucher'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'

export const SectionCarDamage = ({ sourceData }: DetailPayedProps): ReactElement => {
    if (!sourceData?.otherControlResult) return <></>
    const buttonLink = `https://www.doktor-dulek.cz/cs/sluzby/opravy-dulku.html?s_coupon=${
        sourceData.otherControlResult.couponNumber ?? ''
    }`
    return (
        <>
            <ToggleOpenerGeneralVoucher
                href="#saleCarDamage"
                titleLeft="Máte auto poškozené kroupami?"
                button="Detail"
                isOpened={false}
            />
            <ToggleItemGeneral id="saleCarDamage">
                <div className="toggleContent">
                    <div className="saleCarDamage">
                        <p className="text-justify">
                            Společnost Doktor Důlek odstraní důlky po kroupách na karoserii i bez
                            lakování. Je smluvním a doporučovaným partnerem všech pojišťoven,
                            leasingových společností a makléřů. Zajistí opravu poškozeného vozu
                            rychle a šetrně, zpracuje i potřebnou agendu a škodu vyřídí s vaší
                            pojišťovnou. Doktor Důlek opravuje vozidla po celé ČR a poskytuje na
                            opravu doživotní záruku.
                        </p>
                        <p>
                            <a
                                href={buttonLink}
                                target="_blank"
                                className="Button Button--small Button--green mr-10"
                                rel="noreferrer"
                            >
                                <span className="Button-content">Opravit auto</span>
                            </a>
                        </p>
                    </div>
                </div>
            </ToggleItemGeneral>
        </>
    )
}
