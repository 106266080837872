import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { HomepageStep } from '../../../../components/HomepageStep'
import { Section } from '../../../../components/Section'
import { Title } from '../../../../components/Title'
import { SectionShape } from '../../../../components/SectionShape'
import { Translate, _, __ } from '../../../../translations/CebiaTranslator'

export const SectionSteps = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="SectionSteps" variant="medium">
            <Container variant="wide">
                <SectionShape variant="leftMiddle" />
            </Container>
            <Container>
                <div className="SectionSteps-inner">
                    <Title className="Beta mb-40" as="h2" variant="withDash">
                        {tran.GetText('Jak to funguje?')}
                    </Title>
                    <div className="Grid gap-50 gmb-50 text-left lg:text-center">
                        <HomepageStep
                            title={tran.GetText('Zadejte VIN')}
                            subtitle={tran.GetText('1. krok')}
                            isLinkVin={true}
                            text={tran
                                .GetText(
                                    'VIN je unikátní rodné číslo každého vozu. Naleznete ho v dokladech vozu nebo přímo na něm.',
                                )
                                .toString()}
                        />
                        <HomepageStep
                            title={tran.GetText('Dostupné údaje')}
                            subtitle={tran.GetText('2. krok')}
                            text={tran.GetText(
                                'Přehled dostupných kontrol je ihned k dispozici po zadání VIN.',
                            )}
                        />
                        <HomepageStep
                            title={tran.GetText('Platba')}
                            subtitle={tran.GetText('3. krok')}
                            text={tran.GetText(
                                'Zaplatit můžete přes zabezpečenou platební bránu GoPay kartou nebo převodem.',
                            )}
                        />
                        <HomepageStep
                            title={tran.GetText('Výsledek prověření')}
                            subtitle={tran.GetText('4. krok')}
                            text={tran.GetText(
                                'Výsledek prověření je k dispozici ihned po zaplacení. Samozřejmě vám jej pošleme také na email.',
                            )}
                        />
                    </div>
                </div>
            </Container>
        </Section>
    )
}
