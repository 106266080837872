import './index.styl'
import { ReactElement } from 'react'
import i18n from 'i18next'
import { Table } from '../../../../components/Table'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import { SVGCar } from '../../../../components/SVGCar'
import moment from 'moment'
import { AdvertPhotos } from './TotalDamagePhotos'
import { Section } from '../../../../components/Section'
import { LanguageEnum } from '../../../../translations/i18n'

export const SectionDamage = ({
    sourceData,
    isPrint,
    countryId,
}: DetailPayedProps): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="DamageSection">
            <div className="text-center pb-25">
                {!isPrint && <span>{tran.GetText('Výsledek kontroly')}</span>}
                {(sourceData?.damageControlResult?.damageValues?.length ?? 0) > 0 &&
                    sourceData?.damageControlResult?.damageValues != null &&
                    !sourceData?.damageControlResult?.damageValues?.find(
                        x => x.eventType == 'FL',
                    ) && (
                        <h2 className="warning font-bold">{tran.GetText('Poškození nalezeno')}</h2>
                    )}
                {(sourceData?.damageControlResult?.damageValues?.length ?? 0) === 0 && (
                    <h2 className="light-green font-bold">
                        {tran.GetText('Nebyly nalezeny záznamy o poškození')}
                    </h2>
                )}
            </div>

            {(sourceData?.damageControlResult?.damageValues?.length ?? 0) > 0 && (
                <>
                    <p className="text-justify">
                        {tran.GetHtml(
                            'V databázích poškozených vozů z více než 32 zemí čítající 200 milionů záznamů škodních událostí <span class="color-red">byl nalezen</span> jeden či více záznamů o poškození prověřovaného vozidla.',
                        )}
                    </p>
                    {!isPrint && (countryId == 43 || i18n.language == LanguageEnum.cz) && (
                        <p className="mb-20 text-justify">
                            <span className="font-bold">Jak závažné je nalezené poškození?</span>
                            <br></br>Nalezené poškození nemusí vždy znamenat, že je vozidlo špatné.
                            Jak odlišit drobné oděrky od závažného poškození se dozvíte{' '}
                            <a
                                href="https://www.cebia.cz/pruvodce/provereni-historie-odhalilo-poskozeni-co-to-znamena"
                                target="blank"
                                rel="noopener"
                            >
                                v tomto článku
                            </a>
                        </p>
                    )}
                    {isPrint && (countryId == 43 || i18n.language == LanguageEnum.cz) && (
                        <p className="mb-20 print text-justify">
                            <span className="font-bold">Jak číst záznamy o poškození</span>
                            <br></br>Nalezené poškození nemusí vždy znamenat, že je vozidlo špatné.
                            Jak odlišit drobné oděrky od závažného poškození?
                            <ul className="disc">
                                <li>
                                    Výši škody posuzujte podle{' '}
                                    <span className="font-bold"> typu vozidla a jeho stáří</span>.
                                    Ceny oprav jsou u nových a drahých vozidel výrazně vyšší než u
                                    vozidel starších/levnějších.
                                </li>
                                <li>
                                    Berte v potaz <span className="font-bold">zemi</span>, kde k
                                    poškození došlo. Ceny oprav jsou v západní Evropě vyšší než ve
                                    střední nebo východní Evropě.
                                </li>
                                <li>
                                    Dle <span className="font-bold">výše škody</span> lze poškození
                                    rozdělit do čtyř základních kategorií:
                                </li>
                                <ul className="circle">
                                    <li>Do 1 000 EUR – malé škody (např. výměna čelního skla).</li>
                                    <li>
                                        Od 1 000 EUR do 5 000 EUR – střední škody u starších/levných
                                        vozidel, malé škody u mladých/drahých vozidel.
                                    </li>
                                    <li>
                                        Nad 5 000 EUR – větší škody u starších nebo levných vozidel.
                                    </li>
                                    <li>
                                        Nad 10 000 EUR – větší škody u dražších nebo mladých
                                        vozidel.
                                    </li>
                                </ul>
                                <li>
                                    Máte-li jakékoliv pochybnosti, vyžádejte si od prodávajícího{' '}
                                    <span className="font-bold">dokumentaci k poškození</span> a
                                    jeho opravě. Nejlépe také nechte vůz zkontrolovat zkušeným
                                    technikem.
                                </li>
                            </ul>
                        </p>
                    )}
                </>
            )}
            {(sourceData?.damageControlResult?.damageValues?.length ?? 0) === 0 && (
                <p className="text-justify">
                    {tran.GetHtml(
                        'V databázích poškozených vozů z více než 32 zemí čítající 200 milionů záznamů škodních událostí <span class="light-green">nebyly nalezeny</span> záznamy o poškození prověřovaného vozidla.',
                    )}
                </p>
            )}

            {(sourceData?.damageControlResult?.damageValues?.length ?? 0) > 0 &&
                sourceData?.damageControlResult?.damageValues
                    ?.sort((firstItem, secondItem) => firstItem.dateTicks - secondItem.dateTicks)
                    .map((item, index) => (
                        <div key={`tableDamage_${index}`} className="PB-always pmb-30">
                            <div className="flex-wrap-center pageBrakeAvoid" id={item.clientId}>
                                <div className="Table pba">
                                    <div className="row-blue">
                                        {tran.GetText('Číslo záznamu') +
                                            ' ' +
                                            `${index + 1} / ${
                                                sourceData.damageControlResult!.damageValues!.length
                                            }`}
                                        {item.sourceId == 77 && <>.</>}
                                    </div>
                                    <div className="mb-30 tableContainer">
                                        <div className="flex-one-auto">
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td>{tran.GetText('Datum')}</td>
                                                        <td>
                                                            {moment(
                                                                new Date(item.dateTicks),
                                                            ).format('MM/YYYY')}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{tran.GetText('Země')}</td>
                                                        <td>{item.country}</td>
                                                    </tr>
                                                    {item.price > 0 && (
                                                        <tr>
                                                            <td>
                                                                {/*  {item.places &&
                                                                item.places
                                                                    .toUpperCase()
                                                                    .indexOf('T') > 0 &&
                                                                item.partnerId === 151
                                                                    ? tran.GetText(
                                                                          'Kalkulace nákladů na opravu',
                                                                      )
                                                                    : tran.GetText('Výše škody')} */}
                                                                {tran.GetText('Výše škody')}
                                                            </td>
                                                            <td>
                                                                {tran.GetNumber(item.price)}{' '}
                                                                {tran.GetText(item.currency)}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {item.eventType !== 'FL' && (
                                                        <tr>
                                                            <td>{tran.GetText('Druh události')}</td>
                                                            <td>
                                                                {item.eventType == 'KO'
                                                                    ? item.partnerId === 151 ||
                                                                      item.partnerId === 157
                                                                        ? tran.GetText(
                                                                              'Ekonomická totální škoda *',
                                                                          )
                                                                        : tran.GetText(
                                                                              'Pojistná událost – ekonomická totální škoda*',
                                                                          )
                                                                    : item.partnerId === 151 ||
                                                                      item.partnerId === 157
                                                                    ? tran.GetText(
                                                                          'Kalkulace nákladů na opravu',
                                                                      )
                                                                    : tran.GetText(
                                                                          'Pojistná událost',
                                                                      )}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {(item.material?.length ?? 0) > 0 &&
                                                        item.sourceId == 77 && (
                                                            <tr>
                                                                <td>{tran.GetText('Materiál')}</td>
                                                                <td>{item.material}</td>
                                                            </tr>
                                                        )}
                                                    {(item.prace?.length ?? 0) > 0 &&
                                                        item.sourceId == 77 && (
                                                            <tr>
                                                                <td>{tran.GetText('Práce')}</td>
                                                                <td>{item.prace}</td>
                                                            </tr>
                                                        )}
                                                    {(item.lakovani?.length ?? 0) > 0 &&
                                                        item.sourceId == 77 && (
                                                            <tr>
                                                                <td>{tran.GetText('Lakování')}</td>
                                                                <td>{item.lakovani}</td>
                                                            </tr>
                                                        )}
                                                    {(item.description?.length ?? 0) > 0 && (
                                                        <tr>
                                                            <td>
                                                                {tran.GetText(
                                                                    'Evidovaná místa poškození',
                                                                )}
                                                            </td>
                                                            <td>
                                                                <p>{item.description}</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {item.odometer > 0 && (
                                                        <tr>
                                                            <td>
                                                                {tran.GetText('Stav tachometru')}
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    {tran.GetNumber(item.odometer)}{' '}
                                                                    {tran.GetText('km')}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {((item.price <= 0 && item.sourceId == 77) ||
                                                        ((item.description?.length ?? 0) <= 0 &&
                                                            (item.material?.length ?? 0) <= 0)) &&
                                                        (item.photos?.advertImageUrls?.length ??
                                                            0) == 0 && (
                                                            <tr>
                                                                <td>{tran.GetText('Poznámka')}</td>
                                                                <td>
                                                                    <p>
                                                                        {item.partnerId === 151 &&
                                                                            tran.GetText(
                                                                                'U vozidla evidujeme kalkulaci poškození vytvořenou pojišťovnou nebo autoservisem, u které neznáme detail (místo poškození). Věnujte prosím pozornost prohlídce vozidla.',
                                                                            )}
                                                                        {item.partnerId !== 151 &&
                                                                            tran.GetText(
                                                                                'U vozidla evidujeme událost nahlášenou pojišťovnou, u které neznáme detail (místo poškození). Věnujte prosím pozornost prohlídce vozidla.',
                                                                            )}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="flex-text-center">
                                            {/* nejde pouzit enum protoze nazev enumu by prozradil o jaky zdroj se jedna  */}
                                            {(item?.places?.length ?? 0) > 0 &&
                                            item.places != 'U' ? (
                                                <>
                                                    <SVGCar
                                                        width={isPrint ? 200 : 220}
                                                        height={isPrint ? 200 : 220}
                                                        damagePlaces={item.places}
                                                    ></SVGCar>
                                                </>
                                            ) : (
                                                <div
                                                    className="flex-text-center"
                                                    style={{ width: '220px' }}
                                                >
                                                    &nbsp;
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {item.photos && (item.photos.advertImageUrls?.length ?? 0) > 0 && (
                                <AdvertPhotos
                                    index={index}
                                    isFleet={item.eventType == 'FL'}
                                    source={item.photos}
                                    tran={tran}
                                    isPrint={isPrint}
                                ></AdvertPhotos>
                            )}
                            {item.places && item.places.indexOf('T') !== -1 && (
                                <p className="mb-30">
                                    {tran.GetHtml(
                                        '*) Definice <strong>Ekonomické totální škody</strong> se může lišit v různých státech i pojišťovnách. <strong>I když je škoda takto vyhodnocena, nemusí to nutně znamenat, že vozidlo bylo nepojízdné nebo neopravitelné.</strong> Představuje totiž takovou škodu na vozidle, jejíž oprava je dle podmínek pojišťovny neekonomická (tzn. že částka za opravu vozidla se blíží nebo je vyšší než hodnota vozidla v době škody). V každém případě doporučujeme podrobnou kontrolu vozidla.',
                                    )}
                                </p>
                            )}
                        </div>
                    ))}
            {(sourceData?.damageControlResult?.damageValues?.length ?? 0) > 0 && (
                <p className="text-justify">
                    {tran.GetHtml(
                        "<span class='warning'>Upozornění:</span> V systému nemusí být k dispozici informace o všech poškozeních vozidla, některé škody nejsou hlášeny pojišťovnám, nebo nejsou v rámci online prověření dostupné. Doporučujeme tedy pečlivou prohlídku vozidla, ideálně s pomocí kvalifikovaného mechanika nebo ve vámi vybraném servisu. Taková prohlídka může odhalit další poškození nebo je vyloučit.",
                    )}
                </p>
            )}
            {(sourceData?.damageControlResult?.damageValues?.length ?? 0) === 0 && (
                <div className="pb-45">
                    <p className="text-justify">
                        {tran.GetHtml(
                            'I přesto vám doporučujeme pečlivou prohlídku vozidla, ideálně s pomocí kvalifikovaného mechanika nebo ve vámi vybraném servisu. Taková prohlídka může předchozí poškození jednoznačně odhalit nebo vyloučit.',
                        )}
                    </p>
                    <p className="text-justify">
                        {tran.GetHtml(
                            "<span class='warning'>Upozornění:</span> Systém AUTOTRACER nemusí mít k dispozici informace o všech poškozeních vozidla, některé škody nejsou hlášeny pojišťovnám, nebo nejsou v rámci online prověření dostupné.",
                        )}
                    </p>
                </div>
            )}
        </Section>
    )
}
