import { groupBy } from 'lodash'
import { Fragment } from 'react'
import { getCountries } from '../../../../../busLog/enums_gen'
import { CountryCode } from '../../../../../components/AtrMileageTable/countryCodes'
import { Table } from '../../../../../components/Table'
import { ToggleItemGeneral } from '../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralSimple } from '../../../../../components/ToggleOpenerGeneralSimple'
import {
    TechDescriptionItem,
    VehicleInfoControlData,
} from '../../../../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'

export type Props = {
    sourceData: VehicleInfoControlData
    tran: Translate
    isPrint?: boolean
}

export const SectionVehicleTechDesc = ({ sourceData, tran, isPrint }: Props) => {
    const countries = getCountries(tran)

    function getTable(rows: TechDescriptionItem[], item: string) {
        return (
            <>
                <div className="Table mb-20 md:mb-50 half">
                    <Table className="half">
                        <thead>
                            <tr>
                                <th colSpan={2} className="text-left">
                                    {tran.GetText('Technický popis - základní')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => {
                                if (!row.advanced) {
                                    return (
                                        <tr key={`techDescBasicRow_${index++}_${item}`}>
                                            <td>{row.name}</td>
                                            <td>{row.value}</td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </Table>
                </div>
                <div className="mb-20">
                    <Table className="half">
                        <thead>
                            <tr>
                                <th colSpan={2} className="text-left">
                                    {tran.GetText('Technický popis - rozšířený')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => {
                                if (row.advanced) {
                                    return (
                                        <tr key={`techDescAdvanceRow_${index++}_${item}`}>
                                            <td>{row.name}</td>
                                            <td>{row.value}</td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    if ((sourceData?.vehicleTechDesc?.length ?? 0) <= 0)
        return (
            <p>
                {tran.GetText(
                    'Nepodařilo se nalézt technické parametry vozidla. Naleznete je však ve velkém technickém průkazu vozidla, popřípadě je můžete vyžádat u výrobce vozidla.',
                )}
            </p>
        )

    return (
        <div className="sectionVehicleTechDesc">
            <p className="text-justify">
                {tran.GetText(
                    'Údaje z technického průkazu čerpáme od státních institucí a reflektují stav v okamžiku první registrace vozidla v dané zemi.',
                )}
            </p>
            {Object.entries(
                groupBy(sourceData.vehicleTechDesc, item => {
                    return item.language
                }),
            ).map(([item, group]) => {
                return (
                    <Fragment key={item}>
                        {!isPrint ? (
                            <>
                                <ToggleOpenerGeneralSimple
                                    href={`#vehicleTechDescToggle${item}`}
                                    countryCode={
                                        countries.find(x => x.id.toString() == item)
                                            ?.code2 as CountryCode
                                    }
                                    titleLeft={
                                        tran.GetText('Registr vozidel') +
                                        ' - ' +
                                        countries.find(x => x.id.toString() == item)?.countryName
                                    }
                                    titleRight={tran.GetText('K dispozici')}
                                    button={tran.GetText('Detail')}
                                    isOpened={false}
                                    /*isOpened={
                                        Object.entries(
                                            groupBy(sourceData.vehicleTechDesc, item => {
                                                return item.language
                                            }),
                                        ).length == 1
                                    }*/
                                />
                                <ToggleItemGeneral id={`vehicleTechDescToggle${item}`}>
                                    {getTable(group, item)}
                                </ToggleItemGeneral>
                            </>
                        ) : (
                            <>
                                <div className="Table mb-20 md:mb-50 half">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th className="text-left">
                                                    {tran.GetText('Země')}
                                                </th>
                                                <th className="text-left">
                                                    {tran.GetText('Databáze')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={`mainTableDesc_${item}`}>
                                                <td>
                                                    {
                                                        countries.find(x => x.id.toString() == item)
                                                            ?.name
                                                    }
                                                </td>
                                                <td>{tran.GetText('Státní registr vozidel')}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                {getTable(group, item)}
                            </>
                        )}
                    </Fragment>
                )
            })}
        </div>
    )
}
