import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router'
import { usePayedIdentificationPrintData } from '../../../busLog/printBL'
import { LoadArea } from '../../../components/LoadArea'
import { Translate } from '../../../translations/CebiaTranslator'
import './index.styl'
import { CarIdentificationSection } from './sections/carIdentification'
import { CouponInfoSection } from './sections/couponInfo'
import { IcarisPhotoDesc } from './sections/icarisFotoDesc'

export const PrintIdentification = (): ReactElement => {
    const { id } = useParams()
    const { isError, isLoading, data, isSuccess } = usePayedIdentificationPrintData(id!)
    const tran = new Translate()

    useEffect(() => {
        if (isSuccess) {
            setInterval(() => {
                window.status = '812b0f0a3809be580de9357a0eb49db21874ff9018d1a60e143075a893b9f943'
            }, 5000)
        }
    }, [isSuccess])

    return (
        <div className="PrintIdentification">
            {isLoading && (
                <LoadArea
                    isLoading={isLoading}
                    hideBackGround={true}
                    variant="fullscreen"
                    loader={{ variant: 'bars', size: 'big' }}
                    text={tran.GetText('Načítáme data...')}
                ></LoadArea>
            )}

            {!isLoading && isSuccess && (
                <>
                    <h1>{tran.GetText('Kontrola identifikátorů')}</h1>
                    <p>
                        {tran.GetText(
                            'Tento výpis byl vytvořen dotazem do systému AUTOTRACER, který provozuje Cebia, spol. s r.o. dle platných Všeobecných podmínek pro používání systému AUTOTRACER. Poskytnuté informace je uživatel oprávněn v celém rozsahu využívat pouze pro vlastní potřebu. Jejich další šíření či upravování bez souhlasu provozovatele je zakázáno.',
                        )}
                    </p>
                    <CouponInfoSection sourceData={data?.data} tran={tran} />
                    <CarIdentificationSection sourceData={data?.data} tran={tran} />
                    <IcarisPhotoDesc sourceData={data?.data} tran={tran} />

                    <div className="infoBottom">
                        <p>
                            {tran.GetText(
                                'Provozovatel systému AUTOTRACER - Cebia, spol. s r.o., upozorňuje uživatele systému, že uváděné informace byly poskytnuty do systému partnery provozovatele bez možnosti ověřit jejich správnost. Veškeré informace mají výhradně informativní charakter.',
                            )}
                        </p>
                        <p>
                            {tran.GetText(
                                'Na výsledek prověření vozidla v systému AUTOTRACER není poskytována záruka.',
                            )}
                        </p>
                    </div>
                </>
            )}
        </div>
    )
}
