import {} from 'react'
import { AtrSection } from '../enums/AtrSectionEnum'

export interface IAtrSection {
    sectionId: number | undefined
}

export function getSection<T extends IAtrSection>(data: Array<any>, sectionId: AtrSection): T {
    let retVal: T = null as unknown as T

    if ((data?.length ?? 0) > 0) {
        for (let i = 0; i < data.length; i++) {
            const tmpVal = data[i] as T

            if (tmpVal && tmpVal.sectionId == sectionId) retVal = tmpVal
        }
    }
    return retVal
}

// declare global {
//     interface Array<T> {
//         getSection<U>(sectionId: number): U
//     }
// }

// if (!('getSection' in Array.prototype)) {
//     Array.prototype.getSection = function getSection<T extends IAtrSection>(sectionId: number): T {
//         if ((this?.length ?? 0) <= 0) return null as unknown as T

//         for (let i = 0; i < this.length; i++) {
//             const tmpVal = this[i] as T

//             if (tmpVal && tmpVal.sectionId === sectionId) return tmpVal
//         }

//         return null as unknown as T
//     }
// }
