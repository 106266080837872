import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionLeasing } from '../../../../detailPaid/atr-items/SectionLeasing'
import './body.styl'
import { SectionExekuce } from '../../../../detailPaid/atr-items/SectionExekuce'
import { CouponType } from '../../../../../enums/CouponType'
import { i18n } from '../../../../../translations/i18n'

let PrintAllowIncrement = true

export const Exekuce = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    if (
        (sourceData && !sourceData.couponType) ||
        (sourceData && sourceData.couponType != CouponType.WebCustomer) ||
        i18n.language != 'cz'
    )
        return <></>

    const exekuceData = new PayedJsonResult()

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola exekucí')}
            </h2>

            <Section className="ExekuceSection">
                <SectionExekuce sourceData={exekuceData} isPrint={true} />
            </Section>
        </div>
    )
}
