export class TaxiControlData {
    isTaxi: boolean | undefined
    taxiItems: TaxiItem[] | undefined
}

class TaxiItem {
    countryFlagName = ''
    countryName = ''
    hasTaxiDefinition = false
    taxiDefinition: TaxiUseDefinition[] | undefined
}

class TaxiUseDefinition {
    year: number | undefined
}
