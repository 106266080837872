import './index.styl'
import { ReactElement } from 'react'
import { Button } from '../../../../components/Button'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Title } from '../../../../components/Title'
import { VisualBlock } from '../../../../components/VisualBlock'
import AssetsImgCebiaLobby from './img/cebia-lobby.webp'
import AssetsImgCebiaLobby2x from './img/cebia-lobby@2x.webp'
import AssetsImgGjirafaOffice from './img/gjirafa-office.webp'
import { useModal } from '../../../../components/Modal'
import { Translate, _, __ } from '../../../../translations/CebiaTranslator'
import { ContactForm } from '../../../faq/ContactForm'
import { i18n } from '../../../../translations/i18n'

export const SectionAboutContact = (): ReactElement => {
    const tran = new Translate()
    const { open } = useModal('contactFormModal')

    return (
        <Section className="SectionAboutContact">
            <Container variant="narrow">
                <Title as="h2" className="color-darkBlue Beta text-inherit">
                    {tran.GetText('Kontakty')}
                </Title>
                <div className="Grid gap-80 gmb-20">
                    <div className="Grid-cell md:w-7/12 Text color-darkBlue Gama font-grotesk text-center md:text-left">
                        <strong>{tran.GetText('Potřebujete poradit?')}</strong>
                        <br />
                        {tran.GetHtml(
                            'Ozvěte se nám prostřednictvím kontaktního formuláře, nebo na e-mail <a href="mailto:autotracer@cebia.cz">autotracer@cebia.cz</a>',
                        )}
                    </div>
                    <div className="Grid-cell md:w-5/12 flex justify-center smw:justify-end align-center">
                        <Button
                            as="button"
                            variantColor="lightBlue"
                            icon="arrowRight"
                            onClick={() => open()}
                        >
                            {tran.GetText('Kontaktní formulář')}
                        </Button>
                    </div>
                </div>
            </Container>
            <Container className="mt-40 xl:mt-80">
                <VisualBlock
                    variant="elevated"
                    variantColor="dark"
                    visual={{
                        variant: i18n.language === 'al' ? '595x615' : '620x540',
                        image: {
                            src:
                                i18n.language === 'al'
                                    ? AssetsImgGjirafaOffice
                                    : AssetsImgCebiaLobby,
                            srcSet: `${
                                i18n.language === 'al'
                                    ? AssetsImgGjirafaOffice
                                    : AssetsImgCebiaLobby2x
                            } 2x`,
                            alt: tran.GetText('Recepce Cebia'),
                        },
                    }}
                >
                    <dl className="Grid gmb-10">
                        <dt className="Grid-cell smw:w-6/12">
                            <h2 className="font-size-inherit font-bold">
                                {tran.GetText('Sídlo společnosti')}
                            </h2>
                        </dt>
                        <dd className="Grid-cell smw:w-6/12">
                            {tran.GetText('Cebia, spol. s r.o.')}
                            <br />
                            {i18n.language !== 'al' && i18n.language !== 'uk' ? (
                                <>
                                    {tran.GetText('Brumlovka, budova Alpha')}
                                    <br />
                                </>
                            ) : (
                                <></>
                            )}
                            {tran.GetText('Vyskočilova 1461/2a')}
                            <br />
                            {tran.GetText('140 00 Praha 4')}
                            <br />
                            {i18n.language !== 'al' && <>{tran.GetText('Česká republika')}</>}
                        </dd>
                        <div className="Grid-cell w-full">
                            <hr className="Separator mb-10 mt-10 md:mb-40 md:mt-40" />
                        </div>
                        <dt className="Grid-cell smw:w-6/12 font-bold">
                            {tran.GetText('Fakturační údaje')}
                        </dt>
                        <dd className="Grid-cell smw:w-6/12">
                            <div className="Grid">
                                <div className="Grid-cell w-6/12">
                                    {tran.GetText('IČ')}
                                    <strong className="block">{tran.GetText('18628443')}</strong>
                                </div>
                                {i18n.language !== 'uk' && (
                                    <div className="Grid-cell w-6/12">
                                        {tran.GetText('DIČ')}
                                        <strong className="block">
                                            {tran.GetText('CZ18628443')}
                                        </strong>
                                    </div>
                                )}
                            </div>
                        </dd>
                    </dl>
                </VisualBlock>
            </Container>
        </Section>
    )
}
