import { ReactElement, useEffect, useState } from 'react'
import './index.styl'
import { scrollToElement } from '../../services/Scroll'

type ScrollToTop = {
    scrollFrom?: number
    visibleFromElementId?: string
    scrollToElementId?: string
}

export const ScrollToTop = ({
    scrollFrom,
    visibleFromElementId,
    scrollToElementId,
}: ScrollToTop): ReactElement => {
    const [showTopBtn, setShowTopBtn] = useState(false)
    const [scrollFromPosition, setScrollFromPosition] = useState(0)
    useEffect(() => {
        let scfrom = scrollFrom ?? 400
        if (visibleFromElementId) {
            const element = document.getElementById(visibleFromElementId)
            if (element) {
                const rect = element.getBoundingClientRect()
                scfrom = Math.abs(rect.top)
            }
        }
        if (scrollFromPosition === 0) setScrollFromPosition(scfrom)
    }, [])

    useEffect(() => {
        if (scrollFromPosition > 0) {
            window.addEventListener('scroll', () => {
                if (window.scrollY > scrollFromPosition) {
                    setShowTopBtn(true)
                } else {
                    setShowTopBtn(false)
                }
            })
        }
    }, [scrollFromPosition])

    const scrollThis = () => {
        if (scrollToElementId) {
            const element = document.getElementById(scrollToElementId)
            scrollToElement(element, {
                mode: 'auto',
            })
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }
    return (
        <div className="top-to-btm">
            {' '}
            {showTopBtn && (
                <a
                    className="ArrowTop"
                    href="#"
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        scrollThis()
                    }}
                ></a>
            )}{' '}
        </div>
    )
}
