import { AtrNavBlock } from '../../../../../components/AtrNavBlock'
import { AtrNavLink } from '../../../../../components/AtrNavLink'
import {
    DetailPayedSectionItemDefinitionModel,
    getStatus,
    getVariant,
    isEnabled,
    isProcessed,
} from '../../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { Translate } from '../../../../../translations/CebiaTranslator'
import { TextUtils } from '../../../../../utils/textUtils'

type Props = {
    sectionItems: DetailPayedSectionItemDefinitionModel[]
    sourceData?: PayedJsonResult[]
    tran: Translate
}

export const SetcionHeaderContent = ({ sectionItems, sourceData, tran }: Props) => {
    const textUtils = new TextUtils()
    if ((sectionItems?.length ?? 0) <= 0) return <></>
    return (
        <AtrNavBlock>
            {sectionItems.map(defItem => {
                defItem.controlData = sourceData?.find(x => x.sectionId == defItem.sectionId)
                //prodej vaseho stavajiciho vozidla nechci vlevo
                if (defItem.sectionId === 55) {
                    return <></>
                } else
                    return (
                        <AtrNavLink
                            isLoading={!isProcessed(defItem)}
                            unavailable={!isEnabled(defItem)}
                            href={`#sekce${defItem.sectionId}`}
                            atrInfoStatus={{
                                variant: getVariant(defItem, true),
                                text: getStatus(defItem, tran, textUtils, true),
                            }}
                            isOpened={defItem.controlData?.openedSection ?? false}
                            key={`section-${defItem.sectionId}`}
                        >
                            {defItem.caption}
                        </AtrNavLink>
                    )
            })}
        </AtrNavBlock>
    )
}
