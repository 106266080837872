import { useContext } from 'react'
import { AtrSection } from '../../enums/AtrSectionEnum'
import { IAtrSection } from '../../utils/arrayExtensions'
import { SignalRContext } from './SignalRContext'

export type SignalRHookProps = {
    url: string
}

export const useSignalRConnection = ({ url }: SignalRHookProps) => {
    const signalRContext = useContext(SignalRContext)
    const signalForCurrentUrl = signalRContext?.settings.get(url)

    const canCloseConnection = (bufferedData: Array<any>): boolean => {
        const item =
            (bufferedData?.length ?? 0) > 0
                ? (bufferedData[bufferedData.length - 1] as IAtrSection)
                : null

        return (item && item.sectionId === AtrSection.CouponPrice) ?? false
    }

    return {
        signalRsetting: signalForCurrentUrl,
        openConnection: signalForCurrentUrl!.openConnection!,
        closeConnection: signalForCurrentUrl!.closeConnection!,
        isConnected: signalForCurrentUrl?.isConnected ?? false,
        connectionId: signalForCurrentUrl?.connectionId,
        bufferedData: signalForCurrentUrl!.bufferedData,
        hubState: signalForCurrentUrl!.hubState,
        canCloseConnection,
    }
}
