import { ReactElement } from 'react'
import { Title } from '../Title'
import { Translate } from '../../translations/CebiaTranslator'
import Moment from 'react-moment'

type Props = {
    activationDate: Date | undefined
}
export const CouponModalInfo = ({ activationDate }: Props): ReactElement => {
    const tran = new Translate()
    return (
        <div className="Grid">
            <div className="Grid-cell md:w-12/12">
                <Title as="header" variantAlign="left">
                    <p>
                        <b>
                            {tran.GetHtml('Datum aktivace kuponu:')}{' '}
                            <Moment format="DD.MM.YYYY HH:mm:ss">{activationDate}</Moment>
                        </b>
                    </p>
                    <p>
                        {tran.GetHtml(
                            'Kód kuponu umožňuje opakovaný vstup na výpis placených informací a to po dobu 30 dní od aktivace kuponu.',
                        )}
                    </p>
                    <p>
                        {tran.GetHtml(
                            'Provozovatel systému AUTOTRACER – Cebia, spol. s.r.o. upozorňuje uživatele systému, že uváděné informace byly poskytnuty do systému partnery provozovatele bez možnosti ověřit jejich správnost. Veškeré informace mají výhradně informativní charakter.',
                        )}
                    </p>
                    <p>
                        {tran.GetHtml(
                            'Na výsledek prověření vozidla v systému AUTOTRACER není poskytována záruka.',
                        )}
                    </p>
                </Title>
            </div>
        </div>
    )
}
