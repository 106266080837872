import { Table } from '../../../../components/Table'
import { PrintDataProps } from '../../vypis'

export const CarIdentificationSection = ({ sourceData, tran }: PrintDataProps) => {
    if (!sourceData || !sourceData.vehicleInfoControlResult) return <></>

    return (
        <div className="mb-30">
            <h2>{tran.GetText('Kontrola identifikátorů')}</h2>
            <Table>
                <thead>
                    <tr>
                        <th className="text-left" colSpan={2}>
                            {tran.GetText('Kontrola identifikátorů')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{tran.GetText('Značka')}</td>
                        <td>{sourceData.vehicleInfoControlResult.identification!.make}</td>
                    </tr>
                    <tr>
                        <td>{tran.GetText('Model')}</td>
                        <td>{sourceData.vehicleInfoControlResult.identification!.model}</td>
                    </tr>
                    {(sourceData.vehicleInfoControlResult.identification!.typ?.length ?? 0) > 0 && (
                        <tr>
                            <td>{tran.GetText('Detailní určení')}</td>
                            <td>{sourceData.vehicleInfoControlResult.identification!.typ}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )
}
