import './index.styl'
import {
    Children,
    CSSProperties,
    ReactElement,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useAtrNav } from '../../hooks/useAtrNav'
import classNames from 'classnames'
import { AtrNavOpener } from '../AtrNavOpener'
import { useElementsInViewport } from '../../hooks/useElementsInViewport'

type Props = {
    children: ReactNode
}

export const AtrNav = ({ children }: Props): ReactElement => {
    const { atrNavState } = useAtrNav()
    const { state } = useElementsInViewport()
    const innerRef = useRef<HTMLDivElement>(null)
    const [indicatorStyle, setIndicatorStyle] = useState<CSSProperties>()

    const className = classNames('AtrNav', {
        ['is-active']: atrNavState.active,
    })

    //fujky
    useEffect(() => {
        if (!innerRef.current) {
            return
        }

        const edge = state.elementsInViewport.reduce(
            (acc, element) => {
                if (element.documentTop < acc.min) {
                    acc.min = element.documentTop
                    acc.first = element.ref
                }
                if (element.documentTop > acc.max) {
                    acc.max = element.documentTop
                    acc.last = element.ref
                }
                return acc
            },
            {
                first: null as HTMLElement | null,
                last: null as HTMLElement | null,
                min: Number.MAX_VALUE,
                max: -Number.MAX_VALUE,
            },
        )

        const firstId = edge.first?.querySelector('.ToggleItem')?.id
        const lastId = edge.last?.querySelector('.ToggleItem')?.id

        const firstEl = innerRef.current.querySelector(
            `[href="#${firstId}"]`,
        ) as HTMLDivElement | null
        const lastEl = innerRef.current.querySelector(
            `[href="#${lastId}"]`,
        ) as HTMLDivElement | null

        const offsetTop = firstEl?.offsetTop ?? 0
        const offsetBottom = (lastEl?.offsetTop ?? 0) + (lastEl?.offsetHeight ?? 0)

        setIndicatorStyle({
            transform: `translateY(${offsetTop}px)`,
            height: `${offsetBottom - offsetTop}px`,
        })
    }, [state])

    return (
        <nav className={className}>
            <div className="AtrNav-inner" ref={innerRef}>
                <div className="AtrNav-indicator" style={indicatorStyle}></div>

                {Children.map(children, child => (
                    <div className="AtrNav-block">{child}</div>
                ))}
            </div>

            <div className="AtrNav-atrNavOpener">
                <AtrNavOpener />
            </div>
        </nav>
    )
}
