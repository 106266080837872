import { Children, cloneElement, ReactChild, ReactElement, useRef } from 'react'
import { useElementsInViewport } from '../../hooks/useElementsInViewport'

type Props = {
    children: ReactChild
}

export const ElementInViewportObserver = ({ children }: Props): ReactElement => {
    const ref = useRef<HTMLElement>(null)

    useElementsInViewport(ref)

    if (Children.only(children))
        return (
            <>
                {Children.map(children, child => {
                    if (typeof child === 'string' || typeof child === 'number') {
                        return ''
                    }
                    return cloneElement(child, {
                        ref,
                    })
                })}
            </>
        )
    else {
        // eslint-disable-next-line no-console
        console.error(
            'ElementsInViewportObserver can contain only 1 htmlelement child that is observable',
        )
        return <>{children}</>
    }
}
