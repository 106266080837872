import { MutableRefObject, useEffect, useState } from 'react'
import { useIntersection } from 'react-use'
import { Scroll } from '../services/Scroll'
import { makeObservable } from '../utils/makeObservable'

type ElementInViewport = {
    ref: HTMLElement
    documentTop: number
}

type State = {
    elementsInViewport: ElementInViewport[]
}

// type Actions = {}

type Store = {
    state: State
    // actions: Actions
}

const store = makeObservable<State>({
    elementsInViewport: [],
})

export const useElementsInViewport = (ref?: MutableRefObject<HTMLElement | null>): Store => {
    const [state, setState] = useState(store.get())

    useEffect(() => {
        return store.subscribe(setState)
    }, [])

    const intersection =
        ref &&
        useIntersection(ref, {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        })

    useEffect(() => {
        if (!intersection) {
            return
        }

        const removeCurrentFromVisible = () => {
            const index = state.elementsInViewport.findIndex(element => element.ref === ref.current)
            if (index < 0) {
                return
            }
            state.elementsInViewport.splice(index, 1)
            store.set({ ...state })
        }

        const addCurrentToVisible = () => {
            if (!ref.current) {
                return
            }

            const index = state.elementsInViewport.findIndex(element => element.ref === ref.current)
            if (index >= 0) {
                return
            }
            store.set({
                ...state,
                elementsInViewport: [
                    ...state.elementsInViewport,
                    {
                        ref: ref.current,
                        documentTop: Scroll.getScroll().y + intersection.boundingClientRect.top,
                    },
                ],
            })
        }
        if (intersection.isIntersecting) {
            addCurrentToVisible()
        } else {
            removeCurrentFromVisible()
        }

        return () => {
            removeCurrentFromVisible()
        }
    }, [intersection])

    return {
        state,
        // actions: {},
    }
}
