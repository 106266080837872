import './index.styl'
import { ReactElement } from 'react'
import { Image, ImageProps } from '../Image'
import classNames from 'classnames'
import AssetsImgPlaceholderSimple from './img/placeholder-simple.png'
import AssetsImgPlaceholderSimple2x from './img/placeholder-simple.png'
import AssetsImgPlaceholderLarge from './img/placeholder-large.png'
import AssetsImgPlaceholderLarge2x from './img/placeholder-large.png'
import { formatDate } from '../../utils/formatDate'
import { i18n } from '../../translations/i18n'
import { useNavigate } from 'react-router'
import { RssAttachment } from '../../models/RssChannel'

export type ArticleCardProps = {
    hash: string
    variant?: 'large' | 'simple'
    href: string
    image?: ImageProps
    date: Date
    title: string
    urlLink: string
    perex?: string
    category?: string
    attachments?: RssAttachment[]
}

export const ArticleCard = ({
    hash,
    variant = 'simple',
    href,
    image,
    date,
    title,
    urlLink,
    perex,
    category,
    attachments,
}: ArticleCardProps): ReactElement => {
    //large 620x500
    //default 386x220
    const anchorLink = `${process.env.PUBLIC_URL}/detailArticle/${href}`

    const navigate = useNavigate()
    if (!image) {
        const placeholders = {
            simple: {
                src: AssetsImgPlaceholderSimple,
                srcSet: `${AssetsImgPlaceholderSimple2x} 2x`,
            },
            large: {
                src: AssetsImgPlaceholderLarge,
                srcSet: `${AssetsImgPlaceholderLarge2x} 2x`,
            },
        }

        image = {
            ...placeholders[variant],
            alt: 'Článek - zástupný obrázek',
        }
    }

    const className = classNames('ArticleCard', {
        [`ArticleCard--${variant}`]: variant,
    })

    const navigateArticle = (urlLink: string) => {
        navigate('/detailArticle/' + urlLink.toLowerCase())
    }

    return (
        <a href={anchorLink} target="_self" className={className} rel="noreferrer">
            <div className="ArticleCard-body">
                <div className="ArticleCard-image">
                    <Image {...image} />
                </div>
                <div className="ArticleCard-content">
                    <time className="ArticleCard-date" dateTime={formatDate(date, 'Y-m-d')}>
                        {formatDate(date, 'd / m / Y')}
                    </time>
                    <h3 className="ArticleCard-title">{title}</h3>
                    <p className="ArticleCard-perex">{perex}</p>
                </div>
            </div>
        </a>
    )
}
