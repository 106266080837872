import { LanguageType } from '../../enums/LanguageType'
import { PayedStateModel } from '../../models/payedResponseModel'
import { Translate } from '../../translations/CebiaTranslator'
import { PagePayedRegType } from '../PayedRegInfoHeader'
import './index.styl'
import { ReactElement } from 'react'

export const PayedRegEndInfo = ({ carinfo }: PagePayedRegType): ReactElement => {
    const tran = new Translate()
    if (carinfo?.countryId === LanguageType.CZ) {
        return (
            <div className="PayedRegEndInfo">
                <h2 className="mb-10 mt-10 text-center">
                    <b>Ukončení certifikátů Cebia REPORT</b>
                </h2>
                <span>
                    K 1. 11. 2024 jsme ukončili vystavování certifikátů <b>Cebia Report.</b>
                </span>
                <br></br>
                <span>
                    Pro prezentaci vozidla vůči zákazníkům můžete nadále používat plný Výpis z
                    historie vozidla (AUTOTRACER).
                </span>
                <br></br>
                Cebia REPORT je <b>plně nahrazen Základním prověřením Cebia</b>, které máte k
                dispozici zdarma ke každému Výpisu z historie vozidla.
                <br></br>
                <span>
                    <b>Základní prověření vozidla</b> můžete zobrazovat svým zákazníkům v inzerátu
                    na vlastních webových stránkách nebo na inzertních portálech.
                </span>
                <br></br>
                <span>
                    Budete-li mít k Základnímu prověření jakékoliv dotazy, neváhejte se na nás
                    obrátit.
                </span>
                <br></br>
                <span>S pozdravem</span>
                <br></br>
                <span>Petr Kala</span>
                <br></br>
                <span>vedoucí obchodního oddělení</span>
                <br></br>
                <span>
                    Email: <a href="mailto:p.kala@cebia.cz">p.kala@cebia.cz</a>, tel.:{' '}
                    <a href="tel:+420601539800">601 539 800</a>
                </span>
            </div>
        )
    } else if (carinfo?.countryId === LanguageType.SK) {
        return (
            <div className="PayedRegEndInfo">
                <h2 className="mb-10 mt-20 text-center">
                    <b>Ukončenie certifikátov Cebia REPORT</b>
                </h2>
                <span>
                    Ku dňu 1. 11. 2024 sme ukončili vystavovanie certifikátov <b>Cebia Report.</b>
                </span>
                <br></br>
                <span>
                    Overovanie histórie vozidla a výsledný Cebia Car History Report zostávajú v tej
                    podobe, ako ich poznáte teraz. Na ďalšom komplexnom rozvoji tejto služby
                    pracujeme.
                </span>
                <br></br>
                Ak budete mať na overovanie vozidiel akékoľvek otázky, neváhajte sa na nás obrátiť.
                <br></br>
                <span>S pozdravom</span>
                <br></br>
                <span>Petr Kala</span>
                <br></br>
                <span>vedúci obchodného oddelenia</span>
                <br></br>
                <span>
                    Email: <a href="mailto:p.kala@cebia.cz">p.kala@cebia.cz</a>
                </span>
            </div>
        )
    } else {
        return <></>
    }
}
