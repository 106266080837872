import './index.styl'
import { ReactElement } from 'react'
import { ToggleItem, ToggleItemProps } from '../ToggleItem'
import { ToggleOpener } from '../ToggleOpener'

type Props = ToggleItemProps

export const ToggleItemMileageTableNote = ({ children, ...props }: Props): ReactElement => {
    return (
        <ToggleItem className="ToggleItemMileageTableNote" {...props}>
            <div className="ToggleItemMileageTableNote-inner">
                <ToggleOpener
                    href={`#${props.id}`}
                    className="ToggleItemMileageTableNote-close"
                    isOpened={false}
                />
                <div className="Text">{children}</div>
            </div>
        </ToggleItem>
    )
}
