import { ShowDateEnum } from '../../enums/ShowDateEnum'
import { CallingActionControlData } from '../DetailPayedResults/CallingAction/callingActionControlModel'
import { CheckLeaseControlData } from '../DetailPayedResults/CheckLeas/checkLeaseControlModel'
import { DamageControlData } from '../DetailPayedResults/Damage/damagesControlModel'
import { LienRightsControlData } from '../DetailPayedResults/LienRights/lienRightsModel'
import { PhotoControlData } from '../DetailPayedResults/Photos/photosControlModel'
import { RokvyControlData } from '../DetailPayedResults/Rokvy/rokvyControlModel'
import { ServiceRecControlData } from '../DetailPayedResults/ServiceRec/serviceRecControlModel'
import { GraphControlData } from '../DetailPayedResults/Tacho/tachosControlModels'
import { TaxiControlData } from '../DetailPayedResults/Taxi/taxiControlModel'
import { TheftControlData } from '../DetailPayedResults/Theft/theftControlModel'
import { TimeLineData } from '../DetailPayedResults/TimeLine/timeLineControlModel'
import { ValuationControlData } from '../DetailPayedResults/Valuation/valuationControlModel'
import { VehicleInfoControlData } from '../DetailPayedResults/VehicleInfo/vehicleInfoControlModel'

export class PrintDataModel {
    imageData?: string
    make?: string
    model?: string
    vin?: string
    typ?: string
    druh?: string
    fuel?: string
    kw?: string
    ccm?: string
    gearBox?: string
    productYear?: Date
    showProductDate: ShowDateEnum = 1
    firstRegistration?: Date
    warningMessages?: string

    controlDigits?: string
    couponNumber!: string
    couponActivation!: Date
    couponValidTo!: Date
    couponType!: number
    isCouponTest = false

    tachoControlResult: GraphControlData | undefined
    damageControlResult: DamageControlData | undefined
    serviceControlResult: ServiceRecControlData | undefined
    rokvyControlResult: RokvyControlData | undefined
    theftControlResult: TheftControlData | undefined
    checkLeaseControlResult: CheckLeaseControlData | undefined
    taxiControlResult: TaxiControlData | undefined
    vehicleInfoControlResult: VehicleInfoControlData | undefined
    callingActionControlResult: CallingActionControlData | undefined
    valuationControlResult: ValuationControlData | undefined
    photoControlResult: PhotoControlData | undefined
    otherAdvertPhotoControlResult: PhotoControlData | undefined
    timeLineControlResult: TimeLineData | undefined
    lienRightsControlResult: LienRightsControlData | undefined
    // cebiaReportControlResult: CebiaReportControlData | undefined

    // otherControlResult: OtherControlData | undefined
}
