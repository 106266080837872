import { ErrorMessage, Form, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import {
    useCarIdentDropDownData,
    useCarIdentPhotoData,
} from '../../../../../../busLog/vehicleIndetificationBL'
import { Button } from '../../../../../../components/Button'
import { Checkbox } from '../../../../../../components/Checkbox'
import { FormItem } from '../../../../../../components/FormItem'
import { LoadArea } from '../../../../../../components/LoadArea'
import { Select } from '../../../../../../components/Select'
import { DropDownItem } from '../../../../../../models/DetailPayedResults/payedJsonResult'
import { VehicleIdentification } from '../../../../../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { VehicleIdentProps } from './vehicleIdentification'
import { SectionVehicleIdentificationPhotos } from './vehicleIdentificationPhotos'

export const SectionVehicleIdentificationForm = ({ sourceData, tran }: VehicleIdentProps) => {
    const formContext = useFormikContext<VehicleIdentification>()
    const [dropDownTypeChanged, setDropDownChanged] = useState('')
    const [photoCarDef, setPhotoCarDef] = useState('')
    const [dropdownValues, setDropdownValues] = useState({
        carDruhs: sourceData.identification!.carDruhs,
        carMakes: sourceData.identification!.carMakes,
        carModels: sourceData.identification!.carModels,
        carTypes: sourceData.identification!.carTypes,
    })
    const [photoValues, setPhotoValues] = useState(sourceData.identification?.carPhotos)

    const { isLoading, isFetching, refetch, isSuccess, data } = useCarIdentDropDownData(
        formContext,
        dropDownTypeChanged,
    )

    const {
        isLoading: photosIsLoading,
        isFetching: photosIsFetching,
        refetch: photosRefetch,
    } = useCarIdentPhotoData(photoCarDef)

    const dropDownChangeValue = (e: any) => {
        // const t = formContext.getFieldProps(e.target.id) //.childrenDropdownName
        //todo: tohle je trochu zumpa. chtelo by to predelat na dynamiku v html Select je properta childCombo kde by byl nazev navazneho comba.
        switch (e.target.id) {
            case 'druhId':
                formContext.setFieldValue('make', '')
                formContext.setFieldValue('model', '')
                formContext.setFieldValue('typ', '')
                break
            case 'make':
                formContext.setFieldValue('model', '')
                formContext.setFieldValue('typ', '')
                break
            case 'model':
                formContext.setFieldValue('typ', '')
                break
        }
        setDropDownChanged(`${e.target.id}_${Date.now}`)
    }

    const photosChangeDatas = (e: any) => {
        setPhotoCarDef(`${formContext.values.make}|${formContext.values.model}|${e.target.value}`)
    }

    useEffect(() => {
        if (dropDownTypeChanged && dropDownTypeChanged.length > 0) {
            refetch().then(retVal => {})
        }
    }, [dropDownTypeChanged])

    useEffect(() => {
        if ((photoCarDef?.length ?? 0) > 0)
            photosRefetch().then(retVal => {
                setPhotoValues(retVal?.data?.data)
            })
    }, [photoCarDef])

    useEffect(() => {
        if (data) {
            setDropdownValues(data.data)
            const dataReceived = data.data as {
                carDruhs: DropDownItem[]
                carMakes: DropDownItem[]
                carModels: DropDownItem[]
                carTypes: DropDownItem[]
            }
            const values = formContext.values as VehicleIdentification
            values.carTypes = dataReceived.carTypes
            values.carDruhs = dataReceived.carDruhs
            values.carMakes = dataReceived.carMakes
            values.carModels = dataReceived.carModels
            formContext.setValues(values, false)
            let make = ''
            let model = ''
            let typ = ''
            if (dataReceived.carMakes?.find(x => x.value === formContext.values.make) != null) {
                make = formContext.values.make
                formContext.setFieldValue('make', make)
            } else if (
                dataReceived.carMakes?.find(x => x.value === formContext.initialValues.make) != null
            ) {
                make = formContext.initialValues.make
                formContext.setFieldValue('make', make)
            } else {
                make = dataReceived?.carMakes != null ? dataReceived?.carMakes[0]?.value : ''
                formContext.setFieldValue('make', make)
            }

            if (dataReceived.carModels?.find(x => x.value === formContext.values.model) != null) {
                model = formContext.values.model
                formContext.setFieldValue('model', model)
            } else if (
                dataReceived.carModels?.find(x => x.value === formContext.initialValues.model) !=
                null
            ) {
                model = formContext.initialValues.model
                formContext.setFieldValue('model', model)
            } else {
                model = dataReceived?.carModels != null ? dataReceived?.carModels[0]?.value : ''
                formContext.setFieldValue('model', model)
            }

            if (dataReceived.carTypes?.find(x => x.value === formContext.values.typ) != null) {
                typ = formContext.values.typ
                formContext.setFieldValue('typ', typ)
            } else if (
                dataReceived.carTypes?.find(x => x.value === formContext.initialValues.typ) != null
            ) {
                typ = formContext.initialValues.typ
                formContext.setFieldValue('typ', typ)
            } else {
                typ = dataReceived?.carTypes != null ? dataReceived?.carTypes[0]?.value : ''
                formContext.setFieldValue('typ', typ)
            }

            setPhotoCarDef(`${make ?? ''}|${model ?? ''}|${typ ?? ''}`)
        }
    }, [isSuccess])

    return (
        <div>
            <Form className="ajax">
                <div className="Grid gap-30 gmb-20 ">
                    <FormItem className="FormItem smw:w-full md:w-full lg:w-full">
                        <Select
                            id="druhId"
                            name="druhId"
                            label={tran.GetText('Typ vozidla')}
                            onChange={e => dropDownChangeValue(e)}
                            disabled={(dropdownValues.carDruhs?.length ?? 0) <= 0}
                        >
                            {dropdownValues.carDruhs &&
                                dropdownValues.carDruhs!.map((item, index) => (
                                    <option key={`druh_${index}`} value={item.value}>
                                        {item.key}
                                    </option>
                                ))}
                        </Select>
                    </FormItem>
                </div>
                <div className="Grid gap-30 gmb-20 ">
                    <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                        <Select
                            id="make"
                            name="make"
                            label={tran.GetText('Značka')}
                            onChange={e => dropDownChangeValue(e)}
                            disabled={(dropdownValues.carMakes?.length ?? 0) <= 0}
                        >
                            {dropdownValues.carMakes &&
                                dropdownValues.carMakes!.map((item, index) => (
                                    <option key={`make_${index}`} value={item.value}>
                                        {item.key}
                                    </option>
                                ))}
                        </Select>
                    </FormItem>
                    <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                        <Select
                            id="model"
                            name="model"
                            label={tran.GetText('Model')}
                            onChange={e => dropDownChangeValue(e)}
                            disabled={(dropdownValues.carModels?.length ?? 0) <= 0}
                        >
                            {dropdownValues.carModels &&
                                dropdownValues.carModels!.map((item, index) => (
                                    <option key={`model_${index}`} value={item.value}>
                                        {item.key}
                                    </option>
                                ))}
                        </Select>
                    </FormItem>
                </div>
                {(dropdownValues?.carTypes?.length ?? 0) > 0 && (
                    <div className="Grid gap-30 gmb-20 ">
                        <FormItem className="FormItem smw:w-full md:w-full lg:w-full">
                            <Select
                                id="typ"
                                name="typ"
                                value=""
                                label={tran.GetText('Detailní určení')}
                                disabled={(dropdownValues.carTypes?.length ?? 0) <= 0}
                                onChange={e => photosChangeDatas(e)}
                            >
                                <option key={`typ_${-1}`} value={''}>
                                    {' '}
                                </option>
                                {dropdownValues.carTypes!.map((item, index) => (
                                    <option key={`typ_${index}`} value={item.value}>
                                        {item.key}
                                    </option>
                                ))}
                            </Select>
                        </FormItem>
                    </div>
                )}
                <div className="Grid Grid--alignMiddle gmt-30 gmb-10">
                    <div className="FormItem smw:w-1/2 md:w-full lg:w-2/3 xl:w-2/3">
                        <Checkbox
                            className=""
                            variant="green"
                            aria-label="Odsouhlasení zadaných údajů. Po uložení nepůjdou změnit"
                            name="isGdpr"
                            id="vehicleIsGdpr"
                        >
                            <label htmlFor="vehicleIsGdpr">
                                {tran.GetText(
                                    'Odsouhlasení zadaných údajů. Po uložení nepůjdou změnit.',
                                )}
                            </label>
                        </Checkbox>
                        <ErrorMessage name="isGdpr" component="div" className="InputError" />
                    </div>
                    <div className="FormItem FormItem--submit smw:w-1/2 md:w-full lg:w-1/3 xl:w-1/3">
                        <Button className="Button--center" as="button" type="submit">
                            {tran.GetText('Uložit')}
                        </Button>
                    </div>
                </div>
                <LoadArea
                    hideBackGround={true}
                    loader={{ variant: 'bars', size: 'big' }}
                    align="center"
                    isLoading={isLoading || isFetching}
                />
            </Form>
            <SectionVehicleIdentificationPhotos
                sourceData={photoValues}
                tran={tran}
                isLoading={photosIsFetching || photosIsLoading}
            />
        </div>
    )
}
