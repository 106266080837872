import {
    createContext,
    ReactElement,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import TagManager from 'react-gtm-module'
import { useSearchParams } from 'react-router-dom'
import { getApc } from '../busLog/smartCodeBL'

type NavigationContextOptions = {
    isOpen: boolean
    open: () => void
    close: () => void
    toggle: () => void
}

const initialValue: NavigationContextOptions = {
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    open: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    close: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggle: () => {},
}

const NavigationContext = createContext<NavigationContextOptions>(initialValue)

export type NavigationProviderProps = {
    children?: ReactNode
}

export const NavigationProvider = ({
    children,
}: NavigationProviderProps): ReactElement<React.Provider<NavigationContextOptions>> => {
    const [isOpen, setIsOpen] = useState(false)
    const [pathName, setPathName] = useState(window.location.href)
    const [refName, setRef] = useState(document.referrer)
    const [searchParams] = useSearchParams()
    const apc = searchParams.get('utm_campaign')
    const [clientId, setClientId] = useState(searchParams.get('client_id'))
    const [sessionId, setSessionId] = useState(searchParams.get('session_id'))

    const open = useCallback(() => {
        setIsOpen(true)
    }, [setIsOpen])

    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    const toggle = useCallback(() => {
        setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])

    const contextValue = useMemo<NavigationContextOptions>(
        () => ({
            isOpen,
            open,
            close,
            toggle,
        }),
        [isOpen, setIsOpen, open, close, toggle],
    )

    const getAPC = useCallback(async () => {
        const id = pathName.split('/').pop()
        const data = await getApc(id ?? '')
        return data
    }, [pathName])

    useEffect(() => {
        setPathName(window.location.href)
        setClientId(searchParams.get('client_id'))
        setSessionId(searchParams.get('session_id'))
    })

    useEffect(() => {
        const isSmartUrl = window.location.href.toLowerCase().indexOf('/smartcode/') >= 0

        const oldRef = refName
        setRef(pathName)
        if (isSmartUrl && !apc && pathName && pathName.indexOf('utm_campaign') < 0) {
            const result = getAPC().then(x => {
                if (x) {
                    let path = pathName
                    path += '?&utm_source=Smart_kod&utm_medium=tlacitko&utm_campaign=' + x

                    if ((clientId?.length ?? 0 > 0) && (sessionId?.length ?? 0 > 0)) {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'page_view',
                                page: { referrer: oldRef },
                                session: { client_id: clientId, session_id: sessionId },
                                url: path,
                                clear: true,
                            },
                        })
                    } else {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'page_view',
                                page: { referrer: oldRef },
                                url: path,
                                clear: true,
                            },
                        })
                    }
                }
            })
        } else {
            if ((clientId?.length ?? 0 > 0) && (sessionId?.length ?? 0 > 0)) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'page_view',
                        page: { referrer: oldRef },
                        session: { client_id: clientId, session_id: sessionId },
                        url: pathName,
                        clear: true,
                    },
                })
            } else {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'page_view',
                        page: { referrer: oldRef },
                        url: pathName,
                        clear: true,
                    },
                })
            }
        }
    }, [pathName])

    return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>
}

export const useNavigation = (): NavigationContextOptions => useContext(NavigationContext)
