import { Translate } from '../../translations/CebiaTranslator'

export function getMonths(): string[] {
    const tran = new Translate()
    return [
        tran.GetText('leden'),
        tran.GetText('únor'),
        tran.GetText('březen'),
        tran.GetText('duben'),
        tran.GetText('květen'),
        tran.GetText('červen'),
        tran.GetText('červenec'),
        tran.GetText('srpen'),
        tran.GetText('září'),
        tran.GetText('říjen'),
        tran.GetText('listopad'),
        tran.GetText('prosinec'),
    ]
}
