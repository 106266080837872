import React, { ReactElement, useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import { useAppContext } from '../../contexts/AppContext'
import { SmartCodeType } from '../../enums/smartCodeType'
import { Translate } from '../../translations/CebiaTranslator'
import { AtrNavLink } from '../AtrNavLink'
import { AtrOverviewTag } from '../AtrOverviewTag'
import { BubbleOpener } from '../BubbleOpener'
import { Button } from '../Button'
import { ButtonList } from '../ButtonList'
import { Container } from '../Container'
import { LoadArea } from '../LoadArea'
import { Logo, LogoProps } from '../Logo'
import { Modal, useModal } from '../Modal'
import { CouponModalInfo } from '../Modal/CouponModalInfo'
import { CouponModalShare } from '../Modal/CouponModalShare'
import { StickySwapper } from '../StickySwapper'
import { confirm } from 'react-confirm-box'
import { options } from '../../components/ConfirmBox'
import './index.styl'
import { activateSmartCode } from '../../busLog/smartCodeBL'
import { ShowDateEnum } from '../../enums/ShowDateEnum'
import { getHistory } from '../../busLog/payedBL'
import { debug } from 'console'
import { CouponModalHistory } from '../Modal/CouponModalHistory'
import { i18n } from '../../translations/i18n'
import { CouponModalShort } from '../Modal/CouponModalShort'

export type StickyTypeMessage = {
    text: string
    sectionId: number
}

type AtrOverviewCarProps = {
    isLoading: boolean
    logo: LogoProps
    vin: string
    brand: string
    model: string
    type: string
    fuel: string
    dateManufacturing?: Date
    showDateManufacturingType: ShowDateEnum
    dateFirstReg?: Date
    category: string
    enginePower: string
    engineCapacity: string
    gearBox: string
    couponCode?: string
    couponDateExpiry?: Date
    couponDateActivation?: Date
    controlDigits?: string
    stickyRightTypes?: StickyTypeMessage[]
    showActionButtons: boolean
    printDoc?: string
    showInvoiceButton?: boolean
    smartCodeActivation?: Date
    smartCodeType: SmartCodeType
    smartCodeDoc?: string
    showHistoryButton?: boolean
    couponApc?: string
    viewId: string
    isFull?: boolean
}

export const ShowDateManufacturing = (showDateType: ShowDateEnum, dateManufacturing?: Date) => {
    let dateFormat = 'DD. MM. YYYY'

    if (dateManufacturing) {
        switch (showDateType) {
            case ShowDateEnum.ShowYear:
                dateFormat = 'YYYY'
                break
            case ShowDateEnum.ShowYearMonth:
                dateFormat = 'MM/YYYY'
                break
        }

        return <Moment format={dateFormat}>{dateManufacturing}</Moment>
    }

    return <></>
}

export const AtrOverviewCar = ({
    isLoading = true,
    logo,
    vin,
    brand,
    model,
    type,
    fuel,
    dateManufacturing,
    showDateManufacturingType = ShowDateEnum.ShowYear,
    dateFirstReg,
    category,
    enginePower,
    engineCapacity,
    gearBox,
    couponCode,
    couponDateExpiry,
    couponDateActivation,
    controlDigits,
    stickyRightTypes,
    showActionButtons,
    printDoc,
    showInvoiceButton = false,
    smartCodeActivation,
    smartCodeType,
    smartCodeDoc,
    showHistoryButton,
    couponApc,
    viewId,
    isFull = true,
}: AtrOverviewCarProps): ReactElement => {
    const tran = new Translate()
    const { open: openC } = useModal('couponModalInfo')
    const { open: openHistory, close: closeHistory } = useModal('couponModalHistory')
    const { open: openS, close: closeS, active } = useModal('couponModalShare')
    const { open: openShort, close: closeShort, active: activeShort } = useModal('couponModalShort')

    const { id } = useParams()
    const warningText = tran.GetText('Varování')
    const appContext = useAppContext()
    const [smartCode, setSmartCodeDoc] = useState(smartCodeDoc)
    const { history, historyError, historyRefetch } = getHistory(vin, viewId)
    useEffect(() => {
        if (showHistoryButton) {
            historyRefetch()
        }
    }, [showHistoryButton])
    const stickyLink = (typeMessage: StickyTypeMessage) => {
        return typeMessage.sectionId === 0 ? (
            <span>{typeMessage.text}</span>
        ) : (
            <AtrNavLink
                href={`#sekce${typeMessage.sectionId}`}
                isOpened={false}
                className="Link-small Link--white Link"
            >
                {typeMessage.text}
            </AtrNavLink>
        )
    }
    const showWarningCaption = () => {
        if ((stickyRightTypes?.length ?? 0) > 0 && stickyRightTypes?.some(x => x.sectionId > 0)) {
            return (
                <>
                    <span className="Icon Icon--alert"></span>
                    {warningText}
                </>
            )
        }

        return <></>
    }

    return (
        <div className="AtrOverviewCar">
            <div className="AtrOverviewCar-banner">
                <div className="AtrOverviewCar-logo">
                    <Logo className="AtrOverview-logo" {...logo} />
                </div>
            </div>
            {(stickyRightTypes?.length ?? 0) > 0 && (
                <StickySwapper>
                    {({ intersectionRef, staticVisible, stickyActive }) => [
                        <AtrOverviewTag
                            key="primary"
                            className={`AtrOverviewCar-tag ${
                                stickyRightTypes?.some(x => x.sectionId > 0) === true
                                    ? 'AtrOverviewTag--alert'
                                    : ''
                            }`}
                            customRef={intersectionRef}
                            active={staticVisible}
                            variantDisplay="static"
                        >
                            {showWarningCaption()}
                            {stickyRightTypes
                                ?.filter(x => (x.text?.length ?? 0) > 0)
                                .map((item, index) => (
                                    <React.Fragment key={`atrStickyText_${index}`}>
                                        {stickyLink(item)}
                                    </React.Fragment>
                                ))}
                        </AtrOverviewTag>,

                        <AtrOverviewTag
                            key="secondary"
                            variantDisplay="fixed"
                            active={
                                !staticVisible && stickyActive && id?.indexOf('example', 0) !== -1
                            }
                        >
                            {tran.GetText('UKÁZKA výsledku prověření náhodného vozidla.')}
                        </AtrOverviewTag>,
                    ]}
                </StickySwapper>
            )}
            <LoadArea isLoading={isLoading} loader={{ variant: 'circle' }}>
                <div className="AtrOverviewCar-content">
                    <div className="Grid gap-20 gmb-10 mb-20 xl:mb-40">
                        <div className="Grid-cell md:w-1/2 xxl:w-2/6">
                            {isFull && (
                                <>
                                    <span className="AtrOverviewCar-label">
                                        {tran.GetText('VIN')}
                                    </span>
                                    <div className="AtrOverviewCar-vin">{vin}</div>
                                </>
                            )}
                        </div>
                        <div className="Grid-cell md:w-1/2 xxl:w-4/6">
                            <span className="AtrOverviewCar-label AtrOverviewCar-label--empty">
                                &nbsp;
                            </span>
                            {controlDigits && controlDigits.length > 0 && (
                                <span className="Check">{tran.GetText(controlDigits)}</span>
                            )}
                        </div>
                    </div>
                    <div className="Grid gap-20 gmb-10 xl:gmb-30">
                        {(brand?.length ?? 0) > 0 && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Tovární značka')}
                                </span>
                                {brand}
                            </div>
                        )}
                        {(model?.length ?? 0) > 0 && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Model')}
                                </span>
                                {model.split('|').map(item => (
                                    <React.Fragment key={item}>
                                        {item} <br></br>
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                        {(type?.length ?? 0) > 0 && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Typ karosérie')}
                                </span>
                                {type}
                            </div>
                        )}
                        {(category?.length ?? 0) > 0 && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Druh vozidla')}
                                </span>
                                {category}
                            </div>
                        )}
                        {(fuel?.length ?? 0) > 0 && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Palivo')}
                                </span>
                                {fuel}
                            </div>
                        )}
                        {(enginePower?.length ?? 0) > 0 && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Výkon')}
                                </span>
                                {enginePower}
                            </div>
                        )}
                        {(engineCapacity?.length ?? 0) > 0 && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Objem')}
                                </span>
                                {engineCapacity}
                            </div>
                        )}
                        {(gearBox?.length ?? 0) > 0 && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Převodovka')}
                                </span>
                                {gearBox}
                            </div>
                        )}
                        {dateManufacturing && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Datum výroby')}
                                </span>
                                {ShowDateManufacturing(
                                    showDateManufacturingType,
                                    dateManufacturing,
                                )}
                            </div>
                        )}
                        {dateFirstReg && (
                            <div className="Grid-cell w-1/2 md:w-1/3 xl:w-2/12">
                                <span className="AtrOverviewCar-label">
                                    {tran.GetText('Datum 1. registrace')}
                                </span>
                                <Moment format="DD. MM. YYYY">{dateFirstReg}</Moment>
                            </div>
                        )}
                    </div>
                </div>
                {((couponCode && couponDateExpiry) ||
                    ((smartCodeType ?? SmartCodeType.None) > SmartCodeType.None && smartCode)) && (
                    <div className="AtrOverviewCar-footer">
                        <div className="Grid align-center gap-30 gmb-20">
                            <div className="lg:w-fill">
                                <div className="Grid gap-30 gmb-5">
                                    {(couponCode?.length ?? 0) > 0 && (
                                        <span className="AtrOverviewCar-footerLabel w-auto">
                                            <span className="color-textBlue">
                                                {tran.GetHtml('Kód kuponu:')}{' '}
                                            </span>
                                            {couponCode}
                                            <BubbleOpener className="ml-5" onClickHandler={openC} />
                                        </span>
                                    )}
                                    {couponDateExpiry && (
                                        <span className="AtrOverviewCar-footerLabel w-auto">
                                            <span className="color-textBlue">
                                                {tran.GetHtml('Platnost kuponu do:')}{' '}
                                            </span>
                                            <Moment format="DD. MM. YYYY">
                                                {couponDateExpiry}
                                            </Moment>
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="lg:w-auto">
                                <ButtonList visible={showActionButtons && id != 'example'}>
                                    {(smartCodeType ?? SmartCodeType.None) > SmartCodeType.None &&
                                        i18n.language === 'cz' &&
                                        (couponApc?.length ?? 0 > 0) && (
                                            <>
                                                {(smartCode?.length ?? 0) === 0 && (
                                                    <Button
                                                        as="a"
                                                        variantColor={
                                                            smartCodeType ==
                                                            SmartCodeType.CustomerForCreate
                                                                ? 'darkBlue'
                                                                : 'gray'
                                                        }
                                                        variantSize="small"
                                                        onClick={async () => {
                                                            if (
                                                                smartCodeType !=
                                                                SmartCodeType.CustomerForCreate
                                                            ) {
                                                                window.open(
                                                                    'https://www.cebianet.cz/pub/sluzby/smart-kod',
                                                                )
                                                            } else {
                                                                const confirmResult = await confirm(
                                                                    tran.GetText(
                                                                        'Opravdu chcete vygenerovat Smart kód?',
                                                                    ),
                                                                    options,
                                                                ).then(async confirmResult => {
                                                                    if (confirmResult) {
                                                                        const retval =
                                                                            await activateSmartCode(
                                                                                id ?? '',
                                                                            )
                                                                        if (retval)
                                                                            setSmartCodeDoc(retval)
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {tran.GetText('Smart kód')}
                                                    </Button>
                                                )}
                                                {(smartCode?.length ?? 0) > 0 && (
                                                    <>
                                                        {/* <Button
                                                            as="button"
                                                            variantColor={'darkBlue'}
                                                            variantSize="small"
                                                            onClick={openShort}
                                                        >
                                                            {tran.GetText('Základní prověření')}
                                                        </Button> */}

                                                        <Button
                                                            as="a"
                                                            variantColor={'blue'}
                                                            variantSize="small"
                                                            href={`/SmartCode/GetSmartCodeReport?requestId=${smartCode?.replace(
                                                                '?',
                                                                '&',
                                                            )}`}
                                                            target="_blank"
                                                            rel="noreferrer noopener"
                                                        >
                                                            {tran.GetText('Smart kód')}
                                                        </Button>
                                                        {/* <Button
                                                            as="a"
                                                            variantColor={'darkBlue'}
                                                            variantSize="small"
                                                            href={`/SmartCode/GetSmartCodeImage?requestId=${smartCode}`}
                                                            target="_blank"
                                                            rel="noreferrer noopener"
                                                        >
                                                            {tran.GetText('QR – Smart kód')}
                                                        </Button> */}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    {showInvoiceButton && (
                                        <Button
                                            as="a"
                                            variantColor="blue"
                                            variantSize="small"
                                            href={`/print/GetPaymentDocument?requestId=${id}`}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            {tran.GetText('Doklad')}
                                        </Button>
                                    )}
                                    {showHistoryButton && (
                                        <Button
                                            as="a"
                                            variantColor="blue"
                                            variantSize="small"
                                            onClick={() => {
                                                historyRefetch()
                                                openHistory()
                                            }}
                                        >
                                            {tran.GetText('Historie') +
                                                ` (${history?.length ?? 0})`}
                                        </Button>
                                    )}
                                    <Button
                                        as="a"
                                        variantColor="blue"
                                        variantSize="small"
                                        href={`/print/GetPayedCertificate?requestId=${printDoc}`}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {tran.GetText('PDF')}
                                    </Button>
                                    <Button
                                        as="button"
                                        variantColor="blue"
                                        variantSize="small"
                                        onClick={openS}
                                    >
                                        {tran.GetText('Sdílet')}
                                    </Button>
                                </ButtonList>
                            </div>
                        </div>
                    </div>
                )}
            </LoadArea>
            <Modal id="couponModalInfo">
                <Container>
                    <CouponModalInfo activationDate={couponDateActivation} />
                </Container>
            </Modal>
            <Modal id="couponModalHistory">
                <Container>
                    <CouponModalHistory
                        closeModal={closeHistory}
                        activationDate={couponDateActivation}
                        history={history}
                        couponApc={couponApc}
                    ></CouponModalHistory>
                </Container>
            </Modal>
            <Modal id="couponModalShare">
                <div className="ml-50 mr-50">
                    <CouponModalShare
                        closeModal={closeS}
                        isActive={active}
                        couponCode={couponCode ?? ''}
                        smartCode={smartCodeDoc ?? ''}
                    />
                </div>
            </Modal>
            <Modal id="couponModalShort">
                <Container>
                    <CouponModalShort
                        closeModal={closeShort}
                        smartCodeUrl={smartCodeDoc ?? ''}
                        isActive={activeShort}
                    />
                </Container>
            </Modal>
        </div>
    )
}
