import { ReactElement, useEffect, useState } from 'react'
import { Title } from '../Title'
import { Translate } from '../../translations/CebiaTranslator'
import { Button } from '../Button'
import { ButtonList } from '../ButtonList'
import { Input } from '../Input'
import { FormItem } from '../FormItem'
import { Formik } from 'formik'

type Props = {
    smartCodeUrl: string
    closeModal: () => void
    isActive: boolean
}
export const CouponModalShort = ({ smartCodeUrl, closeModal, isActive }: Props): ReactElement => {
    const shareLink = `${window.location.origin}/SmartCode/${smartCodeUrl}`
    const handleFocus = (event: any) => event.target.select()
    const tran = new Translate()
    const [isCopied, setIsCopied] = useState<boolean>()
    useEffect(() => {
        if (!isActive) {
            setIsCopied(false)
        }
    }, [isActive])
    return (
        <div className="Grid">
            <div className="Grid-cell md:w-12/12">
                <Title as="header" variantAlign="left">
                    <h3 className="pb-20 blue">{tran.GetText('Základní prověření vozidla')}</h3>
                    <p>
                        {tran.GetText(
                            'Slouží k propagaci výpisu z historie vozidla ve Vašich inzerátech. Pro implementaci na Váš web kontaktujte obchodní oddělení Cebia.',
                        )}
                    </p>
                    <Formik initialValues={{ link: shareLink }} onSubmit={values => {}}>
                        <FormItem className="smw:w-full md:w-full lg:w-full FormItem--separator">
                            <Input
                                id="link"
                                name="link"
                                type="text"
                                placeholder={tran.GetText('Odkaz')}
                                value={shareLink}
                                onFocus={handleFocus}
                            />
                        </FormItem>
                    </Formik>
                    <p>
                        {isCopied && (
                            <span className="Check">{tran.GetText('Odkaz zkopírován')}</span>
                        )}
                    </p>
                    <div className="lg:w-auto">
                        <ButtonList visible={true}>
                            <Button
                                as="button"
                                variantColor="blue"
                                variantSize="small"
                                onClick={() => {
                                    closeModal()
                                    setIsCopied(false)
                                }}
                            >
                                {tran.GetText('Zavřít')}
                            </Button>
                            <Button
                                as="button"
                                variantColor="green"
                                variantSize="small"
                                onClick={() => {
                                    navigator.clipboard.writeText(shareLink)
                                    const input = document.getElementById('link')
                                    input?.focus()
                                    setIsCopied(true)
                                }}
                            >
                                {tran.GetText('Kopírovat odkaz')}
                            </Button>
                            <Button
                                as="a"
                                variantColor="darkBlue"
                                variantSize="small"
                                href={shareLink}
                                target="_blank"
                            >
                                {tran.GetText('Zobrazit výpis')}
                            </Button>
                        </ButtonList>
                    </div>
                </Title>
            </div>
        </div>
    )
}
