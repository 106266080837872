import './index.styl'
import { ReactElement } from 'react'
import classNames from 'classnames'

type Props = {
    icon: 'cebia' | 'facebook' | 'youtube' | 'linkedin' | 'twitter' | 'instagram' | 'x'
    href: string
    label: string
    variantDesktop?: 'desktopInline' | 'desktopIcon'
    variantMobile?: 'mobileIcon'
}

/* const Socials: Record<SocialVariant, SocialItem> = {
    facebook: {
        icon: 'facebook',
        href: 'https://www.facebook.com/cebia.cz',
        label: 'Facebook',
    },
    youtube: {
        icon: 'youtube',
        href: 'https://www.youtube.com/user/cebiacz',
        label: 'Youtube',
    },
    cebia: {
        icon: 'cebia',
        href: 'https://www.cebia.cz/o-nas',
        label: 'O nás',
    },
} */

export const Social = ({
    icon,
    label,
    href,
    variantDesktop = 'desktopIcon',
    variantMobile = 'mobileIcon',
}: Props): ReactElement | null => {
    const className = classNames('Social', {
        [`Social--${icon}`]: icon,
        [`Social--${variantDesktop}`]: variantDesktop,
        [`Social--${variantMobile}`]: variantMobile,
    })

    return (
        <a target="_blank" href={href} rel="noreferrer noopener" className={className}>
            <span className="Social-label">{label}</span>
        </a>
    )
}
