import { Console } from 'console'
import { Fragment, ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { CouponType } from '../../../../../enums/CouponType'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import {
    PhotosDescriptionItem,
    getPhotosUrlArray,
} from '../../../../../models/DetailPayedResults/Photos/photosControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'
import './body.styl'
import { PhotoAdvertsSection } from './carPhotoAdverts'
import { PhotoVinFotoSection } from './carPhotoVinFoto'
import { Table } from '../../../../../components/Table'
import moment from 'moment'
import parse from 'html-react-parser'
import { AtrGallery } from '../../../../../components/AtrGallery'

let PrintAllowIncrement = true

export type PhotoAdvertsProps = {
    photoAdverts?: PhotosDescriptionItem[]
    tran: Translate
    totalLength?: number
    advertLength?: number
}

export const CarPhoto = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    const carPhotoData = new PayedJsonResult()
    carPhotoData.photoControlResult = sourceData?.photoControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }
    const totalLength = sourceData?.photoControlResult?.photos?.length
    return (
        <div className="">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Fotky a inzerce')}
            </h2>
            {!sourceData?.photoControlResult && (
                <>
                    <p>
                        {tran.GetText(
                            'Nebyly nalezeny žádné záznamy o inzerci vozidla v ČR ani zahraničí.',
                        )}
                    </p>
                </>
            )}

            {sourceData?.photoControlResult && (
                <Section className="CarPhotoSection">
                    <div className="pb-25">
                        <p>
                            {tran.GetText(
                                'Pokud bylo vozidlo v minulosti inzerováno nebo provedena prohlídka, zobrazíme informace a fotodokumentaci.',
                            )}
                            {'  '}
                            {sourceData.couponType != CouponType.Apc &&
                                tran.GetText(
                                    'Můžete tak získat přehled o vývoji ceny a údajů ověřovaného vozidla.',
                                )}
                        </p>
                    </div>
                    <div>
                        {sourceData?.photoControlResult?.photos!.map((item, index) => (
                            <Fragment key={`vinPhotoPrint_${index}`}>
                                <div className="mb-30 print">
                                    <div className="Table mb-20 md:mb-50 half">
                                        <Table className="pba">
                                            <thead className="text-left">
                                                <tr>
                                                    <th>{`${tran.GetText('Záznam')} ${
                                                        index + 1
                                                    }/${totalLength}`}</th>
                                                    {item.advertMatchType === 7 && (
                                                        <th>{tran.GetText('Prohlídka vozidla')}</th>
                                                    )}
                                                    {item.advertMatchType !== 7 && (
                                                        <>
                                                            {((item.priceSale ?? 0) > 0 &&
                                                                item.isSale) ||
                                                            ((item.advertPrice ?? 0) > 0 &&
                                                                item.isSale === false) ? (
                                                                <th>
                                                                    {item.isSale
                                                                        ? tran.GetText('Prodej')
                                                                        : tran.GetText('Inzerce')}
                                                                </th>
                                                            ) : (
                                                                <th>{tran.GetText('Foto')}</th>
                                                            )}
                                                        </>
                                                    )}
                                                </tr>
                                            </thead>
                                            {item.advertMatchType === 7 && (
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {tran.GetText(
                                                                'Datum provedení služby:',
                                                            )}
                                                        </td>
                                                        <td>
                                                            {moment(item.advertDate).format(
                                                                'DD. MM. YYYY',
                                                            )}
                                                        </td>
                                                    </tr>
                                                    {(item.carColor?.length ?? 0) > 0 && (
                                                        <tr>
                                                            <td>
                                                                {tran.GetText('Barva vozidla:')}
                                                            </td>
                                                            <td>{item.carColor}</td>
                                                        </tr>
                                                    )}
                                                    {item.advertKm > 0 && (
                                                        <tr>
                                                            <td>
                                                                {tran.GetText('Stav tachometru:')}
                                                            </td>
                                                            <td>
                                                                {tran.GetNumber(item.advertKm)}{' '}
                                                                {tran.GetText('km')}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {(item.carStateText?.length ?? 0) > 0 && (
                                                        <tr>
                                                            <td>{tran.GetText('Stav vozidla')}</td>
                                                            <td>{parse(item.carStateText)}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            )}
                                            {item.advertMatchType !== 7 && (
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {(item.screenShotUrl?.length ?? 0) > 0
                                                                ? tran.GetText(
                                                                      'Datum pořízení inzerátu',
                                                                  )
                                                                : tran.GetText('Datum pořízení')}
                                                        </td>
                                                        <td>
                                                            {moment(item.advertDate).format(
                                                                'DD. MM. YYYY',
                                                            )}
                                                        </td>
                                                    </tr>
                                                    {item.advertKm > 0 && (
                                                        <tr>
                                                            <td>
                                                                {tran.GetText('Inzerovaný nájezd')}
                                                            </td>
                                                            <td>
                                                                {tran.GetNumber(item.advertKm)}{' '}
                                                                {tran.GetText('km')}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {(item.newPrice ?? 0) > 0 && (
                                                        <tr>
                                                            <td>
                                                                {tran.GetText(
                                                                    'Cena nového vozidla',
                                                                )}
                                                            </td>
                                                            <td>{`${tran.GetNumber(
                                                                item.newPrice!,
                                                            )} ${item.currency}`}</td>
                                                        </tr>
                                                    )}
                                                    {(((item.priceSale ?? 0) > 0 && item.isSale) ||
                                                        ((item.advertPrice ?? 0) > 0 &&
                                                            item.isSale === false)) && (
                                                        <tr>
                                                            {item.isSale ? (
                                                                <>
                                                                    <td>
                                                                        {tran.GetText(
                                                                            'Prodejní cena',
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {item.priceSale}{' '}
                                                                        {item.currency}
                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <td>
                                                                        {tran.GetText(
                                                                            'Inzerovaná cena',
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {item.advertPrice}{' '}
                                                                        {item.currency}
                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    )}
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                    <div id={`vinPhotoGal_${index}`}>
                                        <AtrGallery
                                            mainImage={undefined}
                                            images={getPhotosUrlArray(item.advertImageUrls, false)}
                                            id={`vinPhotoGal_${index}`}
                                            isPrint={true}
                                            isAnonymized={item.isAnonymized}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                        {(sourceData.photoControlResult?.photos?.length ?? 0) <= 0 && (
                            <p>
                                {tran.GetText(
                                    'Nebyly nalezeny žádné záznamy o inzerci vozidla v ČR ani zahraničí.',
                                )}
                            </p>
                        )}
                    </div>
                    <div className="print">
                        {(sourceData?.photoControlResult?.photos?.length ?? 0) > 0 && (
                            <p className="text-justify">
                                {tran.GetHtml(
                                    "<span class='warning'>Poznámka:</span> Pokud fotografie neodpovídají prověřovanému vozidlu, prosím informujte nás na email <a href='mailto:autotracer@cebia.cz'>autotracer@cebia.cz</a>.",
                                )}
                            </p>
                        )}
                    </div>
                </Section>
            )}
        </div>
    )
}
