import { createPortal } from 'react-dom'
import { usePortal } from './hooks'

type Props = {
    id: string
    children?: React.ReactNode
}

export const Portal = ({ id, children }: Props) => {
    const target = usePortal(id)
    return createPortal(children, target)
}
