import { ReactElement } from 'react'
import { AtrMileageTableRow } from '../../../../components/AtrMileageTable'
import { AtrTimeLineTable } from '../../../../components/AtrTimeLineTable'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { TimeLineItem } from '../../../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import './index.styl'

export const SectionTimeLine = ({ sourceData, isPrint }: DetailPayedProps): ReactElement => {
    const tran = new Translate()
    if (sourceData?.timeLineControlResult?.timeLineItems?.length === 0)
        return <>{tran.GetText('V dostupných databázích nejsou k dispozici žádné záznamy.')}</>

    return (
        <div className="sectionTimeLine">
            <AtrTimeLineTable
                isPrint={isPrint}
                rows={sourceData?.timeLineControlResult?.timeLineItems ?? Array<TimeLineItem>(0)}
            />
        </div>
    )
}
