import { RefObject, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { useIntersection } from 'react-use'
import { Scroll } from '../../services/Scroll'

type RenderProp = {
    intersectionRef: RefObject<HTMLDivElement>
    staticVisible: boolean
    stickyActive: boolean
}
type Props = {
    offset?: number | (() => number)
    stickyRange?: 'fromTopToElement' | 'fromElementToBottom' | 'both'
    children?: (renderProps: RenderProp) => ReactNode
}

export const StickySwapper = ({ stickyRange, children, offset = 0 }: Props): ReactElement => {
    const [staticVisible, setStaticVisible] = useState(false)
    const [stickyActive, setStickyActive] = useState(false)
    const offsetRef = useRef(typeof offset === 'function' ? offset() : offset)
    const intersectionRef = useRef<HTMLDivElement>(null)
    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.4,
    })

    useEffect(() => {
        if (!intersection) {
            return
        }

        setStaticVisible(intersection?.isIntersecting)
    }, [intersection, stickyActive])

    useEffect(() => {
        const render = () => {
            const intersectionTop = intersection?.boundingClientRect?.top ?? 0
            let active = Scroll.getScroll().y > offsetRef.current

            if (stickyRange === 'fromTopToElement' && intersectionTop < 0) {
                active = false
            }

            if (stickyRange === 'fromElementToBottom' && intersectionTop > 0) {
                active = false
            }

            if (stickyRange === 'both') active = true

            setStickyActive(active)
        }
        const handleScroll = render

        const handleResize = () => {
            if (typeof offset === 'function') {
                offsetRef.current = offset()
            }
            render()
        }

        const options: AddEventListenerOptions = {
            passive: true,
        }

        window.addEventListener('scroll', handleScroll, options)
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleResize)
        }
    }, [intersection])

    return <>{children && children({ intersectionRef, staticVisible, stickyActive })}</>
}
