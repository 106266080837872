import './index.styl'
import { ReactElement } from 'react'
import classNames from 'classnames'

type Props = {
    title: string
    price: string
    className?: string
}

export const AtrCarValue = ({ title, price, className }: Props): ReactElement => {
    return (
        <div className={`AtrCarValue ${className}`}>
            <span className="AtrCarValue-title">{title}</span>
            <span className="AtrCarValue-text text-noWrap Gama font-grotesk">{price}</span>
        </div>
    )
}
