import * as React from 'react'
import { DashBoardJsonResult } from '../../models/dashBoardJsonResult'
import { ReactElement } from 'react'
type Props = {
    priceInfo: DashBoardJsonResult
    nextSale: number | undefined
}

export const SaleBackgroundSVG = ({ priceInfo, nextSale }: Props): ReactElement => {
    const isPercentSale = (priceInfo?.sale?.nl_sleva_proc ?? 0 > 0) || (nextSale ?? 0 > 0)
    //const saleNumber =  priceInfo?.sale?.nl_sleva_proc ?? 0 > 0 ? priceInfo.sale.nl_sleva_proc : nextSale

    const saleNumber = nextSale ?? 0 > 0 ? nextSale : priceInfo?.sale?.nl_sleva_proc ?? 0
    const sleva = Math.floor(saleNumber ?? 0)
    const isFixedPriceSale = priceInfo?.sale?.nd_sleva_pevna > 0
    const fixedSleva = priceInfo?.sale?.nd_sleva_pevna
    if (isPercentSale) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 500">
                <defs>
                    <image
                        width={1920}
                        height={1080}
                        id="img1"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB4AAAAQ4AQMAAADSHVMAAAAAAXNSR0IB2cksfwAAAANQTFRFASFaoZn65AAAARNJREFUeJztwQENAAAAwqD3T20PBxQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMCnAfjlAAGZJYkPAAAAAElFTkSuQmCC"
                    />
                    <style type="text/css">
                        {
                            "@import url('https://fonts.googleapis.com/css?family=Lato|Open+Sans|Oswald|Raleway|Roboto|Indie+Flower|Gamja+Flower');"
                        }
                    </style>
                </defs>
                <style>
                    {
                        '\n\t\ttspan { white-space:pre } \n\t\t.t0 { font-size: 140px;fill: #023285;font-weight: 500;font-family: "Raleway-Medium", "Raleway" } \n\t'
                    }
                </style>
                <use id="Background" href="#img1" x={0} y={0} />
                <text
                    id={`-${sleva} %  ${sleva} %  ${sleva} %  ${sleva} %  ${sleva}`}
                    style={{
                        transform: 'matrix(.906,-0.423,.423,.906,227.337,-228.873)',
                    }}
                >
                    <tspan y={0} x={-2333.9} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>

                    <tspan y={168} x={-2333.9} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan x={-2333.9} y={336} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={336} className="t0" />
                    <tspan x={-2333.9} y={504} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={504} className="t0" />
                    <tspan x={-2333.9} y={672} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={672} className="t0" />
                    <tspan x={-2333.9} y={840} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={840} className="t0" />
                    <tspan x={-2333.9} y={1008} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={1008} className="t0" />
                    <tspan x={-2333.9} y={1176} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={1176} className="t0" />
                    <tspan x={-2333.9} y={1344} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={1344} className="t0" />
                    <tspan x={-2333.9} y={1512} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={1512} className="t0" />
                    <tspan x={-2333.9} y={1680} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={1680} className="t0" />
                    <tspan x={-2333.9} y={1848} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={1848} className="t0" />
                    <tspan x={-2333.9} y={2016} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={2016} className="t0" />
                    <tspan x={-2333.9} y={2184} className="t0">
                        {`-${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %  -${sleva} %`}
                    </tspan>
                    <tspan y={2184} className="t0" />
                </text>
            </svg>
        )
    }
    if (isFixedPriceSale) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 500">
                <title>{'Sale_background'}</title>
                <defs>
                    <image
                        width={1920}
                        height={1080}
                        id="img1"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB4AAAAQ4AQMAAADSHVMAAAAAAXNSR0IB2cksfwAAAANQTFRFASFaoZn65AAAARNJREFUeJztwQENAAAAwqD3T20PBxQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMCnAfjlAAGZJYkPAAAAAElFTkSuQmCC"
                    />
                </defs>
                <style>
                    {
                        '\n\t\ttspan { white-space:pre } \n\t\t.t0 { font-size: 140px;fill: #023285;font-weight: 500;font-family: "Raleway-Medium", "Raleway" } \n\t'
                    }
                </style>
                <use id="Background" href="#img1" x={0} y={0} />
                <text
                    id={`-${fixedSleva} %  ${fixedSleva} %  ${fixedSleva} %  ${fixedSleva} %  ${fixedSleva}`}
                    style={{
                        transform: 'matrix(.906,-0.423,.423,.906,227.337,-228.873)',
                    }}
                >
                    <tspan y={0} x={-2333.9} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>

                    <tspan y={168} x={-2333.9} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan x={-2333.9} y={336} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={336} className="t0" />
                    <tspan x={-2333.9} y={504} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={504} className="t0" />
                    <tspan x={-2333.9} y={672} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={672} className="t0" />
                    <tspan x={-2333.9} y={840} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={840} className="t0" />
                    <tspan x={-2333.9} y={1008} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={1008} className="t0" />
                    <tspan x={-2333.9} y={1176} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={1176} className="t0" />
                    <tspan x={-2333.9} y={1344} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={1344} className="t0" />
                    <tspan x={-2333.9} y={1512} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={1512} className="t0" />
                    <tspan x={-2333.9} y={1680} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={1680} className="t0" />
                    <tspan x={-2333.9} y={1848} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={1848} className="t0" />
                    <tspan x={-2333.9} y={2016} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={2016} className="t0" />
                    <tspan x={-2333.9} y={2184} className="t0">
                        {`-${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}  -${fixedSleva} ${priceInfo.currency}`}
                    </tspan>
                    <tspan y={2184} className="t0" />
                </text>
            </svg>
        )
    }

    return <></>
}
