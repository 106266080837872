import React, { ReactElement } from 'react'
import { Title } from '../Title'
import { Translate } from '../../translations/CebiaTranslator'

export const VinModalLengthFailure = (): ReactElement => {
    const tran = new Translate()
    return (
        <div className="Grid">
            <div className="Grid-cell md:w-12/12">
                <Title as="header">
                    <h2 className="color-darkBlue">
                        {' '}
                        {tran.GetText('V Inzerátu vozidla není uveden celý VIN.')}
                    </h2>
                    <p>
                        {tran.GetText(
                            'Kontaktujte prodejce, aby Vám sdělil 17místný VIN a následně si vozidlo prověřte.',
                        )}
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        className="Link Link--line color-inherit"
                        href="https://www.cebia.cz/pruvodce/provereni-vozu-bez-znalosti-vin"
                    >
                        {tran.GetText('Lze vůz prověřit i bez znalosti VIN?')}
                    </a>
                </Title>
            </div>
        </div>
    )
}
