import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import { NewsletterForm } from '../NewsletterForm'
import { Social } from '../Social'
import { LogoCebia, LogoGjirafa } from '../LogoCebia'
import { Container } from '../Container'
import { i18n, LanguageDefinition, LanguageEnum } from '../../translations/i18n'
import { LinksTexts } from './data'
import { getVersion } from '../../busLog/supportBL'
import { Version } from '../../models/version'
import { Translate } from '../../translations/CebiaTranslator'
import { ContactForm } from '../../pages/faq/ContactForm'
import { useModal } from '../Modal'
import AssetsImgLogoInstagram from './img/instagram.svg'

export const Footer = (): ReactElement => {
    const data = new LinksTexts()
    const serviceLinks = data.GetServiceLinks()
    const minorLinks = data.GetMinorLinks()

    const [version, setVersion] = useState<Version>()
    const { versionRefetch } = getVersion()
    const mainLinks = data.GetMainLinks()
    const { open } = useModal('contactFormModalBusiness')
    const tran = new Translate()
    const email = LanguageDefinition[LanguageEnum[i18n.language as keyof typeof LanguageEnum]].email
    useEffect(() => {
        versionRefetch().then(retval => {
            setVersion(retval.data?.data)
        })
    }, [])
    return (
        <footer className="Footer" id="footer">
            <Container variant="wide">
                <div className="Container is-pure">
                    <div className="Footer-intro">
                        {i18n.language !== 'al' ? (
                            <div className="smwMax:hidden">
                                <LogoCebia variant="light" />
                            </div>
                        ) : (
                            <div className="smwMax:hidden">
                                <LogoGjirafa variant="light" />
                            </div>
                        )}

                        <p className="Footer-contacts">
                            {i18n.language === 'uk' ? (
                                <a href="mailto:info.ua@cebia.com?subject=Запит&cc=support@dataauto.org">
                                    info.ua@cebia.com
                                </a>
                            ) : (
                                <a href={'mailto:' + email}>{email}</a>
                            )}

                            {/* {i18n.language === 'cz' && (
                                <>
                                    <a href="tel:+420222207111">+420 222 207 111</a>
                                    <span className="smwMax:hidden">Po-Pá: 8:00 - 16:30</span>
                                </>
                            )} */}
                        </p>

                        <p className="Footer-social smwMax:hidden">
                            {i18n.language !== 'uk' && (
                                <>
                                    <Social
                                        icon="facebook"
                                        href={
                                            i18n.language == 'al'
                                                ? 'https://www.facebook.com/auto.tracer.by.gjirafa'
                                                : 'https://www.facebook.com/cebia.cz'
                                        }
                                        label="Facebook"
                                    />
                                    {i18n.language === 'al' ? (
                                        <Social
                                            icon="instagram"
                                            href="https://www.instagram.com/autotracer.bygjirafa/"
                                            label="Instagram"
                                        />
                                    ) : (
                                        <Social
                                            icon="youtube"
                                            href="https://www.youtube.com/user/cebiacz"
                                            label="Youtube"
                                        />
                                    )}
                                </>
                            )}
                        </p>
                    </div>
                </div>
                <div
                    className={`Separator ${
                        i18n.language === 'cz' ? 'smwMax:hidden' : 'mb-20 smw-mb-0'
                    }`}
                />
                <Container pure>
                    {i18n.language === 'cz' ? (
                        <div className="Footer-content">
                            <div className="Footer-block">
                                <ul className="Footer-list">
                                    {mainLinks['cz'].map(({ url, title }, index) => (
                                        <li key={index}>
                                            <a target="_blank" rel="noreferrer noopener" href={url}>
                                                {title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="Footer-block Footer-block--services">
                                <ul className="Footer-list Footer-list--minor">
                                    {serviceLinks['cz'].map(({ url, title }, index) => (
                                        <li key={index}>
                                            <a target="_blank" rel="noreferrer noopener" href={url}>
                                                {title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="Footer-block Footer-block--newsletter">
                                <h3 className="Footer-blockTitle lgMax:hidden">
                                    Přihlaste se k odběru novinek
                                </h3>
                                <p className="Footer-blockTitle xl:hidden">Odběr novinek</p>
                                <NewsletterForm />
                            </div>
                        </div>
                    ) : (
                        <div className="Footer-content">
                            <div className="Footer-block">
                                <ul className="Footer-list Footer-listOne">
                                    {mainLinks[i18n.language] &&
                                        mainLinks[i18n.language].map(
                                            ({ url, title, modalContact }, index) => (
                                                <li key={index}>
                                                    {(modalContact ?? false) && (
                                                        <a
                                                            href={url}
                                                            onClick={() => {
                                                                open()
                                                            }}
                                                        >
                                                            {title}
                                                        </a>
                                                    )}
                                                    {!(modalContact ?? false) && (
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer noopener"
                                                            href={url}
                                                        >
                                                            {title}
                                                        </a>
                                                    )}
                                                </li>
                                            ),
                                        )}
                                </ul>
                            </div>
                            <div className="Footer-block Footer-block--services">
                                <ul className="Footer-list Footer-listOne">
                                    {serviceLinks[i18n.language] &&
                                        serviceLinks[i18n.language].map(({ url, title }, index) => (
                                            <li key={index}>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    href={url}
                                                >
                                                    {title}
                                                </a>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            {i18n.language == 'sk' && (
                                <div className="Footer-block Footer-block--services">
                                    <ul className="Footer-list Footer-list--minor col-1">
                                        {minorLinks['sk'].map(({ url, title }, index) => (
                                            <li key={index}>
                                                {url.length > 0 ? (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                        href={url}
                                                    >
                                                        {title}
                                                    </a>
                                                ) : (
                                                    <span className="color-white">{title}</span>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div className="Footer-block Footer-block--newsletter"></div>
                        </div>
                    )}
                    <span className="transparent">
                        {version?.version} {version?.date}
                    </span>
                    <ContactForm isBusiness={true}></ContactForm>
                </Container>
            </Container>
        </footer>
    )
}
