import './index.styl'
import { ReactElement, useState, useEffect } from 'react'
import { Input, InputChangeHandler, InputFocusHandler, InputProps } from '../Input'
import classNames, { Argument } from 'classnames'
import { Translate, _, __ } from '../../translations/CebiaTranslator'

export type InputWithCounterProps = {
    className?: Argument
    label: string
    maxLength: number
    length: number
    initialerror: string
    nogap?: boolean
} & InputProps

export const InputWithCounter = ({
    className: _className,
    maxLength,
    label,
    nogap,
    onChange,
    validate,
    ...props
}: InputWithCounterProps): ReactElement => {
    const [isFocus, setIsFocus] = useState<boolean>(false)
    const [length, setLength] = useState<number>(props.length)
    const tran = new Translate()

    useEffect(() => {
        setLength(props.length)
    }, [props.length])

    const handleInputFocus: InputFocusHandler = () => {
        setIsFocus(true)
    }

    const handleValidate: InputChangeHandler = (value, event) => {
        if (validate) {
            validate(value, event)
        }
    }

    const handleInputBlur: InputFocusHandler = event => {
        setIsFocus(false)
        if (handleValidate) {
            handleValidate(event.currentTarget.value, event)
        }
        setLength(event?.currentTarget?.value?.length ?? 0)
    }

    const handleInputChange: InputChangeHandler = (value, event) => {
        if (value !== undefined) {
            const length = value.length
            if (nogap) {
                value = value.replace(/\s/g, '')
            }
            if (length > maxLength) {
                value = value.substr(0, maxLength)
            }

            setLength(value.length)

            value = value.toUpperCase()
            event.currentTarget.value = value
        }

        if (onChange) {
            onChange(value, event)
        }
    }
    const className = classNames(
        'InputWithCounter',
        {
            'is-full': length >= maxLength,
            'is-focus': isFocus,
            'is-empty': length === 0,
        },
        _className,
    )
    return (
        <label className={className}>
            <Input
                {...props}
                className="InputWithCounter-input"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                //validate={handleValidate}
            />
            <span className="InputWithCounter-label" data-ref="label">
                {label}
            </span>
            <span className="InputWithCounter-counter">
                <span>{length}</span> / {maxLength} {tran.GetText('znaků')}
            </span>
        </label>
    )
}
