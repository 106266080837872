import './index.styl'
import { ReactElement } from 'react'
import { CountryCode } from '../AtrMileageTable/countryCodes'
import classNames, { Argument } from 'classnames'
import atFlag from '../../assets/img/flags/at.svg'
import beFlag from '../../assets/img/flags/be.svg'
import caFlag from '../../assets/img/flags/ca.svg'
import chFlag from '../../assets/img/flags/ch.svg'
import czFlag from '../../assets/img/flags/cz.svg'
import deFlag from '../../assets/img/flags/de.svg'
import dkFlag from '../../assets/img/flags/dk.svg'
import esFlag from '../../assets/img/flags/es.svg'
import fiFlag from '../../assets/img/flags/fi.svg'
import frFlag from '../../assets/img/flags/fr.svg'
import huFlag from '../../assets/img/flags/hu.svg'
import itFlag from '../../assets/img/flags/it.svg'
import ltFlag from '../../assets/img/flags/lt.svg'
import luFlag from '../../assets/img/flags/lu.svg'
import lvFlag from '../../assets/img/flags/lv.svg'
import nlFlag from '../../assets/img/flags/nl.svg'
import noFlag from '../../assets/img/flags/no.svg'
import plFlag from '../../assets/img/flags/pl.svg'
import ptFlag from '../../assets/img/flags/pt.svg'
import roFlag from '../../assets/img/flags/ro.svg'
import ruFlag from '../../assets/img/flags/ru.svg'
import seFlag from '../../assets/img/flags/se.svg'
import skFlag from '../../assets/img/flags/sk.svg'
import uaFlag from '../../assets/img/flags/ua.svg'
import ukFlag from '../../assets/img/flags/uk.svg'
import usFlag from '../../assets/img/flags/us.svg'
import euFlag from '../../assets/img/flags/eu.svg'

type Props = {
    countryCode: CountryCode
    className?: Argument
}

export const Flag = ({ countryCode, className: _className }: Props): ReactElement => {
    const className = classNames('Flag', _className)
    return (
        <span
            className={className}
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/flag/${countryCode}.png)`,
            }}
            aria-hidden="true"
        ></span>
    )
}

type ImageProps = {
    countryCode: CountryCode
    className: string
    alt: string
}

const flagDefinition: Record<string, string> = {
    at: atFlag,
    be: beFlag,
    ca: caFlag,
    ch: chFlag,
    cz: czFlag,
    de: deFlag,
    dk: dkFlag,
    es: esFlag,
    fi: fiFlag,
    fr: frFlag,
    hu: huFlag,
    it: itFlag,
    lt: ltFlag,
    lu: luFlag,
    lv: lvFlag,
    nl: nlFlag,
    no: noFlag,
    pl: plFlag,
    pt: ptFlag,
    ro: roFlag,
    ru: ruFlag,
    se: seFlag,
    sk: skFlag,
    ua: uaFlag,
    uk: ukFlag,
    us: usFlag,
    eu: euFlag,
}

export const FlagImage = ({ countryCode, className = '', alt = '' }: ImageProps): ReactElement => {
    return <img className={className} src={flagDefinition[countryCode.toLowerCase()]} alt={alt} />
}
