import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { CookiesProvider } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './assets/css/main.styl'
import { MainAppRouting } from './components/UrlRouter/mainAppRouting'
import { PrintAppRouting } from './components/UrlRouter/printRouting'

export const czCurrency = 'Kč'
export const HeaderLanguageKey = 'appLang'

function MainApp() {
    const isPrintUrl = window.location.href.toLowerCase().indexOf('/print/') >= 0

    if (isPrintUrl) {
        return <PrintAppRouting />
    } else {
        return (
            <CookiesProvider>
                <MainAppRouting />
            </CookiesProvider>
        )
    }
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <MainApp />
        </BrowserRouter>
    </React.StrictMode>,
)
