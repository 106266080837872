import classNames, { Argument } from 'classnames'
import { CSSProperties, ReactElement } from 'react'
import './index.styl'

export type ImageProps = {
    src: string
    srcSet?: string
    alt: string
    className?: Argument
    loading?: 'lazy' | 'eager' | undefined
    isAnonymized?: boolean
    onclick?: () => void
    inLineStyle?: CSSProperties | undefined
}

export const Image = ({
    className: _className,
    isAnonymized,
    onclick,
    inLineStyle = undefined,
    ...props
}: ImageProps): ReactElement => {
    const className = classNames(isAnonymized === false ? 'ImageAnonymized' : 'Image', _className)

    return (
        <div className={className}>
            <img onClick={onclick} {...props} style={inLineStyle} />
        </div>
    )
}
