import * as React from 'react'
import { ReactElement } from 'react'
import { DashBoardJsonResult } from '../../models/dashBoardJsonResult'

type Props = {
    priceInfo: DashBoardJsonResult
    nextSale: number | undefined
}

export const SaleSVGShape = ({ priceInfo, nextSale }: Props): ReactElement => {
    const isPercentSale = priceInfo?.sale?.nl_sleva_proc ?? (0 > 0 || (nextSale ?? 0 > 0))
    // const saleNumber = priceInfo?.sale?.nl_sleva_proc ?? 0 > 0 ? priceInfo.sale.nl_sleva_proc : nextSale

    const saleNumber = nextSale ?? 0 > 0 ? nextSale : priceInfo?.sale?.nl_sleva_proc ?? 0
    const sleva = Math.floor(saleNumber ?? 0)
    const slevaLength = sleva?.toString()?.length ?? 0
    const isFixedPriceSale = priceInfo?.sale?.nd_sleva_pevna ?? 0 > 0
    const fixedSleva = priceInfo?.sale?.nd_sleva_pevna
    const fixedSlevaLength = fixedSleva?.toString()?.length ?? 0
    if (isPercentSale) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 567 216" width={567} height={216}>
                <defs>
                    <style type="text/css">
                        {
                            "@import url('https://fonts.googleapis.com/css?family=Lato|Open+Sans|Oswald|Raleway|Roboto|Indie+Flower|Gamja+Flower');"
                        }
                    </style>
                </defs>
                <style>
                    {
                        '\n\t\ttspan { white-space:pre } \n\t\t.s0 { fill: #ff4500 } \n\t\t.t1 { font-size: 114px;fill: #ffffff;font-weight: 700;font-family: "Raleway-Medium", "Raleway" } \n\t'
                    }
                </style>
                <path
                    id="Path 0"
                    fillRule="evenodd"
                    className="s0"
                    d="m321.5 3.6c198.6 0.1 209.3 0.2 214.5 1.9 3 1 7.9 3.4 10.8 5.4 3.3 2.2 6.8 5.8 9 9.1 2 3 4.3 7.3 5.2 9.5 1.3 3.4 1.5 13.9 1.8 75 0.2 47.9-0.1 72.6-0.8 76-0.6 2.8-2 7-3.2 9.5-1.2 2.5-4.6 7-7.7 10.1-3.8 3.7-7.6 6.3-17.6 10.4l-214.5 0.2c-176.1 0.2-215.4 0-219.5-1.1-2.7-0.7-6.3-2-8-2.9-1.7-0.9-21.3-19.9-43.7-42.4-32.6-32.6-41.1-41.8-42.8-45.6-1.2-2.8-2.1-7.2-2.1-10.9-0.1-4.9 0.4-7.5 2.5-11.8 2.1-4.6 9.7-12.6 44.3-47.1 36.9-36.7 42.3-41.7 46.3-42.9 2.5-0.7 7.2-1.6 10.5-1.9 3.3-0.3 100.1-0.6 215-0.5zm-235.3 88.7c-1.8 1.8-3.9 5.3-4.7 7.9-0.9 2.8-1.2 6.2-0.9 8 0.4 1.8 1.1 4.2 1.7 5.3 0.5 1.1 2.3 3.5 4.1 5.3 1.8 1.8 5.2 3.9 8.1 4.8 4.3 1.4 5.6 1.4 9.5 0.3 2.5-0.7 6.2-2.9 8.3-4.9 2.5-2.2 4.4-5.2 5.3-8.3 1.2-4.1 1.2-5.4-0.1-10-0.9-3.2-2.7-6.4-4.7-8.5-1.8-1.8-5.3-3.9-7.8-4.7-2.5-0.8-5.2-1.5-6-1.4-0.8 0-3.3 0.7-5.5 1.5-2.2 0.8-5.5 2.9-7.3 4.7z"
                />
                <text
                    id={`- ${sleva} %`}
                    style={{
                        transform: 'matrix(1,0,0,1,194,55)',
                    }}
                >
                    <tspan x={0} y={83.2} className="t1">
                        {'-'}
                    </tspan>
                    <tspan y={83.2} className="t1" />
                    <tspan y={83.2} className="t1">
                        {sleva.toString().substring(0, 1)}
                    </tspan>
                    <tspan y={83.2} className="t1">
                        {sleva.toString().substring(1, 2)}
                    </tspan>
                    {slevaLength === 3 && (
                        <tspan y={83.2} className="t1">
                            {sleva.toString().substring(2, 2)}
                        </tspan>
                    )}
                    <tspan y={83.2} className="t1">
                        {' '}
                    </tspan>
                    <tspan y={83.2} className="t1">
                        {'%\n'}
                    </tspan>
                </text>
            </svg>
        )
    }
    if (isFixedPriceSale) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 567 216" width={567} height={216}>
                <title>{`Sleva${fixedSleva}_tag`}</title>
                <style>
                    {
                        '\n\t\ttspan { white-space:pre } \n\t\t.s0 { fill: #ff4500 } \n\t\t.t1 { font-size: 100px;fill: #ffffff;font-weight: 700;font-family: "Raleway-Medium", "Raleway" } \n\t'
                    }
                </style>
                <path
                    id="Path 0"
                    fillRule="evenodd"
                    className="s0"
                    d="m321.5 3.6c198.6 0.1 209.3 0.2 214.5 1.9 3 1 7.9 3.4 10.8 5.4 3.3 2.2 6.8 5.8 9 9.1 2 3 4.3 7.3 5.2 9.5 1.3 3.4 1.5 13.9 1.8 75 0.2 47.9-0.1 72.6-0.8 76-0.6 2.8-2 7-3.2 9.5-1.2 2.5-4.6 7-7.7 10.1-3.8 3.7-7.6 6.3-17.6 10.4l-214.5 0.2c-176.1 0.2-215.4 0-219.5-1.1-2.7-0.7-6.3-2-8-2.9-1.7-0.9-21.3-19.9-43.7-42.4-32.6-32.6-41.1-41.8-42.8-45.6-1.2-2.8-2.1-7.2-2.1-10.9-0.1-4.9 0.4-7.5 2.5-11.8 2.1-4.6 9.7-12.6 44.3-47.1 36.9-36.7 42.3-41.7 46.3-42.9 2.5-0.7 7.2-1.6 10.5-1.9 3.3-0.3 100.1-0.6 215-0.5zm-235.3 88.7c-1.8 1.8-3.9 5.3-4.7 7.9-0.9 2.8-1.2 6.2-0.9 8 0.4 1.8 1.1 4.2 1.7 5.3 0.5 1.1 2.3 3.5 4.1 5.3 1.8 1.8 5.2 3.9 8.1 4.8 4.3 1.4 5.6 1.4 9.5 0.3 2.5-0.7 6.2-2.9 8.3-4.9 2.5-2.2 4.4-5.2 5.3-8.3 1.2-4.1 1.2-5.4-0.1-10-0.9-3.2-2.7-6.4-4.7-8.5-1.8-1.8-5.3-3.9-7.8-4.7-2.5-0.8-5.2-1.5-6-1.4-0.8 0-3.3 0.7-5.5 1.5-2.2 0.8-5.5 2.9-7.3 4.7z"
                />
                <text
                    id={`- ${fixedSleva} %`}
                    style={{
                        transform: 'matrix(1,0,0,1,140,45)',
                    }}
                >
                    <tspan x={0} y={83.2} className="t1">
                        {'-'}
                    </tspan>
                    <tspan y={83.2} className="t1" />
                    <tspan y={83.2} className="t1">
                        {fixedSleva.toString().substring(0, 1)}
                    </tspan>
                    <tspan y={83.2} className="t1">
                        {fixedSleva.toString().substring(1, 2)}
                    </tspan>
                    {fixedSlevaLength === 3 && (
                        <tspan y={83.2} className="t1">
                            {fixedSleva.toString().substring(2, 3)}
                        </tspan>
                    )}
                    <tspan y={83.2} className="t1">
                        {' '}
                    </tspan>
                    <tspan y={83.2} className="t1">
                        {priceInfo.currency}
                    </tspan>
                </text>
            </svg>
        )
    }
    return <></>
}
