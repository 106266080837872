import { ReactElement } from 'react'
import { _, __, Translate } from '../../translations/CebiaTranslator'

type Props = {
    title?: string
    meta?: string
    children?: ReactElement
}

export function DynamicTitleText(titleText: string, condition: boolean) {
    const mask = '{-}'

    if (condition && document.title.indexOf(mask) >= 0) {
        document.title = document.title.replace(mask, titleText)
    }
}

export function SetTitleDescText(titleText: string, descriptionText: string, condition: boolean) {
    if (condition) {
        document.title = titleText
        document.getElementById('metaDesc')?.setAttribute('content', descriptionText)
    }
}

export const TitleMetaSection = ({ title, meta = '', children }: Props) => {
    const tran = new Translate()
    document.getElementById('metaDesc')?.setAttribute('content', meta)
    document.title =
        title != null
            ? `${title} | Cebia.com`
            : tran.GetText(
                  'Pořizujete si ojetý vůz? Zjistěte si o něm vše! Zejména stav tachometru, rok výroby nebo záznamy o poškození podle VIN kódu.',
              )

    return <>{children}</>
}
