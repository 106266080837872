import { ReactElement } from 'react'
import { ShowDateEnum } from '../../enums/ShowDateEnum'
import { SmartCodeType } from '../../enums/smartCodeType'
import { PayedStateModel } from '../../models/payedResponseModel'
import { AtrOverviewCar } from '../AtrOverviewCar'
import { Container } from '../Container'
import { LoadArea } from '../LoadArea'
import { Section } from '../Section'

export type PayedRegCarInfo = {
    carInfo: PayedStateModel | undefined
}

export const PayedRegCarInfo = ({ carInfo }: PayedRegCarInfo): ReactElement => {
    return (
        <Section variantPend="appended" className="SectionOverview pb-10">
            <Container variant="medium">
                {carInfo && (
                    <AtrOverviewCar
                        isLoading={!carInfo}
                        vin={carInfo?.carVin ?? ''}
                        logo={{
                            src: carInfo?.imageData
                                ? `data:image/png;base64, ${carInfo.imageData}`
                                : '',
                            alt: '',
                        }}
                        brand={carInfo?.carMake ?? ''}
                        model={carInfo?.carModel ?? ''}
                        type={carInfo?.typ ?? ''}
                        category={carInfo?.druh ?? ''}
                        fuel={carInfo?.fuel ?? ''}
                        gearBox={carInfo?.gearBox ?? ''}
                        enginePower={carInfo?.kw ?? ''}
                        engineCapacity={carInfo?.ccm ?? ''}
                        dateManufacturing={undefined}
                        showDateManufacturingType={ShowDateEnum.ShowYear}
                        dateFirstReg={undefined}
                        couponCode={carInfo?.couponCode ?? ''}
                        couponDateExpiry={undefined}
                        controlDigits={carInfo.controlDigits}
                        couponDateActivation={undefined}
                        showActionButtons={true}
                        smartCodeType={SmartCodeType.None}
                        viewId={carInfo.viewId}
                    />
                )}
            </Container>
        </Section>
    )
}
