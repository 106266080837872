import { forEach } from 'lodash'
import { useEffect } from 'react'

const useScript = (url: string, dataAtributtes: Array<string>) => {
    useEffect(() => {
        const script = document.createElement('script')

        script.src = url
        script.async = true
        dataAtributtes.forEach(x => script.setAttribute(x, ''))

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [url])
}

export default useScript
