import './index.styl'
import { ReactElement } from 'react'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { OtherControlData } from '../../../../models/DetailPayedResults/OthersCebia/otherControlModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import { SectionEPojisteni } from './Section/ePojisteni'
import { SectionProVin } from './Section/proVin'
import { SectionNextCheck } from './Section/saleNextCheck'
import { SectionSaleSafety } from './Section/saleSafety'
import { SectionVinTest } from './Section/vinTest'
import { LoadArea } from '../../../../components/LoadArea'
import { SectionExekuce } from '../SectionExekuce'
import { i18n } from '../../../../translations/i18n'

export type CouponSalesProps = {
    sourceData: OtherControlData
    tran: Translate
}

export const SectionCouponSales = ({ sourceData }: DetailPayedProps): ReactElement => {
    if (!sourceData?.otherControlResult) return <></>
    const tran = new Translate()
    return (
        <>
            <div className="sectionCouponSales">
                <div className="Grid gmb-5">
                    <SectionNextCheck sourceData={sourceData.otherControlResult} tran={tran} />
                    {i18n.language === 'cz' && (
                        <>
                            <SectionSaleSafety
                                sourceData={sourceData.otherControlResult}
                                tran={tran}
                            />
                            <SectionProVin sourceData={sourceData.otherControlResult} tran={tran} />
                            <SectionVinTest
                                sourceData={sourceData.otherControlResult}
                                tran={tran}
                            />
                            {/* <SectionEPojisteni
                                sourceData={sourceData.otherControlResult}
                                tran={tran}
                            /> */}
                        </>
                    )}
                </div>
            </div>
            {/* <LoadArea
                hideBackGround={true}
                position="inherit"
                loader={{ variant: 'bars', size: 'big' }}
                align="center"
                isLoading={sourceData?.otherControlResult === undefined ? false : true}
            /> */}
        </>
    )
}
