import { ReactElement } from 'react'
import { Banner } from '../../../../components/Banner'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Translate } from '../../../../translations/CebiaTranslator'
import { i18n } from '../../../../translations/i18n'

interface linksAboutType {
    urlVp: string
    urlOu: string
}

export const LanguageDefinition: Record<string, linksAboutType> = {
    cz: {
        urlVp: 'https://www.cebia.cz/cs/download/vseob_podm_atr.pdf',
        urlOu: '',
    },
    en: {
        urlVp: 'https://www.cebia.cz/files/general_terms_and_conditions_car_history.pdf',
        urlOu: 'https://www.cebia.cz/en/legal-information/personal-data',
    },
    de: {
        urlVp: 'https://www.cebia.cz/pruvodce?id=107&do=downloadFile',
        urlOu: 'https://www.cebia.cz/pruvodce?id=109&do=downloadFile',
    },
    ro: {
        urlVp: 'https://www.cebia.cz/pruvodce?id=110&do=downloadFile',
        urlOu: 'https://www.cebia.cz/pruvodce?id=111&do=downloadFile',
    },
    al: {
        urlVp: 'https://www.cebia.cz/pruvodce?id=203&do=downloadFile',
        urlOu: 'https://www.cebia.cz/pruvodce?id=204&do=downloadFile',
    },
    sk: {
        urlVp: 'https://www.cebia.cz/pruvodce?id=123&do=downloadFile',
        urlOu: 'https://www.cebia.cz/pruvodce?id=124&do=downloadFile',
    },
    uk: {
        urlVp: 'https://www.cebia.cz/pruvodce?id=157&do=downloadFile',
        urlOu: 'https://www.cebia.cz/pruvodce?id=158&do=downloadFile',
    },
    fr: {
        urlVp: 'https://www.cebia.cz/pruvodce?id=162&do=downloadFile',
        urlOu: 'https://www.cebia.cz/pruvodce?id=163&do=downloadFile',
    },
    it: {
        urlVp: 'https://www.cebia.cz/pruvodce?id=160&do=downloadFile',
        urlOu: 'https://www.cebia.cz/pruvodce?id=161&do=downloadFile',
    },
    nl: {
        urlVp: 'https://www.cebia.cz/pruvodce?id=197&do=downloadFile',
        urlOu: 'https://www.cebia.cz/pruvodce?id=198&do=downloadFile',
    },
}

export const SectionAboutCrossroad = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section variantPend="appended">
            <Container>
                <div className="Grid gap-20 md:gap-30 lg:gap-40 gmb-20 md:gmb-30 lg:gmb-40 align-center">
                    <div className="Grid-cell smw:w-6/12 md:w-4/12">
                        <Banner
                            title={tran.GetText('Všeobecné podmínky služby')}
                            href={LanguageDefinition[i18n.language].urlVp}
                            buttonText={tran.GetText('Zobrazit')}
                        />
                    </div>
                    <div className="Grid-cell smw:w-6/12 md:w-4/12">
                        <Banner
                            className="bg-mediumBlue"
                            title={tran.GetText('Zásady ochrany osobních údajů')}
                            href={LanguageDefinition[i18n.language].urlOu}
                            buttonText={tran.GetText('Zobrazit')}
                        />
                    </div>
                    <div className="Grid-cell smw:w-6/12 md:w-4/12">
                        <Banner
                            className="bg-darkBlue"
                            title={tran.GetText('Zásady používání cookies')}
                            href="/about/cookies"
                            buttonText={tran.GetText('Zobrazit')}
                        />
                    </div>
                </div>
            </Container>
        </Section>
    )
}
