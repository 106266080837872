import './index.styl'
import { ReactElement } from 'react'
import classNames, { Argument } from 'classnames'

type Props = {
    variant?: 'small'
    priceBefore: string
    priceAfter: string
    showDiscount?: boolean
    className?: Argument
}

export const Price = ({
    variant,
    priceBefore,
    priceAfter,
    showDiscount,
    className: _className,
}: Props): ReactElement => {
    const className = classNames(
        'Price',
        {
            [`Price--${variant}`]: variant,
        },
        _className,
    )

    return (
        <div className={className}>
            {priceBefore && (priceBefore !== priceAfter || (showDiscount ?? false)) && (
                <span className="Price-cross bold">{priceBefore}</span>
            )}
            {priceAfter && <span className="Price-final">{priceAfter}</span>}
        </div>
    )
}
