import { ReactElement, useState } from 'react'
import { LoadArea } from '../../../../components/LoadArea'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { ValuationControlData } from '../../../../models/DetailPayedResults/Valuation/valuationControlModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import './index.styl'
import { SectionValuationDone } from './Sections/valuationDone'
import { SectionValuationForm } from './Sections/valuationForm'

export const SectionValuation = ({ sourceData, isPrint }: DetailPayedProps): ReactElement => {
    if (!sourceData?.valuationControlResult) return <></>
    const tran = new Translate()
    const [valuation, setValuation] = useState<ValuationControlData>(
        sourceData!.valuationControlResult,
    )
    if (
        !sourceData.valuationControlResult.isValid ||
        (isPrint && !sourceData.valuationControlResult.doneValuation)
    ) {
        return (
            <>
                <div className="sectionValuation">
                    <p>
                        {tran.GetHtml(
                            'Ocenění vozidla nebylo provedeno. Ocenění lze provést po dobu platnosti kuponu zadáním údajů o vozidle (datum první registrace a aktuálního stav tachometru) na našich webových stránkách po vložení čísla kuponu.',
                        )}
                    </p>
                </div>
                <LoadArea
                    hideBackGround={true}
                    position="inherit"
                    loader={{ variant: 'bars', size: 'big' }}
                    align="center"
                    isLoading={!sourceData.valuationControlResult}
                />
            </>
        )
    } else {
        return (
            <>
                <div className="sectionValuation">
                    <p className="text-justify">
                        {tran.GetHtml(
                            'Sdělíme vám obvyklou tržní cenu vozidla, pomocí které si ověříte, zda cena oceňovaného vozu odpovídá nabídkové ceně inzerce. Do výpočtu ceny je zahrnuto mimo jiné sledování inzerce ojetých vozidel v ČR i zahraničí včetně sledování cen skutečných realizovaných prodejů. Oceňovací algoritmus pracuje s výpočtovými modely postavenými na dlouhodobém monitoringu trhu, ale není schopen zohlednit fyzický stav vozidla.',
                        )}
                    </p>
                    <p className="text-justify">
                        {tran.GetHtml(
                            'V ojedinělých případech se může stát, že cenu vozidla nelze určit. Důvodem jsou nedostatečná data pro kvalifikované ocenění vozidla.',
                        )}
                    </p>

                    <SectionValuationForm
                        sourceData={valuation}
                        tran={tran}
                        setValuationData={setValuation}
                    />
                    <SectionValuationDone sourceData={valuation} tran={tran} />
                </div>
                <LoadArea
                    hideBackGround={true}
                    position="inherit"
                    loader={{ variant: 'bars', size: 'big' }}
                    align="center"
                    isLoading={!sourceData?.valuationControlResult}
                />
            </>
        )
    }
}
