import './index.styl'
import React, { FormEvent, ReactElement, useEffect, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'
import { Field, ErrorMessage, useField } from 'formik'
import { BubbleOpener } from '../BubbleOpener'

export type InputChangeHandler = (event: FormEvent<HTMLInputElement>) => void

export type SelectProps = {
    variant?: 'dark'
    variantSize?: 'desktopLarge'
    id: string
    name: string
    isInvalid?: boolean
    className?: Argument
    initialerror?: string
    label?: string
    children?: ReactNode
    value?: string
    labelQuestionHandler?: () => void
    onChange?: InputChangeHandler
    validate?: InputChangeHandler
    disabled?: boolean
}

export const Select = ({
    variant,
    variantSize,
    isInvalid = false,
    className: _className,
    onChange,
    validate,
    children,
    value,
    labelQuestionHandler,
    disabled = false,
    ...props
}: SelectProps): ReactElement => {
    const [field, meta, helpers] = useField(props.name)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (onChange) {
            onChange(e)
        }
        field.onChange(e)
    }

    const className = classNames(
        'Input Input-Select',
        {
            [`Input--${variant}`]: variant,
            [`Input--${variantSize}`]: variantSize,
            [`is-empty`]: !field.value,
            [`is-invalid`]: isInvalid,
        },
        _className,
    )

    useEffect(() => {
        helpers.setError(props.initialerror)
    }, [])

    return (
        <>
            {props.label && (
                <label className="Label" htmlFor={props.name}>
                    {props.label}

                    {labelQuestionHandler && (
                        <BubbleOpener
                            className={`ml-5 questioner${props.name}`}
                            onClickHandler={labelQuestionHandler}
                        />
                    )}
                </label>
            )}
            <Field
                className={`${className} ${meta.error || props.initialerror ? 'is-invalid' : ''}`}
                as="select"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                multiple={false}
                {...props}
                disabled={disabled}
            >
                {children}
            </Field>
            <ErrorMessage name={props.name} component="div" className="InputError" />
        </>
    )
}
