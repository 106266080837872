import parse, { domToReact } from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import '../translations/i18n'

export class Translate {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    t: any | undefined
    numFormater = new Intl.NumberFormat('cs-CZ')

    constructor() {
        this.t = useTranslation().t
    }

    GetHtml(translateText: string): ReturnType<typeof domToReact> {
        return parse(this.t(translateText))
    }

    GetText(translateText: string): string {
        return this.t(translateText)
    }

    GetNumber(translatedNumber: number): string {
        return this.numFormater.format(translatedNumber)
    }
}

export function _(translateText: string): ReturnType<typeof domToReact> {
    const { t } = useTranslation()
    return parse(t(translateText))
}
export function __(translateText: string): string {
    const { t } = useTranslation()
    return t(translateText)
}
