import { ReactElement } from 'react'
import { Translate } from '../../../../translations/CebiaTranslator'
import { ToggleOpenerGeneralVoucher } from '../../../../components/ToggleOpenerGeneralVoucher'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'

export const SectionCarAukce = (): ReactElement => {
    const tran = new Translate()
    return (
        <>
            <ToggleOpenerGeneralVoucher
                href="#saleCarAukce"
                titleLeft="Prodej vašeho stávajícího vozidla"
                button="Detail"
                isOpened={false}
            />
            <ToggleItemGeneral id="saleCarAukce">
                <div className="toggleContent">
                    <div className="sectionCarAukce">
                        <p className="text-justify">
                            {tran.GetHtml(
                                'Využijte možností online aukcí na <a href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com" target="_blank">Caraukce.cz</a> pro pohodlný a rychlý prodej vašeho stávajícího auta v elektronické aukci.',
                            )}
                        </p>
                        <p className="text-justify">
                            {tran.GetHtml(
                                'Vše vyřídíte z domova online a celý prodej je ZDARMA, tzn. neplatíte žádný aukční poplatek nebo provizi a cenu ojetého auta určujete vy. V těchto aukcích nakupují jak soukromé osoby, tak především autobazary a prodáváte, případně kupujete přímo bez zprostředkovatele. Dosáhnete tak nejlepší ceny na aktuálním trhu. Aukcí můžete využít i pro výběr a koupi vašeho auta. Vždy běží online několik aukcí, kde je v nabídce 50 až 100 vozů.',
                            )}
                        </p>
                        <p>
                            <a
                                href="https://www.caraukce.cz/?utm_source=cebia&utm_medium=cz_cebia_com&utm_campaign=chci_koupit"
                                target="_blank"
                                className="Button Button--small Button--blue mr-10"
                                rel="noreferrer"
                            >
                                <span className="Button-content">
                                    {tran.GetText('Chci koupit auto')}
                                </span>
                            </a>
                            <a
                                href="https://www.caraukce.cz/chci-prodat/?utm_source=cebia&utm_medium=cz_cebia_com&utm_campaign=chci_prodat"
                                target="_blank"
                                className="Button Button--small Button--green"
                                rel="noreferrer"
                            >
                                <span className="Button-content">
                                    {tran.GetText('Chci prodat auto')}
                                </span>
                            </a>
                        </p>
                    </div>
                </div>
            </ToggleItemGeneral>
        </>
    )
}
