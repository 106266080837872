import { ReactElement } from 'react'
import classNames from 'classnames'
import './index.styl'

export type BubbleParams = {
    country: string
    x: number
    y: number
}

export type BubbleProps = BubbleParams & {
    active: boolean
}

export const EuropeMapBubble = ({ x, y, country, active }: BubbleProps): ReactElement => {
    const classes = classNames('EuropeMapBubble', {
        'is-active': active,
    })

    return (
        <div className={classes} style={{ left: `${x}px`, top: `${y}px` }}>
            {country}
        </div>
    )
}
