import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'

export const SectionSeparator = (): ReactElement => {
    return (
        <Section variant="bare">
            <Container>
                <div className="Separator"></div>
            </Container>
        </Section>
    )
}
