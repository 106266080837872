import { ReactElement, useEffect } from 'react'
import { formatDate } from '../../utils/formatDate'
import { Image, ImageProps } from '../Image'
import { Sticky } from '../Sticky'
import './index.styl'
import { RssAttachment } from '../../models/RssChannel'
import { Translate } from '../../translations/CebiaTranslator'

type Props = {
    image: ImageProps
    title: string
    date: Date
    perex: string
    content: string
    attachments?: RssAttachment[]
}

export const ArticleDetail = ({
    image,
    title,
    date,
    perex,
    content,
    attachments,
}: Props): ReactElement => {
    const tran = new Translate()

    useEffect(() => {
        if (content) insertTableIfNeeded()
    }, [content])

    function insertTableIfNeeded() {
        const tables = document.querySelectorAll('.Table')

        tables.forEach(table => {
            const tableParent = table.closest('.Table-parent')

            if (table && !tableParent) {
                // Vytvoření nového divu
                const newDiv = document.createElement('div')
                newDiv.classList.add('Table-parent')

                // Přesunutí tabulky do nového divu
                table.before(newDiv)
                newDiv.appendChild(table)
            }
        })
    }

    return (
        <div className="ArticleDetail">
            <header className="ArticleDetail-header">
                <div className="ArticleDetail-controls ArticleDetail-controls--back"></div>

                <div className="ArticleDetail-headerInner">
                    <h1 className="ArticleDetail-title">{title}</h1>
                    <p className="ArticleDetail-info">
                        <time dateTime={formatDate(date, 'Y-m-d')} className="ArticleDetail-date">
                            {formatDate(date, 'd / m / Y')}
                        </time>
                    </p>
                </div>
            </header>

            <div className="ArticleDetail-main">
                <aside className="ArticleDetail-controlsWrapper">
                    <Sticky offsetTop={70}>
                        <div className="ArticleDetail-controls"></div>
                    </Sticky>
                </aside>
                <div className="ArticleDetail-content">
                    {image && <Image className="ArticleDetail-image" {...image} />}
                    <div
                        className="ArticleDetail-perex Gama color-darkBlue"
                        dangerouslySetInnerHTML={{ __html: perex }}
                    ></div>
                    <div
                        className="article-Text"
                        dangerouslySetInnerHTML={{ __html: content }}
                    ></div>
                    {attachments && attachments.length > 0 && (
                        <div className="ArticleDetail-attachmentsWrapper">
                            <h3 className="ArticleDetail-attachmentsTitle">
                                {tran.GetText('Přílohy k článku')}
                            </h3>
                            <ul className="ArticleDetail-attachments">
                                {attachments.map((attachment, index) => (
                                    <li key={index}>
                                        <a
                                            href={attachment.link}
                                            className="FileLink FileLink--extended"
                                        >
                                            <span className="FileLink-label">
                                                {attachment.name}
                                            </span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
