export class CheckLeaseControlData {
    isLeasing: boolean | undefined
    checkLeaseItems: CheckLeaseItem[] | undefined
}

export class CheckLeaseItem {
    checkDate?: Date
    countryFlagName = ''
    countryName = ''
    isLeasing = false
    companyLeasingCheck: { key: string; value: number }[] | undefined
}
