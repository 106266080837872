export type ANType = 'number' | 'alpha'

export class RandomStringGenerator {
    Generate(length: number, anType: ANType) {
        const an = anType && anType.toLowerCase()
        let str = '',
            i = 0
        const min = an == 'alpha' ? 10 : 0
        const max = an == 'number' ? 10 : 62
        for (; i++ < length; ) {
            let r = (Math.random() * (max - min) + min) << 0
            str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48))
        }
        return str
    }
}
