import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionVehicleTechDesc } from '../../../../detailPaid/atr-items/SectionVehicleInfo/Section/vehicleTechDesc'
import './body.styl'

let PrintAllowIncrement = true

export const TechDescription = ({
    sourceData,
    tran,
    captionNumberRef,
}: PrintDataProps): ReactElement => {
    if (
        !sourceData?.vehicleInfoControlResult ||
        (sourceData.vehicleInfoControlResult.vehicleTechDesc?.length ?? 0) <= 0
    )
        return <></>

    const techDescData = new PayedJsonResult()
    techDescData.vehicleInfoControlResult = sourceData.vehicleInfoControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Údaje z technického průkazu')}
            </h2>

            <Section className="TechDescriptionSection">
                <SectionVehicleTechDesc
                    sourceData={techDescData.vehicleInfoControlResult}
                    tran={tran}
                    isPrint={true}
                />
            </Section>
        </div>
    )
}
