export class RokvyControlData {
    rokVy = 0
    manufactureDate?: string
    madeDateDescription?: string
    differenceRokVyError?: boolean
    firstRegistration?: Date
    firstRegistrationDescription?: string
    firstRegistrationInCz?: Date
    firstRegistrationDescriptionCz?: string
    firstRegistrationInSk?: Date
    firstRegistrationDescriptionSk?: string
    differenceRokVyDescription?: string

    carAge?: string

    madeForMarket?: string
    steeringSide?: string
}
