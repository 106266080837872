import './index.styl'
import { ReactElement, ReactNode } from 'react'

type Props = {
    children?: ReactNode
}

export const AtrNavBlock = ({ children }: Props): ReactElement => {
    return <div className="AtrNavBlock">{children}</div>
}
