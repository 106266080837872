import { Options } from 'react-confirm-box/dist/types'
import { Button } from '../Button'
import { Translate } from '../../translations/CebiaTranslator'
export const options: Options = {
    closeOnOverlayClick: true,
    render: (message: string, onConfirm: () => void, onCancel: () => void): Element | any => {
        const tran = new Translate()
        return (
            <div id="confirm-box" className="confirm-box__content">
                <span> {message} </span>
                <div className="confirm-box__actions">
                    <Button
                        as="button"
                        variantSize="small"
                        variantColor="blue"
                        type="button"
                        onClick={() => {
                            onConfirm()
                        }}
                    >
                        {tran.GetText('OK')}
                    </Button>
                    <Button
                        as="button"
                        variantSize="small"
                        variantColor="lightBlue"
                        type="button"
                        onClick={() => {
                            onCancel()
                        }}
                    >
                        {tran.GetText('Zrušit')}
                    </Button>
                </div>
            </div>
        )
    },
}
