import moment from 'moment'
import { AtrCarValue } from '../../../../../components/AtrCarValue'
import { AtrGallery } from '../../../../../components/AtrGallery'
import { ToggleItemGeneral } from '../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralAdvert } from '../../../../../components/ToggleOpenerGeneralAdvert'
import {
    getPhotosUrlArray,
    PhotosDescriptionItem,
} from '../../../../../models/DetailPayedResults/Photos/photosControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'
import NoImage from '../../../../../assets/img/NoImage.svg'

export type Props = {
    sourceData?: PhotosDescriptionItem[]
    tran: Translate
    advertTypeName: string
}

export const SectionAdvertToggle = ({ sourceData, tran, advertTypeName }: Props) => {
    if ((sourceData?.length ?? 0) <= 0) return <></>

    return (
        <>
            {sourceData?.map((item, index) => (
                <div className="Grid-cell" key={`toggleAdvert${index}_${advertTypeName}`}>
                    <ToggleOpenerGeneralAdvert
                        href={`#togPhotosContent_${index}_${advertTypeName}`}
                        image={{
                            src:
                                (item.advertImageUrls?.length ?? 0) > 0
                                    ? item.advertImageUrls![0]
                                    : NoImage,
                            alt: '',
                        }}
                        mileage={`${tran.GetNumber(item.advertKm)} ${tran.GetText('km')}`}
                        date={moment(item.advertDate).format('DD. MM. YYYY')}
                        title={`${tran.GetNumber(item.advertPrice)} ${item.currency}`}
                        isOpened={false}
                        button={tran.GetText('Inzerce')}
                    />
                    <ToggleItemGeneral id={`togPhotosContent_${index}_${advertTypeName}`}>
                        <div className="Grid gap-20 gmb-15 mb-10">
                            <div className="Grid-cell w-1-2 smw:w-1/3 md:w-1/2 lg:w-1/3">
                                <AtrCarValue
                                    className="AtrCarValue photoToggler"
                                    title={tran.GetText('Datum pořízení inzerátu')}
                                    price={moment(item.advertDate).format('DD. MM. YYYY')}
                                />
                            </div>
                            {item.newPrice && item.newPrice > 0 && (
                                <div className="Grid-cell w-1-2 smw:w-1/3 md:w-1/2 lg:w-1/3">
                                    <AtrCarValue
                                        className="AtrCarValue photoToggler"
                                        title={tran.GetText('Cena nového vozidla')}
                                        price={`${tran.GetNumber(item.newPrice)} ${item.currency}`}
                                    />
                                </div>
                            )}
                            <div className="Grid-cell w-1-2 smw:w-1/3 md:w-1/2 lg:w-1/3">
                                <AtrCarValue
                                    className="AtrCarValue photoToggler"
                                    title={tran.GetText('Inzerovaný nájezd')}
                                    price={`${tran.GetNumber(item.advertKm)} ${tran.GetText('km')}`}
                                />
                            </div>
                            <div className="Grid-cell w-1-2 smw:w-1/3 md:w-1/2 lg:w-1/3">
                                <AtrCarValue
                                    className="AtrCarValue photoToggler"
                                    title={tran.GetText('Inzerovaná cena')}
                                    price={`${tran.GetNumber(item.advertPrice)} ${item.currency}`}
                                />
                            </div>
                        </div>
                        {(item.advertImageUrls?.length ?? 0) > 0 && (
                            <AtrGallery
                                mainImage={{
                                    src: item.screenShotUrl,
                                    alt: '',
                                }}
                                images={getPhotosUrlArray(item.advertImageUrls)}
                                id="advrOthr"
                                setFullScreenProp={false}
                            />
                        )}
                    </ToggleItemGeneral>
                </div>
            ))}
        </>
    )
}
