import { ReactElement, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import { EcommerceData, PayedStateModel } from '../../models/payedResponseModel'
import { i18n, LanguageDefinition, LanguageEnum } from '../../translations/i18n'

export const AffilAdd = (): ReactElement => {
    const navigate = useNavigate()
    const cId =
        LanguageDefinition[LanguageEnum[i18n.language as keyof typeof LanguageEnum]]
            ?.affilCampaignId ?? -1

    useEffect(() => {
        ResolveMetaTagAffil()
    }, [navigate])

    const ResolveMetaTagAffil = () => {
        const isHomePage = location.pathname.toLowerCase() == '/' ? true : false

        if (isHomePage && cId > 0) {
            /*<script type="text/javascript" id="afilA1">
                var ab_instance = 'partner.cebia.com'
                var ab_inzerent = 3
                var ab_kampan = 18
            </script>*/

            /*<script type="text/javascript">
                var ab_instance = "partner.cebia.com";
                var ab_kampan = 18;
                var ab_cena = cena;
                var ab_id_transakce = "cislo_kuponu";
                var ab_mena = "mena";
                var ab_kupon = "KOD_KUPONU";
            </script>*/

            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.id = 'affilEl'
            script.text =
                "var ab_instance = 'partner.cebia.com'; var ab_inzerent = 3; var ab_kampan = " +
                cId +
                ';'
            document.head.appendChild(script)

            //<script src="https://partner.cebia.com/t3.js" type="text/javascript" async defer></script>
            const script2 = document.createElement('script')
            script2.type = 'text/javascript'
            script2.id = 'affilEl2'
            script2.async = true
            script2.defer = true
            script2.src = 'https://partner.cebia.com/t3.js'
            document.head.appendChild(script2)
        } else {
            document.getElementById('affilEl')?.remove()
            document.getElementById('affilEl2')?.remove()
        }
    }

    return (
        <>
            <Outlet />
        </>
    )
}

export function SendAffil(model: PayedStateModel | undefined) {
    const [stateAffil, setSended] = useState(0)
    const cId =
        LanguageDefinition[LanguageEnum[i18n.language as keyof typeof LanguageEnum]]
            ?.affilCampaignId ?? -1

    if (
        model !== null &&
        model !== undefined &&
        model?.ecommerceData !== undefined &&
        model?.ecommerceData !== null &&
        stateAffil == 0 &&
        cId > 0
    ) {
        setSended(currCount => currCount + 1)

        if (document.getElementById('affilEl') === null) {
            /*<script type="text/javascript">
                var ab_instance = "partner.cebia.com";
                var ab_kampan = 18;
                var ab_cena = cena;
                var ab_id_transakce = "cislo_kuponu";
                var ab_mena = "mena";
                var ab_kupon = "KOD_KUPONU";
            </script>*/
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.id = 'affilEl'
            script.text =
                "var ab_instance = 'partner.cebia.com'; var ab_kampan = " +
                cId +
                '; var ab_cena = ' +
                model?.ecommerceData.price.toFixed(2) +
                "; var ab_id_transakce = '" +
                model?.ecommerceData.cuponNumber +
                "'; var ab_mena = '" +
                model?.ecommerceData.currencyCode +
                "'; var ab_kupon ='" +
                model?.ecommerceData.discountCode +
                "';"
            document.head.appendChild(script)

            //<script src="https://partner.cebia.com/c3.js" type="text/javascript" async defer></script>
            const script2 = document.createElement('script')
            script2.type = 'text/javascript'
            script2.id = 'affilEl2'
            script2.async = true
            script2.defer = true
            script2.src = 'https://partner.cebia.com/c3.js'
            document.head.appendChild(script2)
        }
    }
}
