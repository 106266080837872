import { DropDownItem } from '../payedJsonResult'

export class VehicleInfoControlData {
    vehicleIdentificationSubCaption = ''
    vehicleIdentItems?: VehicleIdentificationItem[]
    onlyOtherEquipment = false
    isIvaEquipment = false
    equipmentItems?: EquipmentItem[]
    customizableEquipmentItems?: EquipmentItem[]
    vehicleColor?: VehicleColorItem[]
    vehicleSecurity?: VehicleSecurityItem[]
    couponActivation?: Date
    showVehicleSecurityOffer = false
    vehicleTechDesc?: TechDescriptionItem[]
    isIdentified = false
    identification?: VehicleIdentification
}

class VehicleIdentificationItem {
    name = ''
    value = ''
    unit = ''
}

class VehicleColorItem {
    description = ''
    colorName = ''
    colorCode = ''
    isManufactureData = false
}

export class EquipmentItem {
    equipmentFamily = ''
    equipment?: string[]
}

class VehicleSecurityItem {
    regDate?: Date
    securityDescription = ''
    securityCode = ''
    vehicleColor = ''
}

export class TechDescriptionItem {
    advanced = false
    name = ''
    value = ''
    language = ''
}

export class VehicleIdentification {
    make = ''
    model = ''
    druhId = 0
    typ = ''
    requestId = ''
    isGdpr = false

    carDruhs?: DropDownItem[]
    carMakes?: DropDownItem[]
    carModels?: DropDownItem[]
    carTypes?: DropDownItem[]

    icarisIndetification?: IcarisIdentification[]
    carPhotos?: VehicleIdentificationPhotoGroup[]
}

export class VehicleIdentificationPhotoGroup {
    groupName = ''
    photos?: VehicleIdentificationPhotoItem[]
}

export class VehicleIdentificationPhotoItem {
    id = 0
    url = ''
    urlOrig = ''
    caption = ''
    description = ''
}

export class IcarisIdentification {
    caption = ''
    description = ''
    car = ''
    photoGroups?: VehicleIdentificationPhotoGroup[]
}
