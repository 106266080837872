import { getLanguageCode } from '../busLog/http-common'
import { i18n } from '../translations/i18n'
import { AppModel } from './appModel'

class VinModel {
    Vin = ''
    CaptchaResponse = ''
    Lang = ''
    utm_campaign = ''
    utm_content = ''
    utm_source = ''
    utm_medium = ''
    ClientConnectionId = ''
    requestId = ''

    constructor(vin: string, token: string, connectionId: string) {
        this.Vin = vin
        this.CaptchaResponse = token
        this.Lang = getLanguageCode(i18n.language)
        this.ClientConnectionId = connectionId
    }
}

export { VinModel }
