import { ReactElement } from 'react'
import './index.styl'
import Select, { ActionMeta } from 'react-select'

export type ReactSelectWithIcon = {
    id?: string
    name?: string
    options: any
    value?: any
    label?: string
    isDisabled: boolean | undefined
    isSearchable: boolean | undefined
    isMulti: boolean | undefined
    textValueOption?: string
    textLabelOption?: string
    optionImageHeight?: number
    optionLineHeight?: number
    onChange?: (newValue: any, actionMeta?: ActionMeta<any>) => void
}

export const ReactSelectWithIcon = ({
    id,
    name,
    options,
    value,
    label,
    isDisabled,
    isSearchable,
    isMulti,
    textValueOption,
    textLabelOption,
    optionImageHeight,
    optionLineHeight,
    onChange,
}: ReactSelectWithIcon): ReactElement => {
    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: 60,
            minHeight: 60,
            marginTop: 0,
        }),
        menu: () => ({
            height: 100,
        }),
        singleValue: (provided: any, state: any) => {
            const opacity = state.isDisabled ? 0.5 : 1
            const transition = 'opacity 300ms'
            return { ...provided, opacity, transition, marginLeft: 12 }
        },
    }
    return (
        <>
            <label className="Label" htmlFor={id}>
                {label}
            </label>
            <Select
                menuPosition="fixed"
                closeMenuOnScroll={true}
                className="basic-single"
                classNamePrefix="select"
                id={id}
                getOptionLabel={(option: any) => option[textLabelOption ?? 'label']}
                getOptionValue={(option: any) => option[textValueOption ?? 'value']}
                name={name}
                value={value}
                options={options}
                isSearchable={isSearchable}
                onChange={(val: any) => {
                    if (onChange) onChange(val)
                }}
                isMulti={isMulti}
                isDisabled={isDisabled}
                styles={customStyles}
                formatOptionLabel={(option: any) => (
                    <div className="flex center">
                        <img
                            src={option.icon}
                            style={{ height: optionImageHeight ?? 20 }}
                            alt={option.name}
                        />
                        <span
                            style={{
                                marginLeft: 10,
                                display: 'flex',
                                lineHeight: '100%',
                                alignSelf: 'center',
                            }}
                        >
                            {option.name}
                        </span>
                    </div>
                )}
            />
        </>
    )
}
