import './index.styl'
import classNames, { Argument } from 'classnames'
import { ReactElement, ReactNode } from 'react'

type Props = {
    variant?: 'fat' | 'medium' | 'bare'
    variantPend?: 'appended' | 'prepended'
    className?: Argument
    children?: ReactNode
}

export const Section = ({
    variant,
    variantPend,
    className: _className,
    children,
}: Props): ReactElement => {
    const className = classNames(
        'Section',
        {
            [`Section--${variant}`]: variant,
            [`Section--${variantPend}`]: variantPend,
        },
        _className,
    )

    return <div className={className}>{children}</div>
}
