import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { updateValuation } from '../../../../../busLog/valuationBL'
import { Button } from '../../../../../components/Button'
import { Checkbox } from '../../../../../components/Checkbox'
import { FormItem } from '../../../../../components/FormItem'
import { Input } from '../../../../../components/Input'
import { LoadArea } from '../../../../../components/LoadArea'
import { Select } from '../../../../../components/Select'
import { ValuationControlData } from '../../../../../models/DetailPayedResults/Valuation/valuationControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'

export type Props = {
    sourceData: ValuationControlData
    tran: Translate
    setValuationData: React.Dispatch<React.SetStateAction<ValuationControlData>>
}

export const SectionValuationForm = ({ sourceData, tran, setValuationData }: Props) => {
    if (sourceData.doneValuation) return <></>

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const validationSchema = () => {
        return Yup.object({
            isGdpr: Yup.boolean().oneOf([true], tran.GetText('Musíte odsouhlasit zadané údaje.')),
            firstReg: Yup.date()
                .nullable(true)
                .required(tran.GetText('Prosím zadejte datum první registrace.')),
            tacho: Yup.number()
                .nullable(true)
                .min(1, tran.GetText('Nesprávna hodnota stav tachometru.'))
                .max(999999, tran.GetText('Nesprávna hodnota stav tachometru.'))
                .required(tran.GetText('Prosím zadejte položku stav tachometru.')),
        })
    }

    return (
        <>
            <div className="mb-20 md:mb-40">
                {errorMessage?.length > 0 && (
                    <p className="InputError">
                        <span className=" Icon Icon--alert AlertIcon"></span> {errorMessage}
                    </p>
                )}
                <Formik
                    initialValues={sourceData}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        setIsLoading(true)
                        updateValuation(values).then(x => {
                            if (x) {
                                if (x.message.length > 0) {
                                    setErrorMessage(x.message)
                                } else {
                                    setValuationData(x)
                                }
                            } else {
                                setErrorMessage(tran.GetText('Chyba uložení. Opakujte akci.'))
                            }
                            setIsLoading(false)
                        })
                    }}
                >
                    <Form className="ajax">
                        <div className="mb-20">
                            <div className="Grid gap-30 gmb-20 ">
                                <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                    <Input
                                        id="valTacho"
                                        name="tacho"
                                        type="number"
                                        placeholder={tran.GetText('Stav tachometru')}
                                        label={tran.GetText('Stav tachometru')}
                                        maxLength={10}
                                    />
                                </FormItem>

                                <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                    <Input
                                        id="valFirstReg"
                                        name="firstReg"
                                        type="date"
                                        placeholder={tran.GetText('Datum první registrace')}
                                        label={tran.GetText('Datum první registrace')}
                                    />
                                </FormItem>
                            </div>

                            {(sourceData.models?.length ?? 0) > 0 && (
                                <div className="Grid gap-30 gmb-20 ">
                                    <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                        <Select
                                            id="valModel"
                                            name="model"
                                            label={tran.GetText('Upřesnění modelu')}
                                        >
                                            {sourceData.models!.map((item, index) => (
                                                <option key={index} value={item.ivaId}>
                                                    {item.text}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </div>
                            )}
                        </div>
                        <div className="Grid Grid--alignMiddle gap-30 gmb-10">
                            <div className="FormItem smw:w-1/2 md:w-full lg:w-2/3 xl:w-2/3">
                                <Checkbox className="" variant="green" name="isGdpr" id="valIsGdpr">
                                    <label htmlFor="valIsGdpr">
                                        {tran.GetText(
                                            'Odsouhlasení zadaných údajů. Po uložení nepůjdou změnit.',
                                        )}
                                    </label>
                                </Checkbox>
                                <ErrorMessage
                                    name="isGdpr"
                                    component="div"
                                    className="InputError"
                                />
                            </div>
                            <div className="FormItem FormItem--submit smw:w-1/2 md:w-full lg:w-1/3 xl:w-1/3">
                                <Button className="Button--center" as="button" type="submit">
                                    {tran.GetText('Uložit')}
                                </Button>
                            </div>
                        </div>
                        <LoadArea
                            hideBackGround={true}
                            position="inherit"
                            loader={{ variant: 'bars', size: 'big' }}
                            align="center"
                            isLoading={isLoading}
                        />
                    </Form>
                </Formik>
            </div>
        </>
    )
}
