import './index.styl'
import { ReactElement } from 'react'
import { VisualIconVariant } from './VisualIconVariant'
import classNames, { Argument } from 'classnames'

export type VisualIconProps = {
    name: VisualIconVariant
    variant?: 'inline'
    size?: 'small' | 'desktopLarge'
    className?: Argument
    png?: boolean
}

export const VisualIcon = ({
    name,
    variant,
    size,
    className: _className,
    png,
}: VisualIconProps): ReactElement => {
    const className = classNames(
        'VisualIcon',
        {
            [`VisualIcon--${name}`]: name,
            [`VisualIcon--${variant}`]: variant,
            [`VisualIcon--${size}`]: size,
        },
        _className,
    )

    return (
        <span
            className={className}
            aria-hidden="true"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/visual-icon/${name}.${
                    png ? 'png' : 'svg'
                })`,
            }}
        ></span>
    )
}
