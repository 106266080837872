import './index.styl'
import { CSSProperties, Fragment, ReactElement, useContext, useEffect, useState } from 'react'
import { Image, ImageProps } from '../Image'
import 'react-image-lightbox/style.css'
import { LightboxGallery } from '../LightboxGallery'

type Props = {
    mainImage?: ImageProps
    images: ImageProps[]
    id: string
    isPrint?: boolean
    isAnonymized?: boolean
    setFullScreenProp?: boolean
    generateImageDesc?: (index: number) => ReactElement
}

type SliceCallback = (n: ImageProps[], i: number) => any

export const AtrGallery = ({
    mainImage,
    images,
    id,
    isPrint,
    isAnonymized,
    generateImageDesc,
    setFullScreenProp,
}: Props): ReactElement => {
    const [photosState, setPhotosState] = useState({
        index: 0,
        isOpen: false,
    })
    const [allImages, setAllImages] = useState(new Array<ImageProps>())
    useEffect(() => {
        let concatImages = new Array<ImageProps>()
        if (mainImage) {
            concatImages.push(mainImage)
        }
        concatImages = concatImages.concat(images)
        setAllImages(concatImages)
    }, [])

    if (mainImage) {
        return (
            <>
                {photosState.isOpen && (
                    <LightboxGallery
                        images={allImages}
                        imagesState={photosState}
                        setPhotosState={setPhotosState}
                        setFullScreenProp={setFullScreenProp}
                    ></LightboxGallery>
                )}

                <div className="AtrGallery Grid gap-30 gmb-20">
                    <div className="AtrGallery-main">
                        <div
                            className="AtrGallery-mainImage"
                            onClick={() =>
                                setPhotosState({
                                    index: 0,
                                    isOpen: true,
                                })
                            }
                        >
                            <Image {...mainImage} />
                        </div>
                    </div>
                    {(images?.length ?? 0) > 0 && (
                        <div className="w-fill">
                            <div className="Grid gap-20 gmb-20">
                                {images.map((image, index) => (
                                    <div
                                        onClick={() => {
                                            setPhotosState({
                                                index: index + 1,
                                                isOpen: true,
                                            })
                                        }}
                                        className="Grid-cell w-1/2 smw:w-1/3 xl:w-1/3 xxl:w-1/4"
                                        key={`main_${index}_${id}`}
                                    >
                                        <Image {...image} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </>
        )
    } else {
        return (
            <>
                {photosState.isOpen && (
                    <LightboxGallery
                        images={allImages}
                        imagesState={photosState}
                        setPhotosState={setPhotosState}
                        setFullScreenProp={setFullScreenProp}
                    ></LightboxGallery>
                )}
                <div className={isPrint ? 'AtrGallery' : 'AtrGallery Grid gap-20 xl:gap-15 gmb-15'}>
                    {!isPrint &&
                        images.map((image, index) => (
                            <div
                                className={
                                    isPrint
                                        ? 'Grid-cell w-1/5'
                                        : 'Grid-cell w-1/2 smw:w-1/3 lg:w-1/5 xxl:w-2/12'
                                }
                                key={`los_${index}_${id}`}
                                onClick={() => setPhotosState({ index: index, isOpen: true })}
                            >
                                <Image {...image} />
                                {/* {generateImageDesc && generateImageDesc(index)} */}
                            </div>
                        ))}
                    {isPrint &&
                        mapInSlices(images, 5, (slice, i) => (
                            <Fragment key={`${id}_index_${i}`}>
                                {slice.map((img, index) => (
                                    <div
                                        className={
                                            isPrint
                                                ? 'dsi'
                                                : 'Grid-cell w-1/2 smw:w-1/3 lg:w-1/5 xxl:w-2/12'
                                        }
                                        key={`los_${index}_${id}`}
                                        onClick={() =>
                                            setPhotosState({ index: index, isOpen: true })
                                        }
                                    >
                                        <Image {...img} isAnonymized={isAnonymized ?? false} />
                                        {/* {generateImageDesc && generateImageDesc(index)} */}
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                </div>
            </>
        )
    }

    function mapInSlices(array: ImageProps[], sliceSize: number, sliceFunc: SliceCallback) {
        const out = []
        for (let i = 0; i < array.length; i += sliceSize) {
            const slice = array.slice(i, i + sliceSize)
            out.push(sliceFunc(slice, i))
        }
        return out
    }
}
