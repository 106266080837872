import { Translate } from '../translations/CebiaTranslator'

export class TextUtils extends Translate {
    constructor() {
        super()
    }

    ZaznamyText(pocet: number | undefined): string {
        if (pocet === undefined) pocet = 0

        switch (pocet) {
            case 0:
                return `${pocet} ${this.GetText('záznamů')}`
            case 1:
                return `${pocet} ${this.GetText('záznam')}`
            case 2:
            case 3:
            case 4:
                return `${pocet} ${this.GetText('záznamy')}`
            default:
                return `${pocet} ${this.GetText('záznamů')}`
        }
    }

    FotografieText(pocet: number | undefined): string {
        if (pocet === undefined) pocet = 0

        switch (pocet) {
            case 0:
                return `${pocet} ${this.GetText('fotografií')}`
            case 1:
            case 2:
            case 3:
            case 4:
                return `${pocet} ${this.GetText('fotografie')}`
            default:
                return `${pocet} ${this.GetText('fotografií')}`
        }
    }

    UdajeText(pocet: number | undefined): string {
        if (pocet === undefined) pocet = 0

        switch (pocet) {
            case 1:
                return `${pocet} ${this.GetText('údaj')}`
            case 2:
            case 3:
            case 4:
                return `${pocet} ${this.GetText('údaje')}`
            default:
                return `${pocet} ${this.GetText('údajů')}`
        }
    }

    PolozkyText(pocet: number | undefined): string {
        if (pocet === undefined) pocet = 0

        switch (pocet) {
            case 1:
                return `${pocet} ${this.GetText('položka')}`
            case 2:
            case 3:
            case 4:
                return `${pocet} ${this.GetText('položky')}`
            default:
                return `${pocet} ${this.GetText('položek')}`
        }
    }
}
