import { useState, useEffect } from 'react'
import { LightboxGallery } from '../../../../../../components/LightboxGallery'
import { LoadArea } from '../../../../../../components/LoadArea'
import { ToggleItemGeneral } from '../../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../../../components/ToggleOpenerGeneralVoucher'
import {
    VehicleIdentificationPhotoGroup,
    VehicleIdentificationPhotoItem,
} from '../../../../../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { Translate } from '../../../../../../translations/CebiaTranslator'
import { getPhotoImageProps } from '../../../../../../utils/images'
import { SectionVehicleIdentificationPhotosGallery } from './vehicleIdentificationPhotosGallery'

type VehicleIdentPhotoProps = {
    sourceData?: VehicleIdentificationPhotoGroup[]
    tran: Translate
    isLoading: boolean
}

export const SectionVehicleIdentificationPhotos = ({
    sourceData,
    tran,
    isLoading,
}: VehicleIdentPhotoProps) => {
    if ((sourceData?.length ?? 0) <= 0) return <></>
    const [photosState, setPhotosState] = useState({
        index: 0,
        isOpen: false,
    })
    return (
        <>
            <div className="Grid gap-30 gmb-20 ">
                {sourceData!.map((item, index) => (
                    <SectionVehicleIdentificationPhotosGallery
                        key={index}
                        source={item}
                        index={index}
                    ></SectionVehicleIdentificationPhotosGallery>
                ))}
            </div>
            <LoadArea
                hideBackGround={true}
                position="inherit"
                loader={{ variant: 'bars', size: 'big' }}
                align="center"
                isLoading={isLoading}
            />
        </>
    )
}
