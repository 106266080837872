import { useFormikContext } from 'formik'
import { Fragment, ReactElement, useEffect, useState } from 'react'
import { useEffectOnce, useUpdateEffect } from 'react-use'
import PercentageSVG from '../../../../assets/img/percentage.svg'
import { Container } from '../../../../components/Container'
import { Logo } from '../../../../components/Logo'
import { Modal, useModal } from '../../../../components/Modal'
import { LanguageType } from '../../../../enums/LanguageType'
import { PaymentReqModel } from '../../../../models/paymentReqModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import './index.styl'
import { reloadPayment } from '../../../../busLog/paymentBL'
import { LoadType } from '../../../../enums/LoadTypeEnum'

type Props = {
    payment: PaymentReqModel
    updatePaymentState: (newPaymentState: PaymentReqModel) => void
}

export const getPaymentPrice = (
    payment: PaymentReqModel,
    tran: Translate,
    withSuffix: boolean,
    isTotalPrice: boolean,
) => {
    let result = ''
    const priceBase = payment.price
    if (
        payment.vatDeduction &&
        payment.validatedByVies === 1 &&
        payment.invoiceDic.length > 0 &&
        payment.invoiceCompanyName.length > 0
    ) {
        if (!isTotalPrice && payment.isSale && payment.standardPriceWithoutVat > 0) {
            result += payment.standardPriceWithoutVat
                .toFixed(payment?.sale?.decimalPlaces ?? 0)
                .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
        } else if (!isTotalPrice && (payment.standardPriceWithoutVat ?? 0) > 0) {
            result += payment.standardPriceWithoutVat
                .toFixed(payment?.sale?.decimalPlaces ?? 0)
                .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
        } else if (!isTotalPrice && (payment.price ?? 0) > 0) {
            result += payment.price
                .toFixed(payment?.sale?.decimalPlaces ?? 0)
                .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
        } else if ((payment.priceWithoutVat ?? 0) > 0) {
            result += payment.priceWithoutVat
                .toFixed(payment?.sale?.decimalPlaces ?? 0)
                .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
        } else if (!payment.isSale && payment.standardPriceWithoutVat > 0) {
            result += payment.standardPriceWithoutVat
                .toFixed(payment?.sale?.decimalPlaces ?? 0)
                .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
        } else {
            result += priceBase
                .toFixed(payment?.sale?.decimalPlaces ?? 0)
                .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
        }
        result += ' '
        result += payment.currency ?? ''
        if (withSuffix) {
            result += ' '
            result += tran.GetText('bez DPH')
        }
    } else {
        if (!isTotalPrice) {
            if ((payment.standardPrice ?? 0) > 0) {
                result += payment.standardPrice
                    .toFixed(payment?.sale?.decimalPlaces ?? 0)
                    .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
            } else if (payment.isSale && payment.standardPrice > 0) {
                result += payment.standardPrice
                    .toFixed(payment?.sale?.decimalPlaces ?? 0)
                    .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
            }
        } else {
            if (
                !payment.isSale &&
                !(payment?.sale?.b_discount ?? false) &&
                payment.standardPrice !== priceBase
            ) {
                result += priceBase
                    .toFixed(payment?.sale?.decimalPlaces ?? 0)
                    .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
            } else if (
                !payment.isSale &&
                !(payment?.sale?.b_discount ?? false) &&
                payment.standardPrice > 0
            ) {
                result += payment.standardPrice
                    .toFixed(payment?.sale?.decimalPlaces ?? 0)
                    .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
            } else if ((payment.price ?? 0) > 0) {
                result += payment.price
                    .toFixed(payment?.sale?.decimalPlaces ?? 0)
                    .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
            } else {
                result += priceBase
                    .toFixed(payment?.sale?.decimalPlaces ?? 0)
                    .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
            }
        }

        result += ' '
        result += payment.currency ?? ''
        if (withSuffix) {
            result += ' '
            result += tran.GetText('s DPH')
        }
    }
    return result
}

export const getPaymentPriceSale = (payment: PaymentReqModel) => {
    let result = '-'
    if (payment.validatedByVies && payment.vatDeduction) {
        result += (payment.standardPriceWithoutVat - payment.price)
            .toFixed(payment?.sale?.decimalPlaces ?? 0)
            .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
        result += ' ' + payment.currency
    } else {
        result += (payment.standardPrice - payment.price)
            .toFixed(payment?.sale?.decimalPlaces ?? 0)
            .replace('.', payment.countryId == LanguageType.SK ? ',' : '.')
        result += ' ' + payment.currency
    }
    return result
}

export const SectionHeader = ({ payment, updatePaymentState }: Props): ReactElement => {
    const tran = new Translate()
    const { open } = useModal('CebiaReportModal')
    // useEffect(() => {
    //     reloadPayment(payment.viewId!, LoadType.WithImageData).then(payReq => {
    //         if (payReq) {
    //             updatePaymentState(payReq)
    //         }
    //     })
    // }, [])
    return (
        <>
            <Container className="section-header">
                <section className="SectionHeader--default">
                    <div className="Grid text-left">
                        <div className="md:w-6/12 border-bottom">
                            <div className="Grid">
                                <div className="md:w-6/12">
                                    <div className="SectionHeader-banner">
                                        <div className="SectionHeader-logoWrap">
                                            <Logo
                                                className="Logo SectionHeader-logo"
                                                src={
                                                    payment?.imageData
                                                        ? `data:image/png;base64, ${payment?.imageData}`
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="SectionHeader-box md:w-6/12 mb-10">
                                    <span className="SectionHeader-title">
                                        {tran.GetText('Prověřované vozidlo')}
                                    </span>
                                    <strong className="SectionHeader-vin mb-10">
                                        <span className="AtrOverviewCar-label pb-10">
                                            {tran.GetText('VIN')}
                                        </span>
                                        <span className="fw400">{payment?.vin ?? ''}</span>
                                    </strong>
                                    <span className="SectionHeader-make">
                                        <span className="AtrOverviewCar-label pb-10">
                                            {tran.GetText('Vozidlo')}
                                        </span>
                                        {payment?.make?.split('|').map((item, index) => (
                                            <Fragment key={index}>
                                                {item && item.length > 0 ? (
                                                    <>
                                                        {item} <br></br>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </Fragment>
                                        ))}
                                        {payment?.model?.split('|').map((item, index) => (
                                            <Fragment key={index}>
                                                {item && item.length > 0 ? (
                                                    <>
                                                        {item} <br></br>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </Fragment>
                                        ))}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="border-left md:w-6/12">
                            <div className="Grid">
                                <div className="SectionHeader-box--right pl-75 md:w-12/12">
                                    <span className="SectionHeader-title">
                                        {tran.GetText('Objednávka')}
                                    </span>
                                    <div className="blueBlock md:w-11/12">
                                        <ul className="List List--circleCheck">
                                            <li>
                                                <div>
                                                    {tran.GetText('Prověření historie vozidla')}
                                                </div>
                                                <div>
                                                    {getPaymentPrice(payment, tran, true, false)}
                                                </div>
                                            </li>
                                            {(payment?.productId ?? 0) > 3 &&
                                                payment.countryId != LanguageType.RO &&
                                                payment.countryId != LanguageType.SK &&
                                                payment.countryId != LanguageType.AL &&
                                                payment.countryId != LanguageType.UK &&
                                                payment.countryId != LanguageType.US &&
                                                payment.countryId != LanguageType.DE &&
                                                payment.countryId != LanguageType.IT &&
                                                payment.countryId != LanguageType.FR && (
                                                    <li>
                                                        <div>
                                                            <a onClick={open} className="crLink">
                                                                {tran.GetText('Cebia REPORT ')}
                                                            </a>
                                                        </div>
                                                        <div>{tran.GetText('zdarma')}</div>
                                                    </li>
                                                )}
                                        </ul>
                                    </div>
                                    {payment?.price > 0 &&
                                        (((payment?.productId ?? 0) > 3 &&
                                            payment.countryId != LanguageType.RO &&
                                            payment.countryId != LanguageType.AL &&
                                            payment.countryId != LanguageType.SK) ||
                                            payment.countryId == LanguageType.RO ||
                                            payment.countryId == LanguageType.SK) && (
                                            <div className="md:w-11/12 d-flex spaceBetween">
                                                <div className="d-flex percentage">
                                                    <img src={PercentageSVG} />
                                                    {tran.GetText('50% SLEVA na další prověření')}
                                                </div>
                                                <div>{tran.GetText('dárek')}</div>
                                            </div>
                                        )}
                                    {/* {payment.productId == 3 && !payment.isSale && (
                                        <div className="md:w-11/12 pt-10 d-flex spaceBetween">
                                            <div className="d-flex percentage">
                                                <img src={PercentageSVG} />
                                                <span className="capitalize">
                                                    {tran.GetText('SLEVA')}
                                                </span>
                                            </div>
                                            <div>
                                                -
                                                {(payment.standardPrice - payment.price).toFixed(2)}{' '}
                                                {payment.currency}
                                            </div>
                                        </div>
                                    )} */}
                                    {(payment.isSale || payment.discnoutCaption) && (
                                        <div className="md:w-11/12 pt-10 d-flex percentage spaceBetween">
                                            <div className="d-flex percentage">
                                                <img src={PercentageSVG} />
                                                {payment.discnoutCaption ? (
                                                    payment.discnoutCaption
                                                ) : (
                                                    <span className="capitalize">
                                                        {tran.GetText('SLEVA')}
                                                    </span>
                                                )}
                                            </div>
                                            <div>{getPaymentPriceSale(payment)}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
            <Modal id="CebiaReportModal">
                <Container className="PaymentCouponSection">
                    <div className="SectionPageHeader-highlights">
                        <p>
                            {tran.GetHtml(
                                'Cebia REPORT můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále - <a href="https://www.sauto.cz" >www.sauto.cz</a>',
                            )}
                        </p>
                        <p>
                            {tran.GetHtml(
                                'Cebia REPORT poskytuje hodnocení kontroly stavu tachometru, kontroly v databázi odcizených vozidel, závazků u leasingových společností, kontroly správnosti VIN a roku výroby vozidla. Celkové hodnocení je vyjádřeno počtem hvězdiček (0–5) a slovním hodnocením.',
                            )}
                        </p>
                    </div>
                </Container>
            </Modal>
        </>
    )
}
