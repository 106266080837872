import { ReactElement } from 'react'
import { AtrNavOpener } from '../../../../../components/AtrNavOpener'
import { ElementInViewportObserver } from '../../../../../components/ElementInViewportObserver'
import { ToggleItemAtr } from '../../../../../components/ToggleItemAtr'
import { ToggleOpenerAtr } from '../../../../../components/ToggleOpenerAtr'
import {
    DetailPayedSectionItemDefinitionModel,
    getStatus,
    getSubCaption,
    getVariant,
    isEnabled,
    isProcessed,
} from '../../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { Translate } from '../../../../../translations/CebiaTranslator'
import { PayedStateModel } from '../../../../../models/payedResponseModel'
import { ReferenceType } from '../../../../../enums/ReferenceType'
import { TextUtils } from '../../../../../utils/textUtils'

type Props = {
    sectionItems: DetailPayedSectionItemDefinitionModel[]
    mainCaption: string
    model?: PayedStateModel
    smartCodeCaption: string
    sourceData?: PayedJsonResult[]
    tran: Translate
    referenceType?: ReferenceType
}

export const SectionContent = ({
    sectionItems,
    mainCaption,
    sourceData,
    tran,
    referenceType,
}: Props): ReactElement => {
    const textUtils = new TextUtils()
    if ((sectionItems?.length ?? 0) <= 0) return <></>

    return (
        <div className="SectionMain-category">
            <div className="SectionMain-categoryHeader color-darkBlue ">
                <AtrNavOpener />
                <h2 className="Beta mb-10 md:mb-20">{mainCaption}</h2>
            </div>
            {sectionItems.map(sectionItem => {
                sectionItem.controlData = sourceData?.find(
                    x => x.sectionId == sectionItem.sectionId,
                )

                return (
                    <ElementInViewportObserver key={`sectionContent_${sectionItem.sectionId}`}>
                        <div
                            className={`SectionMain-atrItem${
                                !isEnabled(sectionItem) ? '-disabled' : ''
                            }`}
                        >
                            <ToggleOpenerAtr
                                isLoading={!isProcessed(sectionItem)}
                                visualIcon={sectionItem.icon}
                                title={sectionItem.caption}
                                href={`#sekce${sectionItem.sectionId}`}
                                atrInfoStatus={{
                                    variant: getVariant(sectionItem),
                                    text: getSubCaption(sectionItem),
                                }}
                                status={getStatus(sectionItem, tran, textUtils)}
                                statusIcon={sectionItem.statusIcon}
                                isOpened={sectionItem.controlData?.openedSection ?? false}
                            />
                            <ToggleItemAtr
                                id={`sekce${sectionItem.sectionId}`}
                                active={sectionItem.controlData?.openedSection ?? false}
                            >
                                <sectionItem.toggledControl
                                    referenceType={referenceType}
                                    sourceData={sectionItem.controlData}
                                />{' '}
                            </ToggleItemAtr>
                        </div>
                    </ElementInViewportObserver>
                )
            })}
        </div>
    )
}
