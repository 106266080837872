import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import classNames, { Argument } from 'classnames'

type Props = {
    className?: Argument
}

export const SectionSeparator = ({ className: _className }: Props): ReactElement => {
    const className = classNames(`SectionSeparator`, _className)

    return (
        <Section className={className} variant="bare">
            <Container>
                <div className="Separator"></div>
            </Container>
        </Section>
    )
}
