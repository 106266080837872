import moment from 'moment'
import { ReactElement } from 'react'
import { Table } from '../../../../components/Table'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../components/ToggleOpenerGeneralVoucher'
import { i18n, LanguageEnum } from '../../../../translations/i18n'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'

export const SectionExekuce = ({ sourceData, isPrint }: DetailPayedProps): ReactElement => {
    if (i18n.language !== LanguageEnum.cz) return <></>
    return (
        <>
            <p className="text-justify">
                Při nákupu ojetiny je třeba prověřit nejen vozidlo, ale i jeho majitele. Pokud byste
                koupili vozidlo od osoby v exekuci, s velkou pravděpodobností by vám bylo{' '}
                <b>zabaveno exekutorem</b>. V České republice se bohužel jedná o velmi častý podvod.
            </p>
            <p className="text-justify">
                Kontrolou exekucí předchozích majitelů v{' '}
                {isPrint && (
                    <>
                        <b>Centrální evidenci exekucí</b>
                    </>
                )}
                {!isPrint && (
                    <a
                        href="https://exekuce.cebia.cz/pub/?utm_source=cz.cebia.com&utm_medium=tlacitko&utm_campaign=atr-vypis"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Centrální evidenci exekucí
                    </a>
                )}{' '}
                lze riziko výrazně snížit. Prověřit lze jakoukoliv fyzickou i právnickou osobu.
            </p>
            {isPrint && (
                <p>
                    Výpis exekucí získáte snadno online na webu{' '}
                    <a
                        href="https://exekuce.cebia.cz/pub/?utm_source=cz.cebia.com&utm_medium=tlacitko&utm_campaign=atr-vypis"
                        target="_blank"
                        rel="noreferrer"
                    >
                        exekuce.cebia.cz
                    </a>
                    .
                </p>
            )}
            {!isPrint && (
                <>
                    <Table className="text-left mb-30">
                        <thead></thead>
                        <tbody>
                            <tr className="color-darkBlue">
                                <td className="text-middle">
                                    <strong>Kontrola exekucí fyzické osoby</strong>
                                </td>
                                <td className="text-middle text-left font-bold text-noWrap">
                                    69 Kč
                                </td>
                            </tr>
                            <tr className="color-darkBlue">
                                <td className="text-middle">
                                    <strong>Kontrola exekucí právnické osoby</strong>
                                </td>
                                <td className="text-middle text-left font-bold text-noWrap">
                                    69 Kč
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="ButtonList mb-10">
                        <a
                            href="https://exekuce.cebia.cz/pub/?utm_source=cz.cebia.com&utm_medium=tlacitko&utm_campaign=atr-vypis"
                            target="_blank"
                            className="Button Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">Zkontrolovat exekuce</span>
                        </a>
                    </div>
                </>
            )}
        </>
    )
}
