import classNames, { Argument } from 'classnames'
import { ReactElement } from 'react'
import './index.styl'

type Props = {
    variant?: string
    hasVisual?: boolean
    href: string
    title: string
    buttonText: string
    className?: Argument
}

export const Banner = ({
    variant,
    href,
    title,
    buttonText,
    className: _className,
    hasVisual = false,
}: Props): ReactElement => {
    const className = classNames(
        'Banner',
        {
            [`Banner--${variant}`]: variant,
        },
        _className,
    )

    return (
        <a href={href} className={className}>
            <article className="Banner-body">
                {hasVisual && <div className="Banner-visual"></div>}
                <div className="Banner-content">
                    <h3 className="Banner-title">{title}</h3>
                    <p className="Banner-more" aria-hidden="true">
                        {buttonText}
                    </p>
                </div>
            </article>
        </a>
    )
}
