import moment from 'moment'
import { VinSectionProps } from '..'
import { Table } from '../../../../../components/Table'

export const SectionVinTest = ({ item, tran }: VinSectionProps) => {
    if ((item?.advertMatchType ?? 0) !== 8) return <></>

    return (
        <>
            <div className="pb-25">
                <p className="text-justify">
                    {tran.GetText(
                        'Na vozidle byla provedena fyzická kontrola jeho identifikátorů (ražba VIN, výrobní štítky apod.). Informace byly převzaty z evidence služby VINTEST prováděné společností Cebia nebo jejími smluvními partnery.',
                    )}
                </p>
            </div>
            <div className="mb-30">
                <Table>
                    <thead>
                        {item.advertDate ? (
                            <tr>
                                <th className="text-left">
                                    {tran.GetText('Datum provedení služby:')}
                                </th>
                                <th className="text-left">
                                    {moment(item.advertDate).format('MM / YYYY')}
                                </th>
                            </tr>
                        ) : (
                            <tr>
                                <th colSpan={2}></th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {item.carColor?.length > 0 && (
                            <tr>
                                <td>{tran.GetText('Barva vozidla:')}</td>
                                <td>{item.carColor}</td>
                            </tr>
                        )}
                        {item.advertKm > 0 && (
                            <tr>
                                <td>{tran.GetText('Stav tachometru:')}</td>
                                <td>
                                    {tran.GetNumber(item.advertKm)} {tran.GetText('km')}
                                </td>
                            </tr>
                        )}
                        {(item.carStateText?.length ?? 0) > 0 && (
                            <tr>
                                <td>{tran.GetText('Stav identifikátorů:')}</td>
                                <td dangerouslySetInnerHTML={{ __html: item.carStateText }}></td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}
