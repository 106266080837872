import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionTacho } from '../../../../detailPaid/atr-items/SectionTacho'
import './body.styl'

let PrintAllowIncrement = true

export const Tacho = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    //if (!sourceData?.tachoControlResult) return <></>

    const tachoData = new PayedJsonResult()
    tachoData.tachoControlResult = sourceData?.tachoControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pageBreakAvoid">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola najetých kilometrů')}
            </h2>

            <Section className="TachoSection">
                <SectionTacho sourceData={tachoData} isPrint={true} />
            </Section>
        </div>
    )
}
