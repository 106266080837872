export class UTMModel {
    utm_campaign = ''
    utm_content = ''
    utm_source = ''
    utm_medium = ''
    constructor(
        utm_campaign?: string,
        utm_content?: string,
        utm_source?: string,
        utm_medium?: string,
    ) {
        this.utm_campaign = utm_campaign ?? ''
        this.utm_content = utm_content ?? ''
        this.utm_medium = utm_medium ?? ''
        this.utm_source = utm_source ?? ''
    }
}
