import './index.styl'
import { ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'
import { Field, useField } from 'formik'

type Props = {
    variant?: 'dark' | 'green'
    id: string
    name: string
    placeholder?: string
    required?: boolean
    className?: Argument
    children?: ReactNode
}

export const Checkbox = ({
    variant,
    className: _className,
    required = false,
    children,
    ...props
}: Props): ReactElement => {
    const [, meta] = useField(props.name)
    const className = classNames(
        'Checkbox',
        {
            [`Checkbox--${variant}`]: variant,
        },
        _className,
    )

    return (
        <label className={`${className} ${meta.error ? 'is-invalid' : ''}`}>
            <Field type="checkbox" required={required} {...props} className="Checkbox-input" />
            <span className="Checkbox-placeholder" />
            <span className="Checkbox-label">{children}</span>
        </label>
    )
}
