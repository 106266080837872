import './index.styl'
import { ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'

type Props = {
    className?: Argument
    children?: ReactNode
}

export const FormItem = ({ children, className: _className }: Props): ReactElement => {
    const className = classNames('FormItem', _className)
    return <div className={className}>{children}</div>
}
