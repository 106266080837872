import { ReactElement, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ArticleDetail } from '../../components/ArticleDetail'
import { Container } from '../../components/Container'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { LoadArea } from '../../components/LoadArea'
import { PageShape } from '../../components/PageShape'
import { Section } from '../../components/Section'
import { RssChannel, RssItem } from '../../models/RssChannel'
import { i18n } from '../../translations/i18n'
import http from '../../busLog/http-common'
import { TitleMetaSection } from '../../components/TitleMeta'
import { Translate } from '../../translations/CebiaTranslator'
import { ReferenceType } from '../../enums/ReferenceType'

export const PageArticleDetail = (): ReactElement => {
    const tran = new Translate()
    const [language] = useState(i18n.language)
    const [rssItem, setRssItem] = useState<RssItem>()
    const { title } = useParams()
    const navigate = useNavigate()
    const fetchRssFeed = () => {
        const res = http.get<RssChannel>(
            `/Blog/GetRssFeed?language=${language === 'cz' ? '' : language}`,
        )
        return res
    }

    const { data, isLoading, isFetching } = useQuery(`getRssFeed_${language}`, fetchRssFeed, {
        cacheTime: 300000,
        refetchOnWindowFocus: false,
    })
    useEffect(() => {
        if (data?.data) {
            const item = data?.data.rssItems.find(i => {
                return i.link.toLowerCase() === title?.toString().toLowerCase()
            })
            if (item) {
                setRssItem(item)
            } else {
                navigate('/blog')
            }
        }
    }, [data])

    return (
        <TitleMetaSection
            meta={rssItem ? rssItem.metaDescription : ''}
            title={rssItem ? rssItem.metaTitle : ''}
        >
            <div className="PageArticleDetail">
                <PageShape sectionShape={{ variant: 'radialLeftTop' }} />

                <Header showLanguage={false} referenceType={ReferenceType.Default} />

                {isLoading || isFetching ? (
                    <LoadArea
                        isLoading={isLoading}
                        hideBackGround={true}
                        variant="fullscreen"
                        loader={{ variant: 'bars', size: 'big' }}
                        text={tran.GetText('Načítáme data...')}
                    ></LoadArea>
                ) : (
                    <>
                        <Section variantPend="appended">
                            <Container>
                                <ArticleDetail
                                    title={rssItem?.title ?? ''}
                                    perex={rssItem?.description ?? ''}
                                    content={rssItem?.content ?? ''}
                                    date={new Date(rssItem?.pubDate ?? '')}
                                    image={{
                                        src: rssItem?.rssImageContent.url ?? '',
                                        alt: 'Picture',
                                        srcSet: rssItem?.rssImageContent.url,
                                    }}
                                    attachments={rssItem?.attachments ?? []}
                                />
                            </Container>
                        </Section>
                    </>
                )}
                {/* <SectionArticles /> */}

                <Footer />
            </div>
        </TitleMetaSection>
    )
}
