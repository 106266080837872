import { ReactElement } from 'react'
import Moment from 'react-moment'
import classNames from 'classnames'
import {
    TimeLineEmise,
    TimeLineItem,
} from '../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { Translate } from '../../translations/CebiaTranslator'
import { getMDCRProtocol } from '../../busLog/payedRegBL'
import { useAppContext } from '../../contexts/AppContext'
import { SectionSeparator } from '../../pages/homepage/sections/SectionSeparator'
import { Container } from '../Container'
import { Info } from '../Info'
import { useModal, Modal } from '../Modal'
import { getStickyPositionTop, setStickyPositionTop } from '../AtrTimeLineTableRowItem'

type Props = {
    row: TimeLineItem
    isPrint: boolean
}

export const TimeLineEmiseTable = ({ row, isPrint }: Props): ReactElement => {
    const tran = new Translate()
    const item = row.timeLineObject as TimeLineEmise
    const tableClasses = classNames('Table', { ['noBackground line2rem']: isPrint })
    const { refetch } = getMDCRProtocol(item.protocolNumber)
    const { open: openfileNotFound } = useModal('fileNotFoundModal')
    const { open: openMDCRNotRespond } = useModal('mDCRNotRespondModal')
    const appContext = useAppContext()
    return (
        <>
            <div className={tableClasses}>
                <table>
                    <tbody>
                        <tr>
                            <td>{tran.GetText('Datum kontroly')}:</td>
                            <td>
                                <Moment format="DD. MM. YYYY">{item.dateOfControl}</Moment>
                            </td>
                        </tr>
                        <tr>
                            <td>{tran.GetText('Číslo protokolu')}:</td>
                            <td>
                                <span>{item.protocolNumber}</span>
                            </td>
                        </tr>
                        {!isPrint && item.showLinkToDownload && (
                            <tr>
                                <td> {tran.GetText('Výsledek kontroly')}:</td>
                                <td>
                                    <a
                                        className="fileLink"
                                        //href="#emiseFile"
                                        id="emiseFile"
                                        onClick={() => {
                                            appContext.setLoading(true)
                                            refetch().then(retval => {
                                                const pdfData = retval.data?.data
                                                const decimalNumber = getStickyPositionTop()
                                                try {
                                                    if (pdfData.fileData.length < 10) {
                                                        openfileNotFound()
                                                    } else {
                                                        const linkSource = `data:application/octet-stream;base64,${pdfData.fileData}`
                                                        const downloadLink =
                                                            document.createElement('a')
                                                        const fileName = pdfData.fileName
                                                        downloadLink.href = linkSource
                                                        downloadLink.download = fileName
                                                        downloadLink.click()
                                                    }
                                                } catch (error) {
                                                    openMDCRNotRespond()
                                                } finally {
                                                    appContext.setLoading(false)
                                                    setTimeout(() => {
                                                        setStickyPositionTop(decimalNumber)
                                                    }, 50)
                                                }
                                            })
                                        }}
                                    >
                                        {tran.GetText('Zobrazit protokol')}
                                    </a>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Modal id="fileNotFoundModal">
                    <Container>
                        <div className="Grid">
                            <div className="Grid-cell md:w-12/12">
                                <div className="flex modalVies mb-20">
                                    <Info variant="alert" withIcon={true}>
                                        <h2 className="color-red">
                                            {' '}
                                            {tran.GetText('Protokol není dostupný')}
                                        </h2>
                                    </Info>
                                </div>
                                <SectionSeparator className="mb-20" />
                                <p>
                                    {tran.GetHtml(
                                        'K této prohlídce není detail protokolu dostupný.',
                                    )}
                                </p>
                            </div>
                        </div>
                    </Container>
                </Modal>
                <Modal id="mDCRNotRespondModal">
                    <Container>
                        <div className="Grid">
                            <div className="Grid-cell md:w-12/12">
                                <div className="flex modalVies mb-20">
                                    <Info variant="alert" withIcon={true}>
                                        <h2 className="color-red">
                                            {' '}
                                            {tran.GetText('Databáze neodpovídá')}
                                        </h2>
                                    </Info>
                                </div>
                                <SectionSeparator className="mb-20" />
                                <p>
                                    {tran.GetHtml(
                                        'Databáze Ministerstva dopravy je momentálně nedostupná, zkuste to prosím později.',
                                    )}
                                </p>
                            </div>
                        </div>
                    </Container>
                </Modal>
            </div>
        </>
    )
}
