import './body.styl'
import { ReactElement } from 'react'
import { PrintDataProps } from '../..'
import { Table } from '../../../../../components/Table'
import moment from 'moment'

export const CouponInfo = ({ sourceData, tran }: PrintDataProps): ReactElement => {
    if (!sourceData) return <></>

    return (
        <>
            <div>
                <div className="Table mb-10">
                    <Table>
                        {/* <thead>
                            <tr>
                                <th colSpan={2}>{tran.GetText('Informace o kuponu AUTOTRACER')}</th>
                            </tr>
                        </thead> */}
                        <tbody>
                            {!sourceData.isCouponTest && (
                                <tr>
                                    <td>{tran.GetText('Číslo kuponu pro opakovaný vstup:')}</td>
                                    <td>{sourceData.couponNumber}</td>
                                </tr>
                            )}
                            <tr>
                                <td>{tran.GetHtml('Datum aktivace kuponu:')}</td>
                                <td>
                                    {moment(sourceData.couponActivation).format(
                                        'DD. MM. YYYY HH:mm:ss',
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{tran.GetText('Platnost do:')}</td>
                                <td>
                                    {moment(sourceData.couponValidTo).format(
                                        'DD. MM. YYYY HH:mm:ss',
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
            <p className="couponInfoDescText">
                {tran.GetText(
                    'Tento výpis byl vytvořen dotazem do systému AUTOTRACER, který provozuje Cebia, spol. s r.o. dle platných Všeobecných podmínek pro používání systému AUTOTRACER. Poskytnuté informace je uživatel oprávněn v celém rozsahu využívat pouze pro vlastní potřebu. Jejich další šíření či upravování bez souhlasu provozovatele je zakázáno.',
                )}
            </p>
        </>
    )
}
