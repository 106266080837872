import './index.styl'
import { ReactElement } from 'react'
import { Title } from '../Title'
import { Translate } from '../../translations/CebiaTranslator'
import { PayedStateModel } from '../../models/payedResponseModel'
import { Button } from '../Button'
import { ButtonList } from '../ButtonList'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

type Props = {
    model?: PayedStateModel
    closeModal: () => void
}
export const SmartCodeModal = ({ model, closeModal }: Props): ReactElement => {
    const tran = new Translate()
    const [searchParams] = useSearchParams()
    const { id } = useParams()
    const q = searchParams.get('q')
    return (
        <div className="Grid">
            <div className="Grid-cell md:w-12/12">
                <Title as="header" variantAlign="left">
                    <h2 className="pb-20">
                        {tran.GetText('Co znamená základní prověření vozidla?')}
                    </h2>
                    <p>{tran.GetText('Základní prověření obsahuje tyto kontroly:')}</p>
                    <div className="mb-20">
                        <ul>
                            <li>
                                <span className="Icon Icon--check mr-10 color-green"></span>
                                {tran.GetText('Kontrola najetých kilometrů')}
                            </li>
                            <li>
                                <span className="Icon Icon--check mr-10 color-green"></span>
                                {tran.GetText('Kontrola odcizení')}
                            </li>
                            <li>
                                <span className="Icon Icon--check mr-10 color-green"></span>
                                {tran.GetText('Kontrola financování')}
                            </li>
                            <li>
                                <span className="Icon Icon--check mr-10 color-green"></span>
                                {tran.GetText('Kontrola taxi')}
                            </li>
                        </ul>
                    </div>
                    <p>
                        {tran.GetHtml(
                            'Pro prověření celé historie vozidla doporučujeme zakoupit Kompletní prověření vozidla, které navíc může obsahovat <b>kontrolu poškození, historii inzerce, fotografie vozidla, servisní historii</b> a mnoho dalšího.',
                        )}
                    </p>
                    <div className="lg:w-auto">
                        <ButtonList visible={true}>
                            <Button
                                as="button"
                                variantColor="blue"
                                variantSize="small"
                                onClick={() => {
                                    closeModal()
                                }}
                            >
                                {tran.GetText('Zavřít')}
                            </Button>
                            <Button
                                as="a"
                                variantColor="green"
                                variantSize="small"
                                href={`/?vin=${
                                    model?.carVin
                                }&utm_source=Smart_kod&utm_medium=tlacitko&utm_campaign=${
                                    q === null ? 'Smart_kod' : 'Smart_kodQR'
                                }&requestId=${id}`}
                                rel="noreferrer noopener"
                            >
                                {tran.GetText('Získat kompletní prověření')}
                            </Button>
                        </ButtonList>
                    </div>
                </Title>
            </div>
        </div>
    )
}
