import './index.styl'
import { ReactElement } from 'react'
import { PagePayedReg } from '../../pages/payedReg'
import { Translate } from '../../translations/CebiaTranslator'
import { i18n } from '../../translations/i18n'
import { LanguageDefinition } from '../../pages/about/sections/SectionAboutCrossroad'

type Explanatory = {
    showExplanatory: boolean
}

export type PayedRegInfoExplanatory = PagePayedReg & Explanatory

export const PayedRegInfoExplanatory = ({
    type,
    showExplanatory,
}: PayedRegInfoExplanatory): ReactElement => {
    const viewType = type ?? 'ov'
    const tran = new Translate()
    const urlVP = LanguageDefinition[i18n.language].urlVp
    if (viewType === 'ov') {
        return (
            <>
                <div className="PayedRegInfoExplanatory">
                    {showExplanatory && (
                        <>
                            <h2 className="mb-10">{tran.GetText('Vysvětlivky:')}</h2>
                            <div className="Grid font-bold">
                                <div className="Grid-cell smw:w-full md:w-2/12 lg:w-2/12">
                                    <span className="gray">
                                        {tran.GetText('Není k dispozici:')}
                                    </span>
                                </div>
                                <div className="Grid-cell smw:w-full md:w-4/12 lg:w-4/12">
                                    <span className="fwNormal">
                                        {tran.GetText(
                                            'K vozidlu jsme v dané kategorii nedohledali žádné informace',
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="Grid font-bold">
                                <div className="Grid-cell smw:w-full md:w-2/12 lg:w-2/12 avalaible">
                                    <span>{tran.GetText('K dispozici:')}</span>
                                </div>
                                <div className="Grid-cell smw:w-full md:w-4/12 lg:w-4/12 avalaible">
                                    <span>{tran.GetText('XY záznamů:')}</span>
                                </div>
                            </div>
                            <div className="Grid font-bold">
                                <div className="Grid-cell smw:w-full md:w-full lg:w-full">
                                    <span className="fwNormal">
                                        {tran.GetText(
                                            'K vozidlu jsme v dané kategorii dohledali XY záznamů, které se dozvíte v placené části',
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="separator mt-30"></div>
                        </>
                    )}

                    <h2 className="mb-10 mt-20 font-bold">{tran.GetText('Všeobecné podmínky')}</h2>
                    <span>
                        {tran.GetText(
                            'Zobrazení všeobecných podmínek pro používání systému AUTOTRACER',
                        )}{' '}
                        <a href={urlVP} target="_blank" rel="noreferrer">
                            {tran.GetText('zde')}
                        </a>
                    </span>
                    <br />
                    <span>
                        {tran.GetText(
                            'Platba za zobrazení detailních informací v části Výpis z historie vozidla nebo části OSVĚDČENÍ Cebia REPORT nebo postupně všech částí se Vám započítá podle podmínek uvedených ve smlouvě s Cebia.',
                        )}
                    </span>
                    <br />
                    <span>
                        {tran.GetText(
                            'Detailní informace k 1 VIN si můžete zobrazit po dobu 30 dnů od prvního zobrazení tohoto VIN a platíte pouze jednou.',
                        )}
                    </span>
                    <h2 className="mb-10 mt-20 font-bold">{tran.GetText('Důležité upozornění')}</h2>
                    <span>
                        {tran.GetText(
                            'Cebia, spol. s r.o. upozorňuje uživatele systému, že uváděné informace byly poskytnuty do systému partnery provozovatele bez možnosti ověřit jejich správnost. Veškeré informace mají výhradně informativní charakter. V případě, kdy stav vozidla je ve významném rozporu s informacemi zde uváděnými, doporučujeme kontaktovat patřičného znalce za účelem ověření skutečného stavu vozidla.',
                        )}
                    </span>
                    <h2 className="mb-10 mt-20 font-bold">
                        {tran.GetText('Informace o zpracování osobních údajů')}
                    </h2>
                    <a href="https://www.cebianet.cz/pub/web/Home/LegalNotice">
                        {tran.GetText(
                            'Zobrazení informace o zpracování osobních údajů uživatelů CebiaNET / AUTOTRACER',
                        )}
                    </a>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="PayedRegInfoExplanatory">
                    {/* {showExplanatory && (
                        <>
                            <h2>{tran.GetText('Vysvětlivky:')}</h2>
                            <div className="Grid font-bold">
                                <div className="Grid-cell smw:w-full md:w-2/12 lg:w-2/12">
                                    <span className="">{tran.GetText('Není k dispozici:')}</span>
                                </div>
                                <div className="Grid-cell smw:w-full md:w-4/12 lg:w-4/12">
                                    <span>
                                        {tran.GetText(
                                            'K vozidlu jsme v dané kategorii nedohledali žádné informace',
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="Grid font-bold">
                                <div className="Grid-cell smw:w-full md:w-2/12 lg:w-2/12 avalaible">
                                    <span>{tran.GetText('K dispozici:')}</span>
                                </div>
                                <div className="Grid-cell smw:w-full md:w-6/12 lg:w-6/12">
                                    <span>
                                        {tran.GetText(
                                            'K vozidlu jsme v dané kategorii dohledali informace, které se dozvíte v placené části',
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="separator mt-30"></div>
                        </>
                    )} */}

                    <h2 className="mb-10 mt-20 font-bold">{tran.GetText('Všeobecné podmínky')}</h2>
                    <span>
                        {tran.GetText(
                            'Zobrazení všeobecných podmínek pro používání systému AUTOTRACER',
                        )}{' '}
                        <a href={urlVP} target="_blank" rel="noreferrer">
                            {tran.GetText('zde')}
                        </a>
                    </span>
                    <br />
                    <span>
                        {tran.GetText(
                            'Platba za zobrazení detailních informací v části Výpis z historie vozidla nebo části OSVĚDČENÍ Cebia REPORT nebo postupně všech částí se Vám započítá podle podmínek uvedených ve smlouvě s Cebia.',
                        )}
                    </span>
                    <br />
                    <span>
                        {tran.GetText(
                            'Detailní informace k 1 VIN si můžete zobrazit po dobu 30 dnů od prvního zobrazení tohoto VIN a platíte pouze jednou.',
                        )}
                    </span>
                    <h2 className="mb-10 mt-20 font-bold">{tran.GetText('Důležité upozornění')}</h2>
                    <span>
                        {tran.GetText(
                            'Cebia, spol. s r.o. upozorňuje uživatele systému, že uváděné informace byly poskytnuty do systému partnery provozovatele bez možnosti ověřit jejich správnost. Veškeré informace mají výhradně informativní charakter. V případě, kdy stav vozidla je ve významném rozporu s informacemi zde uváděnými, doporučujeme kontaktovat patřičného znalce za účelem ověření skutečného stavu vozidla.',
                        )}
                    </span>
                    <h2 className="mb-10 mt-20 font-bold">
                        {tran.GetText('Informace o zpracování osobních údajů')}
                    </h2>
                    <a href="https://www.cebianet.cz/pub/web/Home/LegalNotice">
                        {tran.GetText(
                            'Zobrazení informace o zpracování osobních údajů uživatelů CebiaNET / AUTOTRACER',
                        )}
                    </a>
                </div>
            </>
        )
    }
}
