import { ImageProps } from '../Image'
import { ReactElement, useRef, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { Translate } from '../../translations/CebiaTranslator'
import './index.styl'

type ImagesState = {
    index: number
    isOpen: boolean
}

type Props = {
    images: ImageProps[]
    imagesState: ImagesState
    setPhotosState: (imagesState: ImagesState) => void
    setFullScreenProp?: boolean
}
export const LightboxGallery = ({
    images,
    imagesState,
    setPhotosState,
    setFullScreenProp = true,
}: Props): ReactElement => {
    const [allImages, setAllImages] = useState(images)
    const tran = new Translate()
    const [degree, setDegree] = useState(0)
    const GetPictureUrl = (index: number) => {
        let src = allImages[imagesState.index].src

        if (src.indexOf('/0/250') > 0) {
            src = src.replace('/0/250', '')
        }
        if (src.indexOf('/1/250') > 0) {
            src = src.replace('/1/250', '/1')
        }

        return src
    }

    return (
        <Lightbox
            mainSrc={
                (allImages[imagesState.index].src?.length ?? 0) > 0 &&
                allImages[imagesState.index].src.indexOf('GetIcarisPhoto') > 0
                    ? `${GetPictureUrl(imagesState.index)}&fullscreen=1`
                    : GetPictureUrl(imagesState.index)
            }
            nextSrc={GetPictureUrl((imagesState.index + 1) % allImages.length)}
            prevSrc={GetPictureUrl((imagesState.index + allImages.length - 1) % allImages.length)}
            onCloseRequest={() => {
                setPhotosState({ isOpen: false, index: 0 })
                setDegree(0)
            }}
            onMovePrevRequest={() => {
                setPhotosState({
                    index: (imagesState.index + allImages.length - 1) % allImages.length,
                    isOpen: imagesState.isOpen,
                })
                setDegree(0)
            }}
            onMoveNextRequest={() => {
                setPhotosState({
                    index: (imagesState.index + 1) % allImages.length,
                    isOpen: imagesState.isOpen,
                })
                setDegree(0)
            }}
            imageCaption={`Image ${imagesState.index + 1} of ${allImages.length}`}
            nextLabel={tran.GetText('Další obrázek')}
            prevLabel={tran.GetText('Předchozí obrázek')}
            zoomInLabel={tran.GetText('Přiblížit')}
            zoomOutLabel={tran.GetText('Oddálit')}
            closeLabel={tran.GetText('Zavřít')}
            toolbarButtons={[
                <button
                    type="button"
                    onClick={() => {
                        const element = document.getElementsByClassName('ril-image-current')[0]
                        // Získání původní transformace
                        const currentTransform = window
                            .getComputedStyle(element)
                            .getPropertyValue('transform')
                        let newTransform = ''

                        if (element) {
                            if (degree + 90 === 360) {
                                setDegree(0)
                            } else {
                                setDegree(degree + 90)
                            }
                            newTransform = currentTransform + ` rotate(${90}deg)`

                            element.setAttribute('style', `transform: ${newTransform}`)
                        }
                    }}
                    className="ril__toolbarItemChild ril__builtinButton rotate"
                    key="rotate"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            fill="#ddd"
                            d="M24 10.1V3.4c0-.6-.4-1-1-1s-1 .4-1 1v4.3l-3.4-3.2C15.2 1.1 9.9.3 5.6 2.7 1.4 5-.8 9.9.3 14.6c1.1 4.7 5.2 8.2 10 8.5h.6c4.6 0 8.7-2.9 10.3-7.3.2-.5-.1-1.1-.6-1.3-.5-.2-1.1.1-1.3.6-1.3 3.7-5 6.1-8.9 5.9-3.9-.2-7.3-3.1-8.2-6.9-.9-3.8.9-7.9 4.4-9.8 3.5-1.9 7.8-1.3 10.6 1.5L20.5 9h-4.1c-.6 0-1 .4-1 1s.4 1 1 1H23c.1 0 .2 0 .2-.1h.1l.1-.1c.1 0 .2-.1.2-.2 0 0 0-.1.1-.1 0-.1.1-.1.1-.2.2 0 .2-.1.2-.2z"
                        ></path>
                    </svg>
                </button>,
            ]}
        />
    )
}
