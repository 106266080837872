import moment from 'moment'
import { VinSectionProps } from '..'
import { Table } from '../../../../../components/Table'

export const SectionVinFoto = ({ item, tran }: VinSectionProps) => {
    if ((item?.advertMatchType ?? 0) !== 7) return <></>

    return (
        <>
            <div className="text-center pb-25">
                {(item.isAnonymized ?? false) === false && (
                    <p className="Info Info--red mb-20">
                        <span className="Icon Icon--alert" aria-hidden="true"></span>
                        {tran.GetText(
                            'Kontrolujeme fotografie, o provedení budete informování e-mailem.',
                        )}
                    </p>
                )}
                <p className="text-justify">
                    {tran.GetText(
                        'Informace byly převzaty z evidencí služeb dokumentování stavů vozidel pořízených ve společnosti Cebia nebo v síti jejích smluvních partnerů.',
                    )}
                </p>
            </div>
            <div className="mb-30">
                <Table>
                    <thead>
                        {item.advertDate ? (
                            <tr>
                                <th className="text-left">
                                    {tran.GetText('Datum provedení služby:')}
                                </th>
                                <th className="text-left">
                                    {moment(item.advertDate).format('MM / YYYY')}
                                </th>
                            </tr>
                        ) : (
                            <tr>
                                <th colSpan={2}></th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {item.carColor?.length > 0 && (
                            <tr>
                                <td>{tran.GetText('Barva vozidla:')}</td>
                                <td>{item.carColor}</td>
                            </tr>
                        )}
                        {item.advertKm > 0 && (
                            <tr>
                                <td>{tran.GetText('Stav tachometru:')}</td>
                                <td>
                                    {tran.GetNumber(item.advertKm)} {tran.GetText('km')}
                                </td>
                            </tr>
                        )}
                        {(item.carStateText?.length ?? 0) > 0 && (
                            <tr>
                                <td>{tran.GetText('Stav vozidla:')}</td>
                                <td>{item.carStateText}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}
