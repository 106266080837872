export enum AtrSection {
    Tachos = 1,
    TachosDamages = 2,
    TachosServiceRecords = 4,
    CarHistory = 8,
    CarInfo = 16,
    CarSecurity = 32,
    CarPhoto = 64,
    CouponPrice = 128,
    CebiaReport = 129,
    Other = 130,
    CouponSale = 42,
    DiscountOffers = 56,
    RarHistory = 57,
}
