import './index.styl'
import { ReactElement } from 'react'
import { ToggleOpener, ToggleOpenerProps } from '../ToggleOpener'
import { Loader } from '../Loader'
import classNames from 'classnames'
import { AtrInfoStatus, AtrInfoStatusProps } from '../AtrInfoStatus'

type Props = ToggleOpenerProps & {
    atrInfoStatus?: AtrInfoStatusProps
    unavailable?: boolean
    isLoading?: boolean
    isVisble?: boolean
}

export const AtrNavLink = ({
    isLoading,
    unavailable,
    atrInfoStatus,
    isVisble = true,
    ...props
}: Props): ReactElement => {
    if (!isVisble) return <></>

    const className = classNames('AtrNavLink', {
        [`AtrNavLink--unavailable`]: unavailable,
    })

    const showChildComponent = () => {
        if (isLoading) return <Loader variant="bars" />
        else if (atrInfoStatus) return <AtrInfoStatus {...atrInfoStatus} />

        return <></>
    }

    return (
        <ToggleOpener className={className} clickAction="open" {...props}>
            <span>{props.children}</span>
            <span className="AtrNavLink-infoStatus">{showChildComponent()}</span>
        </ToggleOpener>
    )
}
