import './index.styl'
import {
    ReactNode,
    ReactElement,
    useRef,
    useLayoutEffect,
    useState,
    CSSProperties,
    useEffect,
} from 'react'
import Scroll from '../../services/Scroll'
import debounce from 'lodash/debounce'

type Props = {
    offsetTop?: number
    offsetBottom?: number
    children: ReactNode
}

type State = {
    isSticky?: boolean
    style?: CSSProperties
    scrollTop?: number
}

type StickyElementState = {
    rect?: {
        scrollHeight: number
        top: number
        left: number
        width: number
        height: number
    }
}

type ContainerElementState = {
    rect?: {
        width: number
        height: number
        top: number
    }
}

export const Sticky = ({ offsetTop = 0, offsetBottom = 0, children }: Props): ReactElement => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const stickyRef = useRef<HTMLDivElement | null>(null)

    const [isMouseOver, setIsMouseOver] = useState<boolean>(false)
    const [state, setState] = useState<State>({
        isSticky: false,
        style: undefined,
    })
    const [container, setContainer] = useState<ContainerElementState>({})
    const [sticky, setSticky] = useState<StickyElementState>({})

    useEffect(() => {
        const clear = () => {
            setState({
                isSticky: false,
                style: undefined,
            })
        }

        const render = () => {
            if (
                !container ||
                !container.rect ||
                !sticky ||
                !sticky.rect ||
                !stickyRef ||
                !stickyRef.current ||
                !containerRef ||
                !containerRef.current
            ) {
                return
            }
            const offset = Scroll.getScroll().y - container.rect.top + 20
            const max = Math.max(container.rect.height - sticky.rect.scrollHeight, 0)
            if (max === 0) {
                clear()
            }

            if (offset < 0) {
                if (state.isSticky) {
                    setState(() => ({
                        isSticky: false,
                        style: {},
                    }))
                }
            } else if (offset > max) {
                //state.isSticky || isAfterResize?
                if (state.isSticky) {
                    setState({
                        isSticky: false,
                        style: {
                            left: undefined,
                            top: undefined,
                            position: undefined,
                            transform: `translateY(${max}px)`,
                        },
                    })
                }
                if (!isMouseOver) {
                    const progress = Math.min(Math.max(offset / max, 0), 1)
                    stickyRef.current.scrollTop =
                        progress * (sticky.rect.scrollHeight - sticky.rect.height)
                }
            } else {
                setState({
                    isSticky: true,
                    style: {
                        left: state.style?.left,
                        top: offset,
                        transform: undefined,
                    },
                })
                if (!isMouseOver) {
                    const progress = Math.min(Math.max(offset / max, 0), 1)
                    stickyRef.current.scrollTop =
                        progress * (sticky.rect.scrollHeight - sticky.rect.height)
                }
            }
        }

        const handleScroll = render

        Scroll.on('scroll', handleScroll)
        return () => {
            Scroll.off('scroll', handleScroll)
        }
    }, [container, state])

    useEffect(() => {
        if (!containerRef.current) {
            return
        }

        const resize = () => {
            if (!containerRef.current) {
                return
            }

            const containerRect = containerRef.current.getBoundingClientRect()
            setContainer(() => ({
                rect: {
                    height: containerRect.height,
                    width: containerRect.width,
                    top: Scroll.getScroll().y + containerRect.y,
                },
            }))
        }

        // const handleResize = resize
        const handleDebouncedResize = debounce(resize, 200)

        const resizer = new ResizeObserver(handleDebouncedResize)
        resizer.observe(containerRef.current)
        Scroll.on('resize', handleDebouncedResize)
        return () => {
            if (!containerRef.current) {
                return
            }
            resizer.unobserve(containerRef.current)
            Scroll.off('resize', handleDebouncedResize)
        }
    }, [containerRef])

    useEffect(() => {
        if (!stickyRef.current) {
            return
        }

        const handleMouseEnter = () => {
            setIsMouseOver(true)
        }
        const handleMouseLeave = () => {
            setIsMouseOver(false)
        }
        stickyRef.current.addEventListener('mouseenter', handleMouseEnter)
        stickyRef.current.addEventListener('mouseleave', handleMouseLeave)
        return () => {
            if (!stickyRef.current) {
                return
            }
            stickyRef.current.removeEventListener('mouseenter', handleMouseEnter)
            stickyRef.current.removeEventListener('mouseleave', handleMouseLeave)
        }
    }, [stickyRef])

    useLayoutEffect(() => {
        const clear = () => {
            setState({
                isSticky: false,
                style: undefined,
                scrollTop: undefined,
            })
            setSticky({})
        }

        const resize = () => {
            if (!stickyRef.current) {
                return
            }
            const stickyRect = stickyRef.current.getBoundingClientRect()

            setSticky({
                rect: {
                    scrollHeight: stickyRef.current.scrollHeight,
                    height: stickyRect.height,
                    width: stickyRect.width,
                    top: Scroll.getScroll().y + stickyRect.top,
                    left: stickyRect.left,
                },
            })
        }

        resize()

        const handleResize = clear
        const handleDebouncedResize = debounce(resize, 200)

        Scroll.on('resize', handleResize)
        Scroll.on('resize', handleDebouncedResize)
        return () => {
            Scroll.off('resize', handleResize)
            Scroll.off('resize', handleDebouncedResize)
        }
    }, [stickyRef])

    return (
        <div className="Sticky" ref={containerRef}>
            <div
                className="Sticky-sticky"
                style={{
                    ...state.style,
                    maxHeight: `calc(100vh - ${offsetTop + offsetBottom}px)`,
                }}
                ref={stickyRef}
            >
                {children}
            </div>
        </div>
    )
}
