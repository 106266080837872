import './index.styl'
import { ReactElement, ReactNode, RefObject } from 'react'
import classNames, { Argument } from 'classnames'

type Props = {
    variantDisplay: 'static' | 'fixed'
    children: ReactNode
    className?: Argument
    active?: boolean
    customRef?: RefObject<HTMLDivElement>
}

export const AtrOverviewTag = ({
    variantDisplay,
    children,
    active = true,
    className: _className,
    customRef,
}: Props): ReactElement => {
    const className = classNames(
        'AtrOverviewTag',
        {
            [`is-${variantDisplay}`]: variantDisplay,
            [`is-active`]: active,
        },
        _className,
    )

    return (
        <div className={className} ref={customRef}>
            <span className="AtrOverviewTag-inner">{children}</span>
        </div>
    )
}
