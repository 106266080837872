import './index.styl'
import { ReactElement } from 'react'
import { Section } from '../../../../components/Section'
import { Container } from '../../../../components/Container'
import { Review } from '../../../../components/Review'
import { Slider } from '../../../../components/Slider'
import { Title } from '../../../../components/Title'
import { Translate, _, __ } from '../../../../translations/CebiaTranslator'
import { reviewDefinitions } from '../../../../components/Review/reviewData'

export const SectionHpReviews = (): ReactElement => {
    const tran = new Translate()
    const data = new reviewDefinitions().GetData()

    return (
        <Section className="SectionHpReviews">
            <Container>
                <Title className="mb-50" as="header" variant="withDash">
                    <h2 className="Beta color-darkBlue">{tran.GetText('Zkušenosti zákazníků')}</h2>
                    <p>{tran.GetText('Zjistěte, co o našem prověření říkají lidé')}</p>
                </Title>
                <Slider
                    className="text-center md:text-left"
                    variant="hpReview"
                    flickityOptions={{ wrapAround: true }}
                >
                    {data.map(listValue => {
                        return (
                            <Review
                                key={listValue.id}
                                rating={{ stars: listValue.stars }}
                                text={listValue.text}
                                author={listValue.author}
                            />
                        )
                    })}
                </Slider>
            </Container>
        </Section>
    )
}
