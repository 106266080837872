/* eslint-disable react/no-unescaped-entities */
import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { EuropeMap } from '../../../../components/EuropeMap'
import { Section } from '../../../../components/Section'
import { VisualIcon } from '../../../../components/VisualIcon'
import { Translate } from '../../../../translations/CebiaTranslator'
import { i18n } from '../../../../translations/i18n'
import classNames from 'classnames'

export const SectionAboutIntro = (): ReactElement => {
    const tran = new Translate()
    const classNameFirst = classNames('Grid-cell', {
        ['md:w-6/12']: i18n.language === 'al',
        ['md:w-5/12']: i18n.language !== 'al',
    })
    const classNameSecond = classNames('Grid-cell', {
        ['md:w-6/12']: i18n.language === 'al',
        ['md:w-7/12']: i18n.language !== 'al',
    })
    return (
        <Section variantPend="appended" className="SectionAboutIntro">
            <Container>
                {i18n.language === 'al' && (
                    <div className="Grid mb-40 ml-30 mr-30">
                        <p className="JustifyText">
                            <b>Auto-tracer by Gjirafa</b> është një raport historik i veturave i
                            krijuar për t'u ofruar blerësve të veturave informacione të vlefshme në
                            lidhje me historinë e një veture. Raporti ynë gjithëpërfshirës ofron
                            qasje në mbi 1 miliard të dhëna nga më shumë se 32 vende europiane, duke
                            siguruar që blerësit e veturave të kenë qasje në informacion të
                            përditësuar dhe të saktë për të marrë vendim të informuar për blerjen.
                        </p>
                        <p className="JustifyText">
                            Shërbimet tona ofrohen përmes <b>"Carvago by Gjirafa L.L.C"</b>, biznes
                            i regjistruar me seli në Prishtinë, Kosovë me zyra të vendosura në
                            selinë e Gjirafa, Magjistralja Prishtinë - Ferizaj km i 6-të.{' '}
                            <b>Gjirafa</b>, një kompani e angazhuar për rritjen e ekonomisë së
                            internetit në Kosovë, Shqipëri dhe Maqedoni, është bashkuar me{' '}
                            <b>Cebia</b>, një kompani Çeke me mbi 30 vjet përvojë në inspektimin e
                            veturave, sigurinë dhe verifikimin online të historisë së veturave. Së
                            bashku, qëllimi ynë është të përmirësojm përvojën e blerjes së veturave
                            në Kosovë dhe të sjellim një nivel të ri të transparencës dhe kënaqësisë
                            së klientit në industri.
                        </p>
                    </div>
                )}

                <div className="Grid md:gap-50 gap-80 gmb-30">
                    <div className={classNameFirst}>
                        <div className="SectionAboutIntro-quote color-darkBlue">
                            <ul>
                                <li>
                                    <VisualIcon
                                        variant="inline"
                                        className="mr-10"
                                        name="invertFolder"
                                    />
                                    {tran.GetHtml('Největší databáze údajů o autech v Evropě')}
                                </li>
                                <li>
                                    <VisualIcon
                                        variant="inline"
                                        className="mr-10"
                                        name="invertCalendarSearch"
                                    />
                                    {tran.GetHtml(
                                        '<strong>30 let zkušeností</strong> s prověřováním aut',
                                    )}
                                </li>
                                <li>
                                    <VisualIcon
                                        variant="inline"
                                        className="mr-10"
                                        name="invertCarCheck"
                                    />
                                    {tran.GetHtml(
                                        '<strong>1 000 000+</strong> prověřených aut každý rok',
                                    )}
                                </li>
                                <li>
                                    <VisualIcon
                                        variant="inline"
                                        className="mr-10"
                                        name="invertPin"
                                    />
                                    {tran.GetHtml(
                                        'Záznamy o vozidlech z více <strong>než 32 zemí</strong>',
                                    )}
                                </li>
                                <li>
                                    <VisualIcon
                                        variant="inline"
                                        className="mr-10"
                                        name="invertPersonHeart"
                                    />
                                    {tran.GetHtml(
                                        '<strong>750 000+</strong> spokojených zákazníků',
                                    )}
                                </li>
                            </ul>
                        </div>
                        <p className="SectionAboutIntro-text">
                            {tran.GetHtml(
                                'Databáze Cebia obsahuje více než 1 miliardu záznamů o ojetých vozidlech pocházejících z více než 20 zemi. Jedná se především o záznamy najetých kilometrů, poškození vozidel, fotografie vozidel a servisní záznamy.',
                            )}
                        </p>
                    </div>
                    <div className={classNameSecond}>
                        <EuropeMap></EuropeMap>
                    </div>
                </div>
            </Container>
        </Section>
    )
}
