import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionCallingAction } from '../../../../detailPaid/atr-items/SectionCallAction'
import './body.styl'

let PrintAllowIncrement = true

export const CallingAction = ({
    sourceData,
    tran,
    captionNumberRef,
}: PrintDataProps): ReactElement => {
    if (!sourceData?.callingActionControlResult) return <></>

    const callingActionData = new PayedJsonResult()
    callingActionData.callingActionControlResult = sourceData.callingActionControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Svolávací akce')}
            </h2>

            <Section className="CallingActiontSection">
                <SectionCallingAction sourceData={callingActionData} />
            </Section>
        </div>
    )
}
