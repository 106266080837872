import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionVehicleEquipmentInfo } from '../../../../detailPaid/atr-items/SectionVehicleInfo/Section/vehicleEquipment'
import './body.styl'

let PrintAllowIncrement = true

export const Equipment = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    if (
        !sourceData?.vehicleInfoControlResult ||
        ((sourceData?.vehicleInfoControlResult?.equipmentItems?.length ?? 0) <= 0 &&
            (sourceData?.vehicleInfoControlResult?.vehicleIdentItems?.length ?? 0) <= 0)
    )
        return <></>

    const equipmentData = new PayedJsonResult()
    equipmentData.vehicleInfoControlResult = sourceData.vehicleInfoControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div>
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Seznam výbavy dle výrobce')}
            </h2>

            <Section className="EquipmentSection">
                <SectionVehicleEquipmentInfo
                    sourceData={equipmentData.vehicleInfoControlResult!}
                    tran={tran}
                />
            </Section>
        </div>
    )
}
