import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { CouponType } from '../../../../../enums/CouponType'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionValuation } from '../../../../detailPaid/atr-items/SectionVaulation'
import './body.styl'

let PrintAllowIncrement = true

export const Valuation = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    if (
        !sourceData?.valuationControlResult ||
        !sourceData.couponType ||
        (sourceData.couponType != CouponType.WebCustomer &&
            sourceData.couponType != CouponType.SalesCustomer)
    )
        return <></>

    const valuationData = new PayedJsonResult()
    valuationData.valuationControlResult = sourceData.valuationControlResult

    if (
        !valuationData.valuationControlResult.isValid ||
        !valuationData.valuationControlResult.doneValuation
    )
        return <></>

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Ocenění vozidla')}
            </h2>

            <Section className="ValuationSection">
                <SectionValuation sourceData={valuationData} isPrint={true} />
            </Section>
        </div>
    )
}
