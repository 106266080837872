import classNames, { Argument } from 'classnames'
import { ReactElement, ReactNode } from 'react'
import './index.styl'

type Props = {
    children?: ReactNode
    className?: Argument
    variant?: 'full' | 'wide' | 'slim' | 'medium' | 'narrow'
    pure?: boolean
}

export const Container = ({
    children,
    className: _className,
    variant,
    pure,
}: Props): ReactElement => {
    const className = classNames(
        'Container',
        {
            [`Container--${variant}`]: variant,
            'is-pure': pure,
        },
        _className,
    )
    return <div className={className}>{children}</div>
}
