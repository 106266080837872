import './index.styl'
import React, { ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'

type ButtonOwnProps<T extends React.ElementType> = {
    as?: T | 'a' | 'button'
    variant?: 'link' | 'icon' | 'mobileIcon'
    variantSize?: 'desktopLarge' | 'small'
    variantColor?: 'blue' | 'lightBlue' | 'green' | 'gray' | 'darkBlue' | 'transparent'
    variantShape?: 'circle'
    icon?: 'arrowRight' | 'arrowLeft' | 'arrowDown' | 'caretLeft'
    id?: string
    name?: string
    className?: Argument
    children?: ReactNode
    type?: 'button' | 'submit'
    value?: string
    disabled?: boolean | undefined
    onClick?: React.MouseEventHandler<HTMLElement>
}

export type ButtonProps<T extends React.ElementType> = ButtonOwnProps<T> &
    Omit<React.ComponentProps<T>, keyof ButtonOwnProps<T>>

export const Button = <T extends React.ElementType = 'button'>({
    as,
    type,
    variant,
    variantSize,
    variantColor,
    variantShape,
    icon,
    id,
    className: _className,
    children,
    value,
    disabled,
    onClick,
    ...rest
}: ButtonProps<T>): ReactElement => {
    const className = classNames(
        'Button',
        {
            [`Button--${variant}`]: variant,
            [`Button--${variantSize}`]: variantSize,
            [`Button--${variantColor}`]: variantColor,
            [`Button--${variantShape}`]: variantShape,
            [`Button--hasIcon`]: icon,
        },
        _className,
    )

    if (as === 'a') {
        return (
            <a
                className={className}
                href={rest.href}
                onClick={onClick}
                target={rest.target}
                rel={rest.rel}
            >
                {children && <span className="Button-content">{children}</span>}
                {icon && <span className={`Icon Icon--${icon}`} aria-hidden="true"></span>}
            </a>
        )
    } else {
        return (
            <button
                id={id}
                className={className}
                onClick={onClick}
                type={type}
                value={value}
                disabled={disabled ?? false}
            >
                {children && <span className="Button-content">{children}</span>}
                {icon && <span className={`Icon Icon--${icon}`} aria-hidden="true"></span>}
            </button>
        )
    }
}
