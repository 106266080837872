import './index.styl'
import { ReactElement } from 'react'
import { Image } from '../Image'
import AssetsImgHand from './img/hand.png'
import AssetsImgHand2x from './img/hand@2x.webp'

export const BannerCarolinaLarge = (): ReactElement => {
    return (
        <a
            href="https://www.carolina.cz/"
            rel="noreferrer"
            target="_blank"
            className="BannerCarolinaLarge"
        >
            <div className="BannerCarolinaLarge-inner">
                <div className="BannerCarolinaLarge-block">
                    <h2 className="BannerCarolinaLarge-title">
                        Mějte své auto v mobilu s naší aplikací <strong>Carolina</strong>.
                    </h2>
                </div>
                <div className="BannerCarolinaLarge-image">
                    <Image src={AssetsImgHand} srcSet={AssetsImgHand2x} alt="" />
                </div>
                <div className="BannerCarolinaLarge-block Text">
                    <ul>
                        <li>
                            <strong>Prověří historii</strong> vašeho auta
                        </li>
                        <li>
                            <strong>Eviduje náklady</strong> a spotřebu auta
                        </li>
                        <li>
                            <strong>Ocení auto</strong> při nákupu nebo prodeji
                        </li>
                        <li>
                            <strong>Chrání</strong> vás před pokutami
                        </li>
                    </ul>
                </div>
                <span className="BannerCarolinaLarge-download">
                    Stáhnout zdarma <span className="Icon Icon--arrowRight"></span>
                </span>
            </div>
        </a>
    )
}
