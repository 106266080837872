import './index.styl'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { LangSwitch } from '../LangSwitch'
import { NavOpener } from '../NavOpener'
import classNames from 'classnames'
import { NavLayer, NavLayerName } from '../NavLayer'
import { useNavigation } from '../../hooks'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { queries } from '../../utils/queries'
import { i18n, LanguageDefinition, LanguageEnum } from '../../translations/i18n'
import { Translate, _ } from '../../translations/CebiaTranslator'
import { ReferenceType } from '../../enums/ReferenceType'
import { getCoutryToLanguageCode } from '../../busLog/http-common'
import { useModal } from '../Modal'
import { ContactForm } from '../../pages/faq/ContactForm'
import { useNavigate } from 'react-router'

type Props = {
    showLanguage: true | false
    referenceType: ReferenceType
}

export const Nav = ({ showLanguage, referenceType }: Props): ReactElement => {
    const isMediumUp = useMediaQuery(queries.mediumUp)
    const { open } = useModal('contactFormModalBusiness')

    const tran = new Translate()
    const { isOpen } = useNavigation()
    const navigate = useNavigate()

    const [activeNavLayer, setActiveNavLayer] = useState<NavLayerName | null>(null)
    const [navLayerOpener, setNavLayerOpener] = useState<HTMLElement | null>(null)
    const timer = useRef<ReturnType<typeof setTimeout>>()
    const baseUrlPayReg =
        process.env.NODE_ENV !== 'production'
            ? 'https://app.cebia.com/autotracer_test/'
            : 'https://app.cebia.com/atrpro/'

    const clearTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current)
        }
    }

    const handleEnter = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | null,
        navLayer: NavLayerName,
    ) => {
        clearTimer()

        if (!isMediumUp) {
            return
        }

        if (event && navLayerOpener !== event.currentTarget) {
            setNavLayerOpener(event.currentTarget)
        }
        setActiveNavLayer(navLayer)
    }

    const handleLeave = () => {
        clearTimer()

        const leave = () => {
            setActiveNavLayer(null)
        }

        if (isMediumUp) {
            timer.current = setTimeout(() => {
                leave()
            }, 400)
        } else {
            leave()
        }
    }

    const handleLinkClick = (
        event: React.MouseEvent<HTMLElement, MouseEvent> | null,
        navLayer: NavLayerName,
    ) => {
        setActiveNavLayer(navLayer)
    }

    const handleLinkMouseEnter = handleEnter
    const handleLinkMouseLeave = handleLeave
    const handleLayerEnter = handleEnter
    const handleLayerLeave = handleLeave

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current)
            }
        }
    }, [])

    const className = classNames('Nav', {
        'is-active': isOpen,
    })
    return (
        <div className={className}>
            <NavOpener variant="static"></NavOpener>
            <div className="Nav-inner">
                {/* pokud se nejde z placene stranky */}
                {referenceType != ReferenceType.PayedReg ? (
                    <>
                        <div className="Nav-main">
                            <nav className="Nav-links">
                                {i18n.language === 'cz' ? (
                                    <>
                                        <a className="Nav-link" href="/payed/detail/example">
                                            {tran.GetText('Ukázka prověření')}
                                        </a>
                                        <a
                                            className="Nav-link"
                                            href="#"
                                            onClick={() => {
                                                open()
                                            }}
                                        >
                                            {tran.GetText('Pro podnikatele')}
                                        </a>
                                        <a className="Nav-link" href="/faqs">
                                            {tran.GetText('Časté dotazy')}
                                        </a>
                                        {/* <a
                                            className="Nav-link"
                                            href="https://www.cebia.cz/novinky "
                                        >
                                            {tran.GetText('Blog')}
                                        </a> */}
                                        <a
                                            className={classNames(
                                                'Nav-link Nav-link--sublayer text-noWrap',
                                                {
                                                    'is-active': activeNavLayer === 'about',
                                                },
                                            )}
                                            href={
                                                isMediumUp
                                                    ? 'https://www.cebia.cz/o-nas#o-nas'
                                                    : '#'
                                            }
                                            onMouseEnter={
                                                isMediumUp
                                                    ? event => handleLinkMouseEnter(event, 'about')
                                                    : undefined
                                            }
                                            onMouseLeave={
                                                isMediumUp ? handleLinkMouseLeave : undefined
                                            }
                                        >
                                            <span
                                                onClick={() =>
                                                    window.location.assign(
                                                        'https://www.cebia.cz/o-nas',
                                                    )
                                                }
                                            >
                                                {tran.GetText('O nás')}
                                            </span>
                                            <span
                                                className="Nav-button"
                                                onClick={event => handleLinkClick(event, 'about')}
                                            ></span>
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        {i18n.language === 'sk' && (
                                            <>
                                                <a
                                                    className={classNames(
                                                        'Nav-link Nav-link--sublayer text-noWrap',
                                                        {
                                                            'is-active':
                                                                activeNavLayer === 'service',
                                                        },
                                                    )}
                                                    href="#"
                                                    onMouseEnter={
                                                        isMediumUp
                                                            ? event =>
                                                                  handleLinkMouseEnter(
                                                                      event,
                                                                      'service',
                                                                  )
                                                            : undefined
                                                    }
                                                    onMouseLeave={
                                                        isMediumUp
                                                            ? handleLinkMouseLeave
                                                            : undefined
                                                    }
                                                >
                                                    {tran.GetText('Služby')}
                                                    <span
                                                        className="Nav-button"
                                                        onClick={event =>
                                                            handleLinkClick(event, 'service')
                                                        }
                                                    ></span>
                                                </a>

                                                <NavLayer
                                                    name="service"
                                                    active={activeNavLayer === 'service'}
                                                    opener={
                                                        activeNavLayer === 'service'
                                                            ? navLayerOpener
                                                            : null
                                                    }
                                                    onEnter={handleLayerEnter}
                                                    onLeave={handleLayerLeave}
                                                >
                                                    <ul>
                                                        <li>
                                                            <a
                                                                href="https://sk.cebia.com/"
                                                                className="Nav-iconLink"
                                                            >
                                                                Car History Report
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.cebia.cz/sluzby/vinfoto"
                                                                className="Nav-iconLink"
                                                            >
                                                                Prehliadka na poistenie vozidla
                                                                VINFOTO
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a
                                                                href="https://www.cebia.cz/sluzby/cebia-foto"
                                                                className="Nav-iconLink"
                                                            >
                                                                Mobilní aplikácia Cebia Foto
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.cebia.cz/sluzby/homologace-vozidel"
                                                                className="Nav-iconLink"
                                                            >
                                                                Homologácie vozidiel
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </NavLayer>
                                            </>
                                        )}
                                        <a
                                            className="Nav-link"
                                            href={`/payed/detail/example${getCoutryToLanguageCode(
                                                i18n.language,
                                            ).toUpperCase()}`}
                                        >
                                            {tran.GetText('Ukázka prověření')}
                                        </a>
                                        {i18n.language !== 'al' && (
                                            <a
                                                className="Nav-link"
                                                href="#"
                                                onClick={() => {
                                                    open()
                                                }}
                                            >
                                                {tran.GetText('Pro podnikatele')}
                                            </a>
                                        )}
                                        {i18n.language === 'sk' ? (
                                            <>
                                                <a
                                                    className={classNames(
                                                        'Nav-link Nav-link--sublayer text-noWrap',
                                                        {
                                                            'is-active':
                                                                activeNavLayer === 'aboutSk',
                                                        },
                                                    )}
                                                    href="/about"
                                                    onMouseEnter={
                                                        isMediumUp
                                                            ? event =>
                                                                  handleLinkMouseEnter(
                                                                      event,
                                                                      'aboutSk',
                                                                  )
                                                            : undefined
                                                    }
                                                    onMouseLeave={
                                                        isMediumUp
                                                            ? handleLinkMouseLeave
                                                            : undefined
                                                    }
                                                >
                                                    {tran.GetText('O nás')}
                                                    <span
                                                        className="Nav-button"
                                                        onClick={event => {
                                                            navigate('/about')
                                                        }}
                                                    ></span>
                                                </a>

                                                <NavLayer
                                                    name="aboutSk"
                                                    active={activeNavLayer === 'aboutSk'}
                                                    opener={
                                                        activeNavLayer === 'aboutSk'
                                                            ? navLayerOpener
                                                            : null
                                                    }
                                                    onEnter={handleLayerEnter}
                                                    onLeave={handleLayerLeave}
                                                >
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="Nav-iconLink"
                                                                href="/faqs"
                                                            >
                                                                {tran.GetText('Časté dotazy')}
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                className="Nav-iconLink"
                                                                href="/blog"
                                                            >
                                                                {tran.GetText('Blog')}
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a
                                                                className="Nav-iconLink"
                                                                href="/about#contacts"
                                                            >
                                                                {tran.GetText('Kontakt')}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </NavLayer>
                                            </>
                                        ) : (
                                            <>
                                                <a className="Nav-link" href="/faqs">
                                                    {tran.GetText('Časté dotazy')}
                                                </a>
                                                {!['ua'].includes(i18n.language) && (
                                                    <a className="Nav-link" href="/blog">
                                                        {tran.GetText('Blog')}
                                                    </a>
                                                )}

                                                <a className="Nav-link" href="/about">
                                                    {tran.GetText('O nás')}
                                                </a>
                                            </>
                                        )}
                                    </>
                                )}
                            </nav>

                            {/* {!showLanguage && (
                        <div
                            className={classNames('Nav-link Nav-link--langswitch', {
                                'is-active': activeNavLayer === 'langswitch',
                            })}
                        >
                            {i18n.language}
                        </div>
                    )} */}
                        </div>
                        {!isMediumUp && showLanguage && <LangSwitch></LangSwitch>}
                        <NavLayer
                            name="about"
                            active={activeNavLayer === 'about'}
                            opener={activeNavLayer === 'about' ? navLayerOpener : null}
                            onEnter={handleLayerEnter}
                            onLeave={handleLayerLeave}
                        >
                            <ul>
                                <li>
                                    <a href="https://www.cebia.cz/novinky" className="Nav-iconLink">
                                        <span
                                            className="Icon Icon--sheets"
                                            aria-hidden="true"
                                        ></span>
                                        {tran.GetText('Novinky')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="Nav-iconLink"
                                        href="https://www.cebia.cz/o-nas#historie"
                                    >
                                        <span
                                            className="Icon Icon--hourglass"
                                            aria-hidden="true"
                                        ></span>
                                        {tran.GetText('Historie společnosti')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="Nav-iconLink"
                                        href="https://www.cebia.cz/o-nas#partneri"
                                    >
                                        <span className="Icon Icon--note" aria-hidden="true"></span>
                                        {tran.GetText('Naši partneři')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="Nav-iconLink"
                                        href="https://www.cebia.cz/o-nas#media"
                                    >
                                        <span
                                            className="Icon Icon--megaphone"
                                            aria-hidden="true"
                                        ></span>
                                        {tran.GetText('Pro média')}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="Nav-iconLink"
                                        href="https://www.cebia.cz/o-nas#volne-pozice"
                                    >
                                        <span
                                            className="Icon Icon--badge"
                                            aria-hidden="true"
                                        ></span>
                                        {tran.GetText('Kariéra')}
                                    </a>
                                </li>
                            </ul>
                        </NavLayer>
                    </>
                ) : (
                    <>
                        <div className="Nav-main">
                            <nav className="Nav-links">
                                <a className="Nav-link" href={baseUrlPayReg + 'Car/Check'}>
                                    {tran.GetText('Prověření vozidla')}
                                </a>
                                {i18n.language === 'cz' && (
                                    <>
                                        <a
                                            href={baseUrlPayReg + 'Exekuce/Check'}
                                            className="Nav-iconLink"
                                        >
                                            <span
                                                className="Icon Icon--user"
                                                aria-hidden="true"
                                            ></span>
                                            {tran.GetText('Exekuce/Insolvence')}
                                        </a>
                                        <a
                                            href={baseUrlPayReg + 'Doklad/Check'}
                                            className="Nav-iconLink"
                                        >
                                            <span
                                                className="Icon Icon--note"
                                                aria-hidden="true"
                                            ></span>
                                            {tran.GetText('Prověření dokladů zdarma')}
                                        </a>
                                    </>
                                )}

                                <a
                                    className={classNames(
                                        'Nav-link Nav-link--sublayer text-noWrap',
                                        {
                                            'is-active': activeNavLayer === 'history',
                                        },
                                    )}
                                    href="#"
                                    onMouseEnter={
                                        isMediumUp
                                            ? event => handleLinkMouseEnter(event, 'history')
                                            : undefined
                                    }
                                    onMouseLeave={isMediumUp ? handleLinkMouseLeave : undefined}
                                >
                                    {tran.GetText('Historie vyhledávání')}
                                    <span
                                        className="Nav-button"
                                        onClick={event => handleLinkClick(event, 'history')}
                                    ></span>
                                </a>
                            </nav>
                            <NavLayer
                                name="history"
                                active={activeNavLayer === 'history'}
                                opener={activeNavLayer === 'history' ? navLayerOpener : null}
                                onEnter={handleLayerEnter}
                                onLeave={handleLayerLeave}
                            >
                                <ul>
                                    <li>
                                        <a
                                            href={baseUrlPayReg + 'Car/CheckHistory'}
                                            className="Nav-iconLink"
                                        >
                                            <span
                                                className="Icon Icon--sheets"
                                                aria-hidden="true"
                                            ></span>
                                            {tran.GetText('Historie prověření vozidel')}
                                        </a>
                                    </li>
                                    {i18n.language === 'cz' && (
                                        <>
                                            <li>
                                                <a
                                                    className="Nav-iconLink"
                                                    href={baseUrlPayReg + 'Exekuce/CheckHistory'}
                                                >
                                                    <span
                                                        className="Icon Icon--hourglass"
                                                        aria-hidden="true"
                                                    ></span>
                                                    {tran.GetText('Historie Exekuce/Insolvence')}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="Nav-iconLink"
                                                    href={baseUrlPayReg + 'Doklad/CheckHistory'}
                                                >
                                                    <span
                                                        className="Icon Icon--note"
                                                        aria-hidden="true"
                                                    ></span>
                                                    {tran.GetText('Historie prověření dokladů')}
                                                </a>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </NavLayer>
                        </div>
                    </>
                )}
            </div>
            <div className="d-flex switcher">
                {isMediumUp && showLanguage && (
                    <>
                        <NavLayer
                            name="langswitch"
                            opener={activeNavLayer === 'langswitch' ? navLayerOpener : null}
                            active={activeNavLayer === 'langswitch'}
                            onEnter={handleLayerEnter}
                            onLeave={handleLayerLeave}
                        >
                            <LangSwitch></LangSwitch>
                        </NavLayer>
                        <div
                            className={classNames(
                                'Nav-link Nav-link--sublayer Nav-link--langswitch',
                                {
                                    'is-active': activeNavLayer === 'langswitch',
                                },
                            )}
                            onMouseEnter={event => handleLinkMouseEnter(event, 'langswitch')}
                            onMouseLeave={handleLinkMouseLeave}
                        >
                            {LanguageDefinition[
                                LanguageEnum[i18n.language as keyof typeof LanguageEnum]
                            ].displayName.toUpperCase()}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
