import './index.styl'
import { Children, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Button } from '../Button'
import Flickity from 'flickity'
import classNames, { Argument } from 'classnames'

type Props = {
    variant: 'review' | 'steps' | 'hpReview'
    prevNextButtons?: boolean
    className?: Argument
    children?: ReactNode
    flickityOptions?: Flickity.Options
}

export const Slider = ({
    children,
    className: _className,
    variant,
    flickityOptions = {},
}: Props): ReactElement => {
    flickityOptions = {
        prevNextButtons: true,
        cellAlign: 'left',
        ...flickityOptions,
    }
    const [isReady, setIsReady] = useState<boolean>(false)
    const sliderEl = useRef<HTMLDivElement>(null)
    const flickity = useRef<Flickity | null>(null)

    const handlePrevClick = useCallback(() => {
        flickity.current?.previous()
    }, [])

    const handleNextClick = useCallback(() => {
        flickity.current?.next()
    }, [])

    useEffect(() => {
        if (!sliderEl.current) {
            return
        }

        flickity.current = new Flickity(sliderEl.current, {
            ...flickityOptions,
            prevNextButtons: false, //prevnext sou reseny ruco
            // contain: true,
            // groupCells: true,
        })

        setTimeout(() => {
            setIsReady(true)
            flickity.current?.resize()
        }, 0)

        // return () => {
        //     flickity.current?.destroy()
        // }
    }, [children])

    const className = classNames(
        `Slider`,
        {
            [`Slider--${variant}`]: variant,
            'is-ready': isReady,
        },
        _className,
    )

    return (
        <div className={className}>
            {flickityOptions.prevNextButtons && (
                <Button
                    as="button"
                    variant="icon"
                    variantShape="circle"
                    icon="arrowLeft"
                    className="Slider-prevButton"
                    onClick={handlePrevClick}
                />
            )}

            <div className="Slider-slider" ref={sliderEl}>
                {Children.map(children, child => (
                    <div className="Slider-item">{child}</div>
                ))}
            </div>

            {flickityOptions.prevNextButtons && (
                <Button
                    as="button"
                    variant="icon"
                    variantShape="circle"
                    icon="arrowRight"
                    className="Slider-nextButton Button Button--icon"
                    data-ref="next"
                    onClick={handleNextClick}
                />
            )}
        </div>
    )
}
