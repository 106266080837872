import i18n, { Resource, ResourceLanguage } from 'i18next'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { CountryCode } from '../components/AtrMileageTable/countryCodes'
import customDetector from './detector'

import {
    TRANSLATIONS_CZ,
    TRANSLATIONS_SK,
    TRANSLATIONS_US,
    TRANSLATIONS_DE,
    TRANSLATIONS_RO,
    TRANSLATIONS_AL,
    TRANSLATIONS_UA,
    TRANSLATIONS_IT,
    TRANSLATIONS_FR,
    TRANSLATIONS_NL,
} from './CebiaTranslator_gen'

export enum LanguageEnum {
    en = 'en',
    cz = 'cz',
    sk = 'sk',
    de = 'de',
    ro = 'ro',
    al = 'al',
    uk = 'uk',
    it = 'it',
    fr = 'fr',
    nl = 'nl',
}

export type LanguageSpecification = {
    resource: ResourceLanguage
    flag: CountryCode
    email: string
    invisibility: Array<string>
    displayName: string
    affilCampaignId: number
    googleWidgetHP: string
    googleWidgetDashboard: string
    elfsight: string
}

export enum Environment {
    devel = 'development',
    test = 'test',
    prod = 'production',
}

export const LanguageDefinition: Record<LanguageEnum, LanguageSpecification> = {
    en: {
        resource: TRANSLATIONS_US,
        flag: 'gb',
        email: 'info.en@cebia.com',
        invisibility: [],
        displayName: 'en',
        affilCampaignId: 31,
        googleWidgetHP: 'elfsight-app-47d2402b-d783-46bf-b013-b007e805e728',
        googleWidgetDashboard: 'elfsight-app-a521c0cd-739a-49cc-9c45-eefae70f3b40',
        elfsight: 'elfsight-app-31dad137-0440-475c-b041-e6658efe1af2',
    },
    cz: {
        resource: TRANSLATIONS_CZ,
        flag: 'cz',
        email: 'autotracer@cebia.cz',
        invisibility: [],
        displayName: 'cz',
        affilCampaignId: 18,
        googleWidgetHP: 'elfsight-app-f7ef436f-3700-4c97-9308-b694798c62d8',
        googleWidgetDashboard: 'elfsight-app-1f014824-6587-437b-8eb7-1e5c20f05904',
        elfsight: '',
    },
    sk: {
        resource: TRANSLATIONS_SK,
        flag: 'sk',
        email: 'info.sk@cebia.com',
        invisibility: [],
        displayName: 'sk',
        affilCampaignId: 21,
        googleWidgetHP: 'elfsight-app-b4bfd724-7032-4f2c-9b28-82483a13e399',
        googleWidgetDashboard: 'elfsight-app-5d5e068b-fc31-4437-8346-31eeff5e2800',
        elfsight: '',
    },
    de: {
        resource: TRANSLATIONS_DE,
        flag: 'de',
        email: 'info.de@cebia.com',
        invisibility: [],
        displayName: 'de',
        affilCampaignId: -1,
        googleWidgetHP: 'elfsight-app-6391dc94-5cf0-43cb-b38b-16c7495118b2',
        googleWidgetDashboard: 'elfsight-app-c01ab626-73b4-454a-bf7e-17ca0528519d',
        elfsight: 'elfsight-app-7c724f45-b023-4632-9577-0130e56016cb',
    },
    ro: {
        resource: TRANSLATIONS_RO,
        flag: 'ro',
        email: 'info.ro@cebia.com',
        invisibility: [],
        displayName: 'ro',
        affilCampaignId: 24,
        googleWidgetHP: 'elfsight-app-24e0935d-ce47-41f4-abf5-1d64418c76f1',
        googleWidgetDashboard: 'elfsight-app-86aa417a-ddf7-4772-a032-c36e90702f93',
        elfsight: '',
    },
    al: {
        resource: TRANSLATIONS_AL,
        flag: 'al',
        email: 'autotracer@gjirafa.com',
        invisibility: ['zkontroluj.cz', 'cebia.com', 'zkontrolujsi.cz', 'auto-tracer.com'],
        displayName: 'al',
        affilCampaignId: 33,
        googleWidgetHP: '',
        googleWidgetDashboard: '',
        elfsight: '',
    },
    uk: {
        resource: TRANSLATIONS_UA,
        flag: 'ua',
        email: 'info.ua@cebia.com',
        invisibility: [],
        displayName: 'ua',
        affilCampaignId: 36,
        googleWidgetHP: 'elfsight-app-eaa0dc83-155e-40a5-9d8b-cf30bbd456ba',
        googleWidgetDashboard: 'elfsight-app-ce7384d4-03f2-4284-8057-a7d8705f6bf6',
        elfsight: 'elfsight-app-4b9e65b7-3bb8-47b9-8ab5-4e863a38afb8',
    },
    it: {
        resource: TRANSLATIONS_IT,
        flag: 'it',
        email: 'info.it@cebia.com',
        invisibility: [],
        displayName: 'it',
        affilCampaignId: 42,
        googleWidgetHP: 'elfsight-app-bd529ee0-236f-47d0-b239-a143ac5b1e14',
        googleWidgetDashboard: 'elfsight-app-6324244e-5c51-49c5-96a4-6d8e58af3c6f',
        elfsight: '',
    },
    fr: {
        resource: TRANSLATIONS_FR,
        flag: 'fr',
        email: 'info.fr@cebia.com',
        invisibility: [],
        displayName: 'fr',
        affilCampaignId: 39,
        googleWidgetHP: 'elfsight-app-0c6435f1-8df9-43c3-a099-e97c678c3833',
        googleWidgetDashboard: 'elfsight-app-81b8538f-5db8-45d2-93e9-64fa620859af',
        elfsight: '',
    },
    nl: {
        resource: TRANSLATIONS_NL,
        flag: 'nl',
        email: 'info.nl@cebia.com',
        invisibility: [],
        displayName: 'nl',
        affilCampaignId: -1,
        googleWidgetHP: 'elfsight-app-1538ed71-9c86-490e-9b4f-0aed54986533',
        googleWidgetDashboard: 'elfsight-app-37505bd5-458a-434f-8716-d59361be7c25',
        elfsight: '',
    },
}

export const resolveShowLanguage = () => {
    if (i18n.language === 'al' && process.env.NODE_ENV !== Environment.devel) return false
    return true
}

export const resourceDefinition = {
    [LanguageEnum.cz]: { translation: LanguageDefinition[LanguageEnum.cz].resource },
    [LanguageEnum.sk]: { translation: LanguageDefinition[LanguageEnum.sk].resource },
    [LanguageEnum.en]: { translation: LanguageDefinition[LanguageEnum.en].resource },
    [LanguageEnum.de]: { translation: LanguageDefinition[LanguageEnum.de].resource },
    [LanguageEnum.ro]: { translation: LanguageDefinition[LanguageEnum.ro].resource },
    [LanguageEnum.al]: { translation: LanguageDefinition[LanguageEnum.al].resource },
    [LanguageEnum.uk]: { translation: LanguageDefinition[LanguageEnum.uk].resource },
    [LanguageEnum.it]: { translation: LanguageDefinition[LanguageEnum.it].resource },
    [LanguageEnum.fr]: { translation: LanguageDefinition[LanguageEnum.fr].resource },
    [LanguageEnum.nl]: { translation: LanguageDefinition[LanguageEnum.nl].resource },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(customDetector)

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        interpolation: {
            format: function (value, format) {
                if (value instanceof Date) return moment(value).format(format)
                if (typeof value === 'number') return new Intl.NumberFormat().format(value)
                return value
            },
        },
        nsSeparator: '|',
        resources: resourceDefinition,
        detection: {
            order: ['customDetector'],
            caches: ['cookie', 'localStorage'],
            cookieOptions: { path: '/', sameSite: 'strict' },
        },
        fallbackLng: 'cz',
        debug: false,
    })
export { i18n }
