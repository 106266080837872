import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionServiceRec } from '../../../../detailPaid/atr-items/SectionServiceRec'
import './body.styl'

let PrintAllowIncrement = true

export const ServiceRecs = ({
    sourceData,
    tran,
    captionNumberRef,
}: PrintDataProps): ReactElement => {
    const serviceRecData = new PayedJsonResult()
    serviceRecData.serviceControlResult = sourceData!.serviceControlResult
    if (
        PrintAllowIncrement &&
        (sourceData?.serviceControlResult?.serviceRecords?.length ?? 0 > 0)
    ) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    if ((sourceData?.serviceControlResult?.serviceRecords?.length ?? 0) > 0)
        return (
            <div className="pba">
                <h2>
                    {captionNumberRef!.current}. {tran.GetText('Servisní záznamy')}
                </h2>

                <Section className="SerRecSection">
                    <SectionServiceRec sourceData={serviceRecData} />
                </Section>
            </div>
        )
    else return <></>
}
