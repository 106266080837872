import './index.styl'
import { ReactElement } from 'react'
import { Translate } from '../../translations/CebiaTranslator'
import { AtrTimeLineTableRowItem } from '../AtrTimeLineTableRowItem'
import { TimeLineItem } from '../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { groupBy } from 'lodash'
import classNames from 'classnames'
import { TimeLineObjectType } from '../../enums/TimeLineObjectEnum'

export interface AtrTimeLineTableNote {
    variant?: 'alert'
    title: string
    href?: string
    text?: string
}

type Props = {
    rows: TimeLineItem[]
    isPrint?: boolean
}

export const navigateToElement = (id: string, type: TimeLineObjectType | undefined) => {
    const element = document.getElementById(`${id}`) as any
    if (element) {
        element.scrollIntoView()
        if (type !== TimeLineObjectType.Adverts || !type) {
            if (!element.classList.contains('is-active')) {
                element.click()
            }
        } else {
            //u advertu nejde udelat link na anchor, protoze uz ma id, ktere se pouziva, takze parent a pak klik
            const actual = element as HTMLElement
            const parent = actual.parentElement as any
            if (!parent?.classList.contains('is-active')) {
                parent.click()
            }
        }
    }
}

export const navigateToLastElement = (id: string, elementDefinition: string) => {
    const elements = document.querySelectorAll(`${elementDefinition}[id='#${id}']`)
    const element = elements[elements.length - 1] as any
    if (element) {
        element.scrollIntoView()
        if (!element.classList.contains('is-active')) {
            element.click()
        }
    }
}

export const navigateToFirstElement = (id: string, elementDefinition: string) => {
    const elements = document.querySelectorAll(`${elementDefinition}[id='#${id}']`)
    const element = elements[0] as any
    if (element) {
        element.scrollIntoView()
        if (!element.classList.contains('is-active')) {
            element.click()
        }
    }
}

export const AtrTimeLineTable = ({ rows, isPrint }: Props): ReactElement => {
    const tran = new Translate()
    const groupsByYear = groupBy(rows, row => {
        if (row.currentDate) return new Date(row.currentDate).getFullYear()
    })
    const monthClassName = classNames('u-colMonth', {
        monthPrint: isPrint ? 'true' : '',
    })
    const kmClassName = classNames('u-colKm text-noWrap', {
        kmPrint: isPrint ? 'true' : '',
    })
    const noteClassName = classNames('u-colNote', {
        notePrint: isPrint ? 'true' : '',
    })
    return (
        <div className="AtrTimeLineTable">
            <div className="AtrTimeLineTable-header lgMax:hidden">
                <span className="u-colYear">{tran.GetText('Datum')}</span>
                <div className="AtrTimeLineTable-headerRows">
                    <span className={monthClassName}></span>
                    <span className={kmClassName}>{tran.GetText('Stav tachometru')}</span>
                    <span className="u-colCountry pl-50">{tran.GetText('Země')}</span>
                    <span className={noteClassName}>{tran.GetText('Popis')}</span>
                    <span className="u-colAnchor"></span>
                </div>
            </div>
            {Object.entries(groupsByYear).map(([year, group]) => (
                <div className="AtrTimeLineTable-group" key={year}>
                    <div className="u-colYear lgMax:hidden">{year}</div>
                    <div className="AtrTimeLineTable-rows">
                        {group.map((row, id) => {
                            return (
                                <AtrTimeLineTableRowItem
                                    isPrint={isPrint ?? false}
                                    row={row}
                                    id={`${year}-${id}`}
                                    key={`${year}-${id}`}
                                ></AtrTimeLineTableRowItem>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}
