export class TheftControlData {
    //kdy vzniknul report
    couponDateActivation?: Date
    theftItems?: TheftItem[]
    isTheft = false
}

class TheftItem {
    source = ''
    result = ''
    //kdy se auto ukradlo.
    created?: Date
    isPrivateSource = false
    isTheft = false
    countryISO = ''
}
