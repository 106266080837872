import { VisualIconProps } from '../../components/VisualIcon'
import { Translate } from '../../translations/CebiaTranslator'

export class DetailPayedRegItemDefinitionModel {
    SectionId = 0
    Name = ''
    RegDescription = ''
    VisualIcon: VisualIconProps | undefined
    Order = 0
    CrData = false
    DefaultCountText = ''
    DefaultPeriodPrefix = ''
    DefaultPeriodText = ''
    DateType = false
}

export function getOverDesc(): DetailPayedRegItemDefinitionModel[] {
    const tran = new Translate()
    return [
        {
            SectionId: 1,
            Name: tran.GetText('Kontrola najetých kilometrů'),
            RegDescription: tran.GetText(
                'Dozvíte se hodnoty stavu tachometru v daném období. V jednoduchém grafu se můžete přesvědčit, jak se stav tachometru vyvíjel v čase a odhalíte jeho případné stočení.  Pozor: více než 40 % vozidel ojetých vozidel má stočený tachometr!',
            ),
            VisualIcon: { name: 'speedometerCheck' },
            Order: 1,
            CrData: true,
            DefaultCountText: '',
            DefaultPeriodPrefix: '',
            DefaultPeriodText: '',
            DateType: true,
        },
        {
            SectionId: 2,
            Name: tran.GetText('Kontrola poškození'),
            RegDescription: tran.GetText(
                'Zobrazíte si informace o evidovaných škodách na vozidle. V detailu mohou být připojeny informace o výši škody a rozsahu poškození. Pozor: nezapomeňte si zároveň prověřit technický stav vozidla a jeho předchozí poškození. Zda se například nejednalo o totální škodu.',
            ),
            VisualIcon: { name: 'carCrash' },
            Order: 2,
            CrData: false,
            DefaultCountText: '',
            DefaultPeriodPrefix: tran.GetText('kontrolováno') + ':',
            DefaultPeriodText: tran.GetText('32 zemí'),
            DateType: false,
        },
        {
            SectionId: 57,
            Name: 'Verificarea istoricului RAR',
            RegDescription:
                'În cazul în care vehiculul este înmatriculat în România, raportul poate conține înregistrări ale tuturor inspecțiilor tehnice periodice (ITP). Puteți verifica data inspecției, kilometrajul și alte informații importante. Datele sunt furnizate de Registrul Auto Român (RAR).',
            VisualIcon: { name: 'rarHistory' },
            Order: 3,
            CrData: false,
            DefaultCountText: '',
            DefaultPeriodPrefix: '',
            DefaultPeriodText: '',
            DateType: false,
        },
        {
            SectionId: 64,
            Name: tran.GetText('Fotky a inzerce'),
            RegDescription: tran.GetText(
                'Pokud bylo vozidlo v minulosti inzerováno, zobrazíme vám inzerci a fotodokumentaci k vozidlu.',
            ),
            VisualIcon: { name: 'album' },
            Order: 4,
            CrData: false,
            DefaultCountText: '',
            DefaultPeriodPrefix: '',
            DefaultPeriodText: '',
            DateType: false,
        },
        {
            SectionId: 4,
            Name: tran.GetText('Servisní záznamy'),
            RegDescription: tran.GetText(
                'Zjistíte detailní informace a přehled servisních úkonů, provedených v rámci oprav nebo běžného servisu vozidla.  Pozor: porovnejte si záznamy se servisní knihou a požadujte více informací o servisní historii vozidla.',
            ),
            VisualIcon: { name: 'tabletCar' },
            Order: 5,
            CrData: false,
            DefaultCountText: '',
            DefaultPeriodPrefix: '',
            DefaultPeriodText: '',
            DateType: true,
        },
        // {
        //     SectionId: 30,
        //     Name: tran.GetText('Kontrola stáří a původu'),
        //     RegDescription: tran.GetText(
        //         'Ověříme pro Vás skutečný rok výroby, což může mít podstatný dopad na současnou i budoucí cenu vozidla. Rok výroby se v technických průkazech již neuvádí. Zapisuje se pouze datum 1. registrace. To se ale od roku výroby může lišit až o 2 roky, ale není výjimkou ani 6 let. Přitom jeden rok omlazení může v ceně vozidla znamenat i několik desítek tisíc korun, které zaplatíte navíc.   <br />U většiny vozidel se také dozvíte, datum 1. registrace a pro jakou zemi bylo vozidlo původně vyrobeno.',
        //     ),
        //     VisualIcon: { name: 'calendarCar' },
        //     Order: 5,
        //     CrData: true,
        //     DefaultCountText: '',
        //     DefaultPeriodPrefix: '',
        //     DefaultPeriodText: '',
        //     DateType: false,
        // },
        {
            SectionId: 33,
            Name: tran.GetText('Kontrola odcizení'),
            RegDescription: tran.GetText(
                'Vozidlo prověříme v aktuálně dostupných databázích odcizených vozidel.<br />Jedná se převážně o evropské databáze ze státních i nestátních zdrojů. Seznam dostupných zemí a zdrojů najdete v placené části.',
            ),
            VisualIcon: { name: 'thiefCar' },
            Order: 6,
            CrData: true,
            DefaultCountText: tran.GetText('až 12 registrů'),
            DefaultPeriodPrefix: '',
            DefaultPeriodText: '',
            DateType: false,
        },
        {
            SectionId: 35,
            Name: tran.GetText('Kontrola financování'),
            RegDescription: tran.GetText(
                'Vozidlo prověříme v databázích finančních společností, autopůjčoven a zastaváren. V placené části lze zkontrolovat, zda vůz není zatížen financováním, zápůjčkou nebo zástavou.',
            ),
            VisualIcon: { name: 'tabletMoney' },
            Order: 7,
            CrData: true,
            DefaultCountText: '',
            DefaultPeriodPrefix: '',
            DefaultPeriodText: '',
            DateType: false,
        },
        {
            SectionId: 31,
            Name: tran.GetText('Kontrola taxi'),
            RegDescription: tran.GetText(
                'Prověření v databázích vozidel provozovaných jako taxislužba. V placené části se dozvíte, zda vůz byl a nebo stále je provozován jako taxi.',
            ),
            VisualIcon: { name: 'glassCar' },
            Order: 8,
            CrData: false,
            DefaultCountText: '',
            DefaultPeriodPrefix: '',
            DefaultPeriodText: '',
            DateType: false,
        },
        {
            SectionId: 38,
            Name: tran.GetText('Svolávací akce'),
            RegDescription: tran.GetText(
                'Zobrazíme vám seznam svolávacích akcí, které by se mohly týkat prověřovaného vozidla.',
            ),
            VisualIcon: { name: 'megaphone' },
            Order: 10,
            CrData: false,
            DefaultCountText: '',
            DefaultPeriodPrefix: '',
            DefaultPeriodText: '',
            DateType: false,
        },
    ]
}
