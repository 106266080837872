import classNames from 'classnames'
import { useState } from 'react'
import { LightboxGallery } from '../../../../../../components/LightboxGallery'
import { ToggleItemGeneral } from '../../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../../../components/ToggleOpenerGeneralVoucher'
import { VehicleIdentificationPhotoGroup } from '../../../../../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { getPhotoImageProps } from '../../../../../../utils/images'

type VehicleIdentificationPhotoGroupType = {
    source: VehicleIdentificationPhotoGroup
    index: number
}

export const SectionVehicleIdentificationPhotosGallery = ({
    source,
    index,
}: VehicleIdentificationPhotoGroupType) => {
    const [photosState, setPhotosState] = useState({
        index: 0,
        isOpen: false,
    })
    return (
        <div className="Grid gap-20" key={`gridPhotos_${index}`}>
            <ToggleOpenerGeneralVoucher
                href={`#identPhotoSectionTogg_${index}`}
                titleLeft={source.groupName}
                isOpened={false}
            />
            {photosState.isOpen && source.photos && (
                <LightboxGallery
                    images={getPhotoImageProps(source.photos)}
                    imagesState={photosState}
                    setPhotosState={setPhotosState}
                ></LightboxGallery>
            )}
            <ToggleItemGeneral id={`identPhotoSectionTogg_${index}`} grid={true}>
                {source.photos?.map((photo, photoIndex) => (
                    <div
                        className="Grid-cell smw:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 xxl:w-1/5"
                        key={`photoIdx_${photoIndex}`}
                    >
                        <div className="Image">
                            <img
                                alt=""
                                onClick={() =>
                                    setPhotosState({
                                        index: photoIndex,
                                        isOpen: true,
                                    })
                                }
                                className="is-lazy-loaded"
                                src={photo.url}
                            />
                        </div>
                    </div>
                ))}
            </ToggleItemGeneral>
        </div>
    )
}
