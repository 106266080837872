import './index.styl'
import { ReactElement, useEffect, useRef, useState } from 'react'
import classNames, { Argument } from 'classnames'
import { Speedometer } from '../Speedometer'
import { getValueOfProgress } from '../../utils/interpolate'
import { Translate } from '../../translations/CebiaTranslator'

export type LoaderReportProps = {
    vin?: string
    subtitle?: string
    isFastFinish?: boolean
    className?: Argument
}

const TICK_INTERVAL_IN_MS = 200

export const LoaderReport = ({
    vin,
    subtitle,
    isFastFinish = false,
    className: _className,
}: LoaderReportProps): ReactElement => {
    const className = classNames('LoaderReport', _className)
    const tran = new Translate()

    const [groupIndex, setGroupIndex] = useState(0)
    const [itemIndex, setItemIndex] = useState(0)
    const [progress, setProgress] = useState(0)
    const [nextTick, setNextTick] = useState(0)
    const ticker = useRef<ReturnType<typeof setTimeout>>()
    const durationToProgressValues = useRef<Array<[number, number]>>([])
    const durationToItemIndexValues = useRef<Array<[number, number]>>([])
    const duration = useRef({
        total: 0,
        current: 0,
        lastDate: Date.now(),
        startDate: Date.now(),
        speed: 1,
    })

    const groups = [
        { title: tran.GetText('Data o vozidle') },
        { title: tran.GetText('Historie vozidla') },
        { title: tran.GetText('Kontrola poškození') },
        { title: tran.GetText('Kontrola najetých kilometrů') },
    ]

    const items: { group: number; title: string; _itemIndex?: number; duration: number }[] = [
        {
            group: 0,
            title: tran.GetText('Ověřujeme pravost VIN kódu'),
            duration: 1000,
        },
        {
            group: 0,
            title: tran.GetText('Ověřujeme identitu vozidla'),
            duration: 1000,
        },
        {
            group: 0,
            title: tran.GetText('Kontrolujeme výbavu vozidla'),
            duration: 1000,
        },
        {
            group: 1,
            title: tran.GetText('Prohledáváme databáze poškozených vozidel'),
            duration: 3000,
        },
        {
            group: 1,
            title: tran.GetText('Prověřujeme záznamy z pojišťoven'),
            duration: 1000,
        },
        {
            group: 1,
            title: tran.GetText('Prohledáváme historii aukčních portálů'),
            duration: 1000,
        },
        {
            group: 1,
            title: tran.GetText('Prověřujeme záznamy z autorizovaných servisů'),
            duration: 1000,
        },
        {
            group: 1,
            title: tran.GetText('Prověřujeme záznamy z neautorizovaných servisů'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Prohledáváme policejní databáze odcizených vozidel'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Prohledáváme soukromé databáze odcizených vozidel'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Kontrolujeme stáří vozidla'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Kontrolujeme původ vozidla'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Prohledáváme elektronické servisní knížky'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Prohledáváme státní registry vozidel'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Prohledáváme státní databáze vozidel taxi'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Kontrolujeme evropské svolávací akce'),
            duration: 1000,
        },
        {
            group: 2,
            title: tran.GetText('Kontrolujeme zabezpečení vozidla'),
            duration: 1000,
        },
        {
            group: 3,
            title: tran.GetText('Kontrolujeme databáze záznamů najetých kilometrů'),
            duration: 1000,
        },
        {
            group: 3,
            title: tran.GetText('Prověřujeme záznamy z leasingových společností'),
            duration: 1000,
        },
        {
            group: 3,
            title: tran.GetText('Prohledáváme záznamy ze stanic technické kontroly'),
            duration: 1000,
        },
        {
            group: 3,
            title: tran.GetText('Prohledáváme záznamy ze stanic měření emisí'),
            duration: 1000,
        },
        {
            group: 3,
            title: tran.GetText('Prohledáváme historii inzertních portálů'),
            duration: 1000,
        },
        {
            group: 3,
            title: tran.GetText('Prohledáváme databáze prodejců nových vozidel'),
            duration: 1000,
        },
        {
            group: 3,
            title: tran.GetText('Prohledáváme databáze prodejců ojetých vozidel'),
            duration: 1000,
        },
        {
            group: 3,
            title: tran.GetText('Prověřujeme záznamy z autopůjčoven'),
            duration: 1000,
        },
    ]

    //pocatecni vypocty pro interpolace
    useEffect(() => {
        const groupsInfo = items.reduce<{
            duration: number
            group: number
            groupBreakpoints: number[]
            itemBreakpoints: number[]
        }>(
            (acc, item) => {
                if (acc.group !== item.group) {
                    acc.group = item.group
                    acc.groupBreakpoints.push(acc.duration)
                }

                acc.itemBreakpoints.push(acc.duration)
                acc.duration += item.duration
                return acc
            },
            {
                duration: 0,
                group: 0,
                groupBreakpoints: [],
                itemBreakpoints: [],
            },
        )

        duration.current.total = groupsInfo.duration
        durationToProgressValues.current = [
            [0, 0],
            ...groupsInfo.groupBreakpoints.map((breakpoint, index) => {
                const pair: [number, number] = [
                    breakpoint,
                    (index + 1) / (groupsInfo.groupBreakpoints.length + 1),
                ]
                return pair
            }),
            [duration.current.total, 1],
        ]

        durationToItemIndexValues.current = groupsInfo.itemBreakpoints.map((breakpoint, index) => [
            breakpoint,
            index,
        ])
    }, [])

    useEffect(() => {
        if (isFastFinish) {
            const remaining = duration.current.total - duration.current.current
            const DESIRED_MS_TO_FINISH = 1000

            duration.current.speed = remaining / DESIRED_MS_TO_FINISH
        } else {
            duration.current.speed = 1
        }
    }, [isFastFinish])

    //pravidelny tick => vypocet progressu a aktualniho itemu
    useEffect(() => {
        duration.current = {
            ...duration.current,
            current:
                duration.current.current +
                (Date.now() - duration.current.lastDate) * duration.current.speed,
            lastDate: Date.now(),
        }

        const current = duration.current.current

        const index = Math.floor(getValueOfProgress(current, durationToItemIndexValues.current))
        setProgress(getValueOfProgress(current, durationToProgressValues.current))

        if (index !== itemIndex) {
            setItemIndex(index)
        }

        if (items[index]?.group !== groupIndex) {
            setGroupIndex(items[index]?.group)
        }

        if (progress !== 1) {
            ticker.current = setTimeout(() => {
                setNextTick(nextTick + 1)
            }, TICK_INTERVAL_IN_MS)
        }

        return () => {
            if (ticker.current) {
                clearTimeout(ticker.current)
            }
        }
    }, [nextTick])

    return (
        <article className={className}>
            <header className="LoaderReport-header">
                <span className="LoaderReport-title Beta smw:mr-20">
                    {tran.GetText('Vytváříme report')}{' '}
                </span>
                {vin && <span className="font-bold">{vin}</span>}
                {subtitle && <span className="LoaderReport-subtitle">{subtitle}</span>}
            </header>

            <div className="LoaderReport-main">
                <div className="LoaderReport-speedometer">
                    <Speedometer progress={progress} currentGroupIndex={groupIndex} />
                </div>

                <ul>
                    {groups.map((group, index) => (
                        <li
                            className={classNames('LoaderReport-group', {
                                'is-done': index < groupIndex || progress === 1,
                                'is-active': index === groupIndex && progress !== 1,
                            })}
                            key={index}
                        >
                            <span>{group.title}</span>

                            {items[itemIndex].group === index && progress !== 1 ? (
                                <div className="Text md:hidden">
                                    <ul>
                                        <li>{items[itemIndex].title}</li>
                                    </ul>
                                </div>
                            ) : (
                                ''
                            )}
                        </li>
                    ))}
                </ul>

                <div className="LoaderReport-currentItem smwMax:hidden">
                    {items[itemIndex].title}
                </div>
            </div>

            <footer className="LoaderReport-footer">
                <span className="Icon Icon--clock" aria-hidden="true"></span>
                {tran.GetText('Operace může trvat až několik minut')}
            </footer>
        </article>
    )
}
