import { ReactElement, useEffect, useState } from 'react'
import AutoImg from '../../../../assets/img/auto.webp'
import { Container } from '../../../../components/Container'
import { FormCoupon } from '../../../../components/FormCoupon'
import { FormVin } from '../../../../components/FormVin'
import { Image } from '../../../../components/Image'
import { Modal, useModal } from '../../../../components/Modal'
import { PulseDot } from '../../../../components/PulseDot'
import { Shape } from '../../../../components/Shape'
import { Title } from '../../../../components/Title'
import { useAppContext } from '../../../../contexts/AppContext'
import { Translate } from '../../../../translations/CebiaTranslator'
import { i18n } from '../../../../translations/i18n'
import './index.styl'
import { getTechnickaImg } from '../../../detailDashboard'

type View = 'vin' | 'coupon'

type Props = {
    initialView: View
}

export const SectionPageHeader = ({ initialView }: Props): ReactElement => {
    const tran = new Translate()
    const [view, setView] = useState<View>(initialView)
    const appContext = useAppContext()

    const { open } = useModal('findVinModal')
    const { open: openC } = useModal('findCouponModal')
    useEffect(() => {
        if (appContext.appModel.vin) appContext.clearAppModel()
    }, [])

    return (
        <div className="SectionPageHeader" key={view}>
            <Container>
                <Shape className="SectionPageHeader-shapeBlue" variant="blue" />
                <Shape className="SectionPageHeader-shapeGreen" variant="green" />

                {view === 'vin' && (
                    <>
                        <div className="SectionPageHeader-header mb-20 md:mb-50">
                            <h1 className="SectionPageHeader-title Alfa">
                                <span>{tran.GetText('Prověřte si vozidlo')} </span>
                                <span className="text-noWrap">
                                    <PulseDot />
                                    &nbsp;<strong>{tran.GetText('online')}</strong>
                                </span>
                            </h1>

                            <div className="SectionPageHeader-highlights">
                                <ul>
                                    <li>
                                        <span className="Icon Icon--check mr-10 color-green"></span>
                                        {tran.GetText('Přehled informací zdarma')}
                                    </li>
                                    <li>
                                        <span className="Icon Icon--check mr-10 color-green"></span>
                                        {tran.GetText('Historie vozů z 32+ zemí')}
                                    </li>
                                    <li>
                                        <span className="Icon Icon--check mr-10 color-green"></span>
                                        {tran.GetText('30 000 000+ prověřených vozů')}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="SectionPageHeader-form">
                            <FormVin variant="default" id="" />
                            <div className="SectionPageHeader-sublinks">
                                <button
                                    className="Link Link--line color-inherit"
                                    onClick={() => open()}
                                >
                                    {tran.GetText('Kde najdu VIN kód?')}
                                </button>
                                <button
                                    className="Link Link--line color-inherit"
                                    onClick={() => setView('coupon')}
                                >
                                    {tran.GetText('Vstup po zaplacení')}
                                </button>
                            </div>
                        </div>
                    </>
                )}

                {view === 'coupon' && (
                    <>
                        <div className="SectionPageHeader-header mb-20 md:mb-50">
                            <h1 className="SectionPageHeader-title Alfa">
                                <span>{tran.GetText('Zobrazit výsledek prověření')}</span>
                            </h1>

                            <p className="SectionPageHeader-highlights">
                                {tran.GetText(
                                    'Pro zobrazení zaplaceného výsledku prověření vložte číslo kuponu',
                                )}
                            </p>
                        </div>

                        <div className="SectionPageHeader-form">
                            <FormCoupon variant="default" />
                            <div className="SectionPageHeader-sublinks">
                                <button className="Link Link--line color-inherit" onClick={openC}>
                                    {tran.GetText('Kde najdu číslo kuponu?')}
                                </button>
                                <button
                                    className="Link Link--line color-inherit"
                                    onClick={() => setView('vin')}
                                >
                                    {tran.GetText('Zadání VIN')}
                                </button>
                            </div>
                        </div>
                    </>
                )}
                <Modal id="findCouponModal">
                    <Container>
                        <Title as="header">
                            <h2 className="color-darkBlue">
                                {' '}
                                {tran.GetText('Kde najdu číslo kuponu')}
                            </h2>
                        </Title>
                        <div className="SectionPageHeader-highlights fs15">
                            <ul className="ul-left">
                                <li>
                                    <span className="Icon Icon--check mr-10 color-green"></span>
                                    <p>
                                        {tran.GetText(
                                            'Číslo kuponu získáte po koupi prověření v systému AUTOTRACER, tedy po zaplacení příslušného balíčku',
                                        )}
                                    </p>
                                </li>
                                <li>
                                    <span className="Icon Icon--check mr-10 color-green"></span>
                                    <p>
                                        {tran.GetText(
                                            'V případě, že úhradu provádíte formou platební karty, GooglePay nebo ApplePay, ihned se Vám zobrazí zakoupené informace a zároveň obdržíte číslo kuponu mailem',
                                        )}
                                    </p>
                                </li>
                                {i18n.language === 'cz' && (
                                    <>
                                        <li>
                                            <span className="Icon Icon--check mr-10 color-green"></span>
                                            <p>
                                                {tran.GetText(
                                                    'V případě, že úhradu provádíte formou bankovního převodu, obdržíte číslo kuponu mailem ihned poté, co budou finanční prostředky připsány na bankovní účet Cebia',
                                                )}
                                            </p>
                                        </li>
                                        <li>
                                            <span className="Icon Icon--check mr-10 color-green"></span>
                                            <p>
                                                {tran.GetText(
                                                    'V případě, že úhradu provádíte v hotovosti v sídle společnosti Cebia, dostanete číslo kuponu vytištěné',
                                                )}
                                            </p>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <span className="Icon Icon--check mr-10 color-green"></span>
                                    <p>
                                        {tran.GetHtml(
                                            "Ve všech uvedených případech pak zadáte číslo kuponu do příslušného pole na domovské stránce <a href='https://cz.cebia.com/'>cz.cebia.com</a> a získáte ihned přístup k zakoupeným informacím, k nimž se v průběhu 30 dnů budete moci kdykoli vrátit",
                                        )}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </Container>
                </Modal>
                <Modal id="findVinModal">
                    <Container>
                        <div className="Grid">
                            <div className="Grid-cell md:w-6/12 mb-20">
                                <Title as="header" variantAlign="left">
                                    <h2 className="color-darkBlue">
                                        {tran.GetText(
                                            'VIN je 17místný kód, který jednoznačně a jedinečně identifikuje vozidlo.',
                                        )}
                                    </h2>
                                    <br />
                                    <p>
                                        {tran.GetText(
                                            'Umístění VIN se liší u každé značky a modelu vozidla. Vždy ho ale naleznete na malém i velkém technickém průkazu a často třeba pod čelním sklem.',
                                        )}
                                    </p>
                                </Title>
                                {tran.GetHtml(
                                    '<a className="Link Link--lightBlue" href="https://www.cebia.cz/pruvodce/kde-najdu-vin-kod" target="_blank" rel="noreferrer noopener">Více informací</a>',
                                )}
                            </div>
                            <div className="Grid-cell md:w-6/12">
                                <Image
                                    src={getTechnickaImg()}
                                    alt={tran.GetText('technický průkaz - vin kód')}
                                />
                            </div>
                        </div>
                        <div>
                            <Image src={AutoImg} alt={tran.GetText('čelní sklo auta - vin kód')} />
                        </div>
                    </Container>
                </Modal>
            </Container>
        </div>
    )
}
