import './index.styl'
import { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react'
import Flickity from 'flickity'
import { Scroll } from '../../services/Scroll'
import classNames, { Argument } from 'classnames'
import debounce from 'lodash/debounce'

type FlickityScrollEventHandler = (
    event?: Event | undefined,
    pointer?: Element | Touch | undefined,
    cellElement?: Element | undefined,
    cellIndex?: number | undefined,
) => void

type TimelineItem = {
    year: string
    title: string
    text: string
}

type DotState = {
    id: number
    style: CSSProperties
}

type Dot = {
    id: number
    element: HTMLElement
    x: number
}

type State = {
    lineLength: number
    lineWidth: number
    ratio: number
    height: number
    width: number
    diff: number
    dots: Dot[]
}

type Props = {
    className?: Argument
    items: TimelineItem[]
}

export const Timeline = ({ className: _className, items = [] }: Props): ReactElement => {
    const [isReady, setIsReady] = useState<boolean>(false)
    const [state, setState] = useState<State>({
        diff: 0,
        height: 0,
        lineLength: 0,
        lineWidth: 0,
        ratio: 0,
        width: 0,
        dots: [],
    })
    const [dotsState, setDotsState] = useState<DotState[]>(
        items.map((item, index) => ({
            id: index,
            style: {
                opacity: 0,
            },
        })),
    )
    const rootRef = useRef<HTMLDivElement>(null)
    const dotsRef = useRef<Array<HTMLDivElement | null>>([])
    const timelineRef = useRef<HTMLDivElement>(null)
    const svgRef = useRef<SVGSVGElement>(null)
    const pathRef = useRef<SVGPathElement>(null)
    const sliderRef = useRef<HTMLDivElement>(null)
    const flickity = useRef<Flickity | null>(null)

    useEffect(() => {
        if (
            !timelineRef.current ||
            !rootRef.current ||
            !pathRef.current ||
            !svgRef.current ||
            !flickity.current
        ) {
            return
        }

        const timelineBox = timelineRef.current.getBoundingClientRect()
        const elementBox = rootRef.current.getBoundingClientRect()

        const lineLength = pathRef.current.getTotalLength()
        const lineWidth = svgRef.current.getBoundingClientRect().width

        setState({
            lineLength,
            lineWidth,
            ratio: lineLength / lineWidth,
            height: timelineBox.height,
            width: elementBox.width,
            diff: (timelineBox.width - elementBox.width) / 2,
            dots: dotsRef.current
                .filter((element): element is HTMLDivElement => element !== null)
                .map((element, index): Dot => {
                    const box = element.getBoundingClientRect()

                    return {
                        id: index,
                        element,
                        x: box ? box.left - timelineBox.left + box.width / 2 : 0,
                    }
                }),
        })
    }, [flickity.current])

    useEffect(() => {
        const render = (offset = 0) => {
            const _dotsState: DotState[] = []
            state.dots.forEach(dot => {
                if (!pathRef.current) {
                    return
                }

                const x = dot.x - offset
                const { y } = pathRef.current.getPointAtLength(x * state.ratio)

                let opacity = 1

                if (x < state.diff) {
                    opacity = Math.max(x, 0) / state.diff
                }

                if (x > state.width + state.diff) {
                    opacity = 1 - Math.min(x - state.width - state.diff, state.diff) / state.diff
                }

                _dotsState.push({
                    id: dot.id,
                    style: {
                        transform: `translateY(-${state.height - y}px)`,
                        opacity,
                    },
                })
            })

            setDotsState([..._dotsState])
        }

        render()

        const handleFlickityScroll: FlickityScrollEventHandler = (event, progress) => {
            //flickity ma trosku bordel v typech
            render(progress as unknown as number)
        }
        flickity.current?.on('scroll', handleFlickityScroll)
        return () => {
            flickity.current?.off('scroll', handleFlickityScroll)
        }
    }, [state, flickity.current])

    useEffect(() => {
        const clear = () => {
            setIsReady(false)
            setDotsState([])
            flickity.current?.destroy()
            flickity.current = null
        }

        const resize = () => {
            if (!sliderRef.current) {
                return
            }

            flickity.current = new Flickity(sliderRef.current, {
                freeScroll: true,
                // cellSelector: '[data-ref="item"]',
                contain: true,
                prevNextButtons: false,
                pageDots: false,
                resize: false,
            })

            setIsReady(true)
        }

        resize()

        const handleResize = clear
        const handleDebouncedResize = debounce(resize, 400)

        Scroll.on('resize', handleResize)
        Scroll.on('resize', handleDebouncedResize)
        return () => {
            Scroll.off('resize', handleResize)
            Scroll.off('resize', handleDebouncedResize)
            flickity.current?.destroy()
            flickity.current = null
        }
    }, [items])

    const className = classNames(
        `Timeline`,
        {
            'is-ready': isReady,
        },
        _className,
    )

    return (
        <div className={className} ref={rootRef}>
            <div className="Timeline-timeline" ref={timelineRef}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1730.246"
                    height="57"
                    viewBox="0 0 1730.246 56.379"
                    className="Timeline-line"
                    ref={svgRef}
                >
                    <path
                        d="M0,31.673s218.887-56.662,430.832,0,425.137,0,425.137,0,212.766-63.346,430.285,0,443.723-4.82,443.723-4.82"
                        transform="translate(0.125 -2.894)"
                        fill="none"
                        stroke="#5edb3d"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                        strokeDasharray="3"
                        ref={pathRef}
                    />
                </svg>
            </div>
            <div className="Timeline-content" ref={sliderRef}>
                {items.length &&
                    items.map((item, index) => (
                        <article className="Timeline-item" key={item.year}>
                            <div
                                className="Timeline-itemDot"
                                style={dotsState[index]?.style}
                                ref={el => (dotsRef.current[index] = el)}
                            ></div>
                            <time className="Timeline-itemDate">{item.year}</time>
                            <h3 className="Timeline-itemTitle">{item.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                        </article>
                    ))}
            </div>
        </div>
    )
}
