import './index.styl'
import { ChangeEvent, ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'
import { Field, useField } from 'formik'
import { JsxAttribute } from 'typescript'
import { BubbleOpener } from '../BubbleOpener'

type Props = {
    variant?: 'dark' | 'green'
    id: string
    name: string
    placeholder?: string
    required?: boolean
    className?: Argument
    children?: ReactNode
    value?: string | number
    checked?: boolean
    subText?: string | undefined
    onClick?: (e: ChangeEvent<HTMLInputElement>) => void
    labelQuestionHandler?: () => void
}

export const RadioButton = ({
    variant,
    className: _className,
    required = false,
    children,
    labelQuestionHandler,
    subText,
    onClick,
    ...props
}: Props): ReactElement => {
    const [, meta] = useField(props.name)
    const className = classNames(
        'Radio',
        {
            [`RadioButton--${variant}`]: variant,
        },
        _className,
    )

    function handleClick(e: React.ChangeEvent<HTMLInputElement>) {
        if (onClick) {
            onClick(e)
        }
    }

    return (
        <label className={`${className} ${meta.error ? 'is-invalid' : ''}`}>
            <Field
                onClick={handleClick}
                type="radio"
                required={required}
                {...props}
                className="Radio-input"
            />
            <div className="Radio-trigger Grid gap-10 gmb-5 Grid--alignMiddle">
                <div className="Radio-title w-1/1 md:w-5/12">
                    <div className="w-1/12 smw:w-1/12 md:w-1/12 mr-15">
                        <div className="Radio-placeholder"></div>
                    </div>
                    <div className="w-10/12 smw:w-10/12 md:w-10/12">
                        <div className="text-inline">
                            {props.placeholder}
                            {labelQuestionHandler && (
                                <BubbleOpener
                                    className="questionPayMethod"
                                    onClickHandler={labelQuestionHandler}
                                />
                            )}
                        </div>
                        {subText && (
                            <div className="Grid gmb-5 cardDesc">
                                <div
                                    className="w-fill"
                                    dangerouslySetInnerHTML={{ __html: subText }}
                                ></div>
                            </div>
                        )}
                    </div>
                    {/* <div className="w-1/12 smw:w-1/12 md:w-1/12"></div> */}
                </div>
                {children}
            </div>
        </label>
    )
}
