import ContactValuesModel from '../models/ContactValuesModel'
import { RatingMessageModel } from '../models/RatingMessageModel'
import SmartCodeContactValuesModel from '../models/SmartCodeContactValuesModel'
import http from './http-common'

export const sendCustomerContact = async (values: ContactValuesModel) => {
    let retVal: boolean | undefined
    try {
        const response = await http.post('/subscriber/SendCustomerContact', values)
        return response.status === 200 && response.data === true
    } catch (e) {
        console.log(e)
    }
    return false
}

export const sendSmartCodeCustomerContact = async (values: SmartCodeContactValuesModel) => {
    let retVal: boolean | undefined

    try {
        const response = await http.post('/subscriber/SendSmartCodeCustomerContact', values)
        return response.status === 200 && response.data === true
    } catch (e) {
        console.log(e)
    }
    return false
}

export const sendPackageCustomerContact = async (values: ContactValuesModel) => {
    let retVal: boolean | undefined

    try {
        const response = await http.post('/subscriber/SendPackageCustomerContact', values)
        return response.status === 200 && response.data === true
    } catch (e) {
        console.log(e)
    }
    return false
}

export const sendRatingMessage = async (values: RatingMessageModel) => {
    let retVal: boolean | undefined

    try {
        const response = await http.post('/subscriber/SendRatingMessage', values)
        return response.status === 200 && response.data === true
    } catch (e) {
        console.log(e)
    }
    return false
}

export const getRating = async (couponNumber: string) => {
    let retVal: RatingMessageModel | null = null

    if (couponNumber && couponNumber.length > 0) {
        try {
            const tmpResponse = await http.get<RatingMessageModel>(
                '/subscriber/GetRating?coupon=' + couponNumber,
                {
                    timeout: 15000,
                },
            )

            if (tmpResponse.status === 200 && tmpResponse.data) {
                retVal = tmpResponse.data
            }
        } catch (err) {
            retVal = new RatingMessageModel(couponNumber)
            return retVal
        }
    }

    return retVal
}
