import './print.styl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes } from 'react-router-dom'
import { PrintIdentification } from '../../pages/print/identifikace'
import { PrintDetailPaid } from '../../pages/print/vypis'
import { PrintTachoStates } from '../../pages/print/cebiaReports'

export const PrintAppRouting = () => {
    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <Routes>
                <Route path="/print/detail/:id" element={<PrintDetailPaid />} />
                <Route path="/print/identification/:id" element={<PrintIdentification />} />
                <Route path="/print/certTacho/:id" element={<PrintTachoStates />} />
            </Routes>
        </QueryClientProvider>
    )
}
