import moment from 'moment'
import { ReactElement } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { ShowDateManufacturing } from '../../../../../components/AtrOverviewCar'
import { Section } from '../../../../../components/Section'
import './body.styl'

let PrintAllowIncrement = true

export const BaseCarInfo = ({
    sourceData,
    captionNumberRef,
    tran,
}: PrintDataProps): ReactElement => {
    if (!sourceData) return <></>

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <>
            <div className="mt-20">
                <h2>
                    {captionNumberRef!.current}. {tran.GetText('Základní údaje o vozidle')}
                </h2>
            </div>
            <Section className="CarSection">
                <div className="Container--medium">
                    <div className="tracer-Overview d-flex LogoSection">
                        <div className="w-1/4 d-flex">
                            <div className="Logo">
                                <img
                                    src={`data:image/png;base64, ${sourceData.imageData}`}
                                    className="LogoImg"
                                />
                            </div>
                        </div>
                        <div className="w-3/4 pl-25">
                            <div className="Grid gap-20 gmb-10 mb-20 xl:mb-40">
                                <div className="Grid-cell w-1/2">
                                    <span className="tracer-Overview-label">
                                        {tran.GetText('VIN')}
                                    </span>
                                    <div className="tracer-Overview-vin">{sourceData.vin}</div>
                                </div>
                                {(sourceData.controlDigits?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/2">
                                        <span className="tracer-Overview-label tracer-Overview-label--empty">
                                            &nbsp;
                                        </span>
                                        <span className="Check">{sourceData.controlDigits}</span>
                                    </div>
                                )}
                            </div>
                            <div className="Grid gap-20 gmb-10 xl:gmb-30">
                                {(sourceData.make?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Tovární značka')}
                                        </span>
                                        {sourceData.make}
                                    </div>
                                )}
                                {(sourceData.model?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Model')}
                                        </span>
                                        {sourceData.model}
                                    </div>
                                )}
                                {(sourceData.typ?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Typ karoserie')}
                                        </span>
                                        {sourceData.typ}
                                    </div>
                                )}
                                {(sourceData.druh?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Druh vozidla')}
                                        </span>
                                        {sourceData.druh}
                                    </div>
                                )}
                                {(sourceData.fuel?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Palivo')}
                                        </span>
                                        {sourceData.fuel}
                                    </div>
                                )}
                                {(sourceData.kw?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Výkon')}
                                        </span>
                                        {sourceData.kw}
                                    </div>
                                )}
                                {(sourceData.ccm?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Objem')}
                                        </span>
                                        {sourceData.ccm}
                                    </div>
                                )}
                                {(sourceData.gearBox?.length ?? 0) > 0 && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Převodovka')}
                                        </span>
                                        {sourceData.gearBox}
                                    </div>
                                )}
                                {sourceData.productYear && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Datum výroby')}
                                        </span>
                                        {ShowDateManufacturing(
                                            sourceData.showProductDate,
                                            sourceData.productYear,
                                        )}
                                    </div>
                                )}
                                {sourceData.firstRegistration && (
                                    <div className="Grid-cell w-1/3">
                                        <span className="tracer-Overview-label">
                                            {tran.GetText('Datum 1. registrace')}
                                        </span>
                                        {moment(sourceData.firstRegistration).format(
                                            'DD. MM. YYYY',
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            {(sourceData?.warningMessages?.length ?? 0) > 0 && (
                <div className="flex-wrap-center">
                    <div className="row-warning">
                        <span className="Icon Icon--alert IconBaseCarWarMessage"></span>
                        {tran.GetText('VAROVÁNÍ:')}
                        {sourceData.warningMessages?.split('|').map((mess, index) => (
                            <a
                                key={`wrMess_${index}`}
                                className="Link-small Link--warning Link BaseCarWarMessage"
                            >
                                {mess}
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}
