import './index.styl'
import { ReactElement } from 'react'
import { Section } from '../../../../components/Section'
import { Container } from '../../../../components/Container'
import { Review } from '../../../../components/Review'
import { Slider } from '../../../../components/Slider'
import { reviewDefinitions } from '../../../../components/Review/reviewData'
import { Translate, _, __ } from '../../../../translations/CebiaTranslator'

export const SectionSlider = (): ReactElement => {
    const tran = new Translate()
    const titleText = tran.GetText('Zkušenosti zákazníků')
    const data = new reviewDefinitions()

    return (
        <Section className="SectionSlider">
            <Container>
                <Slider
                    className="text-center"
                    variant="review"
                    flickityOptions={{ wrapAround: true }}
                >
                    {data.GetData().map(listValue => {
                        return (
                            <Review
                                key={listValue.id}
                                title={titleText}
                                rating={{ stars: listValue.stars }}
                                text={listValue.text}
                                author={listValue.author}
                            />
                        )
                    })}
                </Slider>
            </Container>
        </Section>
    )
}
