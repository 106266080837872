import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { isPayed } from '../../busLog/payedBL'
import { StickyTypeMessage } from '../../components/AtrOverviewCar'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { PageHeader } from '../../components/PageHeader'
import { useAppContext } from '../../contexts/AppContext'
import { useSignalRConnection } from '../../contexts/SignalR/SignalRHook'
import { SignalRState } from '../../contexts/SignalR/SignalRSetting'
import { CouponType } from '../../enums/CouponType'
import { ReferenceType } from '../../enums/ReferenceType'
import { SignalRUrlType } from '../../enums/SignalRUrlEnum'
import { PayedJsonResult } from '../../models/DetailPayedResults/payedJsonResult'
import { PayedStateModel } from '../../models/payedResponseModel'
import { Translate } from '../../translations/CebiaTranslator'
import { i18n } from '../../translations/i18n'
import { redirectDotazLang } from '../../utils/redirect'
import './index.styl'
import { SectionBanner } from './sections/SectionBanner'
import { SectionHeaderCaption } from './sections/SectionHeaderCaption'
import { SectionMain } from './sections/SectionMain'
import { SectionOverview } from './sections/SectionOverview'
import { SectionRating } from './sections/SectionRating'
import { useCookies } from 'react-cookie'
import { navigateToElement, navigateToFirstElement } from '../../components/AtrTimeLineTable'
import classNames from 'classnames'
import { Loader } from '../../components/Loader'
import { Container } from '../../components/Container'
import { BubbleOpener } from '../../components/BubbleOpener'
import { Modal, useModal } from '../../components/Modal'
import { Title } from '../../components/Title'
import { ButtonList } from '../../components/ButtonList'
import { Button } from '../../components/Button'

export const retNavMenu = (retVal?: PayedStateModel) => {
    return retVal?.couponType === CouponType.Apc ? ReferenceType.PayedReg : ReferenceType.Default
}

const CountdownTimer = ({ couponActivation }: { couponActivation: Date | undefined }) => {
    const [timeLeft, setTimeLeft] = useState<number | null>(null)
    const { open: openCounter, close: closeCounter } = useModal('counterModal')
    const tran = new Translate()
    useEffect(() => {
        if (!couponActivation) return

        const calculateTimeLeft = () => {
            const currentDate = new Date()
            const futureDate = new Date(couponActivation.getTime() + 15 * 60000) // 15 minut = 15 * 60 * 1000 ms
            const difference = futureDate.getTime() - currentDate.getTime()
            return difference > 0 ? difference : 0
        }

        const updateTimer = () => {
            const timeLeft = calculateTimeLeft()
            setTimeLeft(timeLeft)

            if (timeLeft === 0) {
                clearInterval(timer)
                openCounter()
            }
        }

        const timer = setInterval(updateTimer, 1000)

        return () => clearInterval(timer) // Vyčištění intervalu při unmountu komponenty
    }, [couponActivation])

    return (
        <div>
            <Modal id="counterModal">
                <Container>
                    <div className="Grid">
                        <div className="Grid-cell md:w-12/12">
                            <Title as="header" variantAlign="left">
                                <p>
                                    <span className="color-green mr-5">
                                        <b>{tran.GetText('Hotovo!')}</b>
                                    </span>{' '}
                                    {tran.GetText('Výsledek prověření je kompletní.')}
                                </p>
                                <p>
                                    {tran.GetText(
                                        'Všechny dostupné informace o vozidle byly úspěšně načteny.',
                                    )}
                                </p>
                                <div className="lg:w-auto">
                                    <ButtonList visible={true}>
                                        <Button
                                            as="button"
                                            variantColor="blue"
                                            variantSize="small"
                                            onClick={() => {
                                                window.location.reload()
                                                closeCounter()
                                            }}
                                        >
                                            {tran.GetText('Zavřít')}
                                        </Button>
                                    </ButtonList>
                                </div>
                            </Title>
                        </div>
                    </div>
                </Container>
            </Modal>
        </div>
    )
}

export default CountdownTimer

const compareDates = (couponActivation: Date | undefined): boolean => {
    // Pokud není datum zadáno, vraťte false
    if (!couponActivation) return false

    // Získejte aktuální datum a čas
    const currentDate = new Date()

    // Vytvořte kopii data aktivace a přičtěte 15 minut
    const futureDate = new Date(couponActivation.getTime() + 15 * 60000) // 15 minut = 15 * 60 * 1000 ms

    // Porovnejte data a vraťte výsledek
    return futureDate > currentDate
}

export const PageDetailPaid = (): ReactElement => {
    const appContext = useAppContext()
    const { open: openLessData, close: closeLessData } = useModal('lessDataModal')
    const { id } = useParams()
    const [payedBaseModel, setPayedBaseModel] = useState<PayedJsonResult[]>([])
    const [cookies, setCookie, removeCookie] = useCookies(['s_page'])
    const {
        openConnection,
        bufferedData,
        canCloseConnection,
        closeConnection,
        connectionId,
        hubState,
    } = useSignalRConnection({ url: SignalRUrlType.Payed })
    const { retVal, refetch, isFetched } = isPayed(id!, connectionId, ReferenceType.Default)
    const [stickyMessages, setStickyMessages] = useState<StickyTypeMessage[]>([])
    const [dowloadedSections, setDownloadedSections] = useState(0)
    const [canCloseLoader, setCanCloseLoader] = useState(0)
    const tran = new Translate()

    // if (!connectionId) useSignalRConnection({ url: SignalRUrlType.Payed })
    useEffect(() => {
        if (hubState === SignalRState.HubCreated) openConnection()
    }, [hubState])

    useEffect(() => {
        if ((bufferedData?.length ?? 0) > 0) {
            setDownloadedSections(prevState => bufferedData.length)

            bufferedData.forEach(element => {
                const item = element as PayedJsonResult
                if (item != null) {
                    setPayedBaseModel(y => {
                        const newArr = y.filter(z => z.sectionId !== item.sectionId)
                        return [...newArr, ...[item]]
                    })

                    if (item.useStickyMessage) {
                        setStickyMessages(y => {
                            //V hlavičce chybí varování Financované vozidlo, musi se pridavat, ne vytvaret novy pole
                            // problem nastava, kdyz se vrati n-krat stejna subcaption :(
                            // const origArray = y.filter(item => item.sectionId !== item.sectionId)
                            if (!y.find(x => x.text === item.subCaption) && item.sectionId !== 31) {
                                return [...y, { text: item.subCaption, sectionId: item.sectionId! }]
                            } else {
                                return [...y]
                            }
                        })
                    }
                }
            })
        }

        // refetch()
        if (canCloseConnection(bufferedData)) {
            appContext.setLoading(false)
            closeConnection()
            setCanCloseLoader(prevState => prevState + 1)
            setTimeout(() => {
                if (cookies['s_page']) {
                    let element = ''
                    switch (cookies['s_page']) {
                        case 'NabidkaProvin':
                            element = 'proVinToggle'
                            break
                        case 'NabidkaVintest':
                            element = 'vinTestToggle'
                            break
                        case 'NabidkaZabezpeceni':
                            element = 'saleSafetyToggle'
                            break
                    }
                    navigateToElement('#sekce42', undefined)
                    if (element.length > 0) {
                        setTimeout(() => {
                            navigateToFirstElement(element, 'a')
                        }, 1500)
                    }
                }
            }, 1)
        }
    }, [bufferedData])

    useEffect(() => {
        appContext.setLoading(true)
        if (retVal) {
            if (retVal.isValid !== 1) {
                if (retVal.isValid === 2) {
                    window.location.assign(`/carinfo/payment/${id}`)
                } else {
                    window.location.assign('/')
                }
            }
            appContext.storePayedStateModel(retVal)
            appContext.setFirstUse(retVal.firstUse ?? false, (retVal?.apc?.length ?? 0) > 0)
            ///Takto ukoncim spojeni, uz nepotrebuji nic dotahovat.
            appContext.storeClientConnectionId('')
            redirectDotazLang(retVal.countryId)

            if (canCloseLoader > 0) {
                appContext.setLoading(false)
            }
        }
        if (retVal && !retVal?.couponValidity) {
            appContext.setLoading(false)
        }
    }, [retVal])

    console.log('payedBaseModel', payedBaseModel)

    const className = classNames('PageDetailPaid', {
        sample: retVal?.isSample ?? false,
    })
    return (
        <div className={className}>
            <Header
                showLanguage={false}
                stateModel={retVal}
                isDemo={retVal?.isSample ?? false}
                referenceType={retNavMenu(retVal)}
            />
            {canCloseLoader > 0 &&
                payedBaseModel.find(x => x.isAllOk) != null &&
                compareDates(new Date(retVal?.couponActivation ?? '')) && (
                    <Container className="pt-20">
                        <div className="d-flex lessData justify-center">
                            <Loader variant="circleSmall" />
                            <div className="ml-10 mt-10 lessDataInner">
                                <b>
                                    {tran.GetText(
                                        'Probíhá načítání dat – výsledek prověření nemusí být kompletní',
                                    )}
                                </b>
                                <BubbleOpener className="ml-5" onClickHandler={openLessData} />
                            </div>
                            <div>
                                <CountdownTimer
                                    couponActivation={new Date(retVal?.couponActivation ?? '')}
                                />
                            </div>
                        </div>
                        <Modal id="lessDataModal">
                            <Container>
                                <div className="Grid">
                                    <div className="Grid-cell md:w-12/12">
                                        <Title as="header" variantAlign="left">
                                            <p>
                                                <b>
                                                    {tran.GetText(
                                                        'Upozornění: Stále probíhá načítání některých datových zdrojů',
                                                    )}
                                                </b>
                                            </p>
                                            <p>
                                                {tran.GetText(
                                                    'Výsledek prověření nemusí momentálně obsahovat všechny dostupné informace. Načítání dat může trvat až 15 minut.',
                                                )}
                                            </p>
                                            <div className="lg:w-auto">
                                                <ButtonList visible={true}>
                                                    <Button
                                                        as="button"
                                                        variantColor="blue"
                                                        variantSize="small"
                                                        onClick={() => {
                                                            closeLessData()
                                                        }}
                                                    >
                                                        {tran.GetText('Zavřít')}
                                                    </Button>
                                                </ButtonList>
                                            </div>
                                        </Title>
                                    </div>
                                </div>
                            </Container>
                        </Modal>
                    </Container>
                )}

            {retVal && retVal.couponValidity && (
                <>
                    {(retVal?.isSample ?? false) && (
                        <PageHeader>
                            <SectionHeaderCaption
                                isDemo={retVal?.isSample ?? false}
                                referenceType={retNavMenu(retVal)}
                                stateModel={retVal}
                            />
                        </PageHeader>
                    )}

                    <SectionOverview
                        variant="default"
                        model={retVal}
                        payedBaseModel={payedBaseModel}
                        stickyMessages={stickyMessages}
                        showActionButtons={dowloadedSections > 5}
                    />
                    <SectionMain sourceData={payedBaseModel} showLoader={false} />
                </>
            )}
            {isFetched && retVal && retVal.countryId > 0 && !retVal.couponValidity && (
                <>
                    <h2 className="redWarning text-center mt-50">
                        {tran.GetText('Vypršela platnost vašeho kuponu.')}
                    </h2>
                </>
            )}
            {isFetched &&
                retVal &&
                retVal.countryId > 0 &&
                retNavMenu(retVal) === ReferenceType.Default && (
                    <>
                        {retVal.dotazProductId > 3 && i18n.language?.toLowerCase() === 'cz' && (
                            <SectionRating model={retVal}></SectionRating>
                        )}
                        {!['al', 'cz'].includes(i18n.language?.toLowerCase()) && (
                            <SectionRating model={retVal}></SectionRating>
                        )}
                    </>
                )}
            {i18n.language?.toLowerCase() === 'cz' && <SectionBanner />}
            <Footer />
        </div>
    )
}
