import './index.styl'
import { ReactElement } from 'react'
import { ToggleOpener, ToggleOpenerProps } from '../ToggleOpener'

type Props = ToggleOpenerProps

export const ToggleOpenerFaq = ({ href, children, blurInActive }: Props): ReactElement => {
    return (
        <ToggleOpener
            className="ToggleOpenerFaq"
            blurInActive={blurInActive}
            href={href}
            isOpened={false}
        >
            {children}
        </ToggleOpener>
    )
}
