import './index.styl'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { ArticleCard, ArticleCardProps } from '../../components/ArticleCard'
import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { Shapes } from '../../components/Shapes'
import { BannerCarolina } from '../../components/BannerCarolina'
import { Button } from '../../components/Button'
import { SectionControls } from '../../components/SectionControls'
import { PageShape } from '../../components/PageShape'
import { useQuery } from 'react-query'
import { i18n } from '../../translations/i18n'
import { LoadArea } from '../../components/LoadArea'
import { Translate, _ } from '../../translations/CebiaTranslator'
import http from '../../busLog/http-common'
import { RssChannel } from '../../models/RssChannel'
import { TitleMetaSection } from '../../components/TitleMeta'
import { ReferenceType } from '../../enums/ReferenceType'

export const PageArticle = (): ReactElement => {
    const [allArticles, setArticleCards] = useState<ArticleCardProps[]>([])
    const [count, setCount] = useState(10)
    const [language] = useState(i18n.language)
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null) // New state for category filter
    const tran = new Translate()
    const fetchRssFeed = () => {
        const res = http.get<RssChannel>(
            `/Blog/GetRssFeed?language=${language === 'cz' ? '' : language}`,
        )
        return res
    }

    const { data, isLoading, isFetching } = useQuery(`getRssFeed_${language}`, fetchRssFeed, {
        cacheTime: 300000,
        refetchOnWindowFocus: false,
    })
    useEffect(() => {
        if (data?.data && allArticles.length == 0) {
            const articles = new Array<ArticleCardProps>()
            data.data.rssItems.forEach(item => {
                articles.push({
                    hash: item.hash,
                    date: new Date(item.pubDate),
                    href: item.link,
                    title: item.title,
                    perex: item.description,
                    category: item.category,
                    urlLink: item.link,
                    image: {
                        src: item.rssImageContent.url,
                        alt: item.rssImageContent.medium,
                        srcSet: item.rssImageContent.url,
                    },
                    attachments: item.attachments,
                })
            })
            setArticleCards(articles)
        }
    })

    useEffect(() => {
        const hash = window.location.hash.replace('#', '')
        if (hash) {
            setSelectedCategory(hash)
        }
    }, [])

    const filteredArticles = selectedCategory
        ? allArticles.filter(article => article.category === selectedCategory)
        : allArticles

    const handleCategoryClick = (category: string | null) => {
        setSelectedCategory(category)
    }
    console.log('allArticles', allArticles)
    if (language === 'cz') {
        window.location.href = '/'
    }
    return (
        <div className="PageArticle">
            <PageShape sectionShape={{ variant: 'radialLeftTop' }} />

            <Header showLanguage={false} referenceType={ReferenceType.Default} />
            {isLoading || isFetching || filteredArticles.length === 0 ? (
                <LoadArea
                    isLoading={isLoading}
                    hideBackGround={true}
                    variant="fullscreen"
                    loader={{ variant: 'bars', size: 'big' }}
                    text={tran.GetText('Načítáme data...')}
                ></LoadArea>
            ) : (
                <>
                    {filteredArticles && filteredArticles.length > 0 && (
                        <Section>
                            <Container>
                                <Shapes>
                                    <ArticleCard {...filteredArticles[0]} variant="large" />
                                </Shapes>
                            </Container>
                        </Section>
                    )}
                    <Section variantPend="appended">
                        <Container>
                            <div className="article-Categories">
                                <h2 className="article-Categories-title Gama pb-20">
                                    {tran.GetText('Vybrat kategorii')}
                                </h2>
                                <ul className="article-Categories-list">
                                    <li className="article-Categories-item">
                                        <a
                                            href="#"
                                            className={`article-Categories-link ${
                                                !selectedCategory ? 'is-active' : ''
                                            }`}
                                            onClick={() => handleCategoryClick(null)}
                                        >
                                            {tran.GetText('Všechny')}
                                        </a>
                                    </li>
                                    <li className="article-Categories-item">
                                        <a
                                            href="#check"
                                            className={`article-Categories-link ${
                                                selectedCategory === 'check' ? 'is-active' : ''
                                            }`}
                                            onClick={() => handleCategoryClick('check')}
                                        >
                                            {tran.GetText('Prověřování')}
                                        </a>
                                    </li>
                                    <li className="article-Categories-item">
                                        <a
                                            href="#tips"
                                            className={`article-Categories-link ${
                                                selectedCategory === 'tips' ? 'is-active' : ''
                                            }`}
                                            onClick={() => handleCategoryClick('tips')}
                                        >
                                            {tran.GetText('Rady a tipy')}
                                        </a>
                                    </li>
                                    <li className="article-Categories-item">
                                        <a
                                            href="#stats"
                                            className={`article-Categories-link ${
                                                selectedCategory === 'stats' ? 'is-active' : ''
                                            }`}
                                            onClick={() => handleCategoryClick('stats')}
                                        >
                                            {tran.GetText('Trh s automobily')}
                                        </a>
                                    </li>
                                    {(language === 'sk' || language === 'ro') && (
                                        <li className="article-Categories-item">
                                            <a
                                                href="#pr"
                                                className={`article-Categories-link ${
                                                    selectedCategory === 'pr' ? 'is-active' : ''
                                                }`}
                                                onClick={() => handleCategoryClick('pr')}
                                            >
                                                {tran.GetText('Tiskové zprávy')}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Container>
                    </Section>
                    <Section variantPend="appended">
                        <Container>
                            <div className="Grid smw:gap-20 md:gap-40 gmb-30 md:gmb-50 lg:gmb-80">
                                {filteredArticles.slice(1, 0 + count).flatMap((article, index) => [
                                    <div key={index} className="Grid-cell smw:w-1/2 lg:w-1/3">
                                        <ArticleCard {...article} />
                                    </div>,
                                ])}
                            </div>
                            {count < filteredArticles.length && (
                                <SectionControls>
                                    <Button
                                        as="a"
                                        href="#todo"
                                        icon="arrowDown"
                                        onClick={() => {
                                            setCount(count + 6)
                                        }}
                                    >
                                        {tran.GetText('Načíst další')}
                                    </Button>
                                </SectionControls>
                            )}
                        </Container>
                    </Section>
                </>
            )}
            <Footer />
        </div>
    )
}
