import './index.styl'
import moment from 'moment'
import { ReactElement } from 'react'
import { Table } from '../../../../components/Table'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate, _ } from '../../../../translations/CebiaTranslator'
import { ToggleOpenerGeneralSimple } from '../../../../components/ToggleOpenerGeneralSimple'
import { CountryCode } from '../../../../components/AtrMileageTable/countryCodes'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import { LoadArea } from '../../../../components/LoadArea'
import { CheckLeaseItem } from '../../../../models/DetailPayedResults/CheckLeas/checkLeaseControlModel'
import { i18n, LanguageEnum } from '../../../../translations/i18n'

export const SectionLeasing = ({ sourceData, isPrint }: DetailPayedProps): ReactElement => {
    if (!sourceData?.checkLeaseControlResult) return <></>
    const tran = new Translate()
    const allowedLanguages = ['cz', 'sk', 'en', 'de']
    if (sourceData?.checkLeaseControlResult?.isLeasing) allowedLanguages.push(i18n.language)
    const printTableSection = (item: CheckLeaseItem, index: number) => {
        if (isPrint)
            return (
                <div className="pbAllways">
                    <div className="Table text-noWrap mb-20 md:mb-50 half">
                        <table className="Table-table">
                            <thead>
                                <tr>
                                    <th className="text-left">{tran.GetText('Země')}</th>
                                    <th className="text-left">{`${tran.GetText('Ke dni:')} ${moment(
                                        item.checkDate,
                                    ).format('DD. MM. YYYY')}`}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={item.isLeasing ? 'warning' : ''}>
                                        {item.countryName}
                                    </td>
                                    <td className={item.isLeasing ? 'warning' : ''}>
                                        {item.isLeasing
                                            ? `${tran.GetText('ANO')} - ${
                                                  item.companyLeasingCheck?.find(x => x.value == 1)
                                                      ?.key
                                              }`
                                            : tran.GetText('NE - není evidováno')}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {i18n.language !== LanguageEnum.ro &&
                        i18n.language != LanguageEnum.en &&
                        i18n.language != LanguageEnum.de && (
                            <Table>
                                <thead>
                                    <tr>
                                        <th className="text-left">
                                            {tran.GetText('Leasingové a úvěrové společnosti')}
                                        </th>
                                        <th className="text-left">
                                            {moment(item.checkDate).format('DD. MM. YYYY')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.companyLeasingCheck?.map((companyItem, companyIndex) => (
                                        <tr key={companyIndex}>
                                            <td className={companyItem.value == 1 ? 'warning' : ''}>
                                                {tran.GetText(companyItem.key)}
                                            </td>
                                            <td className={companyItem.value == 1 ? 'warning' : ''}>
                                                {companyItem.value == 1
                                                    ? tran.GetText('ANO')
                                                    : companyItem.value == -1
                                                    ? tran.GetText('Neověřeno')
                                                    : tran.GetText('NE')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                </div>
            )
        return (
            <>
                <ToggleOpenerGeneralSimple
                    href={`#togleChecLeas${index}`}
                    countryCode={item.countryFlagName as CountryCode}
                    titleLeft={item.countryName}
                    titleRight={
                        item.isLeasing
                            ? tran.GetText('Financované vozidlo')
                            : tran.GetText('Bez financování')
                    }
                    showAlertTitleRight={item.isLeasing}
                    isOpened={false}
                />
                <ToggleItemGeneral id={`togleChecLeas${index}`}>
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-left">
                                    {tran.GetText('Leasingové a úvěrové společnosti')}
                                </th>
                                <th className="text-left">
                                    {moment(item.checkDate).format('DD. MM. YYYY')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.companyLeasingCheck?.map((companyItem, companyIndex) => (
                                <tr key={companyIndex}>
                                    <td className={companyItem.value == 1 ? 'warning' : ''}>
                                        {tran.GetText(companyItem.key)}
                                    </td>
                                    <td className={companyItem.value == 1 ? 'warning' : ''}>
                                        {companyItem.value == 1
                                            ? tran.GetText('ANO')
                                            : companyItem.value == -1
                                            ? tran.GetText('Neověřeno')
                                            : tran.GetText('NE')}
                                    </td>
                                </tr>
                            ))}
                            {/* {i18n.language !== 'cz' && (
                                <tr>
                                    <td className={item.isLeasing ? 'warning' : ''}>
                                        {item.countryName}
                                    </td>
                                    <td className={item.isLeasing ? 'warning' : ''}>
                                        {item.isLeasing
                                            ? `${tran.GetText('ANO')} - ${
                                                  item.companyLeasingCheck?.find(x => x.value == 1)
                                                      ?.key
                                              }`
                                            : tran.GetText('NE - není evidováno')}
                                    </td>
                                </tr>
                            )} */}
                        </tbody>
                    </Table>

                    {allowedLanguages.findIndex(x => x === i18n.language) > -1 &&
                        item.countryFlagName == 'cz' && (
                            <div>
                                <p>
                                    {!isPrint &&
                                        tran.GetText(
                                            '*Carsharing = krátkodobý pronájem auta přímo od majitele (nebo sdílení auta mezi jeho majitelem a zájemcem o krátkodobou zápůjčku).',
                                        )}
                                </p>
                            </div>
                        )}
                </ToggleItemGeneral>
            </>
        )
    }

    return (
        <>
            <div className="sectionCheckLeas">
                <div className="text-center pb-25">
                    {!isPrint && <span>{tran.GetText('Výsledek kontroly')}</span>}
                    {sourceData?.checkLeaseControlResult?.isLeasing ?? false ? (
                        <h2 className="warning font-bold">{tran.GetText('Vůz je financován')}</h2>
                    ) : (
                        <>
                            <h2 className="noLeasText font-bold">
                                {tran.GetText('Financování nenalezeno')}
                            </h2>
                        </>
                    )}

                    {sourceData?.checkLeaseControlResult && (
                        <p className="pt-15 smallInfoText text-justify">
                            {tran.GetText(
                                'Výsledkem dotazu je zjištění, zda vozidlo není předmětem financování (formou leasingu nebo úvěru), zástavy a zápůjčkou u uvedených společností. Data získáváme přímo z informačních systémů jednotlivých společností.',
                            )}
                        </p>
                    )}
                </div>
                <div className="mb-30">
                    {(sourceData?.checkLeaseControlResult!.checkLeaseItems?.length ?? 0) > 0 &&
                        sourceData?.checkLeaseControlResult!.checkLeaseItems?.map((item, index) => (
                            <div key={index}>{printTableSection(item, index)}</div>
                        ))}
                </div>
            </div>
            <LoadArea
                hideBackGround={true}
                position="inherit"
                loader={{ variant: 'bars', size: 'big' }}
                align="center"
                isLoading={!sourceData?.checkLeaseControlResult}
            />
        </>
    )
}
