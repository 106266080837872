import './index.styl'
import { ReactElement } from 'react'
import { ToggleItem, ToggleItemProps } from '../ToggleItem'
import classNames from 'classnames'

type Props = ToggleItemProps & {
    static?: boolean
    grid?: boolean
}

export const ToggleItemGeneral = ({ children, grid, ...props }: Props): ReactElement => {
    const className = classNames('ToggleItemGeneral-inner', {
        [`Grid gap-20 gmb-20`]: grid ?? false,
    })
    return (
        <ToggleItem className="ToggleItemGeneral" {...props}>
            <div className={className}>{children}</div>
        </ToggleItem>
    )
}
