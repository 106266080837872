import { useFormikContext } from 'formik'
import { useEffect } from 'react'
//import { useLocation } from 'react-router-dom'
import * as router from 'react-router-dom'

type ScrollToTopState = Readonly<{ scrollToTop?: boolean }> | undefined

function useLocation<T>() {
    type L = router.Location & { state: T }

    return router.useLocation() as L
}

export function ScrollToTop() {
    const { pathname, state } = useLocation<ScrollToTopState>()
    useEffect(() => {
        if (state?.scrollToTop === false) return
        if (location.hash.length ?? 0 > 0) return

        window.scrollTo(0, 0)
    }, [pathname, state])

    return <></>
}

export function ScrollToError({ scrollToFormElement }: any) {
    const { errors, isSubmitting, isValidating } = useFormikContext()

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            const keys = Object.keys(errors)

            if (keys.length > 0) {
                let tmpKey = keys[0]

                if (scrollToFormElement && scrollToFormElement.length > 0) {
                    tmpKey = scrollToFormElement
                }

                const selector = `[name=${tmpKey}]`
                const errorElement = document.querySelector(selector) as HTMLElement
                if (errorElement) {
                    errorElement.focus()
                }
            }
        }
    }, [errors, isSubmitting, isValidating])

    return <></>
}
