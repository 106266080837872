import './index.styl'
import { ReactElement } from 'react'
import classNames from 'classnames'
import { useAtrNav } from '../../hooks/useAtrNav'

export const AtrNavOpener = (): ReactElement => {
    const { atrNavState, atrNavActions } = useAtrNav()

    const className = classNames('AtrNavOpener', {
        'is-active': atrNavState.active,
    })

    return (
        <button
            className={className}
            onClick={() => atrNavActions.setActive(!atrNavState.active)}
        ></button>
    )
}
