import './index.styl'

import React, { ReactElement, useState } from 'react'
import * as Yup from 'yup'
import { Translate } from '../../../translations/CebiaTranslator'
import { Modal } from '../../../components/Modal'
import { Container } from '../../../components/Container'
import { Title } from '../../../components/Title'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'
import ContactValuesModel from '../../../models/ContactValuesModel'
import { FormItem } from '../../../components/FormItem'
import { Input, InputChangeHandler } from '../../../components/Input'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { sendCustomerContact } from '../../../busLog/contactFormBL'
import { i18n } from '../../../translations/i18n'
import { RadioButton } from '../../../components/RadioButton'
import { ContactEmailService } from '../../../enums/ContactEmailService'
import { EmailRegex } from '../../../utils/validators'

type Props = {
    isBusiness?: boolean
}

export const ContactForm = ({ isBusiness }: Props): ReactElement => {
    const tran = new Translate()
    const [contactVal, setContactVal] = useState(new ContactValuesModel(isBusiness))
    const [prevVal, setPrevVal] = useState('')
    const [prevValPhone, setPrevValPhone] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [sendedMessage, setSendedMessage] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const phoneRegExp = /^(\+){0,1}(?:[0-9] ?){6,14}[0-9]$/
    contactVal.language = i18n.language
    const id = isBusiness ?? false ? 'contactFormModalBusiness' : 'contactFormModal'
    const validationSchema = () => {
        return Yup.object({
            isBusiness: Yup.boolean(),
            cGdpr: Yup.boolean().oneOf([true], tran.GetText('Musíte odsouhlasit zadané údaje.')),
            firstName: Yup.string()
                .required(tran.GetText('Prosím zadejte položku Jméno'))
                .max(64, tran.GetText('Maximální počet znaků je 64.')),
            lastName: Yup.string()
                .required(tran.GetText('Prosím zadejte položku Příjmení'))
                .max(64, tran.GetText('Maximální počet znaků je 64.')),
            phone: Yup.string()
                .matches(phoneRegExp, tran.GetText('Nesprávna hodnota Telefon'))
                .required(tran.GetText('Prosím zadejte položku Telefon'))
                .max(14, tran.GetText('Maximální počet znaků je 14.')),
            email: Yup.string()
                .matches(EmailRegex, tran.GetText('Email nemá správný formát'))
                .email(tran.GetText('Email nemá správný formát'))
                .required(tran.GetText('Prosím zadejte položku Email'))
                .max(64, tran.GetText('Maximální počet znaků je 64.')),
            coupon: Yup.string()
                .max(10, tran.GetText('Počet znaků musí být 10.'))
                .matches(/^(\d+){10}$/, tran.GetText('Počet znaků musí být 10.')),
            message: Yup.string().when('isBusiness', (isBusiness, schema) => {
                if (!isBusiness)
                    return schema.required(tran.GetText('Prosím zadejte položku Zpráva'))
                return schema
            }),
        })
    }

    const handleInputChange: InputChangeHandler = (value, event) => {
        const re = /^(\d+){0,10}$/
        if (!value || re.test(value ?? '')) {
            setPrevVal(prevVal => value ?? '')
            event.currentTarget.value = value ?? ''
            const model = contactVal
            model.coupon = value?.toString() ?? ''
            setContactVal(model)
        } else event.currentTarget.value = prevVal ?? ''
    }

    const handlePhoneChange: InputChangeHandler = (value, event) => {
        const re = /^(\+){0,1}(\d+){0,14}$/
        if (!value || re.test(value ?? '')) {
            setPrevValPhone(prevValPhone => value ?? '')
            event.currentTarget.value = value ?? ''
            const model = contactVal
            model.phone = value?.toString() ?? ''
            setContactVal(model)
        } else event.currentTarget.value = prevValPhone ?? ''
    }
    function onClick(e: React.ChangeEvent<HTMLInputElement>) {
        const selectedService = parseInt(e.target.value, 10)
        setContactVal(prevVal => {
            return { ...prevVal, service: selectedService }
        })
    }

    return (
        <Modal id={id} className="ContactForm">
            <Container>
                <Title as="header" variantAlign="center">
                    <h1 className="color-darkBlue">
                        {' '}
                        {contactVal.isBusiness
                            ? tran.GetText('Pro podnikatele')
                            : tran.GetText('Kontaktní formulář')}{' '}
                    </h1>
                </Title>
                {sendedMessage ? (
                    <>
                        <h2 className="color-green mb-10">{tran.GetText('Děkujeme')}</h2>
                        <p className="color-darkBlue mb-0">
                            {tran.GetText('Vaše zpráva byla úspěšně odeslána.')}
                        </p>
                        <p className="color-darkBlue">{tran.GetText('Brzy se vám ozveme.')}</p>
                    </>
                ) : (
                    <Formik
                        initialValues={contactVal}
                        enableReinitialize={true}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={validationSchema}
                        onSubmit={values => {
                            setDisabled(true)
                            sendCustomerContact(values).then(x => {
                                if (x) setSendedMessage(true)
                                else {
                                    setDisabled(false)
                                    setErrorMessage(tran.GetText('Chyba uložení. Opakujte akci.'))
                                }
                            })
                        }}
                    >
                        <Form>
                            <div className="Grid gap-30 gmb-20">
                                {i18n.language === 'sk' && !contactVal.isBusiness && (
                                    <FormItem className="FormItem smw:w-full md:w-full lg:w-full">
                                        <label className="Label">
                                            {tran.GetText('Dotaz se týká služby')}
                                        </label>
                                        <RadioButton
                                            id={'service_1'}
                                            name={'service'}
                                            onClick={onClick}
                                            placeholder={tran.GetText('Car History Report')}
                                            value={ContactEmailService.CARHISTORY}
                                        ></RadioButton>
                                        <RadioButton
                                            id={'service_2'}
                                            name={'service'}
                                            onClick={onClick}
                                            placeholder={tran.GetText('Cebia Foto / VINFOTO')}
                                            value={ContactEmailService.VINFOTO}
                                        ></RadioButton>
                                    </FormItem>
                                )}
                                {contactVal.isBusiness && (
                                    <FormItem className="FormItem smw:w-full md:w-full lg:w-full">
                                        <label className="Label">
                                            {tran.GetHtml(
                                                'Chcete prověřit více vozidel za nižší cenu? Získejte přístup pro podnikatele a <b>ušetřete až 50 %</b> z ceny prověření. Sdělte nám prosím vaše kontaktní údaje a my se vám ozveme s nabídkou na míru.',
                                            )}
                                        </label>
                                    </FormItem>
                                )}
                                <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        label={tran.GetText('Jméno')}
                                        placeholder={tran.GetText('Jméno')}
                                        onChange={val => {
                                            const model = contactVal
                                            model.firstName = val?.toString() ?? ''
                                            setContactVal(model)
                                        }}
                                    />
                                </FormItem>
                                <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        label={tran.GetText('Příjmení')}
                                        placeholder={tran.GetText('Příjmení')}
                                        onChange={val => {
                                            const model = contactVal
                                            model.lastName = val?.toString() ?? ''
                                            setContactVal(model)
                                        }}
                                    />
                                </FormItem>

                                <FormItem className="FormItem smw:w-full md:w-full lg:w-full">
                                    <Input
                                        id="email"
                                        name="email"
                                        type="text"
                                        label={tran.GetText('E-mail')}
                                        placeholder={tran.GetText('E-mail')}
                                        onChange={val => {
                                            const model = contactVal
                                            model.email = val?.toString() ?? ''
                                            setContactVal(model)
                                        }}
                                    />
                                </FormItem>
                                <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                    <Input
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        inputmode="numeric"
                                        onChange={handlePhoneChange}
                                        label={tran.GetText('Telefon')}
                                        placeholder={tran.GetText('Telefon')}
                                    />
                                </FormItem>
                                {contactVal.isBusiness && (
                                    <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                        <Input
                                            id="ico"
                                            name="ico"
                                            maxLength={50}
                                            type="text"
                                            label={tran.GetText('IČ')}
                                            placeholder={tran.GetText('Identifikační číslo firmy')}
                                        />
                                    </FormItem>
                                )}
                                {!contactVal.isBusiness && (
                                    <>
                                        <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                            <Input
                                                id="coupon"
                                                name="coupon"
                                                inputmode="numeric"
                                                onChange={handleInputChange}
                                                type="text"
                                                label={
                                                    contactVal.service ==
                                                    ContactEmailService.CARHISTORY
                                                        ? tran.GetText(
                                                              'Číslo kuponu (vyplňte v případě reklamace)',
                                                          )
                                                        : tran.GetText(
                                                              'Číslo smlouvy (je-li k dispozici)',
                                                          )
                                                }
                                                placeholder={
                                                    contactVal.service ==
                                                    ContactEmailService.CARHISTORY
                                                        ? tran.GetText('Číslo kuponu')
                                                        : tran.GetText('Číslo smlouvy')
                                                }
                                            />
                                        </FormItem>
                                    </>
                                )}
                                <FormItem className="FormItem smw:w-full md:w-full lg:w-full">
                                    <Input
                                        id="message"
                                        name="message"
                                        type="text"
                                        component="textarea"
                                        label={tran.GetText('Zpráva')}
                                        placeholder={tran.GetText('Zpráva')}
                                        className="Input--textarea"
                                        onChange={val => {
                                            const model = contactVal
                                            model.message = val?.toString() ?? ''
                                            setContactVal(model)
                                        }}
                                    />
                                </FormItem>

                                <div className="smw:w-6/12 md:w-full lg:w-6/12">
                                    <FormItem className="NewsletterForm-formItem NewsletterForm-formItem--checkbox">
                                        <Checkbox
                                            variant="green"
                                            aria-label={tran.GetText(
                                                'Souhlas se zpracováním osobních údajů',
                                            )}
                                            name="cGdpr"
                                            id="cGdpr"
                                        >
                                            <label htmlFor="cGdpr">
                                                {tran.GetText('Souhlasím se zpracováním')}
                                            </label>{' '}
                                            {tran.GetHtml(
                                                '<a rel="noreferrer noopener" target="_blank" className="Link" href="https://www.cebia.cz/pravni-informace/osobni-udaje">osobních údajů</a>',
                                            )}
                                        </Checkbox>
                                        <ErrorMessage
                                            name="cGdpr"
                                            component="div"
                                            className="InputError"
                                        />
                                    </FormItem>
                                </div>
                                <div className="FormItem FormItem--submit smw:w-6/12 md:w-full lg:w-6/12">
                                    <Button
                                        className="Button--big"
                                        variantColor="green"
                                        as="button"
                                        type="submit"
                                        disabled={disabled}
                                    >
                                        {tran.GetText('Odeslat zprávu')}
                                    </Button>
                                </div>
                            </div>
                            {errorMessage?.length > 0 && (
                                <p className="InputError">
                                    <span className=" Icon Icon--alert AlertIcon"></span>{' '}
                                    {errorMessage}
                                </p>
                            )}
                        </Form>
                    </Formik>
                )}
            </Container>
        </Modal>
    )
}
