/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { LoadArea } from '../components/LoadArea'
import { SignalRUrlType } from '../enums/SignalRUrlEnum'
import { PageComponent } from '../layouts/example/Pages'
import { AppModel } from '../models/appModel'
import { DashBoardJsonResult } from '../models/dashBoardJsonResult'
import { UTMModel } from '../models/utmModel'
import { ValidationResponseModel } from '../models/validationResponseModel'
import { Translate } from '../translations/CebiaTranslator'
import { SignalRProvider } from './SignalR/SignalRProvider'
import { PayedStateModel } from '../models/payedResponseModel'

const defaultState: IAtrContextState = {
    appModel: new AppModel(),
    payedStateModel: new PayedStateModel(),
    storePayedStateModel: (value: PayedStateModel) => {},
    storeAppModel: (value: AppModel, callHandlers: boolean) => {},
    setVinError: (value: string) => {},
    clearAppModel: () => {},
    storeClientConnectionId: (value: string) => {},
    eHandler: (valfunc: () => void) => {},
    storeAppModelHandler: (valfunc: () => void) => {},
    setLoading: (value: boolean) => {},
    setFirstUse: (value: boolean) => {},
}

export interface IAtrContextState {
    appModel: AppModel
    payedStateModel: PayedStateModel
    storeAppModel: (value: AppModel, callHandlers: boolean) => void
    storePayedStateModel: (value: PayedStateModel) => void
    setVinError: (value: string) => void
    clearAppModel: () => void
    storeClientConnectionId: (value: string) => void
    eHandler: (valfunc: () => void) => void
    storeAppModelHandler: (valfunc: () => void) => void
    setLoading: (value: boolean) => void
    setFirstUse: (value: boolean, isApc: boolean) => void
}

const AppContext = React.createContext<IAtrContextState>(defaultState)

type View = {
    page: PageComponent
}

type AppProviderProps = {
    children: React.ReactNode
}

export const useAppContext = () => useContext(AppContext)

const AppProvider = ({ children }: AppProviderProps) => {
    const tran = new Translate()
    const [appModel, setAppModel] = useState<AppModel>(new AppModel())
    const [payedStateModel, setPayedStateModel] = useState<PayedStateModel>(new PayedStateModel())
    const [isLoading, setIsLoading] = useState(false)
    const [isPayed, setIsPayed] = useState(false)
    const [firstUse, setIsFirstUse] = useState(false)
    const [isApc, setIsApc] = useState(false)
    //const [timerUp, setTimerUp] = useState(false)
    const queryClient = new QueryClient()
    let eHandler: () => void
    let storeAppModelHandler: Array<() => void>

    const timer = useRef<ReturnType<typeof setTimeout>>()
    const [isReportReady, setIsReportReady] = useState(false)

    useEffect(() => {
        setIsPayed(() => window.location.href.toLowerCase().indexOf('/payed/detail/', 0) >= 0)
        timer.current = setTimeout(() => {
            setIsReportReady(true)
        }, 25000)
    })

    const storeAppModel = useCallback((value: AppModel, callHandlers: boolean) => {
        setAppModel(value)
        if (callHandlers && storeAppModelHandler && storeAppModelHandler.length > 0) {
            storeAppModelHandler.forEach(func => {
                func()
            })
        }
    }, [])

    const storePayedStateModel = useCallback((value: PayedStateModel) => {
        setPayedStateModel(value)
    }, [])
    const clearAppModel = useCallback(() => {
        storeAppModelHandler = []
        const oldModel = appModel
        const clearModel = new AppModel()
        clearModel.utmModel = new UTMModel()
        clearModel.connectionId = appModel.connectionId
        clearModel.damageDashBoardInfo = new DashBoardJsonResult()
        clearModel.odometerDashBoardInfo = new DashBoardJsonResult()
        clearModel.photoDashboardInfo = new DashBoardJsonResult()
        clearModel.priceDashboardInfo = new DashBoardJsonResult()
        clearModel.serviceHistoryDashboardInfo = new DashBoardJsonResult()
        clearModel.validateResponseModel = new ValidationResponseModel(null)
        clearModel.allLoaded = false
        storeAppModel(clearModel, false)
    }, [])

    const setFirstUse = useCallback((value: boolean, isApc: boolean) => {
        setIsFirstUse(value)
        setIsApc(isApc)
        if (!isApc) {
            setIsReportReady(false)
            setTimeout(() => {
                setIsReportReady(true)
                setIsApc(true)
                setIsFirstUse(false)
            }, 27000)
        }
    }, [])

    const setLoading = useCallback((value: boolean) => {
        if (
            window.location.href.toLowerCase().indexOf('/payed/detail/', 0) >= 0 &&
            value == false
        ) {
            setIsReportReady(true)
            setTimeout(() => {
                setIsLoading(value)
            }, 1200)
        } else setIsLoading(value)

        // if (value) {
        //     if (!timerUp) {
        //         setTimeout(
        //             () => {
        //                 setIsLoading(false)
        //             },
        //             process.env.NODE_ENV === Environment.devel ? 10000 : 30000,
        //         )
        //     }
        //     setTimerUp(true)
        // } else {
        //     setTimerUp(false)
        // }
    }, [])

    const setVinError = useCallback((error: string) => {
        appModel.vinError = error
        setAppModel(appModel)
    }, [])

    const storeConnectionId = useCallback((value: string) => {
        appModel.connectionId = value
        if (eHandler) {
            eHandler()
        }
    }, [])

    const callBackHandler = useCallback((value: () => void) => {
        eHandler = value
    }, [])

    const callBackAppHandler = useCallback((valfunc: () => void) => {
        if (!storeAppModelHandler) storeAppModelHandler = []
        storeAppModelHandler.push(valfunc)
    }, [])

    return (
        <AppContext.Provider
            value={{
                appModel,
                payedStateModel,
                storeAppModel: (value: AppModel, callHandlers: boolean) =>
                    storeAppModel(value, callHandlers),
                storePayedStateModel: (value: PayedStateModel) => storePayedStateModel(value),
                clearAppModel: () => clearAppModel(),
                setVinError: (error: string) => setVinError(error),
                storeClientConnectionId: (value: string) => storeConnectionId(value),
                eHandler: (valfunc: any) => callBackHandler(valfunc),
                storeAppModelHandler: (valfunc: any) => callBackAppHandler(valfunc),
                setLoading: (value: boolean) => setLoading(value),
                setFirstUse: (value: boolean, isApc: boolean) => setFirstUse(value, isApc),
            }}
        >
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                <SignalRProvider urls={[SignalRUrlType.DashBoard, SignalRUrlType.Payed]}>
                    <LoadArea
                        isLoading={isLoading}
                        hideBackGround={true}
                        variant="fullscreen"
                        loader={{ variant: 'bars', size: 'big' }}
                        text={tran.GetText('Načítáme data...')}
                        loaderReport={
                            isPayed && firstUse
                                ? {
                                      isFastFinish: isReportReady && isApc,
                                  }
                                : undefined
                        }
                    ></LoadArea>
                    {children}
                </SignalRProvider>
            </QueryClientProvider>
        </AppContext.Provider>
    )
}
export { AppContext, AppProvider }
