import './index.styl'
import { ReactElement, ReactNode } from 'react'

type Props = {
    children: ReactNode
    visible: boolean
}

export const ButtonList = ({ children, visible }: Props): ReactElement => {
    return <div className="ButtonList">{visible && children}</div>
}
