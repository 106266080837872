import './index.styl'
import { ReactElement } from 'react'
import classNames, { Argument } from 'classnames'

type Props = {
    className: Argument
    onClickHandler?: () => void
}

export const BubbleOpener = ({ className: _className, onClickHandler }: Props): ReactElement => {
    const className = classNames('BubbleOpener', _className)

    const questionClickHandler = () => {
        if (onClickHandler) onClickHandler()
    }

    return (
        <button className={className} type="button" onClick={questionClickHandler} tabIndex={-1}>
            <span className="Icon Icon--questionmark" aria-hidden="true"></span>
        </button>
    )
}
