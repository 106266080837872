import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionTimeLine } from '../../../../detailPaid/atr-items/SectionTimeline'
import './body.styl'

let PrintAllowIncrement = true

export const TimeLine = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    const timeLineData = new PayedJsonResult()
    timeLineData.timeLineControlResult = sourceData!.timeLineControlResult
    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Přehled historie')}
            </h2>

            <Section className="TimeLineSection">
                <SectionTimeLine sourceData={timeLineData} isPrint={true} />
            </Section>
        </div>
    )
}
