import { Coords, TimestampRange } from '../../../components/Chart/getHightchartsOptions'
import {
    AtrMileageTable,
    AtrMileageTableRow,
    AtrMileageTableNote,
} from '../../../components/AtrMileageTable'
import { CountryCode } from '../../../components/AtrMileageTable/countryCodes'
import { isTemplateTail } from 'typescript'

export class GraphControlData {
    odometerValues: { key: number; value: number }[] | undefined
    unverifiedValues: { key: number; value: number }[] | undefined
    descItems: GraphDescriptionItem[] | undefined
    maxValue: number | undefined
}

class GraphDescriptionItem implements AtrMileageTableRow {
    id = 0
    countryFlag: CountryCode = 'cz'
    countryName = ''
    countryNameShort = ''
    dateTicks = 0
    mileAge = 0
    mileAgeUnit = ''
    note: GraphNoteItem = new GraphNoteItem()
}

class GraphNoteItem implements AtrMileageTableNote {
    title = ''
    variant: 'alert' | undefined
    text = ''
}

export function getCoordsFrom(sourceData: GraphControlData | undefined): Coords[] {
    let retVal = Array<Coords>()

    if ((sourceData?.odometerValues?.length ?? 0) > 0) {
        retVal = sourceData!
            .odometerValues!.map(item => [item.key, item.value] as Coords)
            .filter(x => x[1] >= 0)
    }

    return retVal
}

export function getCoordsFromUnverified(
    sourceData: GraphControlData | undefined,
): TimestampRange[] {
    let retVal = Array<TimestampRange>()

    if ((sourceData?.unverifiedValues?.length ?? 0) > 0) {
        retVal = sourceData!
            .unverifiedValues!.map(item => [item.key, item.value] as TimestampRange)
            .filter(x => x[1] >= 0)
    }

    return retVal
}
