import './index.styl'
import { ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'

type Props = {
    variant?: 'small'
    onlyDesktop?: boolean
    mobileBox?: boolean
    rounded?: boolean
    className?: Argument
    children?: ReactNode
}

export const FadedBackground = ({
    className: _className,
    variant,
    onlyDesktop,
    mobileBox,
    rounded,
    children,
}: Props): ReactElement => {
    const className = classNames(
        'FadedBackground',
        {
            [`FadedBackground--${variant}`]: variant,
            [`FadedBackground--onlyDesktop`]: onlyDesktop,
            [`FadedBackground--mobileBox`]: mobileBox,
            [`FadedBackground--rounded`]: rounded,
        },
        _className,
    )
    return <div className={className}>{children}</div>
}
