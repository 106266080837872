import './index.styl'
import { ReactElement, ReactNode } from 'react'
import { Info } from '../Info'
import classNames, { Argument } from 'classnames'
import { Translate, _, __ } from '../../translations/CebiaTranslator'
import { transform } from 'lodash'

export type AtrInfoStatusVariant = 'default' | 'alert' | 'check' | 'date' | null | undefined
export type AtrInfoStatusProps = {
    className?: Argument
    variant?: AtrInfoStatusVariant
    text?: string
    children?: ReactNode
}

export const AtrInfoStatus = ({
    variant,
    className: _className,
    text,
}: AtrInfoStatusProps): ReactElement => {
    const tran = new Translate()

    const getVariantHtml = (variant: AtrInfoStatusVariant) => {
        switch (variant) {
            case 'alert':
                return <Info variant="alert" withIcon={true} text={text}></Info>
            case 'check':
                return <Info variant="check" withIcon={true} text={text}></Info>
            case 'date':
                return (
                    <span className="AtrInfoStatusPeriod">
                        {text?.length ?? 0 > 0 ? tran.GetText('období:') : ''}{' '}
                        <span className="color-blue">{text}</span>
                    </span>
                )
            default:
                return <span>{text}</span>
        }
    }

    const className = classNames('AtrInfoStatus', _className)
    return <div className={className}>{getVariantHtml(variant)}</div>
}
