import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import { Container } from '../Container'
import { Modal, useModal } from '../Modal'
import { Title } from '../Title'
import parse, { domToReact } from 'html-react-parser'
import { useCookies } from 'react-cookie'
import AssetsImgPopup from './img/popup_image.webp'
import { Image } from '../Image'
import { Button } from '../Button'
import { useNavigate } from 'react-router'
import { i18n } from '../../translations/i18n'
import { UTMModel } from '../../models/utmModel'
import { useAppContext } from '../../contexts/AppContext'

type Props = {
    name: string
    validUntil: Date
    showImage?: boolean
    imagePosition: 'left' | 'right'
    title?: string
    html: string
    showButton?: boolean
    buttonText?: string
    buttonUrl?: string
    expireInDate: number
    allowedLanguages: [string]
    utmCampaingn?: string
    showAfterMiliSeconds: number
}

export const TimeLimitedPopup = ({
    name: name,
    validUntil: validUntil,
    showImage: showImage,
    imagePosition: imagePosition,
    title: title,
    showButton: showButton,
    buttonText: buttonText,
    buttonUrl: buttonUrl,
    html: html,
    expireInDate: expireInDate,
    allowedLanguages: allowedLanguages,
    utmCampaingn: utmCampaingn,
    showAfterMiliSeconds: showAfterMiliSeconds,
}: Props): ReactElement => {
    const appContext = useAppContext()
    const { open: openPopup, close: closePopup } = useModal('TimeLimitedPopup')
    const [cookies, setCookie, removeCookie] = useCookies([name])
    const actualDate = new Date()
    const navigate = useNavigate()

    const onClick = (url?: string) => {
        if (url) {
            closePopup()
            navigate(url)
        }
    }

    const setUtmCapaign = (utmCampaign?: string) => {
        if (utmCampaign) {
            const { appModel } = appContext
            appModel.utmModel = new UTMModel(utmCampaign)
            appContext.storeAppModel(appContext.appModel, false)
        }
    }

    useEffect(() => {
        if (
            !cookies[name] &&
            actualDate < validUntil &&
            allowedLanguages.find(x => x === i18n.language)
        ) {
            const tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + expireInDate)
            tomorrow.setHours(0)
            tomorrow.setMinutes(0)
            tomorrow.setSeconds(0)
            setCookie(name, true, { expires: tomorrow, path: '/', secure: true })
            setTimeout(() => {
                openPopup()
            }, showAfterMiliSeconds)
            setUtmCapaign(utmCampaingn)
        }
        if (
            cookies[name] &&
            actualDate < validUntil &&
            allowedLanguages.find(x => x === i18n.language)
        ) {
            setUtmCapaign(utmCampaingn)
        }
    }, [])
    return (
        <Modal id="TimeLimitedPopup">
            <Container className="TimeLimitedPopupContainer">
                <div className="Grid">
                    {title && (
                        <div className="Grid-cell md:w-12/12">
                            <Title as="header" variantAlign="left">
                                <h2 className="title fl mt-20 ml-30">{title}</h2>
                            </Title>
                        </div>
                    )}
                    {html && !showImage && (
                        <div className="Grid-cell md:w-12/12 mt-20">
                            <div className="description">{parse(html)}</div>
                        </div>
                    )}
                    {html && showImage && (
                        <>
                            <div className="Grid-cell smMax:w-full mdMax:w-full lg:w-4/12 mt-20">
                                {imagePosition === 'left' && (
                                    <Image className="DefaultCursor" src={AssetsImgPopup} alt="" />
                                )}
                                {imagePosition !== 'left' && (
                                    <>
                                        <div className="description">{parse(html)}</div>
                                        {showButton && (
                                            <Button
                                                as="button"
                                                variantColor="blue"
                                                onClick={() => onClick(buttonUrl)}
                                            >
                                                {buttonText ?? ''}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="Grid-cell smMax:w-full mdMax:w-full lg:w-8/12 mt-20 pl-50">
                                {imagePosition === 'right' && (
                                    <Image className="DefaultCursor" src={AssetsImgPopup} alt="" />
                                )}
                                {imagePosition !== 'right' && (
                                    <>
                                        <div className="description">{parse(html)}</div>
                                        {showButton && (
                                            <Button
                                                as="button"
                                                variantColor="blue"
                                                onClick={() => onClick(buttonUrl)}
                                            >
                                                {buttonText ?? ''}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </Container>
        </Modal>
    )
}
