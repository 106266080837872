import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { useEffect, useState } from 'react'
import { AtrSection } from '../../enums/AtrSectionEnum'

export interface SignalRSettings {
    settings: Map<string, SignalRReturns>
}

interface SignalRProviderProperties {
    connectionId: string
    hubState: SignalRState
    connector: HubConnection | null
}

export enum SignalRState {
    HubNotCreated,
    HubCreated,
    HubClosed,
}

export type SignalRReturns = {
    connectionId: string
    openConnection: () => void
    closeConnection: () => void
    isConnected: () => boolean
    bufferedData: Array<any>
    hubState: SignalRState
}

export type SignalRProps = {
    url: string
}

export default function SignalRSetting({ url }: SignalRProps): SignalRReturns {
    const _url = url //url se udava v endpoint|actionMethoda...napr:hubs/dashboard|ClientReceivedDashboardMessage
    const [buffer, setBuffer] = useState<Array<any>>([])
    const [signalRProps, setSignalRProps] = useState({
        connectionId: '',
        hubState: SignalRState.HubNotCreated,
        connector: null,
    } as SignalRProviderProperties)

    const responseData = new Array<any>()
    useEffect(() => {
        const fetchData = async () => {
            if (_url.indexOf('|') <= 0) throw URIError("url doesn't contains endpoint or action.")
            const splitUrl = _url.split('|')
            const _urlHubEndpoint = splitUrl[0]
            const _actionHub = splitUrl[1]

            if ((_url?.length ?? 0) > 0) {
                const baseUrl = `${process.env.PUBLIC_URL}/`

                const connect = new HubConnectionBuilder()
                    .withUrl(`${baseUrl}${_urlHubEndpoint}`)
                    .withAutomaticReconnect()
                    //       .configureLogging(LogLevel.Trace)
                    .build()

                connect.on(_actionHub, data => {
                    if (
                        process.env.NODE_ENV === 'development' ||
                        process.env.REACT_APP_DOMAIN?.indexOf('cebia.com', 0) === -1
                    ) {
                        console.log(`${_actionHub}-RecvData:`, data?.value)
                    }
                    responseData.push(data.value)
                    if (data.value.sectionId === AtrSection.CouponPrice) {
                        setBuffer(responseData)
                    }
                })

                setSignalRProps({
                    connectionId: '',
                    hubState: SignalRState.HubCreated,
                    connector: connect,
                })
            }
        }

        fetchData().catch(console.error)

        return () => {
            closeConnection()
        }
    }, [])

    const openConnection = () => {
        if (
            signalRProps.connector &&
            signalRProps.connector.state === HubConnectionState.Disconnected
        ) {
            signalRProps.connector.start().then(() => {
                if ((signalRProps.connector?.connectionId?.length ?? 0) > 0) {
                    setSignalRProps(prevState => ({
                        ...prevState,
                        connectionId: prevState.connector?.connectionId ?? '',
                        hubState: SignalRState.HubCreated,
                    }))

                    setBuffer([])
                }
            })
        }
    }

    const closeConnection = () => {
        if (
            signalRProps.connector &&
            signalRProps.connector.state === HubConnectionState.Connected
        ) {
            signalRProps.connector.stop().then(e => {
                setSignalRProps(prevState => ({
                    ...prevState,
                    connectionId: '',
                    hubState: SignalRState.HubClosed,
                }))
            })
        }
    }

    const isConnected = (): boolean => {
        return (
            (signalRProps.connector?.connectionId?.length ?? 0) > 0 &&
            signalRProps.connector?.state === HubConnectionState.Connected
        )
    }

    return {
        openConnection,
        closeConnection,
        isConnected,
        connectionId: signalRProps.connectionId,
        bufferedData: buffer,
        hubState: signalRProps.hubState,
    }
}
