import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionVehicleSecurity } from '../../../../detailPaid/atr-items/SectionVehicleInfo/Section/vehicleSecurity'
import './body.styl'

let PrintAllowIncrement = true

export const Security = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    if (!sourceData?.vehicleInfoControlResult) return <></>

    const securityData = new PayedJsonResult()
    securityData.vehicleInfoControlResult = sourceData.vehicleInfoControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola zabezpečení vozidla')}
            </h2>

            <Section className="SecuritySection">
                <SectionVehicleSecurity
                    sourceData={securityData.vehicleInfoControlResult}
                    tran={tran}
                />
            </Section>
        </div>
    )
}
