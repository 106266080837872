import './index.styl'
import { ReactElement, UIEventHandler, useEffect, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official'
import { Highcharts } from './highcharts'
import {
    ChartOptions,
    Coords,
    getHightchartsOptions,
    TimestampRange,
} from './getHightchartsOptions'
import classNames from 'classnames'
import { useChartOverflow } from './useChartOverflow'
import { useAtrChartAndTableSync } from '../../hooks/useAtrChartAndTableSync'

type Options = {
    pixelsPerPoint?: number
    pixelsPerPointMobile?: number
}

type Props = {
    variant?: 'short'
    data: Coords[]
    unverifiedRanges: TimestampRange[]
    options?: Options
    chartOptions?: ChartOptions
}

export const Chart = ({
    variant,
    data,
    unverifiedRanges,
    options = {},
    chartOptions,
}: Props): ReactElement => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<HighchartsReact.RefObject | null>(null)

    if (chartOptions?.isPrint === true) {
        chartRef.current?.chart.setSize(905, null, false)
    }

    //CUSTOM OVERFLOW
    const { pixelsPerPoint = 15, pixelsPerPointMobile = 40 } = options
    const {
        isOverflow,
        overflowDirection,
        checkOverflowDirection,
        isOverflowReady,
        overflowChildStyle,
    } = useChartOverflow({
        wrapperRef: scrollRef,
        pointCount: data.length,
        pixelsPerPoint,
        pixelsPerPointMobile,
    })

    const handleElementScroll: UIEventHandler<HTMLDivElement> = () => {
        checkOverflowDirection()
    }

    // //SYNC HOVER S TABULKOU
    // const { atrChatAndTableSyncState, atrChatAndTableSyncActions } = useAtrChartAndTableSync()

    // useEffect(() => {
    //     const chart = chartRef.current?.chart

    //     const previousPoint = chart?.series[0]?.data.find(
    //         point => point.x === atrChatAndTableSyncState.previousPointId,
    //     )
    //     if (previousPoint) {
    //         previousPoint.setState('normal')
    //     }
    //     const point = chart?.series[0]?.data.find(
    //         point => point.x === atrChatAndTableSyncState.pointId,
    //     )
    //     if (point) {
    //         point.setState('hover')
    //         if (chart) chart?.tooltip.refresh(point)
    //     }
    // }, [atrChatAndTableSyncState])

    // const handlePointMouseOver: Highcharts.PointMouseOverCallbackFunction = function () {
    //     if (atrChatAndTableSyncState.pointId === this.x) {
    //         return
    //     }
    //     atrChatAndTableSyncActions.setPointId(this.x)
    // }

    // const handleMouseLeave = () => {
    //     atrChatAndTableSyncActions.setPointId(null)
    // }

    const hightchartOptions: Highcharts.Options = {
        plotOptions: {
            series: {
                // point: {
                //     events: {
                //         mouseOver: handlePointMouseOver,
                //     },
                // },
                animation: chartOptions?.animations ?? true,
            },
        },
    }
    const highchartsOptions = {
        ...getHightchartsOptions(data, unverifiedRanges, chartOptions),
        ...hightchartOptions,
    }

    const className = classNames('Chart', {
        [`Chart--${variant}`]: variant,
        [`is-overflow`]: isOverflow,
        [`is-overflow-left`]: overflowDirection?.left,
        [`is-overflow-right`]: overflowDirection?.right,
    })

    return (
        <div className={className}>
            <div
                className="Chart-scroll"
                ref={scrollRef}
                onScroll={isOverflow ? handleElementScroll : undefined}
            >
                <div className="Chart-chart" style={overflowChildStyle}>
                    {isOverflowReady && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={highchartsOptions}
                            ref={chartRef}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
