import './index.styl'
import { ReactElement } from 'react'
import AssetsImgLogo from './img/logo-cebia.svg'
import AssetsImgLogoGjirafa from './img/logo-gjirafa.svg'
import AssetsImgLogoSmartCode from './img/cebia_logo_np.svg'
import classNames from 'classnames'

type Props = {
    variant?: 'light'
    href?: string
}

export const LogoCebia = ({ variant }: Props): ReactElement => {
    const className = classNames('LogoCebia', {
        [`LogoCebia--${variant}`]: variant,
    })

    return (
        <div className={className}>
            <img src={AssetsImgLogo} width="130" height="41" alt="logo cebia" />
        </div>
    )
}

export const LogoCebiaSmartCode = ({ variant }: Props): ReactElement => {
    const className = classNames('LogoCebia', {
        [`LogoCebia--${variant}`]: variant,
    })

    return (
        <div className={className}>
            <img src={AssetsImgLogoSmartCode} width="260" height="82" alt="logo cebia" />
        </div>
    )
}

export const LogoGjirafa = ({ variant }: Props): ReactElement => {
    const className = classNames('LogoGjirafa', {
        [`LogoGjirafa--${variant}`]: variant,
    })

    return (
        <div className={className}>
            <img src={AssetsImgLogoGjirafa} width="200" height="100%" alt="logo gjirafa" />
        </div>
    )
}
