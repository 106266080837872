import moment from 'moment'
import { ReactElement } from 'react'
import { Table } from '../../../../components/Table'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import './index.styl'

export const SectionCallingAction = ({ sourceData }: DetailPayedProps): ReactElement => {
    if (!sourceData?.callingActionControlResult) return <></>

    const tran = new Translate()

    return (
        <div className="sectionCallingAction">
            {sourceData.callingActionControlResult.isCallingAction && (
                <>
                    <div className="text-center pb-25">
                        <span>{tran.GetText('Prověřovaného vozu se mohou týkat')}</span>
                        <h2 className="mainTextFound font-bold">
                            {tran.GetText('Nalezené svolávací akce')}
                        </h2>
                    </div>
                    <p className="text-justify">
                        <strong>{tran.GetText('SVOLÁVACÍ AKCE')}</strong>{' '}
                        {tran.GetHtml(
                            'je vyhlašována výrobcem vozidla povinně ze zákona vždy, když je ohroženo zdraví, bezpečnost, i životní prostředí. Týkají se zpravidla výrobních závad vozidla, které se projeví až při jeho provozu a výrobce tímto způsobem zajišťuje jejich bezplatnou nápravu. Informace jsou čerpány z webu <a href="https://svolavacky.cz/" target="_blank">Svolávačky.cz</a> s pomocí systému RAPEX.',
                        )}
                    </p>
                    <p className="text-justify">
                        {tran.GetHtml(
                            'Uvedené svolávací akce <strong>byly vygenerovány podle modelu, značky a roku výroby</strong> prověřovaného vozidla, <strong>nikoliv dle jeho VIN</strong>. Vždy si proto v detailu akce ověřte, <strong>zda se týkala i prověřovaného vozidla</strong>. Zároveň si ověřte u prodejce vozidla či v autorizovaném autoservisu, zda vozidlo platné svolávací akce podstoupilo.',
                        )}
                    </p>
                </>
            )}
            {!sourceData.callingActionControlResult.isCallingAction && (
                <>
                    <div className="text-center pb-25">
                        <span>{tran.GetText('K prověřovanému vozu nebyly nalezeny')}</span>
                        <h2 className="mainTextNotFound">{tran.GetText('Žádné svolávací akce')}</h2>
                    </div>
                    <p className="text-justify">
                        {tran.GetHtml(
                            'Svolávací akce je vyhlašována výrobcem vozidla povinně ze zákona, pokud má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada v autorizovaném servisu zdarma odstraněna. Data poskytuje portál <a href="https://svolavacky.cz/" target="_blank">Svolávačky.cz</a> s pomocí systému RAPEX.',
                        )}
                    </p>
                </>
            )}

            <div className="mb-30">
                {(sourceData.callingActionControlResult.callingActionItems?.length ?? 0) > 0 && (
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-left">
                                    {tran.GetText('Svolávací akce')}{' '}
                                    {sourceData.callingActionControlResult?.callActionDescription}
                                </th>
                                <th className="text-left">{tran.GetText('Datum vyhlášení')}</th>
                                <th className="text-left">{tran.GetText('Detail')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sourceData.callingActionControlResult.callingActionItems?.map(
                                (item, index) => (
                                    <tr key={index}>
                                        <td>{tran.GetText(item.decsription)}</td>
                                        <td>{moment(item.created).format('DD. MM. YYYY')}</td>
                                        <td className="text-left">
                                            <a
                                                className="callActLink"
                                                href={item.url}
                                                title="Link"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {tran.GetText('Více info')}
                                            </a>
                                        </td>
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </Table>
                )}
            </div>
        </div>
    )
}
