import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Title } from '../../../../components/Title'
import { VisualBlock } from '../../../../components/VisualBlock'
import { Translate } from '../../../../translations/CebiaTranslator'
import AssetsImgMartinPajer from './img/martin-pajer.webp'
import AssetsImgMartinPajer2x from './img/martin-pajer@2x.webp'

export const SectionAboutCompany = (): ReactElement => {
    const tran = new Translate()
    return (
        <Section variantPend="appended" className="SectionAboutCompany">
            <Container>
                <VisualBlock
                    variant="elevated"
                    withShapes={true}
                    visual={{
                        image: {
                            src: AssetsImgMartinPajer,
                            srcSet: `${AssetsImgMartinPajer2x} 2x`,
                            alt: tran.GetText('Ředite společnosti Cebia, Ing. Martin Pajer'),
                        },
                    }}
                    visualCaption={tran.GetText(
                        '<strong>Ing. Martin Pajer</strong> Ředitel společnosti Cebia',
                    )}
                    visualShape={{ variant: 'blueGradient' }}
                >
                    <Title as="h2" variantAlign="left" className="Beta mb-20 color-darkBlue">
                        {tran.GetText('Společnost Cebia')}
                    </Title>
                    {tran.GetHtml(
                        'Jsme česká společnost založená v roce 1991 v Praze. Naše služby z oblasti prověřování, zabezpečení a identifikace vozidel využívají zákazníci v 9 zemích Evropy. Našimi zákazníky jsou běžní motoristé, pojišťovny, prodejci vozidel, státní instituce, policejní složky i soudní znalci. Za svou historii jsme odhalili desetitisíce podvodů s ojetými vozidly a pomohli s nákupem vozu miliónům zákazníků po celé Evropě.',
                    )}
                </VisualBlock>
            </Container>
        </Section>
    )
}
