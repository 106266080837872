import './index.styl'
import { ReactElement } from 'react'
import { Social } from '../Social'
import { LogoCebia, LogoGjirafa } from '../LogoCebia'
import { Container } from '../Container'
import { i18n, LanguageDefinition, LanguageEnum } from '../../translations/i18n'
import { Translate } from '../../translations/CebiaTranslator'
import AssetsImgLogoInstagram from './img/instagram.svg'

export const FooterReg = (): ReactElement => {
    const email = LanguageDefinition[LanguageEnum[i18n.language as keyof typeof LanguageEnum]].email
    const tran = new Translate()
    return (
        <footer className="Footer" id="footer">
            <Container variant="wide">
                <div className="Container is-pure">
                    <div className="Footer-intro">
                        {i18n.language !== 'al' ? (
                            <div className="smwMax:hidden">
                                <LogoCebia variant="light" />
                            </div>
                        ) : (
                            <div className="smwMax:hidden">
                                <LogoGjirafa variant="light" />
                            </div>
                        )}
                        <p className="Footer-contacts">
                            <a href={'mailto:' + email}>{email}</a>
                            {i18n.language === 'cz' ||
                                (i18n.language === 'sk' && (
                                    <>
                                        <a href="tel:+420222207111">
                                            +420&nbsp;222&nbsp;207&nbsp;111
                                        </a>
                                    </>
                                ))}
                            <span className="smwMax:hidden">
                                {tran.GetText('Po-Pá: 8:00 - 16:30')}
                            </span>
                        </p>

                        <p className="Footer-social smwMax:hidden">
                            {i18n.language !== 'uk' && (
                                <>
                                    <Social
                                        icon="facebook"
                                        href={
                                            i18n.language === 'al'
                                                ? 'https://www.facebook.com/auto.tracer.by.gjirafa'
                                                : 'https://www.facebook.com/cebia.cz'
                                        }
                                        label="Facebook"
                                    />
                                    {i18n.language === 'al' ? (
                                        <Social
                                            icon="instagram"
                                            href="https://www.instagram.com/autotracer.bygjirafa/"
                                            label="Instagram"
                                        />
                                    ) : (
                                        <Social
                                            icon="youtube"
                                            href="https://www.youtube.com/user/cebiacz"
                                            label="Youtube"
                                        />
                                    )}
                                </>
                            )}
                        </p>
                    </div>
                </div>
                <div className="Separator smwMax:hidden" />
            </Container>
        </footer>
    )
}
