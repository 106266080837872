export class RequestOptionsModel {
    id: number | undefined
    requestId: number | undefined
    partnerTypId: number | undefined
    value: string | undefined
    valueBit: boolean | undefined
    valueNum: number | undefined
    isValid: boolean | undefined
    createdAt: Date | undefined
    changedAt: Date | undefined
}
