class RatingMessageModel {
    couponNumber = ''
    email = ''
    message = ''
    starsCount = 0

    constructor(couponNumber: string) {
        this.couponNumber = couponNumber
    }
}

export { RatingMessageModel }
