import { useEffect, useMemo, useState } from 'react'
import { makeObservable, Observable } from '../utils/makeObservable'

export type ToggleItemOpenEvent = () => void
export type ToggleCluster = string | null | undefined
type ToggleGroupFlashRequest = number
type ToggleGroupScrollRequest = number
type ToggleGroupId = string

type ToggleGroup = {
    active: boolean
    shouldFlash?: boolean
    shouldScroll?: boolean
    cluster?: ToggleCluster
    flashRequest?: ToggleGroupFlashRequest
    scrollRequest?: ToggleGroupScrollRequest
}

type ToggleGroupActions = {
    setActive: (active: boolean, shouldFlash?: boolean, shouldScroll?: boolean) => void
    setCluster: (cluster: ToggleCluster) => void
}

type ToggleGroups = Record<ToggleGroupId, Observable<ToggleGroup>>

const groups: ToggleGroups = {}
const getGroupStore = (
    id: ToggleGroupId,
    cluster?: ToggleCluster,
    active = false,
): Observable<ToggleGroup> => {
    if (!groups[id]) {
        const toggleGroup: ToggleGroup = {
            shouldFlash: false,
            shouldScroll: false,
            cluster,
            active,
        }
        groups[id] = makeObservable<ToggleGroup>(toggleGroup)
    }

    if (groups[id].get().cluster !== cluster) {
        groups[id].get().cluster = cluster
    }
    return groups[id]
}

function getGroupsByCluster(cluster: ToggleCluster): Observable<ToggleGroup>[] {
    return Object.values(groups).filter(obsGroup => obsGroup.get().cluster === cluster)
}

export const useToggleGroup = (
    id: string,
    cluster?: ToggleCluster,
    active?: boolean,
): {
    toggleGroup: ToggleGroup
    toggleGroupActions: ToggleGroupActions
} => {
    const groupStore = getGroupStore(id, cluster, active)
    const [group, setGroupState] = useState(groupStore.get())

    useEffect(() => {
        return groupStore.subscribe(setGroupState)
    }, [])

    useEffect(() => {
        if (active === true) {
            const group = groupStore.get()
            group.active = active
            groupStore.set(group)
        }
    }, [])

    const actions = useMemo((): ToggleGroupActions => {
        return {
            setCluster: cluster => {
                groupStore.set({ ...group, cluster })
            },
            setActive: (active, shouldFlash = false, shouldScroll = false) => {
                const flashRequest = shouldFlash ? Math.random() : group.flashRequest
                const scrollRequest = active && shouldScroll ? Math.random() : group.scrollRequest

                const groupPart = {
                    shouldScroll,
                    scrollRequest,
                    shouldFlash,
                    flashRequest,
                }

                if (groupStore.get().cluster) {
                    getGroupsByCluster(group.cluster).forEach(group => {
                        if (group === groupStore) {
                            groupStore.set({ ...group, ...groupPart, active })
                        } else {
                            if (group.get().active) {
                                group.set({ ...group, ...groupPart, active: false })
                            }
                        }
                    })
                } else {
                    groupStore.set({ ...group, ...groupPart, active })
                }
            },
        }
    }, [group])

    return {
        toggleGroup: group,
        toggleGroupActions: actions,
    }
}
