type XCoord = number
type YCoord = number
type XYCoords = [XCoord, YCoord]

export function pathToPolygon(path: SVGPathElement): XYCoords[] {
    const NUM_POINTS = 12

    const len = path.getTotalLength()
    const points: XYCoords[] = []

    for (let i = 0; i < NUM_POINTS; i++) {
        const pt = path.getPointAtLength((i * len) / (NUM_POINTS - 1))
        points.push([pt.x, pt.y])
    }

    return points
}

type SvgViewbox = {
    x: number
    y: number
    width: number
    height: number
}

export function getSvgViewbox(svg: HTMLElement): SvgViewbox | null {
    if (!svg) {
        return null
    }

    const viewBox = svg.getAttribute('viewBox')
    if (!viewBox) {
        return null
    }

    const [x, y, width, height] = viewBox.split(' ').map((value: string) => parseInt(value))

    return {
        x,
        y,
        width,
        height,
    }
}
