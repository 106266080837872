import './index.styl'
import { ReactElement } from 'react'
import { Section } from '../../../../components/Section'
import { PrintDataProps } from '../../vypis'
import { Chart } from '../../../../components/Chart'
import {
    getCoordsFrom,
    getCoordsFromUnverified,
} from '../../../../models/DetailPayedResults/Tacho/tachosControlModels'
import { Coords, TimestampRange } from '../../../../components/Chart/getHightchartsOptions'
import { SectionSeparator } from '../../../homepage/sections/SectionSeparator'
import { Table } from '../../../../components/Table'
import moment from 'moment'

export const PrintTachoStatesSections = ({ sourceData, tran }: PrintDataProps): ReactElement => {
    return (
        <div className="pageBreakAvoid">
            <h2 className="text-center">{tran.GetText('Graf záznamů stavů tachometru')}</h2>

            {sourceData?.tachoControlResult != null && (
                <Section className="PrintTachoSection">
                    <Table className="mt-30 mb-50">
                        <thead>
                            <tr>
                                <th colSpan={2} className="text-left"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{tran.GetText('VIN (identifikační číslo vozidla):')}</td>
                                <td>{sourceData.vin}</td>
                            </tr>
                            <tr>
                                <td>{tran.GetText('Tovární značka:')}</td>
                                <td>{sourceData.make}</td>
                            </tr>
                            <tr>
                                <td>{tran.GetText('Model vozidla:')}</td>
                                <td>{sourceData.model}</td>
                            </tr>
                            <tr>
                                <td>{tran.GetText('Rok první registrace dle tech. průkazu:')}</td>
                                <td>{moment(sourceData.firstRegistration).format('YYYY')}</td>
                            </tr>
                            <tr>
                                <td>{tran.GetText('Stav tachometru:')}</td>
                                <td>
                                    {(sourceData.tachoControlResult.descItems?.length ?? 0) > 0
                                        ? tran.GetNumber(
                                              sourceData.tachoControlResult.descItems![0].mileAge,
                                          )
                                        : '0'}
                                    {tran.GetText('km')}
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <div className="mb-30 sectionChart">
                        <Chart
                            data={getCoordsFrom(sourceData?.tachoControlResult) ?? Array<Coords>(0)}
                            unverifiedRanges={
                                getCoordsFromUnverified(sourceData?.tachoControlResult) ??
                                Array<TimestampRange>(0)
                            }
                            options={{}}
                            chartOptions={{ animations: false, isPrint: true }}
                        />
                    </div>
                    <SectionSeparator className="pb-15" />
                    <p className="underText color-darkBlue">
                        {tran.GetText(
                            'Tento výpis je pouze informativní. Poskytnuté informace se řídí "Všeobecnými podmínkami pro používání systému Cebia REPORT".',
                        )}
                    </p>
                </Section>
            )}
        </div>
    )
}
