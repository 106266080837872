import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Title } from '../../../../components/Title'
import { ToggleItemFaq } from '../../../../components/ToggleItemFaq'
import { ToggleOpenerFaq } from '../../../../components/ToggleOpenerFaq'
import { Button } from '../../../../components/Button'
import { Translate } from '../../../../translations/CebiaTranslator'

export const SectionFaq = (): ReactElement => {
    const tran = new Translate()
    return (
        <Section variant="medium">
            <Container variant="slim">
                <Title className="Beta color-darkBlue" as="h2">
                    {tran.GetText('Časté dotazy')}
                </Title>

                <div className="Grid gap-20 gmb-10">
                    <div className="Grid-cell">
                        <div className="SectionFaq-faqItem">
                            <ToggleOpenerFaq href="#faq-1" blurInActive={true} isOpened={false}>
                                {tran.GetText('Co je to VIN?')}
                            </ToggleOpenerFaq>
                            <ToggleItemFaq id="faq-1" cluster="faq">
                                {tran.GetHtml(
                                    "VIN je zkratka anglického Vehicle Identification Number (identifikační číslo vozidla). Jedná se o unikátní 17místný kód tvořený písmeny a číslicemi, který výrobci přiřazují každému vyrobenému vozidlu. VIN se nachází na různých částech vozidla či v jeho dokladech. Více o VIN naleznete <a href='https://www.cebia.cz/pruvodce/vin-vehicle-identification-number' target='_blank'>zde</a>.",
                                )}
                            </ToggleItemFaq>
                        </div>
                    </div>
                    <div className="Grid-cell">
                        <div className="SectionFaq-faqItem">
                            <ToggleOpenerFaq href="#faq-2" isOpened={false} blurInActive={true}>
                                {tran.GetText('Kolik prověření stojí?')}
                            </ToggleOpenerFaq>
                            <ToggleItemFaq id="faq-2" cluster="faq">
                                {tran.GetHtml('Základní cena prověření je 649 Kč včetně DPH.')}
                            </ToggleItemFaq>
                        </div>
                    </div>
                    <div className="Grid-cell">
                        <div className="SectionFaq-faqItem">
                            <ToggleOpenerFaq href="#faq-3" isOpened={false} blurInActive={true}>
                                {tran.GetText('Jak mohu zaplatit?')}
                            </ToggleOpenerFaq>
                            <ToggleItemFaq id="faq-3" cluster="faq">
                                {tran.GetHtml(
                                    "Online platbou pomocí platební karty, v internetovém bankovnictví, bankovním převodem či prostřednictvím Google Pay, nebo Apple Pay. Zaplatit lze i v hotovosti <a href='https://www.cebia.cz/kontakty' target='_blank'> v sídle Cebia</a>",
                                )}
                            </ToggleItemFaq>
                        </div>
                    </div>
                    <div className="Grid-cell">
                        <div className="SectionFaq-faqItem">
                            <ToggleOpenerFaq href="#faq-4" isOpened={false} blurInActive={true}>
                                {tran.GetText('Jak dlouho trvá, než obdržím výsledek prověření?')}
                            </ToggleOpenerFaq>
                            <ToggleItemFaq id="faq-4" cluster="faq">
                                {tran.GetHtml(
                                    'Výsledek je obvykle vygenerován pár minut po přijetí platby. Služba funguje nepřetržitě a celý proces je automatický. Záleží tedy na zvolené platební metodě. Při platbě kartou je výsledek dostupný téměř okamžitě, při platbě bankovním převodem až po přijetí platby.',
                                )}
                            </ToggleItemFaq>
                        </div>
                    </div>
                    <div className="Grid-cell">
                        <div className="SectionFaq-faqItem">
                            <ToggleOpenerFaq href="#faq-5" isOpened={false} blurInActive={true}>
                                {tran.GetText('Jak si zobrazím výsledek prověření?')}
                            </ToggleOpenerFaq>
                            <ToggleItemFaq id="faq-5" cluster="faq">
                                {tran.GetHtml(
                                    'Výsledek prověření, respektive výpis ze systému AUTOTRACER k vybranému vozidlu, je vygenerován automaticky ihned po přijetí platby. Odkaz na něj naleznete v emailu, který vám přijde po zaplacení. V emailu obdržíte rovněž číslo kuponu pro opakované zobrazení výpisu. Výsledek je možné zobrazovat po dobu 30 dní.',
                                )}
                            </ToggleItemFaq>
                        </div>
                    </div>
                    <div className="Grid-cell">
                        <div className="SectionFaq-faqItem">
                            <ToggleOpenerFaq href="#faq-6" isOpened={false} blurInActive={true}>
                                {tran.GetText('Lze výsledek prověření reklamovat?')}
                            </ToggleOpenerFaq>
                            <ToggleItemFaq id="faq-6" cluster="faq">
                                {tran.GetHtml(
                                    "Ano lze. Máte-li podezření, že jsou ve výsledku uvedeny nesprávné údaje, obraťte se prosím na naše zákaznické oddělení: <a href='mailto: autotracer@cebia.cz'>autotracer@cebia.cz</a>, PO – PÁ: 8:00 – 16:30",
                                )}
                            </ToggleItemFaq>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center mt-50">
                    <Button as="a" href="/faqs" variantColor="lightBlue" icon="arrowRight">
                        {tran.GetText('Všechny dotazy')}
                    </Button>
                </div>
            </Container>
        </Section>
    )
}
