import { ReactElement } from 'react'
import { AtrInfoStatusProps, AtrInfoStatusVariant } from '../../components/AtrInfoStatus'
import { VisualIconProps } from '../../components/VisualIcon'
import { AtrSection } from '../../enums/AtrSectionEnum'
import { SectionCallingAction } from '../../pages/detailPaid/atr-items/SectionCallAction'
import { SectionCebiaReport } from '../../pages/detailPaid/atr-items/SectionCebiaReport'
import { SectionCheckVehicleByPerson } from '../../pages/detailPaid/atr-items/SectionCheckVehicleByPerson'
import { SectionCouponSales } from '../../pages/detailPaid/atr-items/SectionCouponSales'
import { SectionDamage } from '../../pages/detailPaid/atr-items/SectionDamages'
import { SectionLeasing } from '../../pages/detailPaid/atr-items/SectionLeasing'
import { SectionPhoto } from '../../pages/detailPaid/atr-items/SectionPhoto'
import { SectionServiceRec } from '../../pages/detailPaid/atr-items/SectionServiceRec'
import { SectionTacho } from '../../pages/detailPaid/atr-items/SectionTacho'
import { SectionTaxi } from '../../pages/detailPaid/atr-items/SectionTaxi'
import { SectionTheft } from '../../pages/detailPaid/atr-items/SectionTheft'
import { SectionTimeLine } from '../../pages/detailPaid/atr-items/SectionTimeline'
import { SectionValuation } from '../../pages/detailPaid/atr-items/SectionVaulation'
import { SectionVehicleInfo } from '../../pages/detailPaid/atr-items/SectionVehicleInfo'
import { Translate } from '../../translations/CebiaTranslator'
import { PayedJsonResult } from './payedJsonResult'
import { SectionLienRights } from '../../pages/detailPaid/atr-items/SectionLienRights'
import { ReferenceType } from '../../enums/ReferenceType'
import { ScrollToTop } from '../../components/ScrollToTop'
import { SectionCarInsurance } from '../../pages/detailPaid/atr-items/SectionCarInsurance'
import { SectionExekuce } from '../../pages/detailPaid/atr-items/SectionExekuce'
import { SectionCarInsuranceAutoCheck } from '../../pages/detailPaid/atr-items/SectionCarInsuranceAutocheck'
import { SectionDamageZV } from '../../pages/detailPaid/atr-items/SectionDamagesZV'
import { SectionPreviousAdvertZV } from '../../pages/detailPaid/atr-items/SectionPreviousAdvertZV'
import { SectionAgeAndOriginZV } from '../../pages/detailPaid/atr-items/SectionAgeAndOriginZV'
import { SectionCallingActionsZV } from '../../pages/detailPaid/atr-items/SectionCallingActionsZV'
import { SectionSafetyZV } from '../../pages/detailPaid/atr-items/SectionSafetyZV'
import { SectionCarInfoZV } from '../../pages/detailPaid/atr-items/SectionCarInfoZV'
import { SectionCarAukce } from '../../pages/detailPaid/atr-items/SectionCarAukce'
import i18next from 'i18next'
import { TextUtils } from '../../utils/textUtils'
import { SectionDiscountOffers } from '../../pages/detailPaid/atr-items/SectionDiscountOffers'

export type DetailPayedProps = {
    sourceData?: PayedJsonResult | undefined
    isPrint?: boolean
    countryId?: number
    referenceType?: ReferenceType
}

export class DetailPayedSectionDefinitionModel {
    mainSectionCaption = ''
    smartCodeSectionCaption = ''
    sectionItems!: DetailPayedSectionItemDefinitionModel[]
}

export class DetailPayedSectionItemDefinitionModel {
    sectionId = 0
    caption = ''
    icon: VisualIconProps | undefined
    status = ''
    statusIcon?: VisualIconProps | undefined
    atrInfoStatusProps: AtrInfoStatusProps | undefined
    isOpened = false
    visible = false
    toggledControl!: (props: DetailPayedProps) => ReactElement
    controlData?: PayedJsonResult | undefined
}

export const getSubCaption = (payedDataItem: DetailPayedSectionItemDefinitionModel): string => {
    const retVal = payedDataItem?.controlData?.subCaption ?? payedDataItem?.atrInfoStatusProps?.text
    return retVal ?? ''
}

export const getVariant = (
    payedDataItem: DetailPayedSectionItemDefinitionModel,
    isNav?: boolean,
): AtrInfoStatusVariant => {
    const isNavLink = isNav ?? false
    let retVal = payedDataItem?.controlData?.iconName as AtrInfoStatusVariant

    if (isNavLink && payedDataItem.controlData?.navIconName)
        retVal = payedDataItem?.controlData?.navIconName as AtrInfoStatusVariant

    if (!retVal) retVal = payedDataItem?.atrInfoStatusProps?.variant

    return retVal
}

export const isEnabled = (payedDataItem: DetailPayedSectionItemDefinitionModel): boolean => {
    const retVal = payedDataItem?.controlData?.enabled ?? false
    return retVal
}

export const isProcessed = (payedDataItem: DetailPayedSectionItemDefinitionModel): boolean => {
    const alreadyProcessed = [129, 40, 41]
    if (alreadyProcessed.includes(payedDataItem.sectionId)) return true
    const retVal = payedDataItem?.controlData?.isProcessed ?? false
    return retVal
}

export const getStatus = (
    payedDataItem: DetailPayedSectionItemDefinitionModel,
    tran: Translate,
    textUtils: TextUtils,
    isNav?: boolean,
): string => {
    const isNavLink = isNav ?? false
    let retVal =
        payedDataItem?.controlData?.status ??
        (payedDataItem?.sectionId === AtrSection.CarPhoto
            ? `0 ${tran.GetText('fotografií')}`
            : tran.GetText('0 záznamů'))
    if (payedDataItem?.sectionId === AtrSection.CouponSale && textUtils) {
        const div = document.querySelector('.sectionCouponSales') as HTMLDivElement
        if (div) {
            const anchors = div.querySelectorAll('a.ToggleOpenerGeneral')
            retVal = textUtils.PolozkyText(anchors.length)
        }
    }
    if (payedDataItem?.sectionId === AtrSection.DiscountOffers && textUtils) {
        const div = document.querySelector('.sectionDiscountOffers') as HTMLDivElement
        if (div) {
            const anchors = div.querySelectorAll('a.ToggleOpenerGeneral')
            retVal = textUtils.PolozkyText(anchors.length)
        }
    }
    if (isNavLink && (payedDataItem.controlData?.navStatus?.length ?? 0) > 0)
        retVal = payedDataItem.controlData!.navStatus!

    return retVal
}

export function getGlobalSectionDefinition(tran: Translate): DetailPayedSectionDefinitionModel[] {
    const result = [
        {
            smartCodeSectionCaption: '',
            mainSectionCaption: tran.GetText('Historie vozidla'),
            sectionItems: getCarHistory(tran),
        },
        {
            smartCodeSectionCaption: '',
            mainSectionCaption: tran.GetText('Kontrola závazků vozidla'),
            sectionItems: getVehicleCheck(tran),
        },
        {
            smartCodeSectionCaption: '',
            mainSectionCaption: tran.GetText('Stav vozidla'),
            sectionItems: getVehicleState(tran),
        },
        {
            smartCodeSectionCaption: '',
            mainSectionCaption: tran.GetText('Doporučujeme'),
            sectionItems: getOthersCebia(tran),
        },
        {
            smartCodeSectionCaption: '',
            mainSectionCaption: tran.GetText('Základní výpis'),
            sectionItems: getBasicListCebia(tran),
        },
    ]

    return result
}

function getCarHistory(tran: Translate): DetailPayedSectionItemDefinitionModel[] {
    let result = new Array<DetailPayedSectionItemDefinitionModel>()
    result = [
        // {
        //     sectionId: 30,
        //     caption: tran.GetText('Kontrola stáří a původu'),
        //     icon: { name: 'calendarCar' },
        //     status: tran.GetText('K dispozici'),
        //     isOpened: false,
        //     visible: false,
        //     atrInfoStatusProps: {
        //         text: '',
        //         variant: 'default',
        //     },
        //     toggledControl: (props: DetailPayedProps): ReactElement => (
        //         <SectionRokvy sourceData={props.sourceData} />
        //     ),
        // },
        {
            sectionId: 140,
            caption: tran.GetText('Přehled historie'),
            icon: { name: 'chartCar' },
            status: '',
            isOpened: true,
            visible: true,
            atrInfoStatusProps: {
                text: '',
                variant: null,
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <>
                    <SectionTimeLine sourceData={props.sourceData} />
                    <ScrollToTop visibleFromElementId="sekce140" scrollToElementId="sekce140" />
                </>
            ),
        },
        {
            sectionId: 1,
            caption: tran.GetText('Kontrola najetých kilometrů'),
            icon: { name: 'speedometerCheck' },
            status: tran.GetText('K dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: '',
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionTacho
                    sourceData={props.sourceData}
                    referenceType={props.referenceType}
                    isPrint={false}
                />
            ),
        },
        {
            sectionId: 2,
            caption: tran.GetText('Kontrola poškození'),
            icon: { name: 'carCrash' },
            status: tran.GetText('K dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: ' ',
                variant: null,
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionDamage sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 64,
            caption: tran.GetText('Fotky a inzerce'),
            icon: { name: 'album' },
            status: tran.GetText('K dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: '',
                variant: null,
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionPhoto sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 4,
            caption: tran.GetText('Servisní záznamy'),
            icon: { name: 'tabletCar' },
            status: tran.GetText('K dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: '',
                variant: null,
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionServiceRec sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 129,
            caption: tran.GetText('Cebia REPORT'),
            icon: { name: 'certificate' },
            status: tran.GetText('K dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionCebiaReport sourceData={props.sourceData} />
            ),
        },
    ]

    return result
}

function getVehicleCheck(tran: Translate): DetailPayedSectionItemDefinitionModel[] {
    let result = new Array<DetailPayedSectionItemDefinitionModel>()
    result = [
        {
            sectionId: 33,
            caption: tran.GetText('Kontrola odcizení'),
            icon: { name: 'thiefCar' },
            status: tran.GetText('k dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionTheft sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 130,
            caption: tran.GetText('Kontrola zástavního práva'),
            icon: { name: 'tabletPen' },
            status: tran.GetText('k dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionLienRights sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 35,
            caption: tran.GetText('Kontrola financování'),
            icon: { name: 'tabletMoney' },
            status: tran.GetText('k dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionLeasing sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 48,
            caption: tran.GetText('Kontrola exekucí'),
            icon: { name: 'tabletCebia' },
            status: tran.GetText('Lze prověřit'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionExekuce sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 31,
            caption: tran.GetText('Kontrola taxi'),
            icon: { name: 'glassCar' },
            status: tran.GetText('k dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionTaxi sourceData={props.sourceData} />
            ),
        },
    ]

    return result
}

function getVehicleState(tran: Translate): DetailPayedSectionItemDefinitionModel[] {
    let result = new Array<DetailPayedSectionItemDefinitionModel>()
    result = [
        {
            sectionId: 37,
            caption: tran.GetText('Ocenění vozidla'),
            icon: { name: 'carDollar' },
            status: tran.GetText('k dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionValuation sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 38,
            caption: tran.GetText('Svolávací akce'),
            icon: { name: 'megaphone' },
            status: tran.GetText('k dispozici'),
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionCallingAction sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 16,
            caption: tran.GetText('Informace o vozidle'),
            icon: { name: 'car' },
            status: tran.GetText('k dispozici'),
            isOpened: true,
            visible: true,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionVehicleInfo sourceData={props.sourceData} />
            ),
        },
    ]

    return result
}

function getOthersCebia(tran: Translate): DetailPayedSectionItemDefinitionModel[] {
    let result = new Array<DetailPayedSectionItemDefinitionModel>()
    result = [
        {
            sectionId: 42,
            caption: tran.GetText('Slevové kupony Cebia'),
            icon: { name: 'pricetagPercent' },
            status: '',
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionCouponSales sourceData={props.sourceData} />
            ),
        },
        {
            sectionId: 56,
            caption: tran.GetText('Zvýhodněné nabídky'),
            icon: { name: 'carPercent' },
            status: '',
            isOpened: false,
            visible: false,
            atrInfoStatusProps: {
                text: undefined,
                variant: 'default',
            },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionDiscountOffers sourceData={props.sourceData} />
            ),
        },
        // {
        //     sectionId: 41,
        //     caption: tran.GetText('Kontrola vozu technikem'),
        //     icon: { name: 'doorShield' },
        //     status: tran.GetText('k dispozici'),
        //     isOpened: false,
        //     visible: false,
        //     atrInfoStatusProps: {
        //         text: undefined,
        //         variant: 'default',
        //     },
        //     toggledControl: (props: DetailPayedProps): ReactElement => (
        //         <SectionCheckVehicleByPerson sourceData={props.sourceData} />
        //     ),
        // },
        // {
        //     sectionId: 47,
        //     caption: tran.GetText('Pojištění vozidla'),
        //     icon: { name: 'carPercent' },
        //     status: 'k dispozici',
        //     isOpened: false,
        //     visible: true,
        //     atrInfoStatusProps: {
        //         text: undefined,
        //         variant: 'default',
        //     },
        //     toggledControl: (props: DetailPayedProps): ReactElement => <SectionCarInsurance />,
        // },
        // {
        //     sectionId: 49,
        //     caption: tran.GetText('Pojištění vozidla'),
        //     icon: { name: 'carPercent' },
        //     status: 'k dispozici',
        //     isOpened: false,
        //     visible: true,
        //     atrInfoStatusProps: {
        //         text: undefined,
        //         variant: 'default',
        //     },
        //     toggledControl: (props: DetailPayedProps): ReactElement => (
        //         <SectionCarInsuranceAutoCheck />
        //     ),
        // },
        // {
        //     sectionId: 55,
        //     caption: tran.GetText('Prodej vašeho stávajícího vozidla'),
        //     icon: { name: 'tabletPercent' },
        //     status: tran.GetText('k dispozici'),
        //     isOpened: false,
        //     visible: false,
        //     atrInfoStatusProps: {
        //         text: undefined,
        //         variant: 'default',
        //     },
        //     toggledControl: (props: DetailPayedProps): ReactElement => <SectionCarAukce />,
        // },
    ]

    return result
}

function getBasicListCebia(tran: Translate): DetailPayedSectionItemDefinitionModel[] {
    let result = new Array<DetailPayedSectionItemDefinitionModel>()
    result = [
        {
            sectionId: 49,
            caption: tran.GetText('Kontrola poškození'),
            icon: { name: 'carCrash' },
            status: '',
            isOpened: false,
            visible: true,
            atrInfoStatusProps: {
                text: undefined,
            },
            statusIcon: { name: 'orangeLock', png: true },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionDamageZV></SectionDamageZV>
            ),
        },
        {
            sectionId: 50,
            caption: tran.GetText('Kontrola předchozí inzerce'),
            icon: { name: 'album' },
            status: '',
            isOpened: false,
            visible: true,
            atrInfoStatusProps: {
                text: undefined,
            },
            statusIcon: { name: 'orangeLock', png: true },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionPreviousAdvertZV></SectionPreviousAdvertZV>
            ),
        },
        {
            sectionId: 51,
            caption: tran.GetText('Kontrola stáří a původu'),
            icon: { name: 'calendarCar' },
            status: '',
            isOpened: false,
            visible: true,
            atrInfoStatusProps: {
                text: undefined,
            },
            statusIcon: { name: 'orangeLock', png: true },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionAgeAndOriginZV></SectionAgeAndOriginZV>
            ),
        },
        {
            sectionId: 52,
            caption: tran.GetText('Svolávací akce'),
            icon: { name: 'megaphone' },
            status: '',
            isOpened: false,
            visible: true,
            atrInfoStatusProps: {
                text: undefined,
            },
            statusIcon: { name: 'orangeLock', png: true },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionCallingActionsZV></SectionCallingActionsZV>
            ),
        },
        {
            sectionId: 53,
            caption: tran.GetText('Kontrola zabezpečení'),
            icon: { name: 'carLock' },
            status: '',
            isOpened: false,
            visible: true,
            atrInfoStatusProps: {
                text: undefined,
            },
            statusIcon: { name: 'orangeLock', png: true },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionSafetyZV></SectionSafetyZV>
            ),
        },
        {
            sectionId: 54,
            caption: tran.GetText('Informace o vozidle'),
            icon: { name: 'car' },
            status: '',
            isOpened: false,
            visible: true,
            atrInfoStatusProps: {
                text: undefined,
            },
            statusIcon: { name: 'orangeLock', png: true },
            toggledControl: (props: DetailPayedProps): ReactElement => (
                <SectionCarInfoZV></SectionCarInfoZV>
            ),
        },
    ]

    return result
}
