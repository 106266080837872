import Highcharts, {
    SeriesZonesOptionsObject,
    XAxisOptions,
    XAxisPlotBandsOptions,
    YAxisOptions,
} from 'highcharts'
import { createGradient } from './utils'
import { format } from '../../utils/Number'
import { queries } from '../../utils/queries'
import { Translate } from '../../translations/CebiaTranslator'

type Timestamp = number
type Mileage = number
export type Coords = [Timestamp, Mileage]
export type TimestampRange = [Timestamp, Timestamp]

export type ChartOptions = {
    color?: string
    unverifiedBandsColor?: string
    unverifiedColor?: string
    animations?: boolean
    isPrint?: boolean
}

export function getHightchartsOptions(
    data: Coords[],
    unverifiedData: TimestampRange[],
    options: ChartOptions = {},
): Highcharts.Options {
    const {
        color = '#0485F4',
        unverifiedBandsColor = 'rgba(181,228,246,0.15)',
        unverifiedColor = '#0485F4',
    } = options
    const tran = new Translate()
    const xAxis: XAxisOptions = {}
    const yAxis: YAxisOptions = {}
    const zones: SeriesZonesOptionsObject[] = []
    if (!data.length) {
        yAxis.min = 0
        yAxis.max = 300000
        yAxis.tickInterval = 50000

        const date = new Date()
        xAxis.max = date.getTime()
        xAxis.min = date.setFullYear(date.getFullYear() - 16)
    }

    if (data.length && unverifiedData) {
        const xAxisPlotBands: XAxisPlotBandsOptions[] = []
        const froms: number[] = []

        unverifiedData.forEach(([from, to]) => {
            xAxisPlotBands.push({
                from,
                to,
                color: unverifiedBandsColor,
                label: {
                    text: tran.GetText('neověřené období'),
                    verticalAlign: 'middle',
                    align: 'center',
                    textAlign: 'center',
                    rotation: -90,
                    x: 0,
                    y: 0,
                    useHTML: true,
                    // color: '#707990',
                    // fontSize: '13px',
                },
            })
            zones.push({
                value: from,
                dashStyle: 'Solid',
                color,
            })
            zones.push({
                value: to + 1,
                dashStyle: 'Dash',
                color: unverifiedColor,
                fillColor: 'rgba(255,255,255,0)',
            })

            froms.push(from)
        })

        xAxis.min = Math.min(...froms, data[0][0])
        xAxis.plotBands = xAxisPlotBands
    }

    const chartOptions: Highcharts.Options = {
        accessibility: {
            enabled: false,
        },
        chart: {
            type: 'area',
        },
        title: {
            text: '',
        },
        tooltip: {
            formatter: function () {
                if (this.x) {
                    const datum = new Date(this.x)
                    return `
                    <table>
                        <tr><td class="Chart-tooltipValue"><span class="highcharts-label-small">${(
                            '0' +
                            (datum.getUTCMonth() + 1)
                        ).slice(-2)}/${datum.getFullYear()} : </span>${format(this.y).replace(
                        ',',
                        ' ',
                    )} ${tran.GetText('km')}</td></tr>
                    </table>
                `
                }
            },
        },
        legend: {
            enabled: false,
        },
        yAxis: {
            ...yAxis,
            title: {
                text: '',
            },
            labels: {
                style: {
                    fontSize: '13px',
                    fontWeight: '700',
                    color: '#707990',
                },
                formatter: data => {
                    return `${format(data.value).replace(',', ' ')} ${tran.GetText('km')}`
                },
            },
        },
        xAxis: {
            ...xAxis,
            type: 'datetime',
            tickmarkPlacement: 'on',
            // tickInterval: 365 * 24 * 60 * 60 * 1000,
            labels: {
                rotation: 0,
                autoRotation: [-45],
            },
            units: [['year', [1, 4, 10]]],
        },
        series: [
            {
                name: 'Počet najetých km',
                type: 'area',
                color,
                fillColor: createGradient('#B5E4F6'),
                data,
                zoneAxis: 'x',
                zones,
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        callback: () => window.matchMedia(queries.mediumMax).matches,
                    },
                    chartOptions: {
                        yAxis: {
                            labels: {
                                style: {
                                    fontSize: '12px',
                                },
                            },
                        },
                    },
                },
            ],
        },
    }

    return chartOptions
}
