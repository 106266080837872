import { ImageProps } from '../components/Image'
import { VehicleIdentificationPhotoItem } from '../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'

export const getPhotoImageProps = (identificationItems: VehicleIdentificationPhotoItem[]) => {
    const concatImages = new Array<ImageProps>()
    if (identificationItems && (identificationItems.length ?? 0) > 0) {
        identificationItems.forEach(element => {
            concatImages.push({
                alt: element.description,
                src: (element?.urlOrig?.length ?? 0) > 0 ? element.urlOrig : element.url,
            })
        })
    }

    return concatImages
}
