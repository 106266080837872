import { ReactElement } from 'react'
import { ToggleOpenerGeneralVoucher } from '../../../../components/ToggleOpenerGeneralVoucher'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'

export const SectionCarInsurance = (): ReactElement => {
    return (
        <>
            <div className="sectionCouponSales">
                <div className="Grid gmb-5">
                    <ToggleOpenerGeneralVoucher
                        href="#saleCarInsurance"
                        titleLeft="Výhodné pojištění na RIXO.cz"
                        button="Detail"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="saleCarInsurance">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Porovnejte si nabídky povinného ručení nebo havarijního pojištění
                                vozidla od nejlepších českých pojišťoven a vyberte si tu
                                nejvýhodnější.
                            </p>
                            <p className="text-justify">
                                Na{' '}
                                <a
                                    href="https://www.rixo.cz/pojisteni-vozidel/?a_box=kn3ptbnj&a_cam=1"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    RIXO.cz
                                </a>{' '}
                                stačí zadat SPZ vozidla a technické údaje o vozidle se doplní sami.
                                Vy už jen doplníte pár informací o sobě, abyste nepřišli o bonusy.
                                Během několika minut získáte kompletní přehled nabídek a pojistku si
                                můžete rovnou sjednat online.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://www.rixo.cz/pojisteni-vozidel/?a_box=kn3ptbnj&a_cam=1"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Spočítat cenu pojištění</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>

                    <ToggleOpenerGeneralVoucher
                        href="#ePojisteni"
                        titleLeft="Srovnání povinného ručení od ePojisteni.cz"
                        button="Detail"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="ePojisteni">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Sjednejte si nejvýhodnější povinné ručení na trhu do 2 minut a zcela
                                online. Zadejte svoji SPZ, porovnejte si nabídky pojišťoven a
                                ušetřete až 50 %! Zelenou kartu Vám zašleme na e-mail.
                            </p>

                            <div className="ButtonList mb-10">
                                <a
                                    href="https://espolupracecz.go2cloud.org/aff_c?offer_id=2&aff_id=24182"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Srovnat povinné ručení</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>

                    <ToggleOpenerGeneralVoucher
                        href="#Extendedwarrantyinsurance"
                        titleLeft="Pojištění prodloužená záruka pro vaše vozidlo"
                        titleRight="-10 %"
                        button="Detail"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="Extendedwarrantyinsurance">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Nenechte se zaskočit nutnou{' '}
                                <b>
                                    opravou motoru, převodovky, spojky, klimatizace či dalších dílů
                                </b>
                                , které <b>mohou stát desítky tisíc korun</b>. Sjednejte si během
                                koupě vozidla prodlouženou záruku DEFEND Car Protect, která vás{' '}
                                <b>ochrání před nepředvídanými náklady</b> za opravy vašeho vozu.{' '}
                                Nyní <b>s exkluzivní slevou 10 %!</b>
                            </p>
                            <div className="Text mb-30">
                                <ul>
                                    <li>
                                        <b>Efektivní ochrana až na 3 roky</b>
                                    </li>
                                    <li>
                                        <b>Pro vozy v záruce výrobce i po jejím vypršení</b>
                                    </li>
                                    <li>
                                        <b>Široké spektrum krytých součástek</b>
                                    </li>
                                    <li>
                                        <b>Platíte jen za to, co opravdu potřebujete</b> (řada
                                        volitelných parametrů)
                                    </li>
                                    <li>
                                        <b>Můžete využít opakovaně</b> (neomezený počet pojistných
                                        událostí až do výše pojistného limitu)
                                    </li>
                                    <li>
                                        <b>Zvýšíte hodnotu vozu</b> (můžete převést na nového
                                        majitele při prodeji vozu)
                                    </li>
                                </ul>
                            </div>
                            <p className="text-justify">
                                <b>Jak uplatnit slevu?</b>
                            </p>
                            <p className="text-justify">
                                S exkluzivní slevou 10 % při zadání kódu <b>CEBIA1</b> je ochrana
                                vašeho vozidla dostupnější než kdy předtím. Slevu je{' '}
                                <b>možné uplatnit do 10 dnů od nákupu prověření vozidla</b> a nelze
                                ji kombinovat s dalšími slevami.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://www.defend-online.eu/cs/kontaktni-formular?cebia"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Více informací</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>

                    <ToggleOpenerGeneralVoucher
                        href="#TechnicalInspectionInsurance"
                        titleLeft="Pojištění technické kontroly a emisí"
                        button="Detail"
                        titleRight="-10 %"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="TechnicalInspectionInsurance">
                        <div className="toggleContent">
                            <p className="text-justify">
                                Připravte se na{' '}
                                <b>příští pravidelnou technickou kontrolu a měření emisí</b> již
                                nyní. Sjednejte si pojištění DEFEND Technical Control, které vám{' '}
                                <b>
                                    uhradí výměnu či opravu součástek, kvůli kterým kontrolou
                                    neprojdete.{' '}
                                </b>
                                Nyní <b>s exkluzivní slevou 10 %!</b>
                            </p>
                            <div className="Text mb-30">
                                <ul>
                                    <li>
                                        <b>Pro vozy se stářím až 20 let</b>
                                    </li>
                                    <li>
                                        <b>Široký seznam krytých součástek</b>
                                    </li>
                                    <li>
                                        <b>Uhradí i odtah do servisu</b> (při kryté poruše)
                                    </li>
                                    <li>
                                        <b>Uhradí poplatek za opakovanou technickou kontrolu</b>{' '}
                                        (při kryté poruše)
                                    </li>
                                    <li>
                                        <b>Bez omezení počtu najetých km</b> v průběhu trvání
                                        pojištění
                                    </li>
                                </ul>
                            </div>
                            <p className="text-justify">
                                <b>Jak uplatnit slevu?</b>
                            </p>
                            <p className="text-justify">
                                Pokud si sjednáte pojištění DEFEND Technical Control nyní, získáte
                                exkluzivní 10% slevu s kódem <b>CEBIA1</b>. Slevu je{' '}
                                <b>možné uplatnit do 10 dnů od nákupu prověření vozidla</b> a nelze
                                ji kombinovat s dalšími slevami.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://www.defend-online.eu/cs/produkty/pojisteni-stk?ac=CEBIA0#product-calculator-anchor"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Sjednat pojištění</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>

                    <ToggleOpenerGeneralVoucher
                        href="#GapCarMotorbikeInsurance"
                        titleLeft="Pojištění Gap pro auta i motorky"
                        button="Detail"
                        titleRight="-10 %"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="GapCarMotorbikeInsurance">
                        <div className="toggleContent">
                            <p className="text-justify">
                                <b>Chraňte se před finanční ztrátou v důsledku poklesu hodnoty</b>{' '}
                                vašeho vozidla nebo motorky. Sjednejte si pojištění DEFEND Gap
                                MAX+XS, které vám v případě totální škody nebo krádeže{' '}
                                <b>
                                    uhradí rozdíl mezi jeho aktuální tržní cenou a hodnotou, za
                                    kterou jste ho kupovali
                                </b>
                                , případně doplatí zbylou část úvěru nebo leasingu. Nyní{' '}
                                <b>s exkluzivní slevou 10 %!</b>
                            </p>
                            <div className="Text mb-30">
                                <ul>
                                    <li>
                                        <b>Efektivní ochrana až na 5 let</b>
                                    </li>
                                    <li>
                                        <b>Vhodné pro luxusní vozy i ty z kategorie levnějších</b>
                                    </li>
                                    <li>
                                        <b>
                                            Limit pojistného plnění určujete vy podle hodnoty vozu a
                                            svých potřeb
                                        </b>
                                    </li>
                                    <li>
                                        <b>Kryje vše, co vaše havarijní pojištění</b>
                                    </li>
                                    <li>
                                        <b>Bez spoluúčasti z vaší strany</b>
                                    </li>
                                    <li>
                                        <b>Navíc kryje i spoluúčast u havarijního pojištění</b>
                                    </li>
                                </ul>
                            </div>
                            <p className="text-justify">
                                <b>Jak uplatnit slevu?</b>
                            </p>
                            <p className="text-justify">
                                Sleva 10 % na DEFEND Gap MAX+XS s kódem <b>CEBIA1</b>. Slevu je{' '}
                                <b>možné uplatnit do 10 dnů od nákupu prověření vozidla</b> a nelze
                                ji kombinovat s dalšími slevami.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://www.defend-online.eu/cs/produkty/gap-max-xs?ac=CEBIA1#product-calculator-anchor"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Sjednat pojištění</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>
                </div>
            </div>
        </>
    )
}
