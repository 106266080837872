import { default as HC } from 'highcharts'

const globalOptions: HC.Options = {
    // lang: {
    //     decimalPoint: ',',
    //     shortMonths: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čer', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
    //     weekdays: ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle'],
    //     months: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
    //     monthsGenitive: ['Ledna', 'Února', 'Března', 'Dubna', 'Května', 'Června', 'Července', 'Srpna', 'Září', 'Října', 'Listopadu', 'Prosince']
    // },

    yAxis: {
        labels: {
            style: {
                fontSize: '13px',
                fontWeight: '700',
                color: '#707990',
            },
        },
    },

    xAxis: {
        labels: {
            style: {
                fontSize: '13px',
                fontWeight: '700',
                color: '#707990',
            },
        },
        dateTimeLabelFormats: {
            year: {
                main: '01/%Y',
            },
        },
    },

    tooltip: {
        borderWidth: 0,
        borderRadius: 10,
        shadow: false,
        distance: 20,
        padding: 0,
        style: {
            color: '#fff',
        },
        backgroundColor: '#0485F4',
        split: false,
        useHTML: true,
        headerFormat: '',
        footerFormat: '',
    },

    plotOptions: {
        series: {
            marker: {
                symbol: 'circle',
                radius: 5,
                lineWidth: 3,
                lineColor: 'white',
                enabled: true,
            },
        },
    },
}

HC.setOptions(globalOptions)

export const Highcharts = HC
