import { ReactElement, useEffect, useState } from 'react'
import { ImageProps, Image } from '../../../../../components/Image'
import { LightboxGallery } from '../../../../../components/LightboxGallery'
import { ToggleItemGeneral } from '../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralSimple } from '../../../../../components/ToggleOpenerGeneralSimple'
import {
    getPhotosUrlArray,
    PhotosDescriptionItem,
} from '../../../../../models/DetailPayedResults/Photos/photosControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'

type AdvertPhotosType = {
    source: PhotosDescriptionItem
    tran: Translate
    index: number
    isPrint?: boolean
    isFleet?: boolean
}

export const AdvertPhotos = ({
    source,
    tran,
    index,
    isPrint,
    isFleet,
}: AdvertPhotosType): ReactElement => {
    const [photosState, setPhotosState] = useState({
        index: 0,
        isOpen: false,
    })
    const [allImages, setAllImages] = useState(new Array<ImageProps>())
    useEffect(() => {
        if (source.advertImageUrls && source.advertImageUrls.length > 0) {
            setAllImages(getPhotosUrlArray(source.advertImageUrls, false))
        }
    }, source.advertImageUrls)
    const fotoLang = tran.GetText('Fotografie')
    if (allImages.length == 0) return <></>

    if (isPrint === true) {
        return (
            <div className="AtrGallery">
                {allImages.map((image, index) => (
                    <div className="fl" key={`los_${index}_damagePhotos`}>
                        <Image {...image} />
                    </div>
                ))}
            </div>
        )
    }
    return (
        <div className="mb-30">
            <ToggleOpenerGeneralSimple
                image={{
                    src: allImages[0].src,
                    alt: '',
                }}
                href={`#damagePhotos_${index}`}
                titleLeft={
                    isFleet
                        ? tran.GetText(
                              'Stav vozidla evidovaný při jeho prodeji. Případné poškození si prohlédněte na přiložené fotodokumentaci.',
                          )
                        : fotoLang + '(' + allImages.length + ')'
                }
                titleRight=""
                button={tran.GetText('Zobrazit')}
                isOpened={false}
            />
            <ToggleItemGeneral id={`damagePhotos_${index}`}>
                {photosState.isOpen && (
                    <LightboxGallery
                        images={allImages}
                        imagesState={photosState}
                        setPhotosState={setPhotosState}
                    ></LightboxGallery>
                )}
                <div className="AtrGallery Grid gap-20 xl:gap-15 gmb-20">
                    {allImages.map((image, index) => (
                        <div
                            className={'Grid-cell w-1/2 smw:w-1/3 lg:w-1/5 xxl:w-2/12'}
                            key={`los_${index}_damagePhotos`}
                            onClick={() =>
                                setPhotosState({
                                    index: index,
                                    isOpen: true,
                                })
                            }
                        >
                            <Image {...image} />
                        </div>
                    ))}
                </div>
            </ToggleItemGeneral>
        </div>
    )
}
