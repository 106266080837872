import { ReactElement } from 'react'
import { CouponSalesProps } from '..'
import { czCurrency } from '../../../../..'
import { Table } from '../../../../../components/Table'
import { ToggleItemGeneral } from '../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../../components/ToggleOpenerGeneralVoucher'

export const SectionSaleSafety = ({ sourceData, tran }: CouponSalesProps): ReactElement => {
    return (
        <>
            <ToggleOpenerGeneralVoucher
                href="#saleSafetyToggle"
                titleLeft={tran.GetText('Sleva na zabezpečení vozidla')}
                titleRight={`${tran.GetText('- 850')} ${tran.GetText(czCurrency)}`}
                button={tran.GetText('Detail')}
                isOpened={false}
            />
            <ToggleItemGeneral id="saleSafetyToggle">
                <div className="toggleContent">
                    <p className="text-justify">
                        {tran.GetText(
                            'Dovolujeme si Vám nabídnout jednoduché, efektivní a dostupné zabezpečení Vašeho nového vozidla formou bezpečnostního značení skel - OCIS. Tento způsob zabezpečení snižuje pravděpodobnost odcizení Vašeho vozidla až o 96 % a u některých pojišťoven získáte až 10% slevu na pojistném.',
                        )}
                    </p>
                    <Table className="text-left mb-30">
                        <tbody>
                            <tr>
                                <th className="text-middle font-normal">
                                    {tran.GetText('Základní cena')}
                                </th>
                                <td className="text-middle pl-10 md:pl-80 text-right text-noWrap">
                                    1 700 {tran.GetText(czCurrency)}
                                </td>
                                <td></td>
                            </tr>
                            <tr className="color-green">
                                <th className="text-middle">{tran.GetText('SLEVA')}</th>
                                <td className="text-middle text-right text-noWrap">
                                    - 850 {tran.GetText(czCurrency)}
                                </td>
                                <td></td>
                            </tr>
                            <tr className="color-darkBlue">
                                <th className="text-middle">
                                    <strong>{tran.GetText('Cena po slevě')}</strong>
                                </th>
                                <td className="text-middle text-right font-bold text-noWrap">
                                    850 {tran.GetText(czCurrency)}
                                </td>
                                <td className="text-middle font-bold text-noWrap">
                                    {tran.GetText('s DPH')}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="ButtonList mb-10">
                        <a
                            href="https://www.cebia.cz/nase-sluzby/po-nakupu-vozidla/znaceni-oken/popis-sluzeb.html"
                            target="_blank"
                            className="Button Button--blue Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">{tran.GetText('Více info')}</span>
                        </a>
                        {/* <a
                            href="https://cool.iprima.cz/jak-auto-ochranit-pred-zlodeji"
                            target="_blank"
                            className="Button Button--blue Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">{tran.GetText('Video')}</span>
                        </a> */}
                        {!sourceData.couponIsTest && (
                            <a
                                href={
                                    process.env.PUBLIC_URL + '/docs/Darkova_objednavka_EUROVIN.pdf'
                                }
                                target="_blank"
                                className="Button Button--small"
                                rel="noreferrer"
                            >
                                <span className="Button-content">{tran.GetText('Objednat')}</span>
                            </a>
                        )}
                    </div>
                </div>
            </ToggleItemGeneral>
        </>
    )
}
