import { ContactEmailService } from '../enums/ContactEmailService'

export default class ContactValuesModel {
    id?: string = undefined
    firstName = ''
    lastName = ''
    phone = ''
    email = ''
    message = ''
    cGdpr = false
    language = ''
    coupon = ''
    ico = ''
    service = ContactEmailService.CARHISTORY
    isBusiness = false

    constructor(isBusiness?: boolean) {
        this.isBusiness = isBusiness ?? false
    }
}
