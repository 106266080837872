import { ReactElement } from 'react'
import { Translate } from '../../translations/CebiaTranslator'
import { TimeLimitedPopup } from '../TimeLimitedPopup'

export const TimeLimitedOffer = (): ReactElement => {
    const tran = new Translate()
    return (
        <TimeLimitedPopup
            name={'craciun'}
            validUntil={new Date('2023-01-03')}
            imagePosition={'left'}
            showImage={true}
            html={tran.GetText(
                '<h2><b>Vánoční sleva 30 % na prověření vozidla</b></h2><br/><p>Prověřte si jakékoliv vozidlo s 30% vánoční slevou. Akce platí do 2. 1. 2023 a nelze kombinovat s jinými slevami.</p><br/>',
            )}
            expireInDate={1}
            showButton={true}
            buttonText={tran.GetText('Prověřit VIN kód nyní')}
            buttonUrl="/"
            allowedLanguages={['ro']}
            utmCampaingn="CRACIUN"
            showAfterMiliSeconds={2000}
        ></TimeLimitedPopup>
    )
}
