import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Timeline } from '../../../../components/Timeline'
import { Title } from '../../../../components/Title'
import { Translate } from '../../../../translations/CebiaTranslator'
import { i18n } from '../../../../translations/i18n'

export const SectionAboutHistory = (): ReactElement => {
    const tran = new Translate()
    const items = [
        {
            year: '2021',
            title: tran.GetText('Jsme silnější'),
            text: tran.GetText(
                'Stáváme se součástí českého holdingu EAG. Díky tomu se naše databáze výrazně rozšiřují o záznamy z celé Evropy – především Německa, Rakouska či Dánska.',
            ),
        },
        {
            year: '2016',
            title: tran.GetText('Vývoj vlastního nástroje na oceňování vozidel'),
            text: tran.GetText(
                'Uvádíme na trh online nástroj na určení ceny ojetých vozidel. Díky naší rozsáhlé databázi záznamů o ojetých vozech je velmi přesný a rychle si nachází cestu do největších pojišťoven a leasingových společností.',
            ),
        },
        {
            year: '2011',
            title: tran.GetText('Měníme trh s ojetými vozidly'),
            text: tran.GetText(
                'S rostoucím zájmem o výběr vozu online začínáme spolupracovat s největšími inzertními portály ve střední Evropě. Výsledky našeho prověření historie se stávají puncem kvality ojetého vozu.',
            ),
        },
        {
            year: '2008',
            title: tran.GetText('Vstup do online světa'),
            text: tran.GetText(
                'Naše služby jsou od teď snadno dostupné každému z pohodlí domova. Podvody s ojetými vozy jsou na svém vrcholu a my proti nim bojujeme ze všech sil.',
            ),
        },
        {
            year: '2000',
            title: tran.GetText('Rozšiřujeme služby'),
            text: tran.GetText(
                'K našim službám přidáváme fyzickou kontrolu identifikátorů (VIN) vozidel a naše databáze se skokově rozšiřuje. Spolupracujeme s pojišťovnami, státními orgány, policií.',
            ),
        },
        {
            year: '1996',
            title: tran.GetText('Vstup do zahraničí'),
            text: tran.GetText(
                'Nejen v České republice se rozmáhá obchod s odcizenými vozidly. Zakládáme proto mezinárodní databázi odcizených vozidel, která nám pomáhá odhalovat pravý původ ojetých vozů.',
            ),
        },
        {
            year: '1991',
            title: tran.GetText('Založení společnosti v Praze'),
            text: tran.GetText(
                'Tři univerzitní spolužáci zakládají společnost Cebia. Původní záměr podnikat v IT brzy vystřídal zájem o zabezpečování vozidel.',
            ),
        },
    ]
    if (i18n.language === 'al')
        items.unshift({
            year: '2023',
            title: tran.GetText('Spolupráce se společností Gjirafa'),
            text: tran.GetText(
                'Ve spolupráci se společností Gjirafa jsme rozšířili nabídku služeb do Kosova, Albánie a Severní Makedonie.',
            ),
        })
    return (
        <Section variantPend="appended" className="SectionAboutHistory">
            <Container>
                <Title as="h2" className="color-darkBlue Beta">
                    {tran.GetText('Historie společnosti')}
                </Title>

                <Timeline items={items} />
            </Container>
        </Section>
    )
}
