import classNames, { Argument } from 'classnames'
import { ReactElement } from 'react'
import './index.styl'

export type ShapeProps = {
    variant:
        | 'lightBlue'
        | 'lightBlueGradient'
        | 'green'
        | 'greenLine'
        | 'blue'
        | 'blueLine'
        | 'blueGradient'
        | 'darkBlueGradient'
    className?: Argument
}

export const Shape = ({ variant, className: _className }: ShapeProps): ReactElement => {
    const className = classNames('Shape', `Shape--${variant}`, _className)
    return <div className={className}></div>
}
