import { useQuery } from 'react-query'
import http from './http-common'
export const activateSmartCode = async (id: string) => {
    let retVal: string | null = null

    if (id) {
        const tmpResponse = await http.get<string>('/SmartCode/activateSmartCode?requestId=' + id)

        if (tmpResponse.status === 200 && tmpResponse.data) {
            if (tmpResponse.data.length > 0) {
                retVal = tmpResponse.data
            }
        }
    }

    return retVal
}

export const getApc = async (id: string) => {
    let retVal: string | null = null
    if (id) {
        const tmpResponse = await http.get<string>('/Payed/GetApc?id=' + id)

        if (tmpResponse.status === 200 && tmpResponse.data) {
            if (tmpResponse.data.length > 0) {
                retVal = tmpResponse.data
            }
        }
    }

    return retVal
}
