import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionTaxi } from '../../../../detailPaid/atr-items/SectionTaxi'
import './body.styl'

let PrintAllowIncrement = true

export const Taxi = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    if (!sourceData?.taxiControlResult) return <></>

    const taxiData = new PayedJsonResult()
    taxiData.taxiControlResult = sourceData.taxiControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola taxi')}
            </h2>

            <Section className="TaxiSection">
                <SectionTaxi sourceData={taxiData} isPrint={true} />
            </Section>
        </div>
    )
}
