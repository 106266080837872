import './index.styl'
import { ReactElement } from 'react'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Section } from '../../../../components/Section'

export const SectionCallingActionsZV = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="LockedSection">
            <div className="text-center pb-25">
                <h2 className="orange font-bold pt-10">
                    {tran.GetText('Kategorie je dostupná pouze v kompletním prověření')}
                </h2>
            </div>
            <p className="text-justify pb-25">
                {tran.GetHtml(
                    'Lze zkontrolovat, zda se vozidla mohla týkat jedna či více svolávacích akcí. Svolávací akce je vyhlašována výrobcem v případě, že má vozidlo výrobní závadu ohrožující zdraví, bezpečnost či životní prostředí. V rámci svolávací akce je závada zdarma opravena v autorizovaném servisu. Díky informacím v kompletním prověření můžete zkontrolovat, zda vozidlo všechny platné svolávací akce podstoupilo.',
                )}
            </p>
        </Section>
    )
}
