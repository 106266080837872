import { HubConnection } from '@microsoft/signalr'
import { DashBoardCarInfoJsonResult, DashBoardJsonResult } from './dashBoardJsonResult'
import { UTMModel } from './utmModel'
import { ValidationResponseModel } from './validationResponseModel'
class AppModel {
    vin = ''
    vinError = ''
    connectionId = ''
    viewId = ''
    utmModel = new UTMModel()
    odometerDashBoardInfo = new DashBoardJsonResult()
    damageDashBoardInfo = new DashBoardJsonResult()
    priceDashboardInfo = new DashBoardJsonResult()
    validateResponseModel = new ValidationResponseModel(null)
    photoDashboardInfo = new DashBoardJsonResult()
    serviceHistoryDashboardInfo = new DashBoardJsonResult()
    connection: HubConnection | undefined
    allLoaded = false
    isDemoCoupon = false
    variant = ''
}

export { AppModel }
