import { LanguageType } from '../enums/LanguageType'
import { IAtrSection } from '../utils/arrayExtensions'
import { Sale } from './paymentReqModel'
import { ValidationResponseModel } from './validationResponseModel'
class DashBoardJsonResult implements IAtrSection {
    sectionId: number | undefined
    recordsCount: number | undefined
    minDate!: Date | undefined
    maxDate!: Date | undefined
    discountName!: string | undefined
    showDiscount!: boolean | undefined
    standardPrice!: number | undefined
    standardPriceWithoutVat!: number | undefined
    totalPrice!: number | undefined
    totalPriceWithoutVat!: number | undefined
    zeroResultSections: [] | undefined
    isLoading = true
    currency: string | undefined
    // lng: string | undefined
    countryId: LanguageType | undefined
    //kdyz je aktivni 0.bit tak svolavaci akce.Kdyz je aktivni 1.bit tak oceneni vozidla.
    otherType: number | undefined
    productId: number | undefined
    sale!: Sale
    vat: number | undefined
    vatDeduction: boolean | undefined
    decimalPlaces!: number | undefined
}

class DashBoardCarInfoJsonResult implements IAtrSection {
    sectionId: number | undefined
    carMake: string | undefined
    carModel: string | undefined
    vin: string | undefined
    imageData: [] | undefined
    //kdyz je aktivni 0.bit tak svolavaci akce.Kdyz je aktivni 1.bit tak oceneni vozidla.
    otherType: number | undefined
    isLoading = true

    constructor(validationResponseModel?: ValidationResponseModel) {
        if (validationResponseModel && (validationResponseModel.carMake?.length ?? 0) > 0) {
            this.carMake = validationResponseModel.carMake
            this.carModel = validationResponseModel.carModel
            this.vin = validationResponseModel.carVin
            this.imageData = validationResponseModel.imageData
        }
    }
}

class DashBoardOtherJsonResult implements IAtrSection {
    sectionId: number | undefined
    showVinCheck = false
    isNoData = false
}

export { DashBoardJsonResult, DashBoardCarInfoJsonResult, DashBoardOtherJsonResult }
