import './index.styl'
import { ReactElement } from 'react'
import { Section } from '../../../../components/Section'
import { Container } from '../../../../components/Container'
import { TrustedService } from '../../../../components/TrustedService'
import { Translate } from '../../../../translations/CebiaTranslator'

export const SectionHighlights = (): ReactElement => {
    const tran = new Translate()
    return (
        <Section className="SectionHighlights">
            <Container>
                <div className="Grid gap-50 gmb-20 justify-center align-center color-darkBlue lg:font-lg">
                    <div className="Grid-cell w-auto mdMax:hidden">
                        <div className="SectionHighlights-ribbon">
                            <TrustedService />
                        </div>
                    </div>

                    <div className="Grid-cell md:w-9/12 lg:w-8/12">
                        <ul className="SectionHighlights-list text-center lg:text-left">
                            <li className="SectionHighlights-item">
                                <span
                                    className="Icon Icon--globe color-green md:mr-10"
                                    aria-hidden="true"
                                ></span>
                                {tran.GetText('Historie vozidel z 32+ zemí')}
                            </li>
                            <li className="SectionHighlights-item">
                                <span
                                    className="Icon Icon--thumbsup color-green md:mr-10"
                                    aria-hidden="true"
                                ></span>
                                {tran.GetText('Miliony spokojených zákazníků')}
                            </li>
                            <li className="SectionHighlights-item">
                                <span
                                    className="Icon Icon--car color-green md:mr-10"
                                    aria-hidden="true"
                                ></span>
                                {tran.GetText('30 000 000+ prověřených vozů')}
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </Section>
    )
}
