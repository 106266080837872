import './index.styl'
import { ReactElement } from 'react'
import { useModal } from '../../components/Modal'
import { Translate, _, __ } from '../../translations/CebiaTranslator'

type Props = {
    subtitle: string
    title: string
    text: string
    isLinkVin?: boolean
}

export const HomepageStep = ({ subtitle, title, text, isLinkVin = false }: Props): ReactElement => {
    const { open } = useModal('findVinModal')
    const tran = new Translate()

    return (
        <div className="Grid-cell lg:w-3/12">
            <article className="HomepageStep">
                <span className="HomepageStep-subtitle">{subtitle}</span>
                <h3 className="HomepageStep-title">{title}</h3>
                <p className="HomepageStep-text Text">
                    <span dangerouslySetInnerHTML={{ __html: text }}></span>
                    {isLinkVin && (
                        <span>
                            <br />
                            <button
                                className="Link Link--line color-textBlue"
                                onClick={() => open()}
                            >
                                {tran.GetText('Kde najdu VIN?')}
                            </button>
                        </span>
                    )}
                </p>
            </article>
        </div>
    )
}
