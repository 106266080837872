import './index.styl'
import { ReactElement } from 'react'
import classNames from 'classnames'

export type SectionShapeProps = {
    variant:
        | 'radial'
        | 'radialLeftTop'
        | 'right'
        | 'leftBottom'
        | 'left'
        | 'leftMiddle'
        | 'rightMiddleUp'
        | 'rightMiddle'
        | 'rightBottomUp'
        | 'rightCustom'
}

export const SectionShape = ({ variant }: SectionShapeProps): ReactElement => {
    const className = classNames('SectionShape', {
        [`SectionShape--${variant}`]: variant,
    })

    return <div className={className}></div>
}
