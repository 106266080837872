import { ReactElement } from 'react'
import { Title } from '../Title'

type Props = {
    message: string
}

export const CrModalFailure = ({ message }: Props): ReactElement => {
    return (
        <div className="Grid">
            <div className="Grid-cell md:w-12/12">
                <Title as="header">
                    <h2 className="color-darkBlue"> {message}</h2>
                </Title>
            </div>
        </div>
    )
}
