import parse from 'html-react-parser'
import { Fragment } from 'react'
import { ShowIcarisPhotoDescription } from '../../../detailPaid/atr-items/SectionVehicleInfo/Section/VehicleIdentification/vehicleIdentification'
import { PrintDataProps } from '../../vypis'

export const IcarisPhotoDesc = ({ sourceData, tran }: PrintDataProps) => {
    if (
        !sourceData ||
        (sourceData.vehicleInfoControlResult?.identification?.icarisIndetification?.length ?? 0) <=
            0
    )
        return <></>

    const items = sourceData.vehicleInfoControlResult?.identification?.icarisIndetification

    return (
        <>
            {items?.map((item, index) => (
                <div className="itemIdent" key={`icrsDef_${index}`}>
                    <h2>{item.caption}</h2>
                    <p>{parse(item.description)}</p>
                    <span>
                        {tran.GetText('Vozidlo:')} {item.car}
                    </span>
                    <div className="col-md-12">
                        <div className="gap-30 gmb-20">
                            <div className="w-fill">
                                <div className="gmb-20 pageBreakAvoidBefore">
                                    {item.photoGroups?.map((photo, photoIndex) => (
                                        <Fragment key={`icrsPhtIndex_${photoIndex}`}>
                                            <p>{photo.groupName}</p>
                                            {ShowIcarisPhotoDescription(photo?.photos, true)}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}
