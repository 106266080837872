import moment from 'moment'
import { Table } from '../../../../components/Table'
import { PrintDataProps } from '../../vypis'

export const CouponInfoSection = ({ sourceData, tran }: PrintDataProps) => {
    if (!sourceData) return <></>

    return (
        <div className="Table mb-10 half">
            <Table>
                <thead>
                    <tr>
                        <th colSpan={2} className="tblCouponInfoHead">
                            {tran.GetText('Informace o kuponu AUTOTRACER')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!sourceData.isCouponTest && (
                        <tr>
                            <td>{tran.GetText('Číslo kuponu pro opakovaný vstup:')}</td>
                            <td>{sourceData.couponNumber}</td>
                        </tr>
                    )}
                    <tr>
                        <td>{tran.GetHtml('Datum aktivace kuponu:')}</td>
                        <td>
                            {moment(sourceData.couponActivation).format('DD. MM. YYYY HH:mm:ss')}
                        </td>
                    </tr>
                    <tr>
                        <td>{tran.GetText('Platnost do:')}</td>
                        <td>{moment(sourceData.couponValidTo).format('DD. MM. YYYY HH:mm:ss')}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}
