import moment from 'moment'
import { useState } from 'react'
import { Table } from '../../../../../components/Table'
import { ToggleItemGeneral } from '../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../../components/ToggleOpenerGeneralVoucher'
import { ValuationControlData } from '../../../../../models/DetailPayedResults/Valuation/valuationControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'
import { SectionAdvertsOther } from './advertsOtherSection'

export type Props = {
    sourceData: ValuationControlData
    tran: Translate
}

export const SectionValuationDone = ({ sourceData, tran }: Props) => {
    if (!sourceData.doneValuation) return <></>

    return (
        <>
            <div className="mb-20">
                <Table>
                    <thead>
                        <tr>
                            <th colSpan={2} className="text-left">
                                {tran.GetText('Kontrola identifikátorů')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{tran.GetText('VIN:')}</td>
                            <td>{sourceData.vin}</td>
                        </tr>
                        <tr>
                            <td>{tran.GetText('Model:')}</td>
                            <td>{sourceData.model}</td>
                        </tr>
                        <tr>
                            <td>{tran.GetText('Datum první registrace:')}</td>
                            <td>{moment(sourceData.firstReg).format('DD. MM. YYYY')}</td>
                        </tr>
                        <tr>
                            <td>{tran.GetText('Stav tachometru:')}</td>
                            <td>
                                {sourceData.tacho
                                    ? `${tran.GetNumber(sourceData.tacho)} ${tran.GetText('km')}`
                                    : ''}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <article className="Grid gap-20 gmb-15 mb-10">
                <div className="Grid-cell smw:w-1/2">
                    <div className="CarValue">
                        <span className="CarValue-title">
                            {tran.GetText('Cena nového vozidla')}
                        </span>
                        <span className="CarValue-text text-noWrap GamaSize">
                            {`${tran.GetNumber(sourceData.newPrice ?? 0)} ${sourceData.currency}`}
                        </span>
                    </div>
                </div>
                <div className="Grid-cell smw:w-1/2">
                    <div className="CarValue">
                        <span className="CarValue-title">
                            {tran.GetText('Aktuální tržní cena')}
                        </span>
                        <span className="CarValue-text text-noWrap GamaSize">
                            {`${tran.GetNumber(sourceData.marketPriceFrom ?? 0)} - ${tran.GetNumber(
                                sourceData.marketPriceTo ?? 0,
                            )} ${sourceData.currency}`}
                        </span>
                    </div>
                </div>
            </article>
            <div className="Grid gmb-5">
                {(sourceData.advertsOther?.length ?? 0) > 0 && (
                    <div className="advertOther">
                        <ToggleOpenerGeneralVoucher
                            href="#advertsOtherToggle"
                            titleLeft={tran.GetText('Inzerce podobných vozidel')}
                            isOpened={false}
                        />
                        <ToggleItemGeneral id="advertsOtherToggle">
                            <SectionAdvertsOther sourceData={sourceData.advertsOther} tran={tran} />
                        </ToggleItemGeneral>
                    </div>
                )}
            </div>
        </>
    )
}
