import { ReactElement } from 'react'
import { CouponSalesProps } from '..'
import { czCurrency } from '../../../../..'
import { Table } from '../../../../../components/Table'
import { ToggleItemGeneral } from '../../../../../components/ToggleItemGeneral'
import { ToggleOpenerGeneralVoucher } from '../../../../../components/ToggleOpenerGeneralVoucher'

export const SectionProVin = ({ sourceData, tran }: CouponSalesProps): ReactElement => {
    if (sourceData.isRegInCz) return <></>

    return (
        <>
            <ToggleOpenerGeneralVoucher
                href="#proVinToggle"
                titleLeft={tran.GetText('Sleva na prověření v zahraničí')}
                titleRight={`${tran.GetText('- 550')} ${tran.GetText(czCurrency)}`}
                button={tran.GetText('Detail')}
                isOpened={false}
            />
            <ToggleItemGeneral id="proVinToggle">
                <div className="toggleContent">
                    <p>
                        {tran.GetText(
                            'Pokud je Vámi vybrané vozidlo individuálně dovezené do ČR, doporučujeme Vám využít ještě službu prověření původu vozidla v zahraničí PROVIN.',
                        )}
                    </p>
                    <Table className="text-left mb-30">
                        <tbody>
                            <tr>
                                <th className="text-middle font-normal">
                                    {tran.GetText('Základní cena')}
                                </th>
                                <td className="text-middle pl-10 md:pl-80 text-right text-noWrap">
                                    2 370 {tran.GetText(czCurrency)}
                                </td>
                                <td></td>
                            </tr>
                            <tr className="color-green">
                                <th className="text-middle">{tran.GetText('SLEVA')}</th>
                                <td className="text-middle text-right text-noWrap">
                                    - 550 {tran.GetText(czCurrency)}
                                </td>
                                <td></td>
                            </tr>
                            <tr className="color-darkBlue">
                                <th className="text-middle">
                                    <strong>{tran.GetText('Cena po slevě')}</strong>
                                </th>
                                <td className="text-middle text-right font-bold text-noWrap">
                                    1 820 {tran.GetText(czCurrency)}
                                </td>
                                <td className="text-middle font-bold text-noWrap">
                                    {tran.GetText('s DPH')}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>
                        {tran.GetText(
                            'Prověříme registraci vozidla v zemi původu, zda bylo řádně odhlášeno, zda nebylo vozidlo vyřazeno z provozu z důvodu jeho totální havárie, zda vozidlo není evidováno v daném státě jako odcizené a další skutečnosti.',
                        )}
                    </p>
                    <div className="ButtonList mb-10">
                        <a
                            href="https://www.cebia.cz/nase-sluzby/pred-nakupem-vozidla/provin/popis-sluzby.html"
                            target="_blank"
                            className="Button Button--blue Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">{tran.GetText('Více info')}</span>
                        </a>
                        <a
                            href="https://www.cebia.cz/common/2014/video-banners/?utm_source=1videobanner2014&utm_medium=1videobanner2014&utm_content=1videobanner2014&utm_campaign=1videobanner2014#ctvrty-dil"
                            target="_blank"
                            className="Button Button--blue Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">{tran.GetText('Video')}</span>
                        </a>
                        <a
                            href="https://www.cebia.cz/nase-sluzby/pred-nakupem-vozidla/provin/objednavka/objednavka-on-line.html"
                            target="_blank"
                            className="Button Button--small"
                            rel="noreferrer"
                        >
                            <span className="Button-content">{tran.GetText('Objednat')}</span>
                        </a>
                    </div>
                    <h3>{tran.GetText('Postup pro objednání:')}</h3>
                    <p>
                        {tran.GetHtml(
                            'Přes tlačítko Objednat se dostanete na on-line objednávku služby PROVIN. Vyplňte tuto objednávku a do poznámky prosím uveďte heslo: <strong>AUTOTRACER</strong> a <strong>číslo Vašeho kuponu AUTOTRACER.</strong>',
                        )}
                    </p>
                </div>
            </ToggleItemGeneral>
        </>
    )
}
