import { CebiaReportControlData } from '../models/DetailPayedResults/CebiaReport/cebiaReportControlModel'
import { PayedStateModel } from '../models/payedResponseModel'
import http from './http-common'

export const updateCebiaReport = async (values: CebiaReportControlData) => {
    let retVal: CebiaReportControlData | undefined

    try {
        const response = await http.post('/payed/PostCebiaReport', {
            vin: values.vin,
            firstRegistrationYear: values.firstRegistrationYear,
            km: values.km,
            reportModel: values.reportModel,
            requestId: values.requestId,
            isGdpr: values.isGdpr,
        })

        if (response.status === 200 && response.data) {
            retVal = response.data
        }
    } catch (e) {
        console.log(e)
    }

    return retVal
}

export const generateReport = async (
    firsYearRegistration: number,
    tacho: number,
    carModel: PayedStateModel,
    model?: string,
    refNumber?: string,
) => {
    let retVal: CebiaReportControlData | undefined

    try {
        const response = await http.post('/payed/PostCebiaReport', {
            vin: carModel.carVin,
            firstRegistrationYear: firsYearRegistration,
            km: tacho,
            reportModel: model ?? carModel.carModel,
            requestId: carModel.viewId,
            isGdpr: true,
            apcRefNumber: refNumber,
        })

        if (response.status === 200 && response.data) {
            retVal = response.data
        }
    } catch (e) {
        console.log(e)
    }

    return retVal
}
