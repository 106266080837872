import './index.styl'
import { ReactElement } from 'react'
import { Container } from '../../../../components/Container'
import { Section } from '../../../../components/Section'
import { Title } from '../../../../components/Title'
import { PulseDot } from '../../../../components/PulseDot'
import { VisualBlock } from '../../../../components/VisualBlock'
import { VisualProps } from '../../../../components/Visual'
import AssetsImgLobby from './img/lobby.webp'
import AssetsImgGjirafa from './img/gjirafa-office.webp'
import AssetsImgLobby2x from './img/lobby@2x.webp'
import { Social } from '../../../../components/Social'
import { SectionShape } from '../../../../components/SectionShape'
import { Translate, _, __ } from '../../../../translations/CebiaTranslator'
import { i18n, LanguageDefinition, LanguageEnum } from '../../../../translations/i18n'
import AssetsImgLogoInstagram from './img/instagram.svg'

export const SectionContact = (): ReactElement => {
    const visual: VisualProps = {
        variant: i18n.language === 'al' ? '595x615' : '620x510',
        image: {
            src: i18n.language === 'al' ? AssetsImgGjirafa : AssetsImgLobby,
            srcSet: i18n.language === 'al' ? AssetsImgGjirafa : AssetsImgLobby2x,
            alt: 'Recepce Interier',
        },
    }
    const tran = new Translate()

    return (
        <Section variant="medium">
            <Container variant="wide">
                <SectionShape variant="leftBottom" />
            </Container>
            <Container>
                <VisualBlock
                    variant="elevated"
                    variantSize="half"
                    variantColor="dark"
                    mobileSnap={true}
                    visual={visual}
                >
                    <Title as="h2" className="Beta mb-30" variant="withDash" variantAlign="left">
                        {tran.GetText('Potřebujete poradit?')}
                    </Title>

                    <dl>
                        {i18n.language === 'cz' ? (
                            <>
                                <div className="Grid gap-20 gmb-20 mb-0">
                                    {/* <div className="Grid-cell md:w-6/12">
                                        <dt className="font-sm color-textBlue">
                                            {tran.GetText('Telefon')}
                                        </dt>
                                        <dd className="lg:font-xl">+420 222 207 111</dd>
                                    </div> */}
                                    <div className="Grid-cell md:w-6/12">
                                        <dt className="font-sm color-textBlue">
                                            {tran.GetText('Email')}
                                        </dt>
                                        <dd className="lg:font-xl">
                                            {
                                                LanguageDefinition[
                                                    LanguageEnum[
                                                        i18n.language as keyof typeof LanguageEnum
                                                    ]
                                                ].email
                                            }
                                        </dd>
                                    </div>
                                </div>

                                <div className="Grid gap-20">
                                    <div className="Grid-cell md:w-6/12 d-flex">
                                        <dt className="font-sm color-textBlue flex align-center">
                                            {tran.GetText('Jsme k dispozici')}{' '}
                                            <PulseDot className="ml-10" />
                                        </dt>
                                    </div>
                                    <div className="Grid-cell md:w-6/12">
                                        <dd className="lg:font-xl">
                                            {tran.GetText('Po – Pá 8:00 – 16:30')}
                                        </dd>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="Grid gap-20 gmb-20 mb-0">
                                    {/* <div className="Grid-cell md:w-6/12">
                                        <dt className="font-sm color-textBlue flex align-center">
                                            {tran.GetText('Jsme k dispozici')}{' '}
                                            <PulseDot className="ml-10" />
                                        </dt>
                                    </div> */}
                                    <div className="Grid-cell md:w-6/12">
                                        <dt className="font-sm color-textBlue">
                                            {tran.GetText('Email')}
                                        </dt>
                                        <dd className="lg:font-xl">
                                            {i18n.language === 'uk' ? (
                                                <a href="mailto:info.ua@cebia.com?subject=Запит&cc=support@dataauto.org">
                                                    info.ua@cebia.com
                                                </a>
                                            ) : (
                                                LanguageDefinition[
                                                    LanguageEnum[
                                                        i18n.language as keyof typeof LanguageEnum
                                                    ]
                                                ].email
                                            )}
                                        </dd>
                                    </div>
                                </div>
                            </>
                        )}
                    </dl>
                    {i18n.language !== 'uk' && (
                        <>
                            <hr className="Separator mt-30 mb-30" />
                            <div className="Grid justify-center md:justify-between gap-20">
                                <div className="Grid-cell w-auto">
                                    <Social
                                        icon="facebook"
                                        href={
                                            i18n.language == 'al'
                                                ? 'https://www.facebook.com/auto.tracer.by.gjirafa'
                                                : 'https://www.facebook.com/cebia.cz'
                                        }
                                        label="Facebook"
                                        variantDesktop="desktopInline"
                                    />
                                </div>
                                <div className="Grid-cell w-auto">
                                    {i18n.language === 'al' ? (
                                        <Social
                                            icon="instagram"
                                            href="https://www.instagram.com/autotracer.bygjirafa/"
                                            label="Instagram"
                                            variantDesktop="desktopInline"
                                        />
                                    ) : (
                                        <Social
                                            icon="youtube"
                                            href="https://www.youtube.com/user/cebiacz"
                                            label="Youtube"
                                            variantDesktop="desktopInline"
                                        />
                                    )}
                                </div>
                                <div className="Grid-cell w-auto">
                                    <Social
                                        icon="cebia"
                                        href={
                                            i18n.language === 'cz'
                                                ? 'https://www.cebia.cz/o-nas'
                                                : '/about'
                                        }
                                        label={tran.GetText('Cebia')}
                                        variantDesktop="desktopInline"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </VisualBlock>
            </Container>
        </Section>
    )
}
