import classNames from 'classnames'
import { ReactElement } from 'react'
import Moment from 'react-moment'
import {
    TimeLineItem,
    TimeLineRegWorld,
} from '../../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { Translate } from '../../../translations/CebiaTranslator'
import { getCountries } from '../../../busLog/enums_gen'

type Props = {
    row: TimeLineItem
    isPrint: boolean
}
export const TimeLineRegWorldTable = ({ row, isPrint }: Props): ReactElement => {
    const item = row.timeLineObject as TimeLineRegWorld
    const tran = new Translate()
    const countries = getCountries(tran)
    const tableClasses = classNames('Table', { ['noBackground line2rem']: isPrint })
    return (
        <>
            <div className={tableClasses}>
                <table>
                    <tbody>
                        {item.firstRegWorld && (
                            <tr>
                                <td>{tran.GetText('Datum přihlášení vozidla')}:</td>
                                <td>
                                    <Moment format="DD. MM. YYYY">{item.firstRegWorld}</Moment>
                                </td>
                            </tr>
                        )}
                        {item.firstReg && (
                            <tr>
                                <td>
                                    {tran.GetText('Datum 1. registrace uvedené při přihlášení')}:
                                </td>
                                <td>
                                    <Moment format="DD. MM. YYYY">{item.firstReg}</Moment>
                                </td>
                            </tr>
                        )}

                        <tr>
                            <td>{tran.GetText('Přihlášeno jako')}:</td>
                            <td className="firstCapitalize">
                                <span className="firstCapitalize">
                                    {item.wasNew && tran.GetText('nové')}
                                    {!item.wasNew && tran.GetText('ojeté')}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
