import { ImageProps } from '../../../components/Image'

export class PhotoControlData {
    enabled: boolean | undefined
    photos: PhotosDescriptionItem[] | undefined
}

export class PhotosDescriptionItem {
    advertDate?: Date
    advertKm = 0
    advertPrice = 0
    newPrice?: number
    currency = ''
    screenShotUrl = ''
    advertImageUrls?: string[]
    advertMatchType = 0
    subCaption = ''
    description = ''
    carColor = ''
    carState = ''
    carStateText = ''
    isSale = false
    priceSale?: number
    isAnonymized?: boolean
    clientId = ''
}

export const getPhotosUrlArray = (imageUrls?: string[], isLazyLoading = true): ImageProps[] => {
    const retVal = Array<ImageProps>(0)
    if ((imageUrls?.length ?? 0) > 0) {
        imageUrls?.map(x => {
            retVal.push({
                src: x,
                alt: '',
                loading: isLazyLoading ? 'lazy' : undefined,
            } as ImageProps)
        })
    }

    return retVal
}
