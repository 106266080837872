import { ReactElement } from 'react'
import Moment from 'react-moment'
import classNames from 'classnames'
import {
    TimeLineItem,
    TimeLineRokvy,
} from '../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { Translate } from '../../translations/CebiaTranslator'
import { navigateToElement, navigateToFirstElement } from '../AtrTimeLineTable'
import { TimeLineObjectType } from '../../enums/TimeLineObjectEnum'

type Props = {
    row: TimeLineItem
    isPrint: boolean
}

export const TimeLineRokvyTable = ({ row, isPrint }: Props): ReactElement => {
    const tran = new Translate()
    const item = row.timeLineObject as TimeLineRokvy
    const tableClasses = classNames('Table', { ['noBackground line2rem']: isPrint })
    return (
        <>
            <div className={tableClasses}>
                <table>
                    <tbody>
                        <tr>
                            <td> {tran.GetText('Datum výroby vozidla:')}</td>
                            <td>{item.dateOfManufacture}</td>
                        </tr>
                        {item.showCarAge && (
                            <tr>
                                <td>{tran.GetText('Stáří vozidla')}:</td>
                                <td>{item.carAge}</td>
                            </tr>
                        )}
                        {item.carColor && (
                            <tr>
                                <td>{tran.GetText('Barva vozidla')}:</td>
                                <td>{item.carColor}</td>
                            </tr>
                        )}
                        <tr>
                            <td> {tran.GetText('Vyrobeno pro trh')}:</td>
                            <td>{item.madeForMarket}</td>
                        </tr>
                        <tr>
                            <td> {tran.GetText('Strana řízení')}:</td>
                            <td>
                                <span className="firstCapitalize">{item.steeringSide}</span>
                            </td>
                        </tr>
                        {item.showEquipment && !isPrint && (
                            <tr>
                                <td> {tran.GetText('Vyrobeno s výbavou')}:</td>
                                <td>
                                    <a
                                        className="pointer"
                                        //href={`#${row.linkValue}`}
                                        onClick={() => {
                                            navigateToFirstElement(row.linkValue, 'a')
                                        }}
                                    >
                                        {tran.GetText('Zobrazit')}
                                    </a>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}
