import { ErrorMessage, Form, Formik } from 'formik'
import { ReactElement, useState } from 'react'
import parse from 'html-react-parser'
import * as Yup from 'yup'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { FormItem } from '../../../../components/FormItem'
import { Input } from '../../../../components/Input'
import { Table } from '../../../../components/Table'
import { CebiaReportControlData } from '../../../../models/DetailPayedResults/CebiaReport/cebiaReportControlModel'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Select } from '../../../../components/Select'
import { updateCebiaReport } from '../../../../busLog/cebiaReportBL'
import { LoadArea } from '../../../../components/LoadArea'
import crExample from '../../../../assets/img/cr_example.webp'

export const SectionCebiaReport = ({ sourceData }: DetailPayedProps): ReactElement => {
    const tran = new Translate()

    if ((sourceData?.cebiaReportControlResult?.isTheft ?? false) === true)
        return (
            <>
                <p className="Info Info--red mb-20">
                    <span className="Icon Icon--alert"></span>
                    {tran.GetText(
                        'Vozidlo je evidované jako odcizené. Cebia REPORT nelze vytvořit',
                    )}
                </p>
            </>
        )
    if ((sourceData?.cebiaReportControlResult?.reportModel?.length ?? 0) <= 0) {
        const tmpText = tran.GetText(
            'Cebia REPORT nelze vytvořit - VIN nebylo možné jednoznačně identifikovat. </br> Zkontrolujte VIN podle dokladů od vozidla, případně <a href="mailto:autotracer@cebia.cz?subject=Informační podpora systému-ATR identifikace&body=VIN:{0}">Kontaktujte podporu</a>.',
        )
        return (
            <label className="color-red">
                <i className="fas fa-exclamation-circle"></i>{' '}
                {parse(tmpText.replace('{0}', sourceData?.cebiaReportControlResult?.vin ?? ''))}
            </label>
        )
    }

    const [isLoading, setIsLoading] = useState(false)
    const [cebiaReport, setcebiaReport] = useState<CebiaReportControlData>(
        sourceData!.cebiaReportControlResult!,
    )
    const [errorMessage, setErrorMessage] = useState('')

    const validationSchema = () => {
        return Yup.object({
            isGdpr: Yup.boolean().oneOf([true], tran.GetText('Musíte odsouhlasit zadané údaje.')),
            firstRegistrationYear: Yup.number()
                .nullable(true)
                .min(1900, tran.GetText('Nesprávna hodnota rok první registrace.'))
                .max(
                    new Date().getFullYear(),
                    tran.GetText('Nesprávna hodnota rok první registrace.'),
                )
                .required(tran.GetText('Prosím zadejte rok první registrace.')),
            km: Yup.number()
                .nullable(true)
                .min(1, tran.GetText('Nesprávna hodnota stav tachometru.'))
                .max(999999, tran.GetText('Nesprávna hodnota stav tachometru.'))
                .required(tran.GetText('Prosím zadejte položku stav tachometru.')),
        })
    }

    const reportGenerateLink = (isTest: boolean) => {
        return (
            <a
                target="_blank"
                rel="noreferrer"
                href={isTest ? crExample : `/Print/CebiaReport?requestId=${cebiaReport.requestId}`}
                className="Button Button--small"
            >
                <span className="Button-content">{tran.GetText('Zobrazit Cebia REPORT')}</span>
            </a>
        )
    }

    return (
        <>
            <p className="text-justify">
                {tran.GetHtml(
                    'Zde si vytvoříte Osvědčení <a target="_blank" href="https://www.cebia.cz/cebia-report">Cebia REPORT</a>, které můžete poskytnout zájemcům o Vaše auto a zvýšit tak jeho důvěryhodnost při prodeji nebo při inzerci na největším inzertním portále – <a href="http://www.sauto.cz/" target="_blank">www.sauto.cz</a>.',
                )}
            </p>

            {(cebiaReport?.cebiaReportNumber?.length ?? 0) <= 0 && (
                <>
                    {errorMessage?.length > 0 && (
                        <p className="InputError">
                            <span className=" Icon Icon--alert AlertIcon"></span> {errorMessage}
                        </p>
                    )}
                    <Formik
                        initialValues={cebiaReport}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        onSubmit={values => {
                            setIsLoading(true)
                            updateCebiaReport(values).then(x => {
                                if (x) {
                                    setcebiaReport(x)
                                } else {
                                    setErrorMessage(tran.GetText('Chyba uložení. Opakujte akci.'))
                                }
                                setIsLoading(false)
                            })
                        }}
                    >
                        <Form className="ajax mb-20 md:mb-40">
                            <div className="mb-20">
                                <div className="Grid gap-30 gmb-20 ">
                                    <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                        <Input
                                            id="cebiaReportFirstRegistrationYear"
                                            name="firstRegistrationYear"
                                            type="number"
                                            placeholder={tran.GetText(
                                                'Zadejte rok první registrace',
                                            )}
                                            label={tran.GetText('Rok první registrace')}
                                            maxLength={4}
                                        />
                                    </FormItem>

                                    <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                        <Input
                                            id="cebiaReportkm"
                                            name={tran.GetText('km')}
                                            type="number"
                                            placeholder={tran.GetText('Zadejte stav tachometru')}
                                            label={tran.GetText('Stav tachometru')}
                                            maxLength={4}
                                        />
                                    </FormItem>
                                    {(cebiaReport?.reportModels?.length ?? 0) > 0 && (
                                        <FormItem className="FormItem smw:w-1/2 md:w-full lg:w-1/2">
                                            <Select
                                                id="cebiaReportreportModel"
                                                name="reportModel"
                                                label={tran.GetText('Upřesnění modelu')}
                                            >
                                                {cebiaReport?.reportModels &&
                                                    cebiaReport?.reportModels!.map(
                                                        (item, index) => (
                                                            <option
                                                                key={`model_${index}`}
                                                                value={item.value}
                                                            >
                                                                {item.key}
                                                            </option>
                                                        ),
                                                    )}
                                            </Select>
                                        </FormItem>
                                    )}
                                </div>
                            </div>
                            <div className="Grid Grid--alignMiddle gap-30 gmb-10">
                                <div className="FormItem smw:w-1/2 md:w-full lg:w-2/3 xl:w-1/2">
                                    <Checkbox
                                        className=""
                                        variant="green"
                                        name="isGdpr"
                                        id="isGdpr"
                                    >
                                        <label htmlFor="isGdpr">
                                            {tran.GetText(
                                                'Zkontroloval jsem a potvrzuji uvedené informace.',
                                            )}
                                        </label>
                                    </Checkbox>
                                    <ErrorMessage
                                        name="isGdpr"
                                        component="div"
                                        className="InputError"
                                    />
                                </div>
                                <div className="FormItem FormItem--submit smw:w-1/2 md:w-full lg:w-1/3 xl:w-1/2">
                                    <Button className="Button--center" as="button" type="submit">
                                        {tran.GetText('Vygenerovat Cebia REPORT')}
                                    </Button>
                                </div>
                            </div>

                            <LoadArea
                                hideBackGround={true}
                                position="inherit"
                                loader={{ variant: 'bars', size: 'big' }}
                                align="center"
                                isLoading={isLoading}
                            />
                        </Form>
                    </Formik>
                </>
            )}

            {(cebiaReport?.cebiaReportNumber?.length ?? 0) > 0 && (
                <>
                    <div className="Table mb-20 md:mb-50 half">
                        <Table>
                            <thead>
                                <tr>
                                    <th colSpan={2}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{tran.GetText('VIN')}</td>
                                    <td>{cebiaReport.vin}</td>
                                </tr>
                                <tr>
                                    <td>{tran.GetText('Číslo CR:')}</td>
                                    <td>{cebiaReport.cebiaReportNumber}</td>
                                </tr>
                                <tr>
                                    <td>{tran.GetText('Rok první registrace:')}</td>
                                    <td>{cebiaReport.firstRegistrationYear}</td>
                                </tr>
                                <tr>
                                    <td>{tran.GetText('Stav tachometru:')}</td>
                                    <td>{tran.GetNumber(cebiaReport.km ?? 0)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className="text-center">{reportGenerateLink(cebiaReport.isTest)}</div>
                </>
            )}
        </>
    )
}
