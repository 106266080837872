import { Fragment } from 'react'
import { LoadArea } from '../../../../../components/LoadArea'
import { Table } from '../../../../../components/Table'
import {
    EquipmentItem,
    VehicleInfoControlData,
} from '../../../../../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import { Translate } from '../../../../../translations/CebiaTranslator'

export type Props = {
    sourceData: VehicleInfoControlData
    tran: Translate
}

function showEquipmentRow(
    isIvaEqiupment: boolean,
    item: EquipmentItem,
    index: number,
    tran: Translate,
) {
    if (!isIvaEqiupment) {
        return (
            <tr key={`eq_${index}`}>
                <td>
                    <strong>
                        {item.equipmentFamily ? item.equipmentFamily : tran.GetText('Ostatní')}
                    </strong>
                </td>

                <td>{item.equipment![0]}</td>
            </tr>
        )
    }
    return (
        <Fragment key={`ivaEq_${index}`}>
            <tr>
                <td className="text-center">
                    <strong>{item.equipmentFamily}</strong>
                </td>
            </tr>
            {(item.equipment?.length ?? 0) > 0 &&
                item.equipment?.map((eq, indx) => (
                    <tr key={`eqiup_${indx}`}>
                        <td>{eq}</td>
                    </tr>
                ))}
        </Fragment>
    )
}

export const SectionVehicleEquipmentInfo = ({ sourceData, tran }: Props) => {
    const familyEquipmentEmptyCount =
        sourceData.equipmentItems?.filter(x => x.equipmentFamily?.length > 0).length ?? 0

    if ((sourceData?.vehicleIdentItems?.length ?? 0) <= 0)
        return (
            <p>
                {tran.GetText(
                    'Seznam výbavy vozidla není k dispozici. Doporučujeme zkontrolovat funkčnost a originalitu výbavy přímo ve vozidle.',
                )}
            </p>
        )

    return (
        <>
            <div className="sectionVehicleEquipmentInfo">
                <div className="pb-25">
                    <p className="pt-15 text-justify">
                        {sourceData.isIvaEquipment
                            ? tran.GetText(
                                  'Podrobná specifikace výbav vozidla poskytnutá jeho výrobcem obsahuje všechny prvky základní i příplatkové výbavy. Kontrolou výbavy lze odhalit úpravy vozy jako např. změnu strany řízení.',
                              )
                            : tran.GetText(
                                  'Jedná se o dostupné informace základních parametrů, standardní a případně doplňkové výbavy z výroby. Kontrolou výbavy lze odhalit úpravy vozidla jako např. změnu strany řízení.',
                              )}
                    </p>
                </div>
                <div className="mb-30">
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-left" colSpan={2}>
                                    {tran.GetText('Identifikace vozidla')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sourceData
                                .vehicleIdentItems!.filter(
                                    x => (x.name?.length ?? 0) > 0 && (x.value?.length ?? 0) > 0,
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <strong>{item.name}</strong>
                                            {item.unit}
                                        </td>
                                        <td>{item.value}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
                {familyEquipmentEmptyCount > 0 && (
                    <div className="mb-20">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-left" colSpan={2}>
                                        {sourceData.isIvaEquipment
                                            ? tran.GetText('Standardní vybavení')
                                            : tran.GetText('Soupis výbavy vozidla')}{' '}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(sourceData.equipmentItems?.filter(
                                    x => (x.equipmentFamily?.length ?? 0) > 0,
                                ).length ??
                                    0 > 0) && (
                                    <>
                                        {sourceData.equipmentItems
                                            ?.filter(x => (x.equipmentFamily?.length ?? 0) > 0)
                                            ?.map((item, index) =>
                                                showEquipmentRow(
                                                    sourceData.isIvaEquipment,
                                                    item,
                                                    index,
                                                    tran,
                                                ),
                                            )}
                                        {sourceData.equipmentItems
                                            ?.filter(x => (x.equipmentFamily?.length ?? 0) == 0)
                                            ?.map((item, index) =>
                                                showEquipmentRow(
                                                    sourceData.isIvaEquipment,
                                                    item,
                                                    index,
                                                    tran,
                                                ),
                                            )}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </div>
                )}
                {(sourceData.equipmentItems?.length ?? 0) > 0 && familyEquipmentEmptyCount === 0 && (
                    <div className="mb-20">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-left" colSpan={2}>
                                        {sourceData.isIvaEquipment
                                            ? tran.GetText('Standardní vybavení')
                                            : tran.GetText('Soupis výbavy vozidla')}{' '}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sourceData.equipmentItems?.map((item, index) =>
                                    showEquipmentRow(sourceData.isIvaEquipment, item, index, tran),
                                )}
                            </tbody>
                        </Table>
                    </div>
                )}
                {sourceData.customizableEquipmentItems && (
                    <div className="mb-20">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="text-left" colSpan={2}>
                                        {tran.GetText('Volitelná výbava')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sourceData.customizableEquipmentItems
                                    ?.filter(x => (x.equipmentFamily?.length ?? 0) > 0)
                                    .map((item, index) =>
                                        showEquipmentRow(
                                            sourceData.isIvaEquipment,
                                            item,
                                            index,
                                            tran,
                                        ),
                                    )}

                                {sourceData.equipmentItems
                                    ?.filter(x => (x.equipmentFamily?.length ?? 0) === 0)
                                    .map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {/* {!sourceData.onlyOtherEquipment && (
                                                <strong>{tran.GetText('Ostatní')}</strong>
                                            )} */}
                                            </td>
                                            <td>{item.equipment}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                )}
                <p className="text-justify">
                    {tran.GetHtml(
                        "<span class='warning'>Upozornění:</span> V systému nemusí být v některých případech k dispozici kompletní výbava vozidla.",
                    )}
                </p>
            </div>
            <LoadArea
                hideBackGround={true}
                position="inherit"
                loader={{ variant: 'bars', size: 'big' }}
                align="center"
                isLoading={sourceData?.vehicleIdentItems === undefined}
            />
        </>
    )
}
