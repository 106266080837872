import { ReactElement } from 'react'
import { Section } from '../../../../components/Section'
import classNames, { Argument } from 'classnames'

type Props = {
    className?: Argument
}

export const SectionNoContainerSeparator = ({ className: _className }: Props): ReactElement => {
    const className = classNames(`SectionSeparator`, _className)

    return (
        <Section className={className} variant="bare">
            <div className="Separator"></div>
        </Section>
    )
}
