import { ReactElement, useEffect, useState } from 'react'
import { useAppContext } from '../../contexts/AppContext'
import { DashBoardJsonResult } from '../../models/dashBoardJsonResult'
import { Translate } from '../../translations/CebiaTranslator'
import { StripeAlert } from '../StripeAlert'

type PriceDiscountType = {
    tran: Translate
}

export const PriceDiscount = ({ ...props }: PriceDiscountType): ReactElement => {
    const priceDashboardInfo = useAppContext().appModel.priceDashboardInfo

    if (priceDashboardInfo && priceDashboardInfo.showDiscount)
        return (
            <StripeAlert variant="info">
                <span className="Icon Icon--discountTag mr-5"></span>{' '}
                {/* {props.tran.GetText('Získáváte slevu')}{' '} */}
                {/* ten replace je zatim nutny, nazev slevy vraci db procedura */}
                {priceDashboardInfo.discountName}
            </StripeAlert>
        )
    return <></>
}
