import './index.styl'
import { ReactElement, ReactNode } from 'react'
import { Loader, LoaderProps } from '../Loader'
import classNames from 'classnames'
import { LoaderReport, LoaderReportProps } from '../LoaderReport'

type Props = {
    variant?: 'fullscreen'
    position?: 'relative' | 'inherit' | 'absolute' | 'initial'
    isLoading?: boolean
    loader?: LoaderProps
    loaderReport?: LoaderReportProps
    align?: 'centerLeft' | 'center' | 'centerRight'
    text?: string
    children?: ReactNode
    hideBackGround?: boolean
}

export const LoadArea = ({
    variant,
    position,
    isLoading = false,
    hideBackGround = true,
    children,
    align = 'center',
    text = '',
    loader = { variant: 'bars' },
    loaderReport,
}: Props): ReactElement => {
    const className = classNames('LoadArea', {
        [`LoadArea--${variant}`]: variant,
        [`LoadArea--${align}`]: align,
        [`LoadArea--${position}`]: position,
        [`is-loading`]: isLoading,
        [`LoadArea--${loaderReport ? 'loadReport' : 'noLoadReport'}`]: true,
    })
    if (loader.size == 'big') {
        loader.variant = 'circle'
    }

    if (isLoading || (loaderReport && !(loaderReport.isFastFinish ?? true))) {
        return (
            <div className={className}>
                <div className={hideBackGround ? 'LoadArea-content' : ''}>{children}</div>
                <div className="LoadArea-loader">
                    {loaderReport ? (
                        <LoaderReport {...loaderReport}></LoaderReport>
                    ) : (
                        text && (
                            <>
                                <span className="ml-10 mr-10">{text}</span> <Loader {...loader} />
                            </>
                        )
                    )}
                </div>
            </div>
        )
    } else {
        return <>{children}</>
    }
}
