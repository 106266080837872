import './index.styl'
import { MouseEventHandler, ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AtrCategoryCardNew } from '../../../../components/AtrCategoryCardNew'
import { AtrOverview } from '../../../../components/AtrOverview'
import { BubbleOpener } from '../../../../components/BubbleOpener'
import { Button } from '../../../../components/Button'
import { Container } from '../../../../components/Container'
import { Image } from '../../../../components/Image'
import { Section } from '../../../../components/Section'
import { useAppContext } from '../../../../contexts/AppContext'
import { useSignalRConnection } from '../../../../contexts/SignalR/SignalRHook'
import { AtrSection } from '../../../../enums/AtrSectionEnum'
import { LanguageType } from '../../../../enums/LanguageType'
import { SignalRUrlType } from '../../../../enums/SignalRUrlEnum'
import {
    DashBoardCarInfoJsonResult,
    DashBoardOtherJsonResult,
} from '../../../../models/dashBoardJsonResult'
import { ValidationResponseModel } from '../../../../models/validationResponseModel'
import '../../../../utils/arrayExtensions'
import { getSection } from '../../../../utils/arrayExtensions'
import { scrollToTop } from '../../../../utils/redirect'
import { TextUtils } from '../../../../utils/textUtils'

import { Modal, useModal } from '../../../../components/Modal'
import { findVinUrl, getTechnickaImg } from '../..'
import { i18n } from '../../../../translations/i18n'

type props = {
    id: string
}

export const SectionDashboardNew = ({ id }: props): ReactElement => {
    const tran = new TextUtils()
    const { open: openModal } = useModal('dashboardModal')
    const { open } = useModal('findVinModal')
    const { open: openNoData, close: closeModal } = useModal('noDataModal')
    const appContext = useAppContext()
    const navigate = useNavigate()
    const { bufferedData } = useSignalRConnection({
        url: SignalRUrlType.DashBoard,
    })
    const [carInfo, setCarInfo] = useState<DashBoardCarInfoJsonResult>(
        new DashBoardCarInfoJsonResult(appContext.appModel.validateResponseModel),
    )
    const priceDashboardInfo = appContext.appModel.priceDashboardInfo
    const [showVinCheck, setShowVinCheck] = useState(false)
    const [isNoData, setIsNoData] = useState(false)
    const [agreement, setAgreement] = useState(true)
    const [otherService, setdashBoardOther] = useState<DashBoardOtherJsonResult | null>(null)
    useEffect(() => {
        const carInfoData = getSection<DashBoardCarInfoJsonResult>(bufferedData, AtrSection.CarInfo)
        if (carInfoData) {
            appContext.appModel.validateResponseModel = new ValidationResponseModel(carInfoData)
            appContext.setLoading(false)
            setCarInfo(carInfoData)
        }
        setdashBoardOther(getSection<DashBoardOtherJsonResult>(bufferedData, AtrSection.Other))
        const otherSection = getSection<DashBoardOtherJsonResult>(bufferedData, AtrSection.Other)
        if (otherSection?.showVinCheck ?? false) {
            setShowVinCheck(otherSection.showVinCheck)
        }
        if (otherSection?.isNoData ?? false) {
            setIsNoData(otherSection.isNoData)
        }
    }, [bufferedData])

    const handleButtonClick: MouseEventHandler = () => {
        scrollToTop()
        navigate('/carinfo/payment/' + id)
    }

    useEffect(() => {
        if (isNoData) {
            setAgreement(false)
            openNoData()
        }
    }, [isNoData])

    return (
        <Section className="SectionDashboard" variant="bare">
            <Container>
                <div className="Grid gap-50">
                    <div className="Grid-cell lg:w-4/12 sm-mb-30 lg:order-1">
                        <AtrOverview
                            disabled={otherService == null || !agreement}
                            key={`${carInfo?.vin}_default`}
                            variant="default"
                            logo={{
                                src: carInfo?.imageData
                                    ? `data:image/png;base64, ${carInfo?.imageData}`
                                    : '',
                            }}
                            mobileSnap={true}
                            title={`${carInfo?.carMake ?? ''}|${carInfo?.carModel ?? ''}`}
                            vin={carInfo?.vin ?? ''}
                            footerTitle={tran.GetText('Koupit prověření')}
                            showDiscount={priceDashboardInfo?.showDiscount}
                            priceBefore={`${priceDashboardInfo?.standardPrice
                                ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                .replace(
                                    '.',
                                    priceDashboardInfo.countryId == LanguageType.SK ? ',' : '.',
                                )} ${priceDashboardInfo?.currency ?? 'Kč'}`}
                            priceAfter={`${
                                priceDashboardInfo?.totalPrice
                                    ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                    .replace(
                                        '.',
                                        priceDashboardInfo.countryId == LanguageType.SK ? ',' : '.',
                                    ) ?? ''
                            } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                            button={tran.GetText('Pokračovat')}
                            onclick={handleButtonClick}
                            isLoading={priceDashboardInfo?.isLoading}
                            priceInfo={priceDashboardInfo}
                        />
                        <AtrOverview
                            disabled={otherService == null || !agreement}
                            key={`${carInfo?.vin}_fixed`}
                            variant="fixed"
                            className="lg:hidden"
                            title={`${carInfo?.carMake ?? ''}|${carInfo?.carModel ?? ''}`}
                            showDiscount={priceDashboardInfo?.showDiscount}
                            priceBefore={`${
                                priceDashboardInfo?.standardPrice
                                    ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                    .replace(
                                        '.',
                                        priceDashboardInfo.countryId == LanguageType.SK ? ',' : '.',
                                    ) ?? ''
                            } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                            priceAfter={`${
                                priceDashboardInfo?.totalPrice
                                    ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                    .replace(
                                        '.',
                                        priceDashboardInfo.countryId == LanguageType.SK ? ',' : '.',
                                    ) ?? ''
                            } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                            button={tran.GetText('Pokračovat')}
                            onclick={handleButtonClick}
                            isLoading={priceDashboardInfo?.isLoading}
                            priceInfo={priceDashboardInfo}
                        />
                    </div>
                    <div className="Grid-cell lg:w-8/12">
                        <div className="Grid-cell lg:w-12/12">
                            {showVinCheck && (
                                <h2 className="color-errorRed lg:font-xxl mb-20 text-center md:text-left d-flex">
                                    <strong>{tran.GetText('Varování!')}</strong>
                                    <span className="ml-5">
                                        {tran.GetText('Zadané VIN může být chybné!')}
                                    </span>
                                    <a
                                        className="vinFindLink Link--line color-errorRed"
                                        onClick={() => open()}
                                    >
                                        {tran.GetText('Více informací?')}
                                    </a>
                                </h2>
                            )}
                            <h2 className="color-darkBlue lg:font-xxl mb-20 text-center md:text-left d-flex">
                                {/*   <span className="color-green mr-5">{tran.GetText('Hotovo!')}</span> */}
                                <span>
                                    {tran.GetText('U zadaného vozidla zkontrolujeme:')}{' '}
                                    <BubbleOpener
                                        onClickHandler={openModal}
                                        className="Gray AtrCategoryCardNew-bubble"
                                    ></BubbleOpener>
                                </span>
                            </h2>
                        </div>
                        <div className="Grid gap-15 gmb-15 mb-10">
                            <div className="Grid-cell md:w-12/12">
                                <AtrCategoryCardNew
                                    /* description={
                                        otherService == null
                                            ? ''
                                            : tran.GetHtml(
                                                  'více než <label className="color-orange">200 milionů záznamů</label>',
                                              )
                                    } */
                                    description={tran.GetHtml(
                                        'více než <label className="color-orange">200 milionů záznamů</label>',
                                    )}
                                    title={tran.GetText('Poškození vozidla')}
                                    visualIcon={{ name: 'carCrash' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    /*  isLoading={otherService == null}*/
                                    isLoading={false}
                                    descriptionId={1}
                                />

                                <AtrCategoryCardNew
                                    description={tran.GetHtml(
                                        'data z více než <label className="color-orange">32 zemí</label>',
                                    )}
                                    title={tran.GetText('Najeté kilometry')}
                                    visualIcon={{ name: 'speedometerCheck' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    descriptionId={2}
                                />
                                {i18n.language == 'ro' && (
                                    <AtrCategoryCardNew
                                        description={tran.GetHtml(
                                            '<label className="color-orange">k dispozici</label>',
                                        )}
                                        title="Verificarea istoricului RAR "
                                        visualIcon={{ name: 'rarHistory' }}
                                        isVisible={true}
                                        isDisabled={false}
                                        /*  isLoading={otherService == null}*/
                                        isLoading={false}
                                        descriptionId={10}
                                    />
                                )}

                                <AtrCategoryCardNew
                                    description={tran.GetHtml(
                                        'evropské <label className="color-orange">inzertní portály</label>',
                                    )}
                                    title={tran.GetText('Fotky a inzerce')}
                                    visualIcon={{ name: 'photoCamera' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    descriptionId={3}
                                />
                                <AtrCategoryCardNew
                                    description={tran.GetHtml(
                                        'data z <label className="color-orange">EU a USA</label>',
                                    )}
                                    title={tran.GetText('Původ a stáří vozidla')}
                                    visualIcon={{ name: 'calendarCar' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    descriptionId={4}
                                />
                                <AtrCategoryCardNew
                                    description={tran.GetHtml(
                                        'data z <label className="color-orange">18 zemí</label>',
                                    )}
                                    title={tran.GetText('Servisní historie')}
                                    visualIcon={{ name: 'tabletCar' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    descriptionId={5}
                                />
                                <AtrCategoryCardNew
                                    description={tran.GetHtml(
                                        'až <label className="color-orange">12 registrů</label>',
                                    )}
                                    title={tran.GetText('Kontrola odcizení')}
                                    visualIcon={{ name: 'thiefCar' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    descriptionId={6}
                                />
                                <AtrCategoryCardNew
                                    description={tran.GetHtml(
                                        '<label className="color-orange">k dispozici</label>',
                                    )}
                                    title={tran.GetText('Kontrola taxi')}
                                    visualIcon={{ name: 'glassCar' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    descriptionId={7}
                                />

                                <AtrCategoryCardNew
                                    description={tran.GetHtml(
                                        'data z <label className="color-orange">26 společností</label>',
                                    )}
                                    title={tran.GetText('Kontrola financování')}
                                    visualIcon={{ name: 'tabletMoney' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    descriptionId={8}
                                />
                                <AtrCategoryCardNew
                                    description={tran.GetHtml(
                                        '<label className="color-orange">k dispozici</label>',
                                    )}
                                    title={tran.GetText('Technická data')}
                                    visualIcon={{ name: 'car' }}
                                    isVisible={true}
                                    isDisabled={false}
                                    isLoading={false}
                                    descriptionId={9}
                                />
                            </div>
                        </div>
                        <div className="Grid gap-15 gmb-15 mb-10 mobile-show">
                            <div className="Grid-cell md:w-12/12">
                                <AtrOverview
                                    disabled={otherService == null}
                                    key={`${carInfo?.vin}_mobile`}
                                    className="atrOverViewMobile"
                                    variant="mobile"
                                    logo={{
                                        src: carInfo?.imageData
                                            ? `data:image/png;base64, ${carInfo?.imageData}`
                                            : '',
                                    }}
                                    mobileSnap={true}
                                    title={`${carInfo?.carMake ?? ''}|${carInfo?.carModel ?? ''}`}
                                    vin={carInfo?.vin ?? ''}
                                    footerTitle={tran.GetText('Koupit prověření')}
                                    showDiscount={priceDashboardInfo?.showDiscount}
                                    priceBefore={`${
                                        priceDashboardInfo?.standardPrice
                                            ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                            .replace(
                                                '.',
                                                priceDashboardInfo.countryId == LanguageType.SK
                                                    ? ','
                                                    : '.',
                                            ) ?? ''
                                    } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                                    priceAfter={`${
                                        priceDashboardInfo?.totalPrice
                                            ?.toFixed(priceDashboardInfo.sale.decimalPlaces)
                                            .replace(
                                                '.',
                                                priceDashboardInfo.countryId == LanguageType.SK
                                                    ? ','
                                                    : '.',
                                            ) ?? ''
                                    } ${priceDashboardInfo?.currency ?? 'Kč'}`}
                                    button={tran.GetText('Pokračovat')}
                                    onclick={handleButtonClick}
                                    isLoading={priceDashboardInfo?.isLoading}
                                    priceInfo={priceDashboardInfo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id="dashboardModal">
                    <Container>
                        <h2>{tran.GetText('Jak to funguje?')}</h2>
                        <p className="mt-20">
                            {tran.GetText(
                                'Pro zadané vozidlo lze na základě VIN kódu provést všechny kontroly uvedené na této stránce. Výsledky kontrol budou dostupné v placeném Reportu. Počet informací obsažený v placeném Reportu se může u každého vozidla lišit.',
                            )}
                        </p>
                    </Container>
                </Modal>
                <Modal id="findVinModal">
                    <Container>
                        <div className="Grid gap-30 gmb-20 smw:gmb-50 Flex--justifyCenter">
                            <div className="Grid-cell md:w-1/2">
                                <h3 className="sameFontSize">
                                    {tran.GetText('Zadané VIN vozidla může být chybné!')}
                                </h3>
                                <p className="sameFontSize">
                                    {tran.GetText(
                                        'V zadaném čísle VIN byla detekována možná chyba. Zkontrolujte prosím, zda se zadané VIN schoduje s VIN číslem vyraženém na vozidle nebo uvedeném technické průkazu.',
                                    )}
                                </p>
                                <a
                                    className="Link Link--lightBlue"
                                    href={findVinUrl()}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    {tran.GetText('Kde hledat VIN?')}
                                </a>
                            </div>
                            <div className="Grid-cell md:w-1/2">
                                <Image
                                    src={getTechnickaImg()}
                                    alt={tran.GetText('technický průkaz - vin kód')}
                                />
                            </div>
                        </div>
                    </Container>
                </Modal>
                <Modal id="noDataModal" closeOnEsc={false}>
                    <Container>
                        <div className="Grid gap-30 gmb-20 smw:gmb-50 Flex--justifyCenter">
                            <div className="Grid-cell md:w-2/2">
                                <h3 className="sameFontSize text-center bold">
                                    {tran.GetText('Vámi zadané VIN může být chybné')}
                                </h3>
                                <p className="sameFontSize text-center">
                                    {tran.GetText(
                                        'K tomuto VIN nelze dohledat záznamy z jeho historie.',
                                    )}{' '}
                                    <br></br>
                                    <a
                                        className="Link Link--lightBlue text-center"
                                        href={findVinUrl()}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {tran.GetText('Doporučujeme zkontrolovat VIN')}
                                    </a>
                                    {'.'}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-auto mt-20">
                            <div className="ButtonList justifyCenter">
                                <Button
                                    className="mr-80"
                                    as="button"
                                    variantColor="blue"
                                    variantSize="small"
                                    onClick={() => {
                                        window.location.assign('/')
                                    }}
                                >
                                    {tran.GetText('Prověřit jiné VIN')}
                                </Button>

                                <Button
                                    className="ml-80"
                                    as="button"
                                    variantColor="green"
                                    variantSize="small"
                                    onClick={() => {
                                        setAgreement(true)
                                        closeModal()
                                    }}
                                >
                                    {tran.GetText('Přesto prověřit')}
                                </Button>
                            </div>
                        </div>
                    </Container>
                </Modal>
            </Container>
        </Section>
    )
}
