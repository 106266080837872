import { ValuationControlData } from '../models/DetailPayedResults/Valuation/valuationControlModel'
import http from './http-common'

export const updateValuation = async (values: ValuationControlData) => {
    let retVal: ValuationControlData | undefined

    try {
        const response = await http.post('/payed/PostValuation', {
            vin: values.vin,
            firstReg: values.firstReg,
            tacho: values.tacho,
            model: values.model,
            requestId: values.requestId,
            isGdpr: values.isGdpr,
        })

        if (response.status === 200 && response.data) {
            retVal = response.data
        }
    } catch (e) {
        console.log(e)
    }

    return retVal
}
