import { ReactElement } from 'react'
import './index.styl'
import { ToggleOpenerGeneralVoucher } from '../../../../components/ToggleOpenerGeneralVoucher'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Table } from '../../../../components/Table'

export const SectionCarInsuranceAutoCheck = (): ReactElement => {
    const tran = new Translate()
    return (
        <>
            <div className="sectionCouponSales">
                <div className="Grid gmb-5">
                    <ToggleOpenerGeneralVoucher
                        href="#saleCarInsuranceAutocheck"
                        titleLeft="Chceli by ste dlhšiu záruku na vaše vozidlo?"
                        titleRight="Zľava 5%"
                        button="Detail"
                        isOpened={false}
                    />
                    <ToggleItemGeneral id="saleCarInsuranceAutocheck">
                        <div className="toggleContent">
                            <h3>Ochrana pred poruchami vozidla – Autocheck </h3>
                            <br />
                            <p className="text-justify">
                                Ideálne poistenie voči{' '}
                                <b>elektrickým aj mechanickým poruchám – AUTOCHECK</b>, vďaka
                                ktorému sa už nemusíte báť <b>vysokých nákladov za opravy</b> Vášho{' '}
                                <b>vozidla</b>.
                                <br />
                                <br />
                                Zaplatíme:{' '}
                                <ul>
                                    <li className="SectionHighlights-item">
                                        <b>opravu, ale aj výmenu súčiastky</b>
                                    </li>
                                    <li>
                                        vrátane <b>práce</b> a tiež
                                    </li>
                                    <li>
                                        <b>odťah</b> až do výšky <b>200 €</b>
                                    </li>
                                </ul>
                                <br></br>
                                Chcem vedieť viac o Autochecku:{' '}
                                <a
                                    href="https://www.colonnade.sk/pre-jednotlivcov/motorove-vozidla/autocheck"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Poistenie Autocheck od Colonnade
                                </a>
                            </p>

                            <h3>
                                Individuálna <b>ochrana v celej EÚ</b> šitá na mieru.
                            </h3>
                            <br />
                            <p>
                                Vyskladajte si poistenie podľa stavu kilometrov, dĺžky poistenia aj
                                limitu plnenia podľa vašej preferencie.
                            </p>
                            <br />
                            <h3>Ako získam zľavu?</h3>
                            <br />
                            <p className="text-justify">
                                V ponuke nižšie zadajte <b>kód CEBIA</b>. Automaticky odpočítame 5 %
                                z ceny.
                            </p>
                            <div className="ButtonList mb-10">
                                <a
                                    href="https://autocheck.colonnade.sk/?aff=CEBIA"
                                    target="_blank"
                                    className="Button Button--small"
                                    rel="noreferrer"
                                >
                                    <span className="Button-content">Získať nezáväznú ponuku</span>
                                </a>
                            </div>
                        </div>
                    </ToggleItemGeneral>
                </div>
            </div>
        </>
    )
}
