import './index.styl'
import { ReactElement, useState } from 'react'
import classNames from 'classnames'
import { VisualIcon, VisualIconProps } from '../VisualIcon'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import { DetailPayedRegItemDefinitionModel } from '../../models/PayedReg/DetailPayedRegItemDefinitionModel'
import { PayedJsonResult } from '../../models/DetailPayedResults/payedJsonResult'
import { Translate } from '../../translations/CebiaTranslator'
import moment from 'moment'
import { TextUtils } from '../../utils/textUtils'
import { AtrSection } from '../../enums/AtrSectionEnum'

export type PayedRegRowInfoType = {
    visualIcon: VisualIconProps
    state: 'active' | 'inactive'
    mainText: string
    countText: string
    periodVariant: 'date' | 'text'
    periodPrefix: string
    periodText: string
    helpText: string
    sectionId: number
}

export class PayedRegRowInfo {
    visualIcon!: VisualIconProps
    state!: 'active' | 'inactive'
    mainText!: string
    countText!: string
    periodVariant!: 'date' | 'text'
    periodPrefix!: string
    periodText!: string
    helpText!: string
    sectionId!: number
    order!: number
    crData!: boolean
}

export function getRowInfoFromModel(
    model: DetailPayedRegItemDefinitionModel,
    data: PayedJsonResult,
    textUtils: TextUtils,
    tran: Translate,
): PayedRegRowInfo {
    const result = new PayedRegRowInfo()
    result.crData = model.CrData
    result.sectionId = data.sectionId ?? 0
    result.mainText = model.Name
    result.helpText = model.RegDescription
    result.order = model.Order
    result.state = 'active'
    if (!data.enabled || !data.showSection) {
        result.state = 'inactive'
        result.countText = ''
    } else {
        if (!result.countText) result.countText = tran.GetText('K dispozici')
    }
    if ((data.recordsCount ?? 0) > 0) {
        result.countText =
            result.sectionId == 64
                ? tran.GetText('K dispozici')
                : textUtils.ZaznamyText(data.recordsCount)
    } else {
        result.state = 'inactive'
        result.countText = ''
    }
    if (result.state === 'active') {
        if (model.DefaultCountText.length > 0) result.countText = model.DefaultCountText
        if (model.DefaultPeriodPrefix.length > 0) result.periodPrefix = model.DefaultPeriodPrefix
        if (model.DefaultPeriodText.length > 0) result.periodText = model.DefaultPeriodText
    }

    if (model.VisualIcon) {
        result.visualIcon = model.VisualIcon
    }
    if (model.DateType && result.state === 'active') {
        result.periodVariant = 'date'
        result.periodPrefix = tran.GetText('období:')
        result.periodText =
            data?.minDate && data.maxDate
                ? moment(data?.minDate).format('YYYY') +
                  ' - ' +
                  moment(data?.maxDate).format('YYYY')
                : ''
    }
    if (result.sectionId === AtrSection.TachosDamages) {
        if (result.state === 'active') result.countText = tran.GetText('Poškození nalezeno')
        if (result.state === 'inactive') {
            result.countText = ''
            if (model.DefaultCountText.length > 0) result.countText = model.DefaultCountText
            if (model.DefaultPeriodPrefix.length > 0)
                result.periodPrefix = model.DefaultPeriodPrefix
            if (model.DefaultPeriodText.length > 0) result.periodText = model.DefaultPeriodText
        }
    }
    if (result.sectionId == AtrSection.RarHistory) {
        result.state = 'active'
        result.countText = tran.GetText('K dispozici')
    }
    return result
}

export const PayedRegInfo = ({
    state,
    visualIcon,
    mainText,
    countText,
    periodVariant,
    periodText,
    periodPrefix,
    helpText,
}: PayedRegRowInfoType): ReactElement => {
    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
        usePopperTooltip()
    const tran = new Translate()
    const className = classNames('PayedRegRowInfo', {
        [`Grid PayedRegRowInfo--${state}`]: state,
    })
    const classNameSmallHidden = classNames('')
    return (
        <>
            <div className={className}>
                <div className="Grid-cell smw:w-full md:w-full lg:w-1/12 justify-center xs-offset-icon">
                    <VisualIcon {...visualIcon} />
                </div>
                <div className="Grid-cell smw:w-full md:w-full lg:w-4/12 xs-offset-2">
                    <span className="font-bold">{mainText}</span>
                </div>
                {countText === '' && (
                    <div className="Grid-cell smw:w-full md:w-full lg:w-3/12 font-bold unavalaible xs-offset-2">
                        {tran.GetText('Není k dispozici')}
                    </div>
                )}
                {countText !== '' && (
                    <div className="Grid-cell smw:w-full md:w-full lg:w-3/12 font-bold avalaible xs-offset-2">
                        {countText}
                    </div>
                )}
                <div
                    className={
                        !periodText
                            ? 'Grid-cell smw:w-full md:w-full lg:w-3/12 font-bold xs-offset-2 xs-small-hidden'
                            : 'Grid-cell smw:w-full md:w-full lg:w-3/12 font-bold xs-offset-2'
                    }
                >
                    <span className="black pr-5">{periodPrefix}</span>&nbsp;{' '}
                    <span className="avalaible">{periodText}</span>
                </div>
                {helpText !== '' && (
                    <div className="Grid-cell smw:w-1/12 md:w-1/12 lg:w-1/12 justify-center xs-offset-helper">
                        <button className={`BubbleOpener ml-5`} type="button" ref={setTriggerRef}>
                            <span className="Icon Icon--questionmark" aria-hidden="true"></span>
                        </button>
                    </div>
                )}
                {visible && helpText !== '' && (
                    <div
                        ref={setTooltipRef}
                        {...getTooltipProps({ className: 'tooltip-container' })}
                    >
                        <div dangerouslySetInnerHTML={{ __html: helpText }}></div>
                        <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                    </div>
                )}
            </div>
        </>
    )
}
