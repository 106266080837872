import './index.styl'
import { ReactElement } from 'react'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate } from '../../../../translations/CebiaTranslator'
import { ToggleOpenerGeneralSimple } from '../../../../components/ToggleOpenerGeneralSimple'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import { SectionVehicleIdentification } from './Section/VehicleIdentification/vehicleIdentification'
import { SectionVehicleColor } from './Section/vehicleColor'
import { SectionVehicleSecurity } from './Section/vehicleSecurity'
import { SectionVehicleTechDesc } from './Section/vehicleTechDesc'
import { SectionVehicleEquipmentInfo } from './Section/vehicleEquipment'

export const SectionVehicleInfo = ({ sourceData }: DetailPayedProps): ReactElement => {
    if (!sourceData?.vehicleInfoControlResult) return <></>
    const tran = new Translate()
    return (
        <div className="sectionVehicleInfo">
            <div className="Grid gmb-5">
                {((sourceData?.vehicleInfoControlResult?.equipmentItems?.length ?? 0) > 0 ||
                    (sourceData?.vehicleInfoControlResult?.vehicleIdentItems?.length ?? 0) > 0) && (
                    <>
                        <ToggleOpenerGeneralSimple
                            href="#equipmentToggle"
                            titleLeft={tran.GetText('Seznam výbavy dle výrobce')}
                            titleRight={
                                sourceData.vehicleInfoControlResult?.vehicleIdentificationSubCaption
                            }
                            button={tran.GetText('Detail')}
                            isOpened={false}
                            visualIcon={{ name: 'gearCar' }}
                        />
                        <ToggleItemGeneral id="equipmentToggle">
                            <SectionVehicleEquipmentInfo
                                sourceData={sourceData.vehicleInfoControlResult}
                                tran={tran}
                            />
                        </ToggleItemGeneral>
                    </>
                )}
                {(sourceData.vehicleInfoControlResult?.vehicleColor?.length ?? 0) > 0 && (
                    <>
                        <ToggleOpenerGeneralSimple
                            href="#colorToggle"
                            titleLeft={tran.GetText('Kontrola barvy vozidla')}
                            titleRight={tran.GetText('K dispozici')}
                            button={tran.GetText('Detail')}
                            isOpened={false}
                            visualIcon={{ name: 'carPalete' }}
                        />
                        <ToggleItemGeneral id="colorToggle">
                            <SectionVehicleColor
                                sourceData={sourceData.vehicleInfoControlResult}
                                tran={tran}
                            />
                        </ToggleItemGeneral>
                    </>
                )}
                <ToggleOpenerGeneralSimple
                    href="#securityToggle"
                    titleLeft={tran.GetText('Kontrola zabezpečení vozidla')}
                    titleRight={tran.GetText('K dispozici')}
                    button={tran.GetText('Detail')}
                    isOpened={false}
                    visualIcon={{ name: 'carLock' }}
                />
                <ToggleItemGeneral id="securityToggle">
                    <SectionVehicleSecurity
                        sourceData={sourceData.vehicleInfoControlResult}
                        tran={tran}
                    />
                </ToggleItemGeneral>
                {(sourceData?.vehicleInfoControlResult?.vehicleTechDesc?.length ?? 0) > 0 && (
                    <>
                        <ToggleOpenerGeneralSimple
                            href="#techDescToggle"
                            titleLeft={tran.GetText('Údaje z technického průkazu')}
                            titleRight={tran.GetText('K dispozici')}
                            button={tran.GetText('Detail')}
                            isOpened={false}
                            visualIcon={{ name: 'clipboard' }}
                        />
                        <ToggleItemGeneral id="techDescToggle">
                            <SectionVehicleTechDesc
                                sourceData={sourceData.vehicleInfoControlResult}
                                tran={tran}
                            />
                        </ToggleItemGeneral>
                    </>
                )}
                <ToggleOpenerGeneralSimple
                    href="#techVehicleIdent"
                    titleLeft={tran.GetText('Návod na identifikaci vozu')}
                    titleRight={tran.GetText('K dispozici')}
                    button={tran.GetText('Detail')}
                    isOpened={false}
                    visualIcon={{ name: 'plateCheck' }}
                />
                <ToggleItemGeneral id="techVehicleIdent">
                    <SectionVehicleIdentification
                        sourceData={sourceData.vehicleInfoControlResult}
                        tran={tran}
                    />
                </ToggleItemGeneral>
            </div>
        </div>
    )
}
