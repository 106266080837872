import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import { PrintTachoStatesSections } from './tachoStatesSection'
import { Translate } from '../../../translations/CebiaTranslator'
import { PrintDataModel } from '../../../models/Prints/printDataModel'
import { useParams } from 'react-router'
import { useTachoStatesPrintData } from '../../../busLog/printBL'

export const PrintTachoStates = (): ReactElement => {
    const tran = new Translate()
    const { id } = useParams()
    const { data, isSuccess } = useTachoStatesPrintData(id!)
    const [tachoStates, setTachoStates] = useState(new PrintDataModel())

    useEffect(() => {
        if (isSuccess) {
            setInterval(() => {
                window.status = 'b6a82e9304682e967462d545d63822962884c86a723a64f13ee6c219d28fc6d1'
            }, 2000)
        }
    }, [isSuccess])

    return (
        <>
            {isSuccess && data?.data && (
                <PrintTachoStatesSections tran={tran} sourceData={data!.data!} />
            )}
        </>
    )
}
