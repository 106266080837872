import './index.styl'
import { ReactElement } from 'react'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Section } from '../../../../components/Section'

export const SectionPreviousAdvertZV = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="LockedSection">
            <div className="text-center pb-25">
                <h2 className="orange font-bold pt-10">
                    {tran.GetText('Kategorie je dostupná pouze v kompletním prověření')}
                </h2>
            </div>
            <p className="text-justify pb-25">
                {tran.GetHtml(
                    'Lze prověřit, zda bylo vozidlo v minulosti inzerováno či fotografováno. K nalezeným záznamům inzerce mohou být přiloženy detailní informace, jako datum inzerce, stav tachometru, inzerovaná cena nebo fotografie vozu. Pomocí historických fotografií vozidla můžete zkontrolovat, zda nebylo vozidlo v minulosti inzerováno jako havarované.',
                )}
            </p>
        </Section>
    )
}
