import { useEffect, useRef } from 'react'

const createRootElement = (id: string): Element => {
    const rootContainer = document.createElement('div')
    rootContainer.setAttribute('id', id)
    return rootContainer
}

const addRootElement = (rootElement: Element): void => {
    document.body.insertBefore(
        rootElement,
        document.body.lastElementChild?.nextElementSibling || null,
    )
}

export const usePortal = (id: string): Element => {
    const rootElementRef = useRef<Element>()

    useEffect(() => {
        if (!rootElementRef.current) {
            return
        }

        const existingParent = document.getElementById(id)
        const parentElement = existingParent || createRootElement(id)

        if (!existingParent) {
            addRootElement(parentElement)
        }

        parentElement.appendChild(rootElementRef.current)

        return () => {
            rootElementRef.current?.remove()
            if (!parentElement.childElementCount) {
                parentElement.remove()
            }
        }
    }, [id])

    const getRootElement = () => {
        if (!rootElementRef.current) {
            rootElementRef.current = document.createElement('div')
        }
        return rootElementRef.current
    }

    return getRootElement()
}
