import { ReactElement } from 'react'
import { Header } from '../../components/Header'
import { ReferenceType } from '../../enums/ReferenceType'
import { PageHeader } from '../../components/PageHeader'
import { PageShape } from '../../components/PageShape'
import { Translate } from '../../translations/CebiaTranslator'
import { Title } from '../../components/Title'
import { Footer } from '../../components/Footer'
import AssetsImgCebiaLobby from '../../pages/about/sections/SectionAboutContact/img/cebia-lobby.webp'
import AssetsImgCebiaLobby2x from '../../pages/about/sections/SectionAboutContact/img/cebia-lobby@2x.webp'
import { Container } from '../../components/Container'
import { Shapes } from '../../components/Shapes'
import { VisualBlock } from '../../components/VisualBlock'

export const PageImpressum = (): ReactElement => {
    const tran = new Translate()
    return (
        <>
            <div className="PageImpressum">
                <PageShape sectionShape={{ variant: 'radialLeftTop' }} />
                <Header showLanguage={false} referenceType={ReferenceType.Default} />
                <PageHeader>
                    {/* <Title as="h1" className="Beta color-darkBlue" variant="withDash">
                        Impressum
                    </Title> */}
                </PageHeader>
                <Container className="pb-60">
                    <VisualBlock
                        variant="elevated"
                        withShapes={true}
                        visualShape={{ variant: 'blueGradient' }}
                        visual={{
                            variant: '620x540',
                            image: {
                                src: AssetsImgCebiaLobby,
                                srcSet: `${AssetsImgCebiaLobby2x} 2x`,
                                alt: tran.GetText('Recepce Cebia'),
                            },
                        }}
                    >
                        <Title as="h2" variantAlign="left" className="Beta mb-20 color-darkBlue">
                            Impressum
                        </Title>
                        <b>Cebia, spol. s r.o.</b>
                        <br></br>
                        Sitz: Vyskočilova 1461/2a, 140 00 Prag 4, Tschechien<br></br>
                        E-Mail: info.de@cebia.com <br></br>Identifikationsnummer: 18628443<br></br>
                        Umsatzsteuer-ID: CZ18628443<br></br> Handelsregister: Eingetragen im
                        Handelsregister beim Stadtgericht in Prag, Abteilung C, Einlage 4057
                        <br></br>
                        Geschäftsführer: Ing. Martin Pajer
                    </VisualBlock>
                </Container>
                <Footer />
            </div>
        </>
    )
}
