export class CallingActionControlData {
    callActionDescription: string = ''
    isCallingAction: boolean = false
    callingActionItems: CallingActionItem[] | undefined
}

class CallingActionItem {
    decsription: string = ''
    created?: Date
    url: string = ''
}
