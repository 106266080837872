import './index.styl'
import { ReactElement, ReactNode } from 'react'
import classNames, { Argument } from 'classnames'

type InfoVariant = 'alert' | 'check'

type Props = {
    variant: InfoVariant
    withBg?: boolean
    withIcon?: boolean
    className?: Argument
    children?: ReactNode
    text?: string
}

const ICONS: Record<InfoVariant, string> = {
    alert: 'alert',
    check: 'check',
}

export const Info = ({
    variant,
    className: _className,
    withBg = false,
    withIcon = false,
    text,
    children,
}: Props): ReactElement => {
    const className = classNames(
        'Info',
        {
            [`Info--${variant}`]: variant,
            [`Info--withBg`]: withBg,
            [`Info--withIcon`]: withIcon,
        },
        _className,
    )

    return (
        <div className={className}>
            {withIcon && <span className={`Icon Icon--${ICONS[variant]}`}>{text}</span>}
            {children}
        </div>
    )
}
