import './index.styl'
import { ReactElement } from 'react'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Section } from '../../../../components/Section'

export const SectionDamageZV = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="LockedSection">
            <div className="text-center pb-25">
                <h2 className="orange font-bold pt-10">
                    {tran.GetText('Kategorie je dostupná pouze v kompletním prověření')}
                </h2>
            </div>
            <p className="text-justify">
                {tran.GetHtml(
                    'Vozidlo lze prověřit v databázích poškozených vozů z více než 32 zemí čítajících přes 200 milionů záznamů škodních událostí.',
                )}
            </p>
            <p className="text-justify pb-25">
                {tran.GetHtml(
                    'V kompletním prověření se dozvíte, zda je v těchto databázích vozidlo v jeho historii evidováno jako poškozené. K nalezeným záznamům poškození mohou být přiloženy detailní informace jako datum události, stav tachometru, rozsah poškození, výše škody nebo fotografie vozu.',
                )}
            </p>
        </Section>
    )
}
