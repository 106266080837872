import classNames from 'classnames'
import i18n from 'i18next'
import { ReactElement, useState } from 'react'
import { LanguageType } from '../../enums/LanguageType'
import { Environment, LanguageDefinition, LanguageEnum } from '../../translations/i18n'
import { Flag } from '../Flag'
import './index.styl'

export const LangSwitch = (): ReactElement => {
    const [language, setLanguage] = useState(i18n.language)

    const handleOnclick = (language: string, e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        const lang = language === 'ua' ? 'uk' : language
        setLanguage(lang)
        i18n.changeLanguage(lang)
        if (process.env.NODE_ENV !== Environment.devel)
            if (language === 'al') {
                window.location.replace(`https://${language}.${process.env.REACT_APP_AL_DOMAIN}`)
            } else {
                window.location.replace(`https://${language}.${process.env.REACT_APP_DOMAIN}`)
            }
    }

    document.documentElement.lang = language
    if (language === 'al' && process.env.NODE_ENV !== Environment.devel) return <></>
    const languageItem = (currentLanguage: LanguageEnum) => {
        if (
            LanguageDefinition[currentLanguage].invisibility.indexOf(
                process.env.REACT_APP_DOMAIN ?? '',
            ) !== -1 &&
            process.env.NODE_ENV !== Environment.devel
        )
            return <></>
        return (
            <a
                className={classNames('', {
                    'is-active': language === currentLanguage,
                })}
                href="#"
                onClick={e => handleOnclick(currentLanguage === 'uk' ? 'ua' : currentLanguage, e)}
            >
                <Flag countryCode={LanguageDefinition[currentLanguage].flag} />
                {LanguageDefinition[currentLanguage].displayName.toUpperCase()}
            </a>
        )
    }

    return (
        <div className="LangSwitch">
            <div className="LangSwitch-col">
                {languageItem(LanguageEnum.cz)}
                {languageItem(LanguageEnum.sk)}
                {languageItem(LanguageEnum.en)}
                {languageItem(LanguageEnum.de)}
                {languageItem(LanguageEnum.ro)}
                {languageItem(LanguageEnum.al)}
                {languageItem(LanguageEnum.uk)}
                {languageItem(LanguageEnum.it)}
                {languageItem(LanguageEnum.fr)}
                {languageItem(LanguageEnum.nl)}
            </div>
        </div>
    )
}
