import './index.styl'
import { ReactElement } from 'react'
import { ToggleOpener, ToggleOpenerProps } from '../ToggleOpener'

type Props = ToggleOpenerProps

export const ToggleOpenerFaqTab = ({ children, ...props }: Props): ReactElement => {
    return (
        <ToggleOpener className="ToggleOpenerFaqTab" {...props}>
            {children}
        </ToggleOpener>
    )
}
