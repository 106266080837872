import './index.styl'
import { createRef, ReactElement, useRef, useState } from 'react'
import { InputWithCounter } from '../InputWithCounter'
import { Button, ButtonProps } from '../Button'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import validate from '../../busLog/validationBL'
import { Form, Formik, FormikProps, useFormikContext } from 'formik'
import { useAppContext } from '../../contexts/AppContext'
import { CouponModel } from '../../models/couponModel'
import { useNavigate } from 'react-router'
import { Translate } from '../../translations/CebiaTranslator'
import * as Yup from 'yup'
import { FormItem } from '../FormItem'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { redirectDotazLang } from '../../utils/redirect'
import { InputChangeHandler } from '../Input'

type Variant = 'default' //| 'static' | 'fixed'

type Props = {
    variant: Variant
}

export const FormCoupon = ({ variant }: Props): ReactElement => {
    const appContext = useAppContext()
    const [searchParams] = useSearchParams()
    const { executeRecaptcha } = useGoogleReCaptcha()
    const formRef = createRef<FormikProps<CouponModel>>()
    const formModel = new CouponModel()
    const scoupon = searchParams.get('s_coupon')
    const [prevVal, setPrevVal] = useState('')
    const tran = new Translate()

    const validationSchema = () => {
        return Yup.object({
            coupon: Yup.string()
                .min(10, tran.GetText('Počet znaků musí být 10.'))
                .max(10, tran.GetText('Počet znaků musí být 10.'))
                .matches(/^\d+$/, tran.GetText('Číslo kuponu má nesprávný formát.'))
                .required(tran.GetText('Prosím zadejte položku Číslo kuponu.')),
        })
    }

    const validateCoupon = async (value: CouponModel) => {
        appContext.setLoading(true)
        if (!executeRecaptcha) {
            appContext.setLoading(false)
            return
        }

        value.CaptchaResponse = await executeRecaptcha('contact')
        const result = await validate.validateCoupon(value)

        if (result?.isValid === 1) {
            appContext.appModel.viewId = result.viewId
            if (result) {
                const countryId = result.countryId
                if (countryId) redirectDotazLang(countryId, `/payed/detail/${result.viewId}`, true)
            }
            appContext.setLoading(false)
            return
        } else {
            const couponElement = document.querySelector('#couponn') as HTMLElement
            if (couponElement) {
                couponElement.focus()
            }
            if (!result.message) result.message = tran.GetText('Nespravna hodnota kuponu')

            appContext.setLoading(false)
        }
        return result.message
    }

    useEffect(() => {
        if (
            scoupon &&
            formRef &&
            formRef.current &&
            !formRef.current.errors.coupon &&
            !formRef.current.isSubmitting
        ) {
            formModel.coupon = scoupon
            formRef.current.setValues(formModel)
            formRef.current.setFieldTouched('coupon', true)
        }
    }, [scoupon, formRef])

    useEffect(() => {
        async function submitFromUrl() {
            if (scoupon && executeRecaptcha) {
                if (!executeRecaptcha) {
                    appContext.setLoading(false)
                    return
                }
                if (executeRecaptcha) {
                    formModel.CaptchaResponse = await executeRecaptcha('contact')
                }
                if (
                    formRef &&
                    formRef.current &&
                    !formRef.current.errors.coupon &&
                    !formRef.current.isSubmitting
                ) {
                    appContext.setLoading(true)
                    await formRef.current.submitForm()
                }
            }
        }
        if (
            scoupon &&
            formRef &&
            formRef.current &&
            !formRef.current.errors.coupon &&
            !formRef.current.isSubmitting &&
            executeRecaptcha
        ) {
            submitFromUrl()
        }
    }, [formRef, executeRecaptcha])

    const handleInputChange: InputChangeHandler = (value, event) => {
        const re = /^\d+$/
        if (!value || re.test(value ?? '')) {
            setPrevVal(prevVal => value ?? '')
            event.currentTarget.value = value ?? ''
        } else event.currentTarget.value = prevVal ?? ''
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={formModel}
            validationSchema={validationSchema}
            onSubmit={async (values, { setFieldError }) => {
                const res = await validateCoupon(values)
                if ((res?.length ?? 0) > 0) setFieldError('coupon', res)
            }}
        >
            {formik => {
                return (
                    <Form className={`FormCoupon FormCoupon--${variant}`}>
                        <FormItem className="FormCoupon-input">
                            <InputWithCounter
                                maxLength={10}
                                initialerror={''}
                                label={tran.GetText('Číslo kuponu')}
                                id={'couponn'}
                                name={'coupon'}
                                variantSize={'desktopLarge'}
                                length={formModel.coupon.length}
                                showErrorMessage={false}
                                nogap={true}
                                inputmode="numeric"
                                onChange={handleInputChange}
                            />
                            {formik.touched.coupon && formik.errors.coupon && (
                                <div className="InputError">{formik.errors.coupon}</div>
                            )}
                        </FormItem>

                        <div className="FormCoupon-button">
                            <Button
                                as="button"
                                variantColor="blue"
                                variantSize="desktopLarge"
                                className="w-full"
                                icon="arrowRight"
                                type="submit"
                            >
                                {tran.GetText('Zobrazit')}
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}
