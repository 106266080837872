import http from './http-common'
import { SubscriberValues } from '../models/subscriberModel'

const create = (data: SubscriberValues) => {
    return http.post('/subscriber/reg', data)
}

// eslint-disable-next-line import/no-default-export
export default {
    create,
}
