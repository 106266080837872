import { IAtrSection } from '../../utils/arrayExtensions'
import { CallingActionControlData } from './CallingAction/callingActionControlModel'
import { CebiaReportControlData } from './CebiaReport/cebiaReportControlModel'
import { CheckLeaseControlData } from './CheckLeas/checkLeaseControlModel'
import { DamageControlData } from './Damage/damagesControlModel'
import { OtherControlData } from './OthersCebia/otherControlModel'
import { PhotoControlData } from './Photos/photosControlModel'
import { RokvyControlData } from './Rokvy/rokvyControlModel'
import { ServiceRecControlData } from './ServiceRec/serviceRecControlModel'
import { GraphControlData } from './Tacho/tachosControlModels'
import { TaxiControlData } from './Taxi/taxiControlModel'
import { TheftControlData } from './Theft/theftControlModel'
import { TimeLineData } from './TimeLine/timeLineControlModel'
import { ValuationControlData } from './Valuation/valuationControlModel'
import { VehicleInfoControlData } from './VehicleInfo/vehicleInfoControlModel'
import { LienRightsControlData } from './LienRights/lienRightsModel'

export class PayedJsonResult implements IAtrSection {
    sectionId: number | undefined
    recordsCount: number | undefined
    minDate!: Date | string
    maxDate!: Date | string

    status!: string
    navStatus?: string
    iconName!: string
    navIconName?: string
    subCaption!: string
    showSection!: boolean
    openedSection!: boolean
    useStickyMessage!: boolean
    enabled!: boolean
    isProcessed!: boolean
    isAllOk!: boolean

    tachoControlResult: GraphControlData | undefined
    damageControlResult: DamageControlData | undefined
    photoControlResult: PhotoControlData | undefined
    serviceControlResult: ServiceRecControlData | undefined
    rokvyControlResult: RokvyControlData | undefined
    cebiaReportControlResult: CebiaReportControlData | undefined
    theftControlResult: TheftControlData | undefined
    checkLeaseControlResult: CheckLeaseControlData | undefined
    taxiControlResult: TaxiControlData | undefined
    callingActionControlResult: CallingActionControlData | undefined
    valuationControlResult: ValuationControlData | undefined
    vehicleInfoControlResult: VehicleInfoControlData | undefined
    otherControlResult: OtherControlData | undefined
    timeLineControlResult: TimeLineData | undefined
    lienRightsControlResult: LienRightsControlData | undefined
}

export class DropDownItem {
    key = ''
    value = ''
}
