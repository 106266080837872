import { CSSProperties, RefObject, useEffect, useLayoutEffect, useState } from 'react'
import { queries } from '../../utils/queries'

type Props = {
    wrapperRef: RefObject<HTMLDivElement>
    pointCount: number
    pixelsPerPoint: number
    pixelsPerPointMobile: number
}

type OverflowDirection = {
    left: boolean
    right: boolean
}

type ReturnProps = {
    isOverflowReady: boolean
    overflowDirection: OverflowDirection | null
    isOverflow: boolean
    overflowChildStyle: CSSProperties
    checkOverflowDirection: () => void
}

export const useChartOverflow = ({
    wrapperRef,
    pointCount,
    pixelsPerPoint,
    pixelsPerPointMobile,
}: Props): ReturnProps => {
    const [isReady, setIsReady] = useState<boolean>(false)
    const [overflowDirection, setOverflowDirection] = useState<OverflowDirection | null>(null)
    const [isOverflow, setIsOverflow] = useState<boolean>(false)
    const [overflowChildStyle, setOverflowChildStyle] = useState<CSSProperties>({})

    const checkOverflowDirection = () => {
        if (!wrapperRef.current) {
            return
        }

        const element = wrapperRef.current
        const direction: OverflowDirection = {
            left: element.scrollLeft > 0,
            right: element.scrollLeft + element.offsetWidth < element.scrollWidth,
        }

        setOverflowDirection(direction)
    }

    useEffect(() => {
        checkOverflowDirection()
    }, [isOverflow])

    useLayoutEffect(() => {
        const resize = () => {
            if (!wrapperRef.current) {
                return
            }

            const _pixelsPerPoint = window.matchMedia(queries.mediumUp).matches
                ? pixelsPerPoint
                : pixelsPerPointMobile
            const desiredWidth = pointCount * _pixelsPerPoint //minimalne 100px per point, odhad, aby to vypadalo +- ok
            const offset = 100 //aby to nezalezlo jen par pixelu, tak radsi neresizovat

            if (wrapperRef.current.offsetWidth + offset > desiredWidth) {
                setIsOverflow(false)
                setOverflowChildStyle({})
            } else {
                setIsOverflow(true)
                setOverflowChildStyle({
                    width: desiredWidth,
                })
            }
        }

        resize()
        setIsReady(true)

        const handleResize = () => {
            resize()
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [wrapperRef])

    return {
        isOverflowReady: isReady,
        overflowDirection,
        isOverflow,
        overflowChildStyle,
        checkOverflowDirection,
    }
}
