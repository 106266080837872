import { ReactElement, useEffect, useRef } from 'react'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { PageHeader } from '../../components/PageHeader'
import { PageShape } from '../../components/PageShape'
import { Title } from '../../components/Title'
import { ReferenceType } from '../../enums/ReferenceType'
import { Translate } from '../../translations/CebiaTranslator'
import { SectionAboutCompany } from './sections/SectionAboutCompany'
import { SectionAboutComplaints } from './sections/SectionAboutComplaints'
import { SectionAboutContact } from './sections/SectionAboutContact'
import { SectionAboutCrossroad } from './sections/SectionAboutCrossroad'
import { SectionAboutHistory } from './sections/SectionAboutHistory'
import { SectionAboutIntro } from './sections/SectionAboutIntro'
import { SectionSeparator } from './sections/SectionSeparator'
import { ContactForm } from '../faq/ContactForm'

export const PageAbout = (): ReactElement => {
    const tran = new Translate()
    const contacts = useRef(null)
    const complaints = useRef(null)

    useEffect(() => {
        const currentLocation = window.location.href
        const hasAnchor = currentLocation.includes('#')

        if (hasAnchor) {
            const hasAnchorId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`

            const anchorComment = document.getElementById(hasAnchorId)
            if (anchorComment) {
                anchorComment.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [])

    return (
        <div className="PageAbout">
            <PageShape sectionShape={{ variant: 'radialLeftTop' }} />
            <Header showLanguage={false} referenceType={ReferenceType.Default} />
            <PageHeader>
                <Title as="h1" className="Beta color-darkBlue" variant="withDash">
                    {tran.GetText('O nás')}
                </Title>
            </PageHeader>

            <SectionAboutIntro />
            <SectionAboutCompany />
            <SectionAboutHistory />
            <SectionSeparator />
            <div id="contacts" ref={contacts}>
                <SectionAboutContact />
            </div>
            <div id="complaints" ref={complaints}>
                <SectionAboutComplaints />
            </div>
            <SectionAboutCrossroad />
            <ContactForm />
            <Footer />
        </div>
    )
}
