import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionLeasing } from '../../../../detailPaid/atr-items/SectionLeasing'
import './body.styl'

let PrintAllowIncrement = true

export const CheckLease = ({
    sourceData,
    tran,
    captionNumberRef,
}: PrintDataProps): ReactElement => {
    if (!sourceData?.checkLeaseControlResult) return <></>

    const checkLeaseData = new PayedJsonResult()
    checkLeaseData.checkLeaseControlResult = sourceData.checkLeaseControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola financování')}
            </h2>

            <Section className="CheckLeaseSection">
                <SectionLeasing sourceData={checkLeaseData} isPrint={true} />
            </Section>
        </div>
    )
}
