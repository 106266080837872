import classNames from 'classnames'
import { ReactElement } from 'react'
import { getCountries } from '../../busLog/enums_gen'
import { TimeLineObjectType } from '../../enums/TimeLineObjectEnum'
import {
    TimeLineDamages,
    TimeLineFirstReg,
    TimeLineItem,
    TimeLineRokvy,
    TimeLineThefts,
} from '../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { Translate } from '../../translations/CebiaTranslator'
import { format } from '../../utils/Number'
import { CountryCode } from '../AtrMileageTable/countryCodes'
import { getMonths } from '../AtrMileageTable/months'
import { Flag } from '../Flag'
import { TimeLineDegRegAbroadTable } from '../TimeLineDeRegAbroadTable'
import { TimeLineDeRegPermanentlyTable } from '../TimeLineDeRegPermanentlyTable'
import { TimeLineEmiseTable } from '../TimeLineEmiseTable'
import { TimeLineFirstRegTable } from '../TimeLineFirstRegTable'
import { TimeLineRegCzSkTable } from '../TimeLineRegCzSkTable'
import { TimeLineRokvyTable } from '../TimeLineRokvyTable'
import { TimeLineSTKTable } from '../TimeLineSTKTable'
import { ToggleItemMileageTableNote } from '../ToggleItemMileageTableNote'
import { ToggleOpenerGeneral } from '../ToggleOpenerGeneral'
import {
    navigateToElement,
    navigateToFirstElement,
    navigateToLastElement,
} from '../AtrTimeLineTable'
import { TimeLineRegWorldTable } from '../Timeline/TimeLineRegWorldTable'

type Props = {
    row: TimeLineItem
    id: string
    isPrint: boolean
}

export function getStickyPositionTop(): string | null {
    const ele = window.document.getElementsByClassName('Sticky-sticky')
    let decimalNumber: string | null = null
    if (ele) {
        const styles = ele[0].getAttribute('style')?.split(';')
        if (styles) {
            styles.forEach(element => {
                if (element.indexOf('top', 0) > 0) {
                    const regex = /(\d+\.\d+)/
                    const match = element.match(regex)
                    decimalNumber = match ? match[0] : null
                }
            })
        }
    }
    return decimalNumber
}

export function setStickyPositionTop(value: string | null) {
    const ele = window.document.getElementsByClassName('Sticky-sticky')
    if (!value) value = (window.scrollY - 670).toString()
    if (ele) {
        ele[0].setAttribute('style', `top: ${value}px`)
    }
}

export const AtrTimeLineTableRowItem = ({ row, id, isPrint }: Props): ReactElement => {
    const tmpDate = new Date(row.currentDate ?? '')
    const tran = new Translate()
    const countries = getCountries(tran)
    let timeLineObject = row.timeLineObject
    const type = timeLineObject?.timeLineObjectType as TimeLineObjectType
    switch (type) {
        case 0:
            timeLineObject = row.timeLineObject as TimeLineRokvy
            break
    }
    let showError = (row?.timeLineObject as TimeLineFirstReg)?.showError ?? false
    if (!showError) showError = (row?.timeLineObject as TimeLineThefts)?.showError ?? false
    if (!showError) showError = (row?.timeLineObject as TimeLineDamages)?.showError ?? false

    const className = classNames('u-colNoteRow d-flex', {
        'color-red': showError ? 'red' : '',
        notePrint: isPrint ? 'true' : '',
    })
    const classNamePrint = classNames('', {
        'color-red': showError ? 'red' : '',
    })
    const innerHTML = (
        <>
            {showError && (
                <>
                    <span className="Icon Icon--alert mt-2"></span>
                    <span>&nbsp;</span>
                </>
            )}
        </>
    )

    const getRowNoteTextId = (row: TimeLineItem) => {
        return `atrmileagetable-${id}`
    }
    const rowClassName = classNames('AtrTimeLineTable-rowHeader', {
        referer: row.linkVisible || row.anchorVisible ? 'true' : '',
    })
    const monthClassName = classNames('u-colMonth', {
        monthPrint: isPrint ? 'true' : '',
    })
    const kmClassName = classNames('u-colKm text-noWrap', {
        kmPrint: isPrint ? 'true' : '',
    })
    const countryClassName = classNames('u-colCountry', {
        countryPrint: isPrint ? 'true' : '',
    })
    const getTableDataArea = (row: TimeLineItem) => {
        if (row.timeLineObject == null) return null
        switch (row.timeLineObject?.timeLineObjectType) {
            case TimeLineObjectType.Rokvy:
                return <TimeLineRokvyTable isPrint={isPrint} row={row}></TimeLineRokvyTable>
                break
            case TimeLineObjectType.FirstReg:
                return <TimeLineFirstRegTable isPrint={isPrint} row={row}></TimeLineFirstRegTable>
                break
            case TimeLineObjectType.WorldReg:
                return <TimeLineRegWorldTable isPrint={isPrint} row={row}></TimeLineRegWorldTable>
                break
            case TimeLineObjectType.CzSkReg:
                return <TimeLineRegCzSkTable isPrint={isPrint} row={row}></TimeLineRegCzSkTable>
                break
            case TimeLineObjectType.DeRegAbroad:
                return (
                    <TimeLineDegRegAbroadTable
                        isPrint={isPrint}
                        row={row}
                    ></TimeLineDegRegAbroadTable>
                )
                break
            case TimeLineObjectType.DeRegPermanently:
                return (
                    <TimeLineDeRegPermanentlyTable
                        isPrint={isPrint}
                        row={row}
                    ></TimeLineDeRegPermanentlyTable>
                )
                break
            case TimeLineObjectType.STK:
                return <TimeLineSTKTable isPrint={isPrint} row={row}></TimeLineSTKTable>
                break
            case TimeLineObjectType.Emise:
                return <TimeLineEmiseTable isPrint={isPrint} row={row}></TimeLineEmiseTable>
                break
        }
    }
    const rowClick = (row: TimeLineItem, isPrint: boolean) => {
        if (row.linkVisible) {
            const href = `#${getRowNoteTextId(row)}`
            const element = document.getElementById(href)
            if (element) {
                element.click()
            }
        }
        if (row.anchorVisible) {
            let section = 'sekce2'
            const type = timeLineObject?.timeLineObjectType as TimeLineObjectType
            switch (type) {
                case TimeLineObjectType.Damages:
                    section = 'sekce2'
                    break
                case TimeLineObjectType.ServiceRecords:
                    section = 'sekce4'
                    break
                case TimeLineObjectType.Adverts:
                    section = 'sekce64'
                    break
                case TimeLineObjectType.Taxis:
                    section = 'sekce31'
                    break
                case TimeLineObjectType.Calling:
                    section = 'sekce38'
                    break
                case TimeLineObjectType.Thefts:
                    section = 'sekce33'
                    break
                case TimeLineObjectType.TechLicence:
                    section = 'sekce16'
                    break
            }
            navigateToLastElement(section, 'a')
            setTimeout(() => {
                if (
                    type == TimeLineObjectType.Taxis ||
                    type == TimeLineObjectType.Ocis ||
                    type == TimeLineObjectType.TechLicence
                ) {
                    navigateToFirstElement(row.linkValue, 'a')
                    if (type == TimeLineObjectType.TechLicence) {
                        setTimeout(() => {
                            const countryId =
                                countries.find(x => x.code2 == row.flagIconName)?.id ?? ''
                            navigateToFirstElement('vehicleTechDescToggle' + countryId, 'a')
                        }, 100)
                    }
                } else {
                    navigateToElement(row.linkValue, type)
                }
            }, 100)
        }
    }

    const renderDataArea = (row: TimeLineItem, isPrint: boolean) => {
        const tran = new Translate()

        if (row.linkVisible) {
            const href = `#${getRowNoteTextId(row)}`
            return (
                <ToggleOpenerGeneral
                    className="AtrTimeLineTable-state"
                    showArrowDown={!isPrint}
                    button={isPrint ? '' : tran.GetText('Detail')}
                    href={href}
                    isOpened={isPrint || row.isOpened}
                ></ToggleOpenerGeneral>
            )
        }
    }
    return (
        <article key={id} className={classNames('AtrTimeLineTable-row')}>
            <div
                className={rowClassName}
                onClick={() => {
                    rowClick(row, isPrint)
                }}
            >
                <span className={monthClassName}>
                    <span className="xl:hidden printMonthNumber">
                        {tmpDate &&
                            row.showMonth &&
                            ('0' + (tmpDate.getMonth() + 1)).slice(-2) + '/'}
                        {tmpDate && tmpDate.getFullYear()}
                    </span>
                    <span className="lgMax:hidden">
                        {tmpDate && row.showMonth && getMonths()[tmpDate.getMonth()]}
                    </span>
                </span>
                <span className={kmClassName}>
                    {row.km >= 0 && (
                        <strong>
                            {format(row.km).replace(',', ' ')} {tran.GetText('km')}
                        </strong>
                    )}
                </span>

                <span className={countryClassName}>
                    {row.flagIconName && row.flagIconName.length > 0 && (
                        <>
                            <Flag countryCode={row.flagIconName as CountryCode} className="mr-5" />
                            <span className="countryFlagIconName xl:hidden xxl:hidden">
                                {row.flagIconName.toUpperCase()}
                            </span>
                            <span className="countryName lgMax:hidden">
                                {countries.find(x => x.code2 == row.flagIconName)?.name ??
                                    row.countryName}
                            </span>
                        </>
                    )}
                </span>

                <span className={isPrint ? classNamePrint : className}>
                    {showError && innerHTML}
                    {row.comment ? row.comment : row.linkCaption}
                </span>
                {row.linkCaption && row.linkVisible && (
                    <span className="u-colAnchor">{renderDataArea(row, isPrint)}</span>
                )}
                {!row.linkCaption && !isPrint && (
                    <>
                        <span className="u-colAnchor">
                            <a className="ToggleOpenerGeneral AtrTimeLineTable-state">
                                <span className="ToggleOpenerGeneral-button"></span>
                            </a>
                        </span>
                    </>
                )}
                {row.anchorVisible && !isPrint && (
                    <span className="u-colAnchor">
                        <a
                            onClick={e => {
                                let section = 'sekce2'
                                const type =
                                    timeLineObject?.timeLineObjectType as TimeLineObjectType
                                switch (type) {
                                    case TimeLineObjectType.Damages:
                                        section = 'sekce2'
                                        break
                                    case TimeLineObjectType.ServiceRecords:
                                        section = 'sekce4'
                                        break
                                    case TimeLineObjectType.Adverts:
                                        section = 'sekce64'
                                        break
                                    case TimeLineObjectType.Taxis:
                                        section = 'sekce31'
                                        break
                                    case TimeLineObjectType.Calling:
                                        section = 'sekce38'
                                        break
                                    case TimeLineObjectType.Thefts:
                                        section = 'sekce33'
                                        break
                                }
                                navigateToLastElement(section, 'a')
                                setTimeout(() => {
                                    if (type == TimeLineObjectType.Taxis) {
                                        navigateToFirstElement(row.linkValue, 'a')
                                    } else {
                                        navigateToElement(row.linkValue, type)
                                    }
                                }, 100)
                            }}
                            className="ToggleOpenerGeneral AtrTimeLineTable-state  "
                        >
                            <span className="ToggleOpenerGeneral-button">
                                {tran.GetText('Detail')}
                            </span>
                        </a>
                    </span>
                )}
            </div>
            <ToggleItemMileageTableNote id={getRowNoteTextId(row)}>
                <div>{getTableDataArea(row)}</div>
            </ToggleItemMileageTableNote>
        </article>
    )
}
