import { FormikContextType } from 'formik'
import { useQuery } from 'react-query'
import {
    VehicleIdentification,
    VehicleInfoControlData,
} from '../models/DetailPayedResults/VehicleInfo/vehicleInfoControlModel'
import http from './http-common'

export const useCarIdentDropDownData = (
    formData: FormikContextType<VehicleIdentification>,
    dropDownTypeChanged: string,
) => {
    return useQuery(
        [
            'dropDownData',
            formData?.values?.druhId,
            formData?.values?.make,
            formData?.values?.model,
            dropDownTypeChanged,
        ],
        () => {
            return http.post('/CarIdentification/GetDropDownData', {
                druhId: formData.values.druhId,
                make: formData.values.make,
                model: formData.values.model,
                typ: dropDownTypeChanged.substring(0, dropDownTypeChanged.indexOf('_')),
            })
        },
        {
            enabled:
                !!(formData?.values?.druhId || formData?.values?.make || formData?.values?.model) &&
                (dropDownTypeChanged?.length ?? 0) > 0,
            refetchOnMount: false,
        },
    )
}

export const useCarIdentPhotoData = (photosCarDef: string) => {
    return useQuery(
        ['carPhotoData', photosCarDef],
        () => {
            const carDefs = photosCarDef.split('|')
            return http.post('/CarIdentification/GetPhotoSpecification', {
                druhId: 0,
                make: carDefs[0],
                model: carDefs[1],
                typDetail: carDefs[2],
                typ: '_',
            })
        },
        {
            enabled: (photosCarDef?.length ?? 0) > 0,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    )
}

export const updateVehicleIdentification = async (values: VehicleIdentification) => {
    let retVal: VehicleInfoControlData | undefined

    try {
        const response = await http.post('/payed/PostVehicleIdentification', {
            druhId: values.druhId,
            make: values.make,
            model: values.model,
            typ: values.typ,
            requestId: values.requestId,
            isGdpr: values.isGdpr,
        })

        if (response.status === 200 && response.data) {
            retVal = response.data
        }
    } catch (e) {
        console.log(e)
    }

    return retVal
}
