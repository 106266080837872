/* eslint-disable */

import Emitter from '../Emitter'
import throttle from 'lodash/throttle'

export const STATES = {
    DISABLED: 'is-scrolling-disabled',
}

export class NativeScroll extends Emitter {
    constructor() {
        super()

        this.window = {
            height: window.innerHeight,
            width: window.innerWidth,
        }
        this.offset = {
            x: 0,
            y: 0,
        }
        this.limit = {
            x: 0,
            y: 0,
        }
        this.velocity = {
            x: 0,
            y: 0,
            time: 0,
        }
        this.shouldUpdate = {
            offset: true,
            limit: true,
            velocity: true,
        }

        this.scrollElementBox = {} //cache getBoundingClientRect scrollovanyho elementu

        this.stateBeforeDisable = 0
        this.disableScrollCounter = 0
        this.isAttached = false
        this.isVirtual = false
        this.scrollToService = new ScrollToService()

        this.handleResize = throttle(this.handleResize, 50)
        window.addEventListener('resize', this.handleResize)
    }

    destroy() {
        window.removeEventListener('resize', this.handleResize)
    }

    attach() {
        if (this.isAttached) {
            return
        }

        window.addEventListener('scroll', this.handleScroll, { passive: true })
        this.isAttached = true
    }

    detach() {
        if (!this.isAttached) {
            return
        }

        window.removeEventListener('scroll', this.handleScroll, { passive: true })
        this.isAttached = false
    }

    useNative() {
        this.attach()
    }

    async useVirtual() {
        console.error('Není naimportovaný virtual scroll v Scroll/index.js')
    }

    handleResize = () => {
        this.resize()
    }

    handleScroll = () => {
        this.render()
    }

    resize() {
        const _window = {
            height: window.innerHeight,
            width: window.innerWidth,
        }
        if (_window.width !== this.window.width) {
            this.emit('resize-x')
        }
        if (_window.height !== this.window.height) {
            this.emit('resize-y')
        }
        this.window = _window

        this.shouldUpdate.offset = true
        this.shouldUpdate.limit = true
        this.emit('resize')
    }

    render() {
        this.shouldUpdate.offset = true

        this.emit('scroll', this.getScroll())
    }

    disableScroll() {
        this.disableScrollCounter += 1

        if (this.disableScrollCounter > 1) {
            return
        }

        this.stateBeforeDisable = this.getScroll()
        document.body.style.top = `${-this.stateBeforeDisable.y}px`
        document.documentElement.classList.add(STATES.DISABLED)
    }

    enableScroll(force = false) {
        if (this.disableScrollCounter === 0) {
            return
        }

        if (force) {
            this.disableScrollCounter = 0
        } else {
            this.disableScrollCounter -= 1

            if (this.disableScrollCounter > 0) {
                return
            }
        }

        document.body.removeAttribute('style')
        document.documentElement.classList.remove(STATES.DISABLED)
        document.body.offsetWidth
        window.scrollTo(this.stateBeforeDisable.x, this.stateBeforeDisable.y)
    }

    getOffset() {
        if (this.shouldUpdate.offset) {
            this.offset = {
                x: document.body.scrollLeft || document.documentElement.scrollLeft || 0,
                y: document.body.scrollTop || document.documentElement.scrollTop || 0,
            }
            this.shouldUpdate.offset = false
        }

        return this.offset
    }

    getVelocity() {
        //native scroll zatim nema implementaci pro velocity,
        //zatim se updatuje pouze ve virtualnim, v handleVirtualScroll
        return this.velocity
    }

    getLimit() {
        if (this.shouldUpdate.limit) {
            const width = document.body.offsetWidth || document.documentElement.offsetWidth || 0
            const height = document.body.offsetHeight || document.documentElement.offsetHeight || 0
            this.limit = {
                x: width - this.window.width, //kvuli scrollbar se spatne pocita, je negative, az bude nekde potreba, tak by se to mohlo fixnout
                y: height - this.window.height,
            }
            this.shouldUpdate.limit = false
        }
        return this.limit
    }

    getScroll() {
        return {
            ...this.getOffset(),
            offset: this.getOffset(), //backwards compatibility
            velocity: this.getVelocity(),
            limit: this.getLimit(),
        }
    }

    setPosition(x, y) {
        document.body.scrollLeft = x
        document.documentElement.scrollLeft = x
        document.body.scrollTop = y
        document.documentElement.scrollTop = y
    }

    getScrollOffset() {
        //mozno predefinovat podle projektu
        return 0
    }

    scrollToElement(element, options) {
        options = {
            animate: true,
            mode: 'force',
            ...options,
        }

        this.scrollElementBox = element.getBoundingClientRect()

        if (options.mode === 'auto' && this.isElementInView(element)) {
            return
        }

        let offset = options.offset ? options.offset : this.getScrollOffset()
        if (options.mode === 'auto' || options.mode === 'force-center') {
            offset = offset + this.getOffsetToCenterElement(element)
        }

        if (options.animate) {
            this.scrollToService.scrollTo(this.getScroll().y + this.scrollElementBox.top - offset)
        } else {
            this.setPosition(0, this.getScroll().y + this.scrollElementBox.top - offset)
        }
    }

    isElementInView(element) {
        //mozna brat v potaz getScrollOffset?
        if (this.scrollElementBox.top < 0) {
            return false //moc vysoko
        }

        if (this.scrollElementBox.top > (this.window.height * 3) / 5) {
            return false //moc nizko
        }

        return true
    }

    getOffsetToCenterElement(element) {
        const offset = this.getScrollOffset()
        const remaningSpace = Math.max(
            0,
            this.window.height - offset - this.scrollElementBox.height,
        )
        return remaningSpace / 3
    }
}

class ScrollToService {
    constructor() {
        this.UP = -1
        this.DOWN = 1

        this.friction = 0.7
        this.acceleration = 0.04

        this.positionY = 100
        this.velocityY = 0
        this.targetPositionY = 400

        this.raf = null

        window.addEventListener(
            'mousewheel',
            () => {
                if (this.raf) {
                    cancelAnimationFrame(this.raf)
                    this.raf = null
                }
            },
            { passive: true },
        )
    }

    getScrollTop() {
        return document.body.scrollTop || document.documentElement.scrollTop
    }

    animate() {
        const distance = this.getCurrentDistance()
        this.render()

        if (Math.abs(distance) > 0.1) {
            this.raf = requestAnimationFrame(() => this.animate())
        }
    }

    getCurrentDistance() {
        const distance = this.targetPositionY - this.positionY
        const attraction = distance * this.acceleration

        this.velocityY += attraction

        this.velocityY *= this.friction
        this.positionY += this.velocityY

        return distance
    }

    render() {
        window.scrollTo(0, this.positionY)
    }

    scrollTo(offset, callback) {
        this.positionY = this.getScrollTop()
        this.targetPositionY = offset
        this.velocityY = 0
        this.animate()
    }
}

export default new NativeScroll()
