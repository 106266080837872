import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Hamburger } from '../Hamburger'
import { useNavigation } from '../../hooks'
import { Scroll } from '../../services/Scroll'

type Props = {
    variant?: 'static'
}

export const NavOpener = ({ variant }: Props): ReactElement => {
    const { isOpen, toggle } = useNavigation()
    const [isPinned, setIsPinned] = useState(false)

    const className = classNames('NavOpener', {
        [`NavOpener--${variant}`]: variant,
        'is-active': isOpen,
        'is-pinned': isPinned,
    })

    useEffect(() => {
        if (variant === 'static') {
            return
        }

        const handleScroll = () => {
            const { offset } = Scroll.getScroll()

            if (offset.y > 400 && !isPinned) {
                setIsPinned(true)
            }

            if (offset.y < 400 && isPinned) {
                setIsPinned(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [variant, isPinned])

    return (
        <button type="button" className={className} onClick={() => toggle()}>
            <span className="NavOpener-label">Menu</span>
            <Hamburger />
        </button>
    )
}
