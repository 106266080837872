import './index.styl'

import { Translate, _ } from '../../../../translations/CebiaTranslator'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { PayedStateModel } from '../../../../models/payedResponseModel'
import { ReferenceType } from '../../../../enums/ReferenceType'
import moment from 'moment'
import { i18n } from '../../../../translations/i18n'

type Props = {
    isDemo: boolean | undefined
    referenceType: ReferenceType
    stateModel: PayedStateModel | undefined
}

export const SectionHeaderCaption = ({
    isDemo,
    referenceType,
    stateModel,
}: Props): ReactElement => {
    let captionBanner
    const tran = new Translate()
    const navigate = useNavigate()
    if (isDemo) {
        captionBanner = (
            <>
                <h1 className="Beta color-darkBlue">
                    {referenceType !== ReferenceType.SmartCode
                        ? tran.GetText('Ukázka prověření')
                        : tran.GetText('Ukázka prověření vozidla')}
                </h1>
                <p>
                    {referenceType !== ReferenceType.SmartCode
                        ? tran.GetText(
                              'Rozsah a cena prověření se může u každého vozidla lišit podle množství dostupných informací.',
                          )
                        : tran.GetText(
                              'Množství dostupných informací je u každého vozidla rozdílné',
                          )}
                </p>
            </>
        )
    } else {
        captionBanner = (
            <>
                <div
                    className={`w-full md:w-12/12 SectionHeaderCaption${
                        referenceType == ReferenceType.SmartCode ? ' Center' : ''
                    }`}
                >
                    <div className="d-flex w-full mr-1">
                        {referenceType == ReferenceType.PayedReg && (
                            <>
                                <a
                                    className="NoneUnderline ArrowLeft font-normal mr-20"
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault()
                                        window.location.assign(
                                            `${process.env.PUBLIC_URL}/PayedReg/Index?s_url=Index&dotaz_id=${stateModel?.viewId}#ov`,
                                        )
                                    }}
                                >
                                    {tran.GetText('Zpět')}
                                </a>
                            </>
                        )}
                        {referenceType == ReferenceType.SmartCode ? (
                            <h1 className="CaptionStyle">
                                {tran.GetText('Základní prověření vozidla ke dni:')}{' '}
                                {moment(stateModel?.couponActivation).format('D.M.YYYY')}
                            </h1>
                        ) : referenceType == ReferenceType.PayedReg ? (
                            <h1 className="CaptionStyle m-auto pr-50">
                                {tran.GetText('Výsledek prověření historie vozidla')}
                            </h1>
                        ) : (
                            <h1 className="CaptionStyle m-auto">
                                {tran.GetText('Výsledek prověření historie vozidla')}
                            </h1>
                        )}
                    </div>
                    {/* {referenceType == ReferenceType.PayedReg && i18n.language === 'cz' && (
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault()
                                window.location.assign(
                                    `${process.env.PUBLIC_URL}/PayedReg/Index?s_url=Index&dotaz_id=${stateModel?.viewId}#cr`,
                                )
                            }}
                        >
                            {tran.GetText('Cebia REPORT')}
                        </a>
                    )} */}
                </div>
            </>
        )
    }

    return <>{captionBanner}</>
}
