import classNames from 'classnames'
import { ReactElement } from 'react'
import Moment from 'react-moment'
import {
    TimeLineFirstReg,
    TimeLineItem,
} from '../../models/DetailPayedResults/TimeLine/timeLineControlModel'
import { Translate } from '../../translations/CebiaTranslator'

type Props = {
    row: TimeLineItem
    isPrint: boolean
}

export const TimeLineFirstRegTable = ({ row, isPrint }: Props): ReactElement => {
    const item = row.timeLineObject as TimeLineFirstReg
    const tran = new Translate()
    const tableClasses = classNames('Table', { ['noBackground line2rem']: isPrint })
    return (
        <>
            <div className={tableClasses}>
                <table>
                    <tbody>
                        <tr>
                            <td>{tran.GetText('Datum 1. registrace vozidla')}:</td>
                            <td>
                                <Moment format="DD. MM. YYYY">{item.firstReg}</Moment>
                            </td>
                        </tr>
                        {(item?.fromManifacture?.length ?? 0) > 0 && (
                            <tr>
                                <td>{tran.GetText('Doba od výroby po 1. registraci')}:</td>
                                <td>{item.fromManifacture}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {item.showError && <span className="warning normal">{item.error}</span>}
        </>
    )
}
