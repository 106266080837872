import { ReactElement, useEffect } from 'react'
import { PrintDataProps, PrintSectionInc } from '../..'
import { Section } from '../../../../../components/Section'
import { PayedJsonResult } from '../../../../../models/DetailPayedResults/payedJsonResult'
import { SectionTheft } from '../../../../detailPaid/atr-items/SectionTheft'
import './body.styl'

let PrintAllowIncrement = true

export const Theft = ({ sourceData, tran, captionNumberRef }: PrintDataProps): ReactElement => {
    if (!sourceData?.theftControlResult) return <></>

    const theftData = new PayedJsonResult()
    theftData.theftControlResult = sourceData.theftControlResult

    if (PrintAllowIncrement) {
        captionNumberRef!.current += PrintSectionInc
        PrintAllowIncrement = false
    }

    return (
        <div className="pba">
            <h2>
                {captionNumberRef!.current}. {tran.GetText('Kontrola odcizení')}
            </h2>

            <Section className="TheftSection">
                <SectionTheft sourceData={theftData} isPrint={true} />
            </Section>
        </div>
    )
}
