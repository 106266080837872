import './index.styl'
import { ReactElement, ReactNode } from 'react'

type Props = {
    children?: ReactNode
}

export const PageHeader = ({ children }: Props): ReactElement => {
    return <div className="PageHeader">{children}</div>
}
